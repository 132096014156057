import React from "react";
import PhenomId from "../../../requests/phenom-id";
import { PhenomLabel, ColorTextCollapsible } from "../../util/stateless";
import NavTree from "../../tree/NavTree";
import { BasicAlert } from "../../dialog/BasicAlert";
import { getShortenedStringRepresentationOfXmiType } from "../../util/util";
import { Checkbox } from '@progress/kendo-react-inputs';
import { _ajax } from "../../../requests/sml-requests";

class MergeSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        creations: {},
        edits: {},
        deletions: {},
        moves: {},

        hideCheckbox: false,
    }
  }

  // ------------------------------------------------------------
  // Life Cycle Methods
  // ------------------------------------------------------------

  componentDidMount() {
    const { page } = this.props
    if (["push", "update-review"].includes(page)) this.fetchMergeNodes();

    window.addEventListener('ADD_MERGE_CANDIDATES', this.setMergeCandidates);
    window.addEventListener('CHECKED_CANDIDATES', this.refresh);
  }

  componentWillUnmount() {
    window.removeEventListener('ADD_MERGE_CANDIDATES', this.setMergeCandidates);
    window.removeEventListener('CHECKED_CANDIDATES', this.refresh);
  }


  // ------------------------------------------------------------
  // Setters
  // ------------------------------------------------------------

  refresh = () => {
    this.forceUpdate();
  }

  setHideCheckbox = (bool) => {
    this.setState({hideCheckbox: bool});
  }

  fetchMergeNodes = () => {
    this.clearMergeSummary();
  }

  clearMergeSummary = () => {
    this.setState({
      creations: {},
      edits: {},
      deletions: {},
      moves: {},
    });
  }

  handleCheckCategory = (e, mergeType) => {
    e.syntheticEvent.stopPropagation();
    const mergeTypeNodes = this.state[mergeType];
    const isCheck = !this.getCategorySelected(mergeType);
    const selectNodes = [];

    Object.keys(mergeTypeNodes).forEach((xmiType) => {
      mergeTypeNodes[xmiType].forEach((node) => selectNodes.push(node.getGuid()));
    });
    
    NavTree.selectMergeCandidates(selectNodes, isCheck);
  }

  handleCheckXmiType = (mergeType, xmiType) => {
    const mergeNodes = this.state[mergeType][xmiType];
    const isCheck = !mergeNodes.every(node => node.isChecked())

    NavTree.selectMergeCandidates(mergeNodes.map(n => n.getGuid()), isCheck);
  }

  setMergeCandidates = (e) => {
    if(e?.detail?.hideCheckbox) this.setHideCheckbox(true);

    const mergeCandidates = NavTree.getAllMergeCandidates();
    const creations = {}, edits={}, moves={}, deletions={};

    Array.from(mergeCandidates).forEach((leaf) => {
      const xmiType = leaf.getXmiType();
      switch (true) {
        case !!leaf.config.isDeletedForMerge:
          (deletions[xmiType] = deletions[xmiType] || []).push(leaf);
          break;
        case !!leaf.config.isMovedOutForMerge:
          (moves[xmiType] = moves[xmiType] || []).push(leaf);
          break;
        case !!leaf.config.isEditedForMerge:
          (edits[xmiType] = edits[xmiType] || []).push(leaf);
          break;
        default:
          if (!NavTree.isMovedInNode(leaf.getGuid())) {
            (creations[xmiType] = creations[xmiType] || []).push(leaf);
          }
      }
    });
    this.setState({ creations, edits, deletions, moves });
  }


  // ------------------------------------------------------------
  // Getters
  // ------------------------------------------------------------

  getCategoryCount = (mergeTypeNodes) => {
    return Object.values(mergeTypeNodes).reduce((a, v) => a + v.length, 0);
  }

  getCategorySelected = (mergeType) => {
    const mergeTypeNodes = this.state[mergeType];
    const allSelected = Object.keys(mergeTypeNodes).every((xmiType) => mergeTypeNodes[xmiType].every(node => node.isChecked()));

    return allSelected;
  }


  // ------------------------------------------------------------
  // Render Methods
  // ------------------------------------------------------------

  renderCollapsableContent = (mergeType) => {
    const { hideCheckbox } = this.state;
    const mergeTypeNodes = this.state[mergeType];
    const phenomId = new PhenomId("merge-summary");
    return Object.keys(mergeTypeNodes).length === 0 ?
      false :
      <div id={phenomId.gen(["merge-nodes",mergeType],"wrapper")}>
        {Object.keys(mergeTypeNodes).map((xmiType) => {  
          const allChecked = mergeTypeNodes[xmiType].every(node => node.isChecked());

          return <>
            <span onClick={(e) => e.stopPropagation()}
                  style={{ display: "block", margin: "10px 0px 10px 45px" }}>
              {hideCheckbox ? 
                <span>{getShortenedStringRepresentationOfXmiType(xmiType)}</span> 
              :
                <Checkbox label={getShortenedStringRepresentationOfXmiType(xmiType)}
                          id={phenomId.gen(["collapsable-checkbox",mergeType], xmiType)}
                          checked={allChecked}
                          hideCheckbox={hideCheckbox}
                          onClick={(e) => this.handleCheckXmiType(mergeType, xmiType)} />
              }
              {mergeTypeNodes[xmiType].map((node) => {
                return <p style={{ margin: "0 30px" }}>{node.getName()}</p>
              })}
            </span>
          </>
        })}
      </div>
  }

  render() {
    const { creations, deletions, moves, edits, hideCheckbox } = this.state;
    const phenomId = new PhenomId("merge-summary",this.props.idCtx);

    return <div id={phenomId.gen("","wrapper")}>
      <PhenomLabel text="Merge Summary"/>
      <ColorTextCollapsible headerText={"Node Creations"} 
                        color={"#4f9c54"}
                        count={this.getCategoryCount(creations)}
                        content={this.renderCollapsableContent("creations")}
                        contentId={phenomId.gen(["merge-nodes","creations"],"wrapper")}
                        noToggle={this.getCategoryCount(creations) === 0}
                        onCheck={(e) => this.handleCheckCategory(e, "creations")}
                        checked={this.getCategorySelected("creations")}
                        hideCheckbox={hideCheckbox} />
      <ColorTextCollapsible headerText={"Node Edits"} 
                        color={"#489bb3"}
                        count={this.getCategoryCount(edits)}
                        content={this.renderCollapsableContent("edits")}
                        contentId={phenomId.gen(["merge-nodes","edits"],"wrapper")}
                        noToggle={this.getCategoryCount(edits) === 0} 
                        onCheck={(e) => this.handleCheckCategory(e, "edits")}
                        checked={this.getCategorySelected("edits")}
                        hideCheckbox={hideCheckbox} />
      <ColorTextCollapsible headerText={"Node Deletions"} 
                        color={"#8b0000"}
                        count={this.getCategoryCount(deletions)}
                        content={this.renderCollapsableContent("deletions")}
                        contentId={phenomId.gen(["merge-nodes","deletions"],"wrapper")}
                        noToggle={this.getCategoryCount(deletions) === 0}
                        onCheck={(e) => this.handleCheckCategory(e, "deletions")}
                        checked={this.getCategorySelected("deletions")}
                        hideCheckbox={hideCheckbox} />
      <ColorTextCollapsible headerText={"Node Moves"} 
                        color={"#833b83"}
                        count={this.getCategoryCount(moves)}
                        content={this.renderCollapsableContent("moves")}
                        contentId={phenomId.gen(["merge-nodes","moves"],"wrapper")}
                        noToggle={this.getCategoryCount(moves) === 0}
                        onCheck={(e) => this.handleCheckCategory(e, "moves")}
                        checked={this.getCategorySelected("moves")}
                        hideCheckbox={hideCheckbox} />
    </div>;
  }
}
  
export default MergeSummary