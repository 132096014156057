import React, {Component} from "react";
import {Notifications, Notifications2} from "../notifications";
import {CadetInput, CadetTextArea, LineLabel, PackageComboBox} from "../../util/stateless";
import {NodeHistory2} from "../node-history";
import {getNodesOfType, modelRemoveNode, smmSaveNodes} from "../../../requests/sml-requests";
import {NavLink} from "react-router-dom";
import {Grid, GridColumn as Column, GridNoRecords} from "@progress/kendo-react-grid";
import { Button, Toolbar, ToolbarItem } from "@progress/kendo-react-buttons";
import {orderBy} from "@progress/kendo-data-query";
import $ from "jquery";
import {BasicConfirm} from "../../dialog/BasicConfirm";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import PhenomId from "../../../requests/phenom-id";
import {withPageLayout} from "../node-layout";
import DeletionConfirm2 from "../../dialog/DeletionConfirm2";
import { cloneDeep } from "lodash";
import { deGuidify, createPhenomGuid } from "../../util/util";
import { createNodeUrl } from "../../../requests/type-to-path";
import { getActiveChangeSetId } from "../../../requests/actionCreators";
import ChangeSetPicker from "../../widget/ChangeSetPicker";
import NavTree from "../../tree/NavTree";
import { _ajax } from "../../../requests/sml-requests";
import NodeDetails from "../node-details";


export class MainProgramManager extends Component {
    constructor(props) {
        super(props);

        this.noticeRef = undefined;
        this.original = undefined;
        this.uopis = {};
    }

    emptyValues = {
        name: "",
        guid: "",
        description: "",
        children: [],
        running: [],
        runningIn: null,
    };

    state = {
        ...cloneDeep(this.emptyValues),
        processingElements: [],
        uopiOptions: [],
        parent: undefined,
    };

    parentTypes = ["skayl:DeploymentModel"];

    componentDidMount() {
        this.loadData(this.props.match.params.guid);
        this.setProcessingElement();
        window.addEventListener('MOVED_NODES', this.mutateOriginalParentListener);
    }

    componentDidUpdate(prevProps) {
        const currGuid = this.props.match.params.guid;
        if (currGuid !== prevProps.match.params.guid) {
            this.loadData(currGuid);
        }
    }

    componentWillUnmount() {
      window.removeEventListener('MOVED_NODES', this.mutateOriginalParentListener);
    }

    setProcessingElement() {
      getNodesOfType("pedm:ProcessingElement").then((res) => {
          this.setState({
              processingElements: res.data.nodes
          });
      });
    }

    fetchUoPIs = () => {
      getNodesOfType("im:UoPInstance", {
        coreAddenda: ["runningIn"],
      }).then((res) => {
        this.uopis = deGuidify(res.data.nodes);
        this.filterUoPIOptions();
      })
    }

    filterUoPIOptions = () => {
      const running = this.state.running.map(uopi => uopi.guid);
      this.setState({
        uopiOptions: Object.values(this.uopis).filter(uopi => !running.includes(uopi.guid)),
      })
    }

    loadData = (guid) => {
        if (guid !== "new") {
            _ajax({
                url: "/index.php?r=/integration-main-program/model-get-node",
                method: "get",
                data: {
                    guid: this.props.match.params.guid
                }
            }).then(response => {
                if (this.props.updateTemplateNode) {
                  this.props.updateTemplateNode(response);
                }

                this.setStateFromResponse(response);
                this.original = cloneDeep(response);
            }).fail((err) => {
                err.status === 500 && this.props.renderPageNotFound && this.props.renderPageNotFound();
            });
        } else {
            this.setStateFromResponse(this.emptyValues);
            this.original = this.emptyValues;
        }
    };



    setStateFromResponse = (resp) => {
      this.setState({
        ...resp,
        running: resp.running || [],
        runningIn: resp.runningIn || null,
      }, this.fetchUoPIs)
    };

    handleReset = () => {
        this.setStateFromResponse(this.original);
    };

    update = (e) => {
      const target = e.target.id.match("name|description")[0]
        this.setState({
            [target]: e.target.value
        });
    };

    handleSave = async () => {
        const processingElement = {
            xmiType: "ddm:MainProgram",
            name: this.state.name,
            guid: this.state.guid || createPhenomGuid(),
            description: this.state.description,
            changeSetId: getActiveChangeSetId(),
            parent: this.state?.parent,
        };

        // save Main Program
        const mpRes = await smmSaveNodes(processingElement);
        const mp = mpRes.data;
        Notifications2.parseResponse(mp);
        if (mp.errors) return;

        const promises = [];
        // save Main Program to Processing Element association
        if (this.state.runningIn) {
          promises.push(this.saveMainProgramToProcessingElement(this.state.associationNode, mp, this.state.runningIn));
        }

        // save new UoPI to Main Program association
        // save for new MainPrograms only - (existing MPs will save when the user selects the UoPI)
        if (!this.state.guid && this.state.running.length) {
          // note: this.state.running does not come with "AssociationNode" - this caused an issue with duplicate nodes in the backend
          const uopis = this.state.running.map(run => this.uopis[run.guid]);
          uopis.forEach(uopi => promises.push(this.saveUoPIToMainProgram(uopi, mp.guid)));
        }

        await Promise.all(promises);
        NavTree.addNodes([ mp ]);

        if (this.props.match.params.guid === "new") {
          return this.props.history.push( createNodeUrl(mp) );
        } else {
          this.loadData(mp.guid);
          this.fetchUoPIs();
        }
    };

    saveTPM = () => {
        const tpm = {
            xmiType: "ddm:TransportProtocolModule",
            parent: this.state.guid,
            name: this.state.newTPM.name,
            TPM_Type: this.state.newTPM.type,
            description: this.state.newTPM.description
        };
        smmSaveNodes(tpm).then((response) => {
            if (response.errors) {
                this.noticeRef.error(response.errors[0]);
            }
            if (response.data.xmiType) {
                this.setState({children: [...this.state.children, response.data]});
            }
        });
    };

    saveUoPIToMainProgram = (uopi, Main_Program_Guid) => {
      return smmSaveNodes({
        xmiType: "im:UoPInstanceToMainProgram",
        guid: uopi.associationNode?.guid || createPhenomGuid(),
        UoP_Instance_Guid: uopi.guid,
        Main_Program_Guid,
        parent: uopi.parent,
      })
    }

    saveMainProgramToProcessingElement = (association, Main_Program, Processing_Element_Guid) => {
      return smmSaveNodes({
        xmiType: "ddm:MainProgramToProcessingElement",
        guid: association?.guid || createPhenomGuid(),
        Main_Program_Guid: Main_Program.guid,
        Processing_Element_Guid,
        parent: Main_Program.parent,
      })
    }

    handleDelete = () => {
        DeletionConfirm2.show(this.state.guid, this.state.name);
        return null;
    };

    addNewUoPI = (e) => {
      const running = [...this.state.running];
      const uopi = this.uopis[e.target.value];
      if (!uopi) return;

      const addUopi = async () => {
        // existing main program -> create association node
        if (this.state.guid) {
          const associationRes = await this.saveUoPIToMainProgram(uopi, this.state.guid);
          const associationNode = associationRes.data;
          if (associationNode.guid) {
            uopi.associationNode = associationNode;
            Notifications2.parseLogs(`Successfully saved ${uopi.name}'s' association`);
          } else {
            return Notifications2.parseErrors("Server responded with an unexpected status code. Please try again.");
          }
        }

        running.push(uopi);
        this.setState({ running },
          this.filterUoPIOptions);
      }

      if (uopi.runningIn) {
        return BasicConfirm.show(`The UoP instance '${uopi.name}' is already used by the main program '${uopi.runningIn.name}'. \nDo you want to use here and remove it from the other main program?`, addUopi);
      }
      addUopi();
    }

    deleteUoPIAssociation = (guid) => {
      const uopi = this.uopis[guid]
      const { associationNode } = uopi;
      const running = [...this.state.running];
      const idx = running.findIndex(u => u.guid === guid);

      if (associationNode?.Main_Program_Guid === this.state.guid) {
        BasicConfirm.show(`You are about to delete the node '${uopi.name}'s association' from your model. This action cannot be reversed. \nAre you sure you would like to go through with this action?`, () => {
          modelRemoveNode(associationNode.guid, getActiveChangeSetId()).then((isDeleted) => {
            if (!isDeleted) return;
            running.splice(idx, 1);
            this.setState({ running }, this.fetchUoPIs);
          })
        })
      } else {
        running.splice(idx, 1);
        this.setState({ running }, this.fetchUoPIs);
      }
    }

    mutateOriginalParentListener = (e) => {
      // invalid
      if (!this.state.guid) {
        return;
      }

      const leaf = NavTree.getLeafNode(this.state.guid);
      if (!leaf) {
        return;
      }

      const newParentGuid = leaf.getParentGuid();
      if (this.state.parent !== newParentGuid) {
        this.original["parent"] = newParentGuid;
      }
    }

    render() {
        const phenomId = new PhenomId("edit-main-program");

        return (<div className="subview-wrapper" style={{position: "relative"}}>
            <Notifications ref={el => this.noticeRef = el} />
            {this.state.creatingTPM &&
                <Dialog title="Create TPM" className="dialog-no-exit" id={phenomId.gen("tpm","dialog")}>
                    <div style={{margin: "5px"}}>
                        <div className="flex-v" style={{
                            whiteSpace: "pre-line",
                            minWidth: "300px",
                            maxWidth: "calc(100vh - 300px)",
                            maxHeight: "calc(80vh - 340px)",
                            overflowY: "auto"
                        }}>
                        </div>
                    </div>
                        TYPE:
                        <select className="cadet-select" style={{margin: "5px 0 0 0"}}
                        id={phenomId.gen("tpm","type")}
                        value={this.state.newTPM?.type || ""}
                        onChange={evt => this.setState({newTPM: {...this.state.newTPM, type: evt.target.value}})}>
                        <option value="udp">UDP</option>
                        <option value="dds">DDS</option>
                        <option value="amqp">AMQP</option>
                        </select>
                    <br />

                        NAME:
                        <CadetInput text={this.state.newTPM?.name || ""} idCtx={phenomId.gen("tpm","name")}
                        onChange={evt => this.setState({newTPM: {...this.state.newTPM, name: evt.target.value}})}
                        style={{marginBottom: 0}} />

                    <br />

                        DESCRIPTION:
                        <CadetTextArea
                        text={this.state.newTPM?.description || ""}
                        title={this.state.newTPM?.description || ""}
                        idCtx={phenomId.gen("tpm","description")}
                        onChange={evt => this.setState({newTPM: {...this.state.newTPM, description: evt.target.value}})}
                        style={{marginBottom: 0}} />

                    <DialogActionsBar>
                        <button className="k-button" id={phenomId.gen("tpm","cancel")} onClick={() => {
                            this.setState({creatingTPM: false});
                        }}>Cancel
                            </button>
                        <button className="k-button k-primary" id={phenomId.gen("tpm","save")} disabled={(this.state.newTPM.name == "")}
                            onClick={() => {
                                this.saveTPM();
                                this.setState({creatingTPM: false});
                            }}>Save
                            </button>
                    </DialogActionsBar>
                </Dialog>}

            <div className="flex-h">
              <div className="flex-v" style={{flexGrow: 1}}>
                  <LineLabel text="Main Program" idCtx={phenomId.gen(["init","details"],"")}/>
                  <CadetInput text={this.state.name} idCtx={phenomId.gen("details","name")} onChange={this.update}
                      style={{marginBottom: 0}} />
                  <LineLabel text="Description" style={{marginTop: 15}} idCtx={phenomId.gen("details","description")}/>
                  <CadetTextArea
                      text={this.state.description}
                      title={this.state.description}
                      idCtx={phenomId.gen("details","description")}
                      onChange={this.update}
                      style={{marginBottom: 0}} />
                  <div style={{margin: "15px 0"}}>
                    <PackageComboBox id={phenomId.genPageId("parent")}
                                      label="Package"
                                      xmiType={this.parentTypes}
                                      placeholder="<Default>"
                                      nodeGuid={this.state.guid}
                                      selectedGuid={this.state.parent}
                                      onChange={(parent) => this.setState({ parent: parent.guid })}
                                      onClickCancelIcon={() => this.setState({ parent: undefined })} />
                  </div>
              </div>
              <div className="edit-side-bar">
                  <NodeHistory2 ref={ele => this.historyRef = ele} guid={this.state.guid} idCtx={phenomId.genPageId()}/>
                  <NodeDetails guid={this.state.guid}/>
                  {/* <ChangeSetPicker id={phenomId.genPageId()}
                                   label="Change Set" /> */}
              </div>
           </div>
              <div className="flex-v" style={{flexGrow: 1}}>
                  <LineLabel text="Contains UOP Instances:" style={{margin: "15px 0"}} idCtx={phenomId.gen("uop-instances","")}/>
                  <Grid
                      id={phenomId.gen("uop-instances","grid")}
                      data={orderBy(this.state["running"], this.state["runningSort"] || [])}
                      sortable
                      sort={this.state["runningSort"]}
                      className="editorTable"
                      resizable>
                      <GridNoRecords>
                          No UOP Instances associated.
                      </GridNoRecords>
                      <Column
                          title="NAME"
                          field="name"
                          cell={(props) => {
                              const {guid, name} = props.dataItem;
                              return (
                                  <td><NavLink className="cadet-anchor normal-anchor" id={phenomId.gen(["uop-instances",`${props.columnIndex}`],"link")}
                                      to={`/edit/details/uop_instance/${guid}`}>{name}</NavLink>
                                  </td>
                              );
                          }}
                      />
                      <Column title="CONFIGURATION ID" field="configurationURI" />
                      <Column title="DELETE"
                              width="60px"
                              cell={(props) => {
                                return <td style={{ textAlign: "center" }}>
                                        <Button icon="close"
                                                onClick={() => this.deleteUoPIAssociation(props.dataItem.guid)} /></td>
                              }} />
                  </Grid>

                  {this.state.uopiOptions.length > 0 &&
                  <Toolbar>
                    <ToolbarItem>
                      <select className="cadet-select" 
                              value="" 
                              onChange={this.addNewUoPI} 
                              style={{ margin: 5, fontSize: 14 }}
                              id={phenomId.gen("add-new")}>
                        <option value="" disabled>--Add UoP Instance--</option>
                        {this.state.uopiOptions.map(uopi => {
                          return <option key={uopi.guid}
                                         value={uopi.guid}>{uopi.name}</option>
                        })}
                      </select>
                    </ToolbarItem>
                  </Toolbar> }

                  <LineLabel text="Transport Protocol Modules:" style={{margin: "15px 0"}} idCtx={phenomId.gen(["details","tpm"],"")}/>
                  <Grid
                      id={phenomId.gen("tpm","grid")}
                      data={this.state["children"]}
                      sortable
                      sort={this.state["runningSort"]}
                      className="editorTable"
                      resizable>
                      <GridNoRecords>
                          No TPMs.
                      </GridNoRecords>
                      <Column title="NAME" field="name" />
                      <Column title="Type" field="TPM_Type" />
                      <Column title="Description" field="description" />
                  </Grid>

                  <button className="k-button"
                      id={phenomId.gen("tpm","create")}
                      disabled={(this.state.guid == "")}
                      onClick={() => {
                          this.setState({creatingTPM: true, newTPM: {type: "udp", name: "", description: ""}});
                      }}
                  >Create TPM</button>

                  <LineLabel text="Run on Processing Element:" style={{marginTop: 15}} idCtx={phenomId.gen("tpm","create")}/>
                  <select className="cadet-select" style={{width: "100%", marginBottom: 0}}
                          value={this.state.runningIn || ""}
                          id={phenomId.gen("runningIn","select")}
                          onChange={(e) => this.setState({ runningIn: e.target.value })}>
                      <option value="" disabled>---SELECT OPTION---</option>
                      {this.state.processingElements.map((e, idx) => <option key={e.guid}
                                                                             id={phenomId.gen(["runningIn",`${idx}`],"option")}
                                                                             value={e.guid}>{e.name}</option>)}
                  </select>
              </div>
          </div>);
    }
}


export const EditMainProgramManager = withPageLayout(MainProgramManager)
