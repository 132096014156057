import Draggable from "react-draggable";
import React from "react";
import { preReviewNodes } from "../../requests/sml-requests";
import { PreviewRawAttrGrid } from "../widget/RawAttrGrid";
import NavTree from "../tree/NavTree";
import loadingIcon from "../../images/Palette Ring-1s-200px.gif"
import { getShortenedStringRepresentationOfXmiType } from "../util/util";

export class Preview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            candidateNodes: [],
            originalNodes: [],
            loading: true,
        };
    }

    componentDidMount() {
        const { guid, thisNodeOnly } = this.props;

        preReviewNodes(guid, thisNodeOnly)
            .then((res) => {
                const candidateNodes = [];
                const originalNodes = [];

                res.data.nodes.forEach((node) => { //format nodes and build before/after arrays
                    if(node['original-attrs']) node['original-attrs'].xmiType = getShortenedStringRepresentationOfXmiType(node['original-attrs'].xmiType);
                    if(node['candidate-attrs']) node['candidate-attrs'].xmiType = getShortenedStringRepresentationOfXmiType(node['candidate-attrs'].xmiType);

                    candidateNodes.push(node['candidate-attrs']);
                    originalNodes.push(node['original-attrs']);
                })

                this.setState({
                    candidateNodes: candidateNodes,
                    originalNodes: originalNodes,
                    loading: false,
                });
            });
    }

    componentDidUpdate(prevProps) {
    }

    nodeAttrDereffer = (guid, derefKind) => {
        const { candidateNodes, originalNodes } = this.state;
        const scope = derefKind === "candidate" ? candidateNodes : originalNodes;

        const nodeInScope = scope.find(node => node?.guid === guid);
        if (nodeInScope) return nodeInScope.name || nodeInScope.rolename;

        const data = NavTree.getLeafNode(guid)?.data;
        return data?.name || data?.rolename;
    }

    render() {
        const { candidateNodes, originalNodes, loading } = this.state;
        const { thisNodeOnly } = this.props;
        const boxStyle = {top: window.innerHeight/2 - 300, left: window.innerWidth/3, width: "min-content", maxWidth: "57%", zIndex: 2};

        const descriptionText = (
            <>
                Below is a review of the proposed attribute changes for the selected node{thisNodeOnly ? "" : " and its children under review"}.
            </>
        );

        return (
            <Draggable enableUserSelectHack={false} handle=".dialog-box-header">
                <div className="dialog-box" style={boxStyle}>
                    <span className="dialog-box-header">
                        Review of Proposed Changes
                        <button onClick={this.props.close}>X</button>
                    </span>
                    <div style={{padding: "0 15px", maxHeight: "600px", overflowY: "auto", gap: "1em", display: "flex", flexDirection: "column"}}>
                        {loading ?
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                                <img 
                                    id="loading-spinner"
                                    style={{ width: 100, height: 100 }}
                                    src={loadingIcon} 
                                />
                                <p>Fetching preview...</p>
                            </div>
                        : <>
                            <p style={{color: "hsl(246, 24%, 32%)", margin: "10px 0px 0px 0px"}}>
                                {descriptionText}
                            </p>

                            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '1em', alignItems: 'stretch', justifyItems: 'stretch'}}>
                                {originalNodes && originalNodes.length && candidateNodes && candidateNodes.length &&
                                    <PreviewRawAttrGrid
                                        originalLabel={"Original Node"}
                                        originalNode={originalNodes[0]}
                                        candidateLabel={"Proposed Changes"}
                                        candidateNode={candidateNodes[0]}
                                        nodeDereffer={(guid, derefKind) => this.nodeAttrDereffer(guid, derefKind)}
                                    />
                                }

                                {originalNodes && candidateNodes && originalNodes.length > 1 && candidateNodes.length > 1 &&  
                                    <>
                                        <PreviewRawAttrGrid
                                            originalLabel={"Children (Original Node)"}
                                            originalNode={originalNodes[1]}
                                            candidateLabel={"Children (Proposed Changes)"}
                                            candidateNode={candidateNodes[1]}
                                            nodeDereffer={(guid, derefKind) => this.nodeAttrDereffer(guid, derefKind)}
                                        />
                                        {originalNodes.length > 2 && candidateNodes.length > 2 && 
                                            originalNodes.slice(2).map((originalNode, idx) => {
                                                return (
                                                    <PreviewRawAttrGrid
                                                        key={idx}
                                                        originalNode={originalNode}
                                                        candidateNode={candidateNodes[idx + 2]}
                                                        nodeDereffer={(guid, derefKind) => this.nodeAttrDereffer(guid, derefKind)}
                                                    />
                                                );
                                            })
                                        }
                                    </>
                                }
                            </div>
                        </>}
                    </div>
                </div>
            </Draggable>
        );
    }
}
