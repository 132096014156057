import React, {Component} from "react";
import ReactTooltip from "react-tooltip";
import $ from "jquery";
import { Action, CanvasWidget, InputType } from "@projectstorm/react-canvas-core";
import createEngine, { DagreEngine, DiagramModel, PathFindingLinkFactory, DefaultLinkModel, NodeModel } from "@projectstorm/react-diagrams";

import { FadingDirections, Toggle } from "../../../util/stateless";
import { ContextMenu, isPhenomGuid, convertNullToUndefined, getKbUrl } from "../../../util/util";
import { createImPort, diagramColors, DiagramZoomLevel, isStormData, StencilItem, StencilItemImage } from "../util";
import { Modal2 } from "../../../util/Modal";
import { BasicAlert } from "../../../dialog/BasicAlert";
import { BasicConfirm } from "../../../dialog/BasicConfirm";
import { UOPInstanceManager } from "../../../edit/integration/EditUOPInstance";

import "../design/scratchpadStyles.css";
import PhenomId from '../../../../requests/phenom-id';

import { StormState } from "../stormy/StormState";
import { BaseNodeModel } from "../base/BaseNode";
import { ImBlockNodeFactory, ImBlockNodeModel } from "./node/ImBlock";
import { ImUopInstanceNodeFactory, ImUopInstanceNodeModel } from "./node/ImUopInstance";
import { ImComposedPortBlockFactory, ImComposedPortBlockModel } from "./node/ImComposedPortBlock";
import { ImPortFactory } from "./misc/ImPort";
import { ImLinkFactory } from "./misc/ImLink";
import { ImLabelFactory } from "./misc/ImLabel";
import { ImSidePanel } from "./misc/ImSidePanel";
import DataTypeModal from "./misc/DataTypeModal";

import {
  findConnectedLink,
  centerCoordinatesOnDragEnd,
  extractDataTypeFromDestinationMessagePort,
  extractDataTypeFromSourceMessagePort,
  calculateLineEquation,
  findIntersectingPoint,
} from "../util";

import { nodeProps } from '../index';
import Portal from "../../../dialog/Portal";
import { Notifications2 } from "../../../edit/notifications";
import { smmDeleteNodes } from "../../../../requests/sml-requests";
import StormData from "../base/StormData";
import { receiveErrors, receiveWarnings, removeNodes } from "../../../../requests/actionCreators";
import NavTree from "../../../tree/NavTree";

import PortNoneImg from "../../../../images/diagram_images/stencil_port_none.png";



/**
 * INDEX
 * ------------------------------------------------------------
 * 00. Colors
 * 01. Tool Bar
 * 02. State
 * 03. Life Cycle Methods
 * 04. Stencil Box
 * 05. Canvas
 * 06. Model
 * 07. Getters
 * 08. Setters
 * 09. Remove
 * 10. Link
 * 15. Drag and Drop (Adding Nodes to Diagram)
 * 20. Context
 * 25. Side Bar
 * 50. Helper
 * 55. Action - Modal Methods
 * 60. Action - Custom Delete Action
 * 61. Action - Custom Mouse Up Action
 * 62. Action - Custom Mouse Down Action
 * ------------------------------------------------------------
 */



export default class IdmEditor extends Component {
  constructor(props) {
    super(props);
    this.phenomId = new PhenomId(`${this.props.id}-idm-editor`);

    /*
    * This configures the layout processor
    * */
    this.dagreEngine = new DagreEngine({
        graph: {
            rankdir: "LR",
            ranker: "tight-tree",
            marginx: 25,
            marginy: 25
        },
        includeLinks: true
    });

    // removes default actions
    this.engine = createEngine({
        registerDefaultDeleteItemsAction: false,
        registerDefaultZoomCanvasAction: false,
    });

    this.engine.getStateMachine().pushState(new StormState());

    this.engine.getNodeFactories().registerFactory(new ImUopInstanceNodeFactory());
    this.engine.getNodeFactories().registerFactory(new ImBlockNodeFactory());
    this.engine.getNodeFactories().registerFactory(new ImComposedPortBlockFactory());

    this.engine.getLinkFactories().registerFactory(new ImLinkFactory());
    this.engine.getPortFactories().registerFactory(new ImPortFactory());
    this.engine.getLabelFactories().registerFactory(new ImLabelFactory());


    /*
      * MODEL
      */
    this.model = new DiagramModel();

    this.model.registerListener({
      nodesUpdated: (e) => {
        // set saved status to false
        this.setDiagramUnsavedStatus();

        this.resizeCanvas();
      },
    });

    this.engine.setModel(this.model);

    this.engine.getActionEventBus().registerAction(new CustomDeleteItemsAction());
    this.engine.getActionEventBus().registerAction(new CustomMouseDownAction());
    this.engine.getActionEventBus().registerAction(new CustomMouseUpAction());

    this.modelPickerRef = React.createRef();
    this.sidePanelRef = React.createRef();

    /*
      * This reference is important to have because it allows us to easily access the
      * state and methods in this file from individual nodes
      * */
    this.engine.$app = this;
  }

  // ------------------------------------------------------------
  // 02. State
  // ------------------------------------------------------------
  version = 2
  saved = true
  deleteNodesOnCommit = {}

  state = {
    hideLinkTypes: new Set(),

    // used to create custom drag line
    draggingFor: null,

    // used to display uopi/transport-channel modal
    modalType: null,

    // used to dipslay highlighted uncommited changes
    showUncommitted: true,

    //used to display connector lines
    showConnectorLines: true,

    context: this.props.$manager.createImContextData(),
  }

  sidebarState = {
    nodeModel: null,
  }

  setDiagramUnsavedStatus = () => {
    this.setDiagramSavedStatus(false);
  }

  setDiagramSavedStatus = (bool) => {
    this.saved = bool;
  }

  getDiagramSaveStatus = () => {
    return this.saved;
  }

  hasContent = () => {
    return !!this.model.getNodes().length;
  }

  refresh = () => {
    this.model.getNodes().forEach(nodeModel => {
      nodeModel.restoreNodeSize();
      nodeModel.fireEvent({}, "refreshData");
    })

    this.model.getLinks().forEach(linkModel => {
      linkModel.fireEvent({}, "refreshData");
    })

    ReactTooltip.rebuild();
    this.engine.repaintCanvas();
    this.forceUpdate();
  }

  // ------------------------------------------------------------
  // 03. Life Cycle Methods
  // ------------------------------------------------------------
  componentDidMount() {
    this.updateContextName(this.props.fileName);

    // React Storm only listens for these mouse actions: Mouse_Down, Mouse_Up, Mouse_Wheel, and Mouse_Move
    $(this.engine.canvas).on('contextmenu', (e) => {
      e.preventDefault();
    })

    this.addComposedBorders();
  }




  // ------------------------------------------------------------
  // 05. Canvas
  // ------------------------------------------------------------
  getCanvasLayerDOM = () => {
    return this.engine.canvas;
  }

  getModelLayerDOM = () => {
    return this.engine.canvas.querySelector('div:first-of-type');
  }

  getSvgLayerDOM = () => {
    return this.engine.canvas.querySelector('svg:first-of-type');
  }

  domModelLayerOnTop = () => {
    const svgLayer = this.getSvgLayerDOM();
    svgLayer.style.zIndex = "0";
  }

  domSvgLayerOnTop = () => {
    const svgLayer = this.getSvgLayerDOM();
    svgLayer.style.zIndex = "1";
  }

  resizeCanvas = () => {
    const zoom = this.model.getZoomLevel() / 100;
    const canvas = this.getCanvasLayerDOM();

    if(zoom > 1) {
        var scroll_width = Math.floor(canvas.scrollWidth / zoom);
        var scroll_height = Math.floor(canvas.scrollHeight / zoom);
    } else {
        var scroll_width = canvas.scrollWidth;
        var scroll_height = canvas.scrollHeight;
    }

    if(scroll_width > canvas.clientWidth) {
        canvas.style.width = (scroll_width + 1000) + "px";
    }

    if(scroll_height > canvas.clientHeight) {
        canvas.style.height = (scroll_height + 500) + "px";
    }

    this.forceUpdate();
  }

  addComposedBorders = () => {
    if (this.getContextData().getXmiType() !== "im:ComposedBlock") {
      return;
    }

    let top = this.getCanvasLayerDOM().querySelector('.top-composed-border');
    let right = this.getCanvasLayerDOM().querySelector('.right-composed-border');
    let bottom = this.getCanvasLayerDOM().querySelector('.bottom-composed-border');
    let left = this.getCanvasLayerDOM().querySelector('.left-composed-border');

    // lines already exist
    if (top) {
      return;
    }

    top = document.createElement("line");
    top.classList.add("composed-border");
    top.classList.add("top-composed-border");

    right = document.createElement("line");
    right.classList.add("composed-border");
    right.classList.add("right-composed-border");

    bottom = document.createElement("line");
    bottom.classList.add("composed-border");
    bottom.classList.add("bottom-composed-border");

    left = document.createElement("line");
    left.classList.add("composed-border");
    left.classList.add("left-composed-border");

    this.getCanvasLayerDOM().prepend(top);
    this.getCanvasLayerDOM().prepend(right);
    this.getCanvasLayerDOM().prepend(bottom);
    this.getCanvasLayerDOM().prepend(left);
  }

  removeComposedBorders = () => {
    if (this.getContextData().getXmiType() === "im:ComposedBlock") {
      return;
    }

    let top = this.getCanvasLayerDOM().querySelector('.top-composed-border');
    let right = this.getCanvasLayerDOM().querySelector('.right-composed-border');
    let bottom = this.getCanvasLayerDOM().querySelector('.bottom-composed-border');
    let left = this.getCanvasLayerDOM().querySelector('.left-composed-border');

    top && this.getCanvasLayerDOM().removeChild(top);
    right && this.getCanvasLayerDOM().removeChild(right);
    bottom && this.getCanvasLayerDOM().removeChild(bottom);
    left && this.getCanvasLayerDOM().removeChild(left);
  }




  // ------------------------------------------------------------
  // 06. Model
  // ------------------------------------------------------------
  /**
   * There are three ways of loading a context
   *    1) context with json data - this was created by the current IM Editor
   *    2) context without json data - this was imported
   *    3) context with old json data - this was created by rappid
   * 
   * @param {node} context 
   * @param {object} presentationData 
   */
  loadImContext = async (context, presentationData={}) => {
    // invalid node
    if (!context?.guid || !context?.children) {
      return;
    }

    // Standard Load Method
    if (presentationData?.id) {
      await this.loadStormIdmContext(presentationData);

    // Old Diagram (Rappid) or if presentationData is empty
    } else {
      await this.loadRappidIdmContext(context, presentationData, true);
    }
    
    const contextData = this.getDiagramNodeData(context.guid);
    if (isStormData(contextData)) {
      this.updateContextName(context.name);
      this.setContextData(contextData);
    }

    if (context.warnings) {
        Notifications2.parseWarnings(context.warnings)
    }

    // slight delay is needed or else the line positions will be slightly off
    setTimeout(() => {
      this.resizeCanvas();
      this.refresh();
      this.forceUpdate();
      this.engine.repaintCanvas();
      this.addComposedBorders();
    }, 0)
  }


  /**
   * Standard Load Method
   *    -> React Storm uses the presenation data to rebuild the environment
   *    -> First step is to clean up the presentation data - remove any nodes that was deleted from the outside
   *    -> Second step is to reassign the StormData pointers - the parent component has an index of nodes, every node is referencing the index
   *
   * @param {object} presentationData parsed json data
   */
  loadStormIdmContext = async (presentationData) => {
    const { deleteNodesOnCommit } = presentationData;
    const linkModels = presentationData.layers.find(e => e.type === "diagram-links").models;
    const nodeModels = presentationData.layers.find(e => e.type === "diagram-nodes").models;

    this.cleanPresentationData(presentationData);
    this.setDeleteNodesOnCommit(deleteNodesOnCommit)

    // reassign reference pointers
    for (let uid in nodeModels) {
      const jsonNodeModel = nodeModels[uid];
      const jsonData = jsonNodeModel.nodeData;
      if (!jsonData?.guid) {
        continue;
      }

      jsonNodeModel.nodeData = this.getDiagramStormData(jsonData.guid);

      for (const port of jsonNodeModel.ports) {
        const jsonPortData = port?.portData;
        if (!jsonPortData?.guid) {
          continue;
        }
        port.portData = this.getDiagramStormData(jsonPortData.guid);
      }
    }

    // reassign reference pointers
    for (let uid in linkModels) {
      const jsonLinkModel = linkModels[uid];
      const jsonData = jsonLinkModel.attrData;
      if (!jsonData?.guid) {
        continue;
      }

      jsonLinkModel.attrData = this.getDiagramStormData(jsonData.guid);
    }

    // Load Content
    this.model.deserializeModel(presentationData, this.engine);
    this.engine.repaintCanvas();
  }

  /**
   * Alternate Load Method
   *    1) Add each node to the canvas
   *    2) Create connections between the nodes
   *    3) Run auto layout and rerposition the nodes, because if presentationData is empty then every node is placed on top of each other.
   *
   * @param {node} context
   * @param {object} presentationData
   * @param {boolean} runAutoLayout
   */
  loadRappidIdmContext = async (context, presentationData={}, runAutoLayout=true) => {
    // invalid node
    if (!context?.guid || !Array.isArray(context?.im_nodes)) {
      return;
    }
    
    const nodeTypes = new Set(["im:UoPInstance", "im:SourceNode", "im:SinkNode", "im:FanIn", "im:FanOut", "im:Generic", "im:FilterNode", "im:TransformNode", "im:ViewTransporterNode", "im:SIMAdapter", "im:QueuingAdapter", "im:DataPump", "im:ComposedBlockInstance", "im:ComposedInPort", "im:ComposedOutPort"]);
    const nodeList = context.im_nodes.filter(n => nodeTypes.has(n.xmiType));
    const connectionList = context.im_nodes.filter((n) => n.xmiType === "im:NodeConnection");
    const connectionMap = {};

    // Search through Rappid data and find smallest X/Y values
    let offset_x = 0, offset_y = 0;

    for (let pData of Object.values(presentationData)) {
      if (offset_x === 0 || pData.position.x < offset_x) {
        offset_x = pData.position.x;
      }

      if (offset_y === 0 || pData.position.y < offset_y) {
        offset_y = pData.position.y;
      }
    }

    // default drag and drop will center the node relative to the mouse's position.  turning this setting off
    let addNodeConfig = {
      useRelativePoint: false,
      centerNodeOnDrop: false,
    }

    // add Nodes
    for (let node of nodeList) {
      const position = presentationData[node.guid]?.position || { x: 0, y:0 };
      const nodeModel = await this.addNode(node, [position.x - offset_x, position.y - offset_y], addNodeConfig);

      if (Array.isArray(node.children)) {
        node.children.forEach(childGuid => connectionMap[childGuid] = nodeModel);
      }

      // special case
      if (["im:ComposedOutPort", "im:ComposedInPort"].includes(node.xmiType)) {
        connectionMap[node.guid] = nodeModel;
      }
    }

    // add Links between each node
    setTimeout(() => {
      for (let connection of connectionList) {
        const linkData = this.getDiagramStormData(connection?.guid);
        const srcChildData = this.getDiagramStormData(connection?.source);
        const dstChildData = this.getDiagramStormData(connection?.destination);

        // invalid nodes
        if (!isStormData(linkData) || !isStormData(srcChildData) || !isStormData(dstChildData)) {
          continue;
        }

        const srcNodeModel = connectionMap[srcChildData.getGuid()];
        const dstNodeModel = connectionMap[dstChildData.getGuid()];

        let srcPort;
        if (["im:UoPInputEndPoint", "im:UoPOutputEndPoint"].includes(srcChildData.getXmiType())) {
          const msgPortData = this.getDiagramStormData(srcChildData.getAttr("connection"));
          if (isStormData(msgPortData)) {
            srcPort = this.findOrCreateUoPiOutputPort(srcNodeModel, msgPortData.getData());
          }
        } else if (["im:ComposedInPort"].includes(srcChildData.getXmiType())) {
          srcPort = this.findOrCreateOutPort_for_ComposedInPort(srcNodeModel);
        } else {
          srcPort = srcNodeModel.getImOutPort(connection.source);
        }

        let dstPort;
        if (["im:UoPInputEndPoint", "im:UoPOutputEndPoint"].includes(dstChildData.getXmiType())) {
          const msgPortData = this.getDiagramStormData(dstChildData.getAttr("connection"));
          if (isStormData(msgPortData)) {
            dstPort = this.findOrCreateUoPiInputPort(dstNodeModel, msgPortData.getData());
          }
        } else if (["im:ComposedOutPort"].includes(dstChildData.getXmiType())) {
          dstPort = this.findOrCreateInPort_for_ComposedOutPort(dstNodeModel);
        } else {
          dstPort = dstNodeModel.getImInPort(connection.destination);
        }
        
        if (srcPort && dstPort) {
          this.createLink(srcPort, dstPort, linkData);
        }
      }

      if (runAutoLayout) {
        setTimeout(() => {
          this.autoLayout();
        }, 0);
      }
    }, 0);
  }


  /**
   * React Storm uses the presentationData to rebuild the environment
   *    -> Nodes are spread out in different sections
   *    -> Loop through each node and remove them if they don't exist in the database anymore
   * 
   * @param {object} presentationData 
   */
  cleanPresentationData = (presentationData) => {
    const { deleteNodesOnCommit } = presentationData;
    const linkModels = presentationData.layers.find(e => e.type === "diagram-links").models;
    const nodeModels = presentationData.layers.find(e => e.type === "diagram-nodes").models;

    // ---------------------------------------------
    // -- CLEAN UP HASHMAP (deletedNodesOnCommit) --
    // ---------------------------------------------
    if (deleteNodesOnCommit) {
      for (let guid in deleteNodesOnCommit) {
        const node = this.getDiagramStormData(guid);
        if (isStormData(node)) continue;
        delete deleteNodesOnCommit[guid];
      }
    }

    // ---------------------------------------------
    // -- CLEAN UP NODEMODELS --
    // ---------------------------------------------
    for (let jsonNodeModel of Object.values(nodeModels)) {
      const jsonData = jsonNodeModel.nodeData;

      const stormData = this.getDiagramStormData(jsonData?.guid);
      if (!jsonData || !isStormData(stormData)) {
        this.removeNodeFromPresentationData(jsonNodeModel, nodeModels, linkModels);
        continue;
      }

      // ---------------------------------------------
      // -- CLEAN UP PORTS --
      // ---------------------------------------------
      for (const port of [...jsonNodeModel.ports]) {
        if (port.name === 'draw-tool') continue;

        let jsonAttrData = port.portData;
        let attr = this.getDiagramStormData(jsonAttrData?.guid);

        // Fixing a previous mistake
        // -> old json files was saved without inPort/outPort data because of a typo
        // -> this is readding that data. it only takes effect for Block nodes' inPort/outPort nodes
        if (!attr && isPhenomGuid(jsonAttrData?.guid) && stormData.getXmiType() !== "im:UoPInstance") {
          jsonAttrData = {
            ...createImPort(!port.in, stormData.getGuid()),
            guid: jsonAttrData.guid
          }

          attr = this.props.$manager.setDiagramStormData(jsonAttrData);
        }

        if (!jsonAttrData || !isStormData(attr)) {
          port.links.forEach(linkId => delete linkModels[linkId]);
          const removeIdx = jsonNodeModel.ports.findIndex(p => p.id === port.id);
          removeIdx > -1 && jsonNodeModel.ports.splice(removeIdx, 1);
        }
      }

      // ---------------------------------------------
      // -- CLEAN UP NODE CONNECTIONS --
      // ---------------------------------------------
      for (const port of [...jsonNodeModel.ports]) {
        if (port.name === 'draw-tool') continue;

        for (const linkId of [...port.links]) {
          const jsonLink = linkModels[linkId];
          const jsonAttrData = jsonLink?.attrData;

          // node connection was deleted
          const nodeConnection = this.getDiagramStormData(jsonAttrData?.guid);
          if (!jsonAttrData || !isStormData(nodeConnection)) {
            delete linkModels[linkId];
            const removeIdx = port.links.findIndex(id => id === linkId);
            removeIdx > -1 && port.links.splice(removeIdx, 1);
          }
        }

        // delete port if it has no links
        if (["im:UoPInstance"].includes(stormData.getXmiType()) && !port.links.length) {
          const removeIdx = jsonNodeModel.ports.findIndex(p => p.id === port.id);
          removeIdx > -1 && jsonNodeModel.ports.splice(removeIdx, 1);
        }
      }

      // Remove link with corrupted data - this was an edge case when prod was using a different version of React Storm
      for (let jsonLinkModel of Object.values(linkModels)) {
        if (!jsonLinkModel.sourcePort || !jsonLinkModel.targetPort) {
          delete linkModels[jsonLinkModel.id];
        }
      }
    }
  }

  /**
   * Delete a node from the Presentation Data
   *   The model and json can easily go out of sync.
   *   When this happens, the node is removed from the presentation data before it is reinjected back into the diagram
   *
   * @param {NodeModel} removeMe
   * @param {object} nodeModels
   * @param {object} linkModels
   */
  removeNodeFromPresentationData = (removeMe, nodeModels, linkModels) => {
    removeMe.ports.forEach(port => {
        port.links.forEach(linkId => delete linkModels[linkId]);
    })
    delete nodeModels[removeMe.id];
  }


  /**
   * Steps to committing a context node:
   *    1) Delete nodes and continue only if successful - instead of having a deletion popup appear every time, nodes are marked for deletion
   *    2) Retrieve all nodes for request
   *    3) Save context
   *    4) Convert nodes - using the stencil box will create placeholder nodes, these need to be converted after save
   * @returns
   */
  saveContext = async () => {
    BasicAlert.show("Saving context...", "Saving");

    // 1) Delete nodes
    if (this.getNodesToBeDeleted().length) {
      const guidsToBeDeleted = this.getNodeGuidsToBeDeleted();
      const deletionStatus = await smmDeleteNodes(guidsToBeDeleted);

      if (deletionStatus.errors || deletionStatus.error) {
        BasicAlert.hide();
        Notifications2.parseErrors(deletionStatus.errors || deletionStatus.error);
        return;

      } else {
        removeNodes(guidsToBeDeleted);    // remove from NavTree
        this.clearNodesForDeletion();     // remove local data
      }
    }

    // 2) Retrieve all nodes for request
    const context = this.getContextData().serializeData();
          context.deconflictName = isPhenomGuid(this.getContextGuid());
          context.children = [];
    const requestNodes = [ context ];

    this.model.getNodes().forEach(nm => {
      const sd = nm.getStormData();
      if (!isStormData(sd)) return;

      // add nodes to request
      this.serializeNodesAndAddToRequest(sd, this.getContextData(), requestNodes);

      // add node connections to request
      this.serializeConnectionsAndAddToRequest(nm, requestNodes);
    })

    // 3) Save context & diagram
    const result = await this.props.$manager.saveContextNode(requestNodes, context.xmiType);

    // 4) Convert nodes
    const contextData = result?.contextData;
    if (!isStormData(contextData)) {
      return; // the save errored/timed out
    }

    this.setContextData(contextData);
    this.refresh();
    this.forceSidebarUpdate();

    BasicAlert.hide();
    this.setDiagramSavedStatus(true);
    this.saveDiagram(true);
    window.dispatchEvent(new CustomEvent('DIAGRAM_SAVE', { detail: { fileId: this.props.fileID } }));
    Notifications2.parseResponse(contextData.getData());
  }

  saveDiagram = async (recentCommit=false) => {
    BasicAlert.show("Saving diagram...", "Saving");

    let contextData = this.getContextData();
    let contextGuid = contextData.getGuid();
    const contextReq = {
      ...contextData.getData(),
      children: [],
      deconflictName: isPhenomGuid(contextGuid),
      content: JSON.stringify(this.serializeModel())
    }

    // Save Context & Diagram
    if (contextData.isEdited() || isPhenomGuid(contextGuid) || recentCommit) {
      contextData = await this.props.$manager.saveContextNodeOnly([contextReq], contextReq.xmiType);

      if (!isStormData(contextData)) {
        return;
      }

      this.setContextData(contextData);
      this.updateContextName(contextData.getName());
      contextGuid = contextData.getGuid();
    }

    BasicAlert.hide();
    this.setDiagramSavedStatus(true);
    Notifications2.parseLogs("Diagram successfully saved.");
    window.dispatchEvent(new CustomEvent('DIAGRAM_SAVE', { detail: { fileId: this.props.fileID } }));

    this.forceUpdate();
  }

  reassignAllStormData = (hashPhenomToReal={}) => {
    // main nodes
    this.model.getNodes().forEach(nodeModel => nodeModel.reassignStormData(hashPhenomToReal));

    // ports
    this.model.getNodes().forEach(nodeModel => nodeModel.reassignPorts(hashPhenomToReal));

    // links
    this.reassignAllLinkData(hashPhenomToReal);
  }

  reassignAllLinkData = (hashPhenomToReal={}) => {
    this.model.getLinks().forEach(linkModel => {
      const currAttrGuid = linkModel.getAttrGuid();
      const newGuid = hashPhenomToReal[currAttrGuid];

      // link was committed and converted from phenom_guid to real_guid
      if (newGuid) {
        const attrData = this.getDiagramStormData(newGuid);
        linkModel.setAttrData(attrData);
      }

      const currAttrData = linkModel.getAttrData();
      const srcGuid = currAttrData.getAttr("source");
      const dstGuid = currAttrData.getAttr("destination");
      const convertedSrcGuid = hashPhenomToReal[srcGuid];
      const convertedDstGuid = hashPhenomToReal[dstGuid];

      if (convertedSrcGuid) {
        currAttrData.setAttr("source", convertedSrcGuid);
      }

      if (convertedDstGuid) {
        currAttrData.setAttr("destination", convertedDstGuid);
      }
    })
  }

  serializeNodesAndAddToRequest = (stormData, parentData, requestNodes=[], memo = new Set()) => {
    if (!isStormData(stormData) || memo.has(stormData.getGuid())) {
      return;
    }

    memo.add(stormData.getGuid());

    if (stormData.isEdited()) {
      const serialized = stormData.serializeData();
      serialized["children"] = [];  // smm-save-nodes expects children with nested nodes, and not an array of guids/strings
      this.assignParentToSerializedNode(serialized, parentData);
      convertNullToUndefined(serialized);
      requestNodes.push(serialized);
    }

    for (let key of stormData.getAttributeKeys()) {
      const attr = stormData.getAttr(key);

      // skip deref
      // connection -> uop:MessagePort
      // realizes   -> uop:PortableComponent
      if (["guid", "name", "rolename", "description", "parent", "connection", "realizes"].includes(key) || memo.has(attr)) {
        continue;
      }

      // dereference array of nested nodes
      if (Array.isArray(attr) && attr.every(ele => typeof ele === 'string')) {
        let dataList = attr.map(guid => this.getDiagramStormData(guid)).filter(ele => isStormData(ele));
        dataList.forEach(nd => this.serializeNodesAndAddToRequest(nd, stormData, requestNodes, memo));

      // deference a single nested node
      } else if (typeof attr === 'string') {
        const nd = this.getDiagramStormData(attr);
        if (!isStormData(nd)) continue;
        this.serializeNodesAndAddToRequest(nd, stormData, requestNodes, memo);
      }
    }
  }

  serializeConnectionsAndAddToRequest = (nodeModel, requestNodes=[]) => {
    for (const portName in nodeModel.getPorts()) {
      const port = nodeModel.getPort(portName);
      if (port.getName() === "draw-tool") continue;

      for (let linkId in port.getLinks()) {
        const link = port.getLinks()[linkId];
        const linkData = link.getAttrData();
        if (!isStormData(linkData) || !linkData.isEdited()) continue;

        const serialized = linkData.serializeData();
        this.assignParentToSerializedNode(serialized, this.getContextData());
        requestNodes.push(serialized);
      }
    }
  }

  assignParentToSerializedNode = (serialized={}, parentData) => {
    const contextData = this.getContextData();
    const packageGuid = contextData.getParentGuid();

    // assign parent
    switch (serialized.xmiType) {
      // Context's siblings
      case "im:Equation":
        if (!serialized["parent"]) {
          serialized["parent"] = packageGuid;
        }
        break;

      // "im:TransporterNodeToTransportChannel" must share the same parent as the TransportChannel
      case "im:TransporterNodeToTransportChannel":
        const transport_channel = NavTree.getLeafNode(serialized["Channel_Guid"]);
        if (transport_channel) {
          serialized["parent"] = transport_channel.getParentGuid();
        }
        break;
        
      // "im:UoPInstanceToMainProgram" must share the same parent as the UoPi
      // "ddm:MainProgramToProcessingElement" must share the same parent as the Main Program
      case "im:UoPInstanceToMainProgram":
      case "ddm:MainProgramToProcessingElement":
        serialized["parent"] = parentData.getGuid();
        break;
      
      default:
        if (!serialized["parent"]) {
          serialized["parent"] = parentData.getGuid();
        }
    }
  }

  serializeStormData = (stormData, siblingNodes=[], memo = new Set()) => {
    const contextData = this.getContextData();
    const packageGuid = contextData.getParentGuid();
    let serialized = stormData.serializeData();

    // loop through and dereference all attributes
    for (let key in serialized) {
      let attr = serialized[key];

      // skip deref
      // connection -> uop:MessagePort
      // realizes   -> uop:PortableComponent
      if (["guid", "parent", "connection", "realizes"].includes(key) || memo.has(attr)) {
        continue;
      }

      // when fetching the context, these association/equation nodes are attached via addenda. On commit they need to be moved back to the request's top level (i.e. context's sibling)
      // action, test, update -> im:Equation
      if (["associationNode", "action", "test", "update"].includes(key)) {
        const nestedData = this.getDiagramStormData(attr);
        if (!isStormData(nestedData) || memo.has(nestedData.getGuid() || !nestedData.isEdited())) {
          continue;
        }

        const nestedSerialized = this.serializeStormData(nestedData, siblingNodes, memo);

        // ensure undefined is not added
        if (nestedSerialized) {
          // "im:TransporterNodeToTransportChannel" must share the same parent as the TransportChannel
          // "im:UoPInstanceToMainProgram" must share the same parent as the UoPi
          // "ddm:MainProgramToProcessingElement" must share the same parent as the Main Program
          switch(nestedSerialized?.xmiType) {
            case "im:TransporterNodeToTransportChannel":
              const transport_channel = NavTree.getLeafNode(nestedSerialized.Channel_Guid);
              if (transport_channel) {
                nestedSerialized.parent = transport_channel.getParentGuid();
              }
              break;

            case "im:UoPInstanceToMainProgram":
            case "ddm:MainProgramToProcessingElement":
              nestedSerialized.parent = stormData.getParentGuid();
              break;
            
            default:
              nestedSerialized.parent = packageGuid;    // move these nodes to the context's parent
          }

          memo.add(nestedSerialized.guid);          // add guid to memo
          siblingNodes.push(nestedSerialized);      // add to sibiling nodes
        }
        continue;
      }
      
      // dereference array of nested nodes
      if (Array.isArray(attr) && attr.every(ele => typeof ele === 'string')) {
        let dataList = attr.map(guid => this.getDiagramStormData(guid)).filter(ele => isStormData(ele) && !memo.has(ele.getGuid()));

        if (key === "children") {
          serialized["children"] = [];                      // smm-save-nodes expects children with nested nodes, and not an array of guids/strings
          dataList = dataList.filter(sd => sd.isEdited());  // edited children only
        }

        if (!dataList.length) {
          continue;
        }

        serialized[key] = [];
        dataList.forEach(nestedData => {
          const nestedSerialized = this.serializeStormData(nestedData, siblingNodes, memo);

          // ensure undefined is not added to list
          if (nestedSerialized) {
            memo.add(nestedSerialized.guid);          // add guid to memo
            serialized[key].push(nestedSerialized);   // add to list
          }
        })

      // deference a single nested node
      } else if (typeof attr === 'string') {
        const nestedData = this.getDiagramStormData(attr);
        if (!isStormData(nestedData) || memo.has(nestedData.getGuid()) || !nestedData.isEdited()) {
          continue;
        }

        memo.add(nestedData.getGuid());   // add guid to memo
        serialized[key] = this.serializeStormData(nestedData, siblingNodes, memo);
      }
    }

    return convertNullToUndefined(serialized);
  }


  serializeModel = () => {
    const serializedDeletions = {};
    const serializedHash = {};
    const stackStormDatas = [];

    // UoPi and Block nodes
    this.model.getNodes().forEach(nodeModel => {
      const node = nodeModel.getStormData();
      isStormData(node) && node.getGuid() && stackStormDatas.push(node);
    })

    // NodeConnection nodes
    this.model.getLinks().forEach(linkModel => {
      const node = linkModel.getAttrData();
      isStormData(node) && node.getGuid() && stackStormDatas.push(node);
    })

    // Deleted nodes
    for (let guid in this.getDeleteNodesOnCommit()) {
      const node = this.getNodeMarkedForDeletion(guid);
      if (!node?.data) continue;

      const stormData = this.getDiagramStormData(guid);
      if (!isStormData(stormData)) continue;
      stackStormDatas.push(stormData);

      // flatten the data
      serializedDeletions[guid] = {
        ...node,
        data: {
          guid: node.data.guid,
          xmiType: node.data.xmiType,
        }
      }
    }

    // Gather all nested nodes
    while (stackStormDatas.length) {
      const stormData = stackStormDatas.pop();
      serializedHash[stormData.getGuid()] = stormData.serializeData();

      for (let key in stormData.getData()) {
        if (key === 'guid' || key === 'xmiType') {
          continue;
        }

        const attr = stormData.getAttr(key);

        if (typeof attr === 'string') {
          const nestedData = this.getDiagramStormData(attr);
          isStormData(nestedData) && !serializedHash[nestedData.getGuid()] && stackStormDatas.push(nestedData);

        } else if (Array.isArray(attr)) {
          attr.forEach(ele => {
            if (typeof ele === 'string') {
              const nestedData = this.getDiagramStormData(ele);
              isStormData(nestedData) && !serializedHash[nestedData.getGuid()] && stackStormDatas.push(nestedData);
            }
          })
        }
      }
    }

    const serializedData = this.model.serialize();
          serializedData.version = this.version;
          serializedData.deleteNodesOnCommit = serializedDeletions;
          serializedData.nodeHash = serializedHash;

    return serializedData
  }



  // ------------------------------------------------------------
  // 07. Getters
  // ------------------------------------------------------------

  /**
   * Dereference a node
   * 
   * @param {string} guid 
   * @returns StormData if found, undefined otherwise
   */
  getDiagramStormData = (guid) => {
    return this.props.$manager.getDiagramStormData(guid);
  }

  // deprecated
  getDiagramNodeData = (guid) => {
    return this.getDiagramStormData(guid);
  }

  getDraggingFor = () => {
    return this.state.draggingFor;
  }

  getPhenomDomId = () => {
    return this.phenomId.genPageId();
  }

  isLinkTypeHidden = (linkType) => {
    return this.state.hideLinkTypes.has(linkType);
  }

  getDeleteNodesOnCommit = () => {
    return this.deleteNodesOnCommit;
  }

  getNodesToBeDeleted = () => {
    return Object.values(this.getDeleteNodesOnCommit()).map(node => node.data);
  }

  getNodeGuidsToBeDeleted = () => {
    const deleteGuids = new Set();
    this.getNodesToBeDeleted().forEach(node => {
      deleteGuids.add(node.guid);
      const data = this.getDiagramStormData(node.guid);
      if (isStormData(data)) {
        data.getChildren().forEach(childGuid => deleteGuids.add(childGuid));
      }
    });
    return [...deleteGuids];
  }

  getNodeMarkedForDeletion = (guid) => {
    return this.deleteNodesOnCommit[guid];
  }

  getViewList = () => {
    return this.props.viewList || [];
  }

  isNodeMarkedForDeletion = (guid) => {
    return !!this.getNodeMarkedForDeletion(guid);
  }

  isShowUncommitted = () => {
    return this.state.showUncommitted;
  }

  isShowConnectorLines = () => {
    return this.state.showConnectorLines;
  }



  // ------------------------------------------------------------
  // # Setters
  // ------------------------------------------------------------
  setDraggingFor = (nodeModel) => {
    this.setState({ draggingFor: nodeModel }, () => {
      this.refresh();
    })
  }

  setModalType = (modalType=null) => {
    this.setState({ modalType });
  }

  setDeleteNodesOnCommit = (info={}) => {
    this.deleteNodesOnCommit = info;
  }

  /**
   *
   * @param {object} obj format: { data: {...}, position: [x, y], dependencies: [guids] }
   * @returns
   */
  markNodeForDeletion = (obj={}) => {
    if (!obj?.data?.guid || isPhenomGuid(obj.data.guid)) return;
    this.deleteNodesOnCommit[obj.data.guid] = obj;
  }

  clearNodesForDeletion = () => {
    this.deleteNodesOnCommit = {};
  }

  createStormData = (xmiType) => {
    return this.props.$manager.createStormData(xmiType);
  }


  // ------------------------------------------------------------
  // # Remove
  // ------------------------------------------------------------
  unmarkNodeForDeletion = (guid) => {
    // clear any dependencies (i.e. non-children nodes like AssociationNodes)
    const data = this.deleteNodesOnCommit[guid];
          data?.dependencies && data.dependencies.forEach(g => delete this.deleteNodesOnCommit[g]);
    delete this.deleteNodesOnCommit[guid];
    this.forceUpdate();
  }

  readdDeletedNode = (guid) => {
    const readd = this.getNodeMarkedForDeletion(guid);
    if (readd?.data) {
      // default drag and drop will center the node relative to the mouse's position.  turning this setting off
      let addNodeConfig = {
        useRelativePoint: false,
        centerNodeOnDrop: false,
      }
      const position = readd.position || [0, 0];

      this.addNodes([readd.data], position, addNodeConfig);
      this.unmarkNodeForDeletion(guid);
    }
  }

  removeNodeModelsFromDiagram = (nodeModels=[]) => {
    const composedPortsToBeRemoved = new Set();

    // Remove the Nodes from the Diagram
    for (let nm of nodeModels) {
      if (nm instanceof ImComposedPortBlockModel) {
        composedPortsToBeRemoved.add(nm);
      }

      nm.remove();
    }

    // // Remove Ports from other tabs if they are using this Composed Context
    // for (let nm of [...composedPortsToBeRemoved]) {
    //   this.props.$manager.removeAttributePortsFromAllTabs(this.getContextGuid(), nm.getGuid());
    // }

    ReactTooltip.hide();
    this.engine.repaintCanvas();
  }

  /**
   * Find and remove Blocks and UoPis based on their guids
   * 
   * @param {array} guids 
   */
  removeNodesFromDiagram = (guids=[], options={}) => {
    const { clearDeleteNodesOnCommit=false } = options;
    const guidsToBeRemoved = new Set(guids);
    const nodesToBeRemoved = this.model.getNodes().filter(nm => guidsToBeRemoved.has(nm.getGuid()));

    
    if (clearDeleteNodesOnCommit) {
      for (let guid of guids) {
        this.unmarkNodeForDeletion(guid);
      }
    }
    
    this.removeNodeModelsFromDiagram(nodesToBeRemoved);
  }


  removeAttributePorts = (parentGuid, childGuid) => {
    const nodeModel = this.model.getNodes().find(nm => nm.getGuid() === parentGuid);

    if (nodeModel instanceof BaseNodeModel === false) {
      return;
    }

    const normalPort = nodeModel.getPort(childGuid);
    if (normalPort) {
      normalPort.remove();
    }

    this.engine.repaintCanvas();
    this.refresh();
  }

  

  // ------------------------------------------------------------
  // 10. Link
  // ------------------------------------------------------------
  /**
   * Adds Link Node to the canvas
   * 
   * @param {Port} srcPort 
   * @param {Port} dstPort 
   * @param {StormData} linkData 
   * @returns LinkNodeModel
   */
  createLink = (srcPort, dstPort, linkData) => {
    // exit if link already exist
    const link = findConnectedLink(srcPort, dstPort);
    if (link) return link;

    const newLink = this.model.addLink(srcPort.link(dstPort));

    if (!linkData) {
      linkData = this.props.$manager.createNodeConnectionData(srcPort.getAttrGuid(), dstPort.getAttrGuid());
    }
    linkData.checkEditedStatus();

    if (["im:FanIn", "im:Generic", "im:ComposedInPort", "im:ComposedOutPort", "im:UoPInstance"].includes(dstPort.getNode().getXmiType())) {
      newLink.setOnDeleteRemoveInPort(true);
    }

    if (["im:FanOut", "im:Generic", "im:ComposedInPort", "im:ComposedOutPort", "im:UoPInstance"].includes(srcPort.getNode().getXmiType())) {
      newLink.setOnDeleteRemoveOutPort(true);
    }

    newLink.setArrowHead("thinArrow");
    newLink.setAttrData(linkData);
    newLink.forceLinkToUpdate();
    srcPort.reportPosition();
    dstPort.reportPosition();

    this.engine.repaintCanvas();
    return newLink;
  }

  /**
   * Adds Link Node to the canvas
   * 
   * @param {Port} srcPort 
   * @param {Port} dstPort 
   * @returns LinkNodeModel
   */
  createDashedLink = (srcPort, dstPort) => {
    // exit if link already exist
    // this is to prevent the setColor and setDashSize from triggering again if the link already exist
    let link = Object.values(srcPort.links)[0];
    if (link?.targetPort) return link;

    link = this.createLink(srcPort, dstPort);
    link.setLinkColor();
    link.setDashSize();
    link.setDashOffset();
    link.setArrowHead("thinArrow");
    return link;
  }

  /**
   * Establish connection between two NodeModels
   * 
   * @param {NodeModel} srcNodeModel 
   * @param {NodeModel} dstNodeModel 
   * @param {event} mouseEvent 
   */
  establishConnection = (srcNodeModel, dstNodeModel, mouseEvent) => {
    // UoPI --> UoPI
    if (srcNodeModel.getXmiType() === "im:UoPInstance" && dstNodeModel.getXmiType() === "im:UoPInstance") {
      this.dataTypeModalRef.show(srcNodeModel, dstNodeModel, (srcMessagePortGuid, dstMessagePortGuid) => {
        this.connectUopiToUopi(srcNodeModel, dstNodeModel, srcMessagePortGuid, dstMessagePortGuid, mouseEvent);
      })

    // UoPI --> Block
    } else if (srcNodeModel.getXmiType() === "im:UoPInstance") {
      this.dataTypeModalRef.show(srcNodeModel, dstNodeModel, (srcMessagePortGuid, dstPortGuid) => {
        this.connectUopiToBlock(srcNodeModel, dstNodeModel, srcMessagePortGuid, dstPortGuid, mouseEvent);
      })

    // Block --> UoPI
    } else if (dstNodeModel.getXmiType() === "im:UoPInstance") {
      this.dataTypeModalRef.show(srcNodeModel, dstNodeModel, (srcPortGuid, dstMessagePortGuid) => {
        this.connectBlockToUopi(srcNodeModel, dstNodeModel, srcPortGuid, dstMessagePortGuid, mouseEvent);
      })

    } else if (srcNodeModel.getXmiType() === "im:ComposedBlockInstance") {
      this.dataTypeModalRef.show(srcNodeModel, dstNodeModel, (srcPortGuid, _) => {
        this.connectBlockToBlock(srcNodeModel, dstNodeModel, mouseEvent, { srcPortGuid });
      })
    
    } else if (dstNodeModel.getXmiType() === "im:ComposedBlockInstance") {
      this.dataTypeModalRef.show(srcNodeModel, dstNodeModel, (_, dstPortGuid) => {
        this.connectBlockToBlock(srcNodeModel, dstNodeModel, mouseEvent, { dstPortGuid });
      })

    // Block --> Block
    } else {
      this.connectBlockToBlock(srcNodeModel, dstNodeModel, mouseEvent);
    }
  }

  /**
   * Connect Block to Block
   * 
   * @param {NodeModel} srcNodeModel 
   * @param {NodeModel} dstNodeModel 
   * @param {event} mouseEvent 
   */
  connectBlockToBlock = (srcNodeModel, dstNodeModel, mouseEvent, options={}) => {
    let inPortData = null;
    let outPortData = null;

    // srcPortGuid was selected from the DataType Modal
    if (options.srcPortGuid) {
      outPortData = this.getDiagramStormData(options.srcPortGuid);
    }

    // dstPortGuid was selected from the DataType Modal
    if (options.dstPortGuid) {
      inPortData = this.getDiagramStormData(options.dstPortGuid);
    }

    // Make a new out port
    // special case for Composed Input Port
    if (srcNodeModel.getXmiType() === "im:ComposedInPort") {
      outPortData = srcNodeModel.getStormData();
      this.findOrCreateOutPort_for_ComposedInPort(srcNodeModel);
    }

    // Make a new in port
    // special case for Composed Output Port
    if (dstNodeModel.getXmiType() === "im:ComposedOutPort") {
      inPortData = dstNodeModel.getStormData();
      this.findOrCreateInPort_for_ComposedOutPort(dstNodeModel);
    }

    //Make a new in port
    if (dstNodeModel.getXmiType() === "im:FanIn") {
      inPortData = this.props.$manager.createChildStormData("im:InPort", dstNodeModel.getStormData());  
      if (isStormData(inPortData)) {
        this.makePortCopy(dstNodeModel, inPortData);
      }
    }

    //Make a new in port
    if (dstNodeModel.getXmiType() === "im:Generic") {
      inPortData = this.props.$manager.createChildStormData("im:InPort", dstNodeModel.getStormData());  
      if (isStormData(inPortData)) {
        this.addStormPort(dstNodeModel, inPortData);
      }
    }

    //Make a new out port
    if (srcNodeModel.getXmiType() === "im:FanOut") {
      outPortData = this.props.$manager.createChildStormData("im:OutPort", srcNodeModel.getStormData());  
      if (isStormData(outPortData)) {
        this.makePortCopy(srcNodeModel, outPortData);
      }
    }

    //Make a new out port
    if (srcNodeModel.getXmiType() === "im:Generic") {
      outPortData = this.props.$manager.createChildStormData("im:OutPort", srcNodeModel.getStormData());  
      if (isStormData(outPortData)) {
        this.addStormPort(srcNodeModel, outPortData);
      }
    }

    const srcPort = srcNodeModel.getImOutPort(outPortData?.getGuid());
    const dstPort = dstNodeModel.getImInPort(inPortData?.getGuid());

    if (!srcPort || !dstPort) {
      return;
    }

    const sourcePoint = srcNodeModel.getCenterPoint();
    const targetPoint = mouseEvent ? this.engine.getRelativeMousePoint(mouseEvent) : dstNodeModel.getCenterPoint();
    const lineEquation = calculateLineEquation(sourcePoint, targetPoint);

    // reposition port if this is the first connection
    if (!srcPort.countLinks() && !srcPort.isComposedPort()) {
      const srcPos = findIntersectingPoint(lineEquation, srcNodeModel, targetPoint);
      srcPort.setPosition(srcPos.portPosition.x, srcPos.portPosition.y);
      srcPort.setWidgetPosition(srcPos.widgetPosition);
    }

    // reposition port if this is the first connection
    if (!dstPort.countLinks() && !dstPort.isComposedPort()) {
      const dstPos = findIntersectingPoint(lineEquation, dstNodeModel, sourcePoint);
      dstPort.setPosition(dstPos.portPosition.x, dstPos.portPosition.y);
      dstPort.setWidgetPosition(dstPos.widgetPosition);
    }

    this.createLink(srcPort, dstPort);

    if (srcPort.isTemplated() || dstPort.isTemplated()) {
      if (srcPort.getTemplateType()) {
        // assign dstPort's templateType with srcPort's templateType
        dstPort.setTemplateType(srcPort.getTemplateType());
      } else {
        // assign srcPort's templateType with dstPort's templateType
        srcPort.setTemplateType(dstPort.getTemplateType());
      }

    } else {
      if (srcPort.getDataType()) {
        const view = this.props.viewList.find(v => v.guid === srcPort.getDataType());
        dstPort.setDataType(view);

      } else if (dstPort.getDataType()) {
        const view = this.props.viewList.find(v => v.guid === dstPort.getDataType());
        srcPort.setDataType(view);
      }
    }

    // slight delay is needed or else the line positions will be slightly off
    setTimeout(() => {
      srcNodeModel.fireEvent({}, "nodeDataChanged");
      dstNodeModel.fireEvent({}, "nodeDataChanged");
      this.engine.repaintCanvas();
    }, 0)
  }

  /**
   * Connect UoPi to UoPi
   * 
   * @param {NodeModel} srcUoPi 
   * @param {NodeModel} dstUoPi 
   * @param {object} srcMessagePort 
   * @param {object} dstMessagePort 
   * @param {event} mouseEvent 
   */
  connectUopiToUopi = (srcUoPi, dstUoPi, srcMessagePortGuid, dstMessagePortGuid, mouseEvent) => {
    const srcMessagePort = this.getDiagramStormData(srcMessagePortGuid);
    const dstMessagePort = this.getDiagramStormData(dstMessagePortGuid);

    if (!srcMessagePort || !dstMessagePort) {
      return;
    }

    const srcPort = this.findOrCreateUoPiOutputPort(srcUoPi, srcMessagePort.getData());
    const dstPort = this.findOrCreateUoPiInputPort(dstUoPi, dstMessagePort.getData());

    const sourcePoint = srcUoPi.getCenterPoint();
    const targetPoint = mouseEvent ? this.engine.getRelativeMousePoint(mouseEvent) : dstUoPi.getCenterPoint();
    const lineEquation = calculateLineEquation(sourcePoint, targetPoint);

    // reposition port if this is the first connection
    if (!srcPort.countLinks()) {
      const srcPos = findIntersectingPoint(lineEquation, srcUoPi, targetPoint);
      srcPort.setPosition(srcPos.portPosition.x, srcPos.portPosition.y);
      srcPort.setWidgetPosition(srcPos.widgetPosition);
    }

    // reposition port if this is the first connection
    if (!dstPort.countLinks()) {
      const dstPos = findIntersectingPoint(lineEquation, dstUoPi, sourcePoint);
      dstPort.setPosition(dstPos.portPosition.x, dstPos.portPosition.y);
      dstPort.setWidgetPosition(dstPos.widgetPosition);
    }

    this.createLink(srcPort, dstPort);

    // slight delay is needed or else the line positions will be slightly off
    setTimeout(() => {
      srcUoPi.fireEvent({}, "nodeDataChanged");
      dstUoPi.fireEvent({}, "nodeDataChanged");
      this.engine.repaintCanvas();
    }, 0)
  }

  /**
   * 
   * @param {NodeModel} srcUoPi 
   * @param {NodeModel} dstBlock 
   * @param {object} srcMessagePort 
   * @param {event} mouseEvent 
   */
  connectUopiToBlock = (srcUoPi, dstBlock, srcMessagePortGuid, dstPortGuid, mouseEvent) => {
    const srcMessagePort = this.getDiagramStormData(srcMessagePortGuid);
    let inPortData = null;

    if (!srcMessagePort) {
      return;
    }

    // dstPortGuid was selected from the DataType Modal
    if (dstPortGuid) {
      inPortData = this.getDiagramStormData(dstPortGuid);
    }

    // special case for Composed Port
    if (["im:ComposedInPort", "im:ComposedOutPort"].includes(dstBlock.getXmiType())) {
      return receiveWarnings("Cannot connect a UoP Instance to a Composed Port.");
    }

    //Make a new in port
    if (dstBlock.getXmiType() === "im:FanIn") {
      inPortData = this.props.$manager.createChildStormData("im:InPort", dstBlock.getStormData());  
      if (isStormData(inPortData)) {
        this.makePortCopy(dstBlock, inPortData);
      }
    }

    //Make a new in port
    if (dstBlock.getXmiType() === "im:Generic") {
      inPortData = this.props.$manager.createChildStormData("im:InPort", dstBlock.getStormData());  
      if (isStormData(inPortData)) {
        this.addStormPort(dstBlock, inPortData);
      }
    }

    const srcPort = this.findOrCreateUoPiOutputPort(srcUoPi, srcMessagePort.getData());
    const dstPort = dstBlock.getImInPort(inPortData?.getGuid());

    if (!srcPort || !dstPort) {
      return;
    }

    const sourcePoint = srcUoPi.getCenterPoint();
    const targetPoint = mouseEvent ? this.engine.getRelativeMousePoint(mouseEvent) : dstBlock.getCenterPoint();
    const lineEquation = calculateLineEquation(sourcePoint, targetPoint);

    // reposition port if this is the first connection
    if (!srcPort.countLinks()) {
      const srcPos = findIntersectingPoint(lineEquation, srcUoPi, targetPoint);
      srcPort.setPosition(srcPos.portPosition.x, srcPos.portPosition.y);
      srcPort.setWidgetPosition(srcPos.widgetPosition);
    }

    // reposition port if this is the first connection
    if (!dstPort.countLinks()) {
      const dstPos = findIntersectingPoint(lineEquation, dstBlock, sourcePoint);
      dstPort.setPosition(dstPos.portPosition.x, dstPos.portPosition.y);
      dstPort.setWidgetPosition(dstPos.widgetPosition);
    }

    this.createLink(srcPort, dstPort);

    const viewGuid = extractDataTypeFromSourceMessagePort(srcMessagePort.getData());
    const view = this.props.viewList.find(v => v.guid === viewGuid);
    dstPort.setDataType(view);

    // slight delay is needed or else the line positions will be slightly off
    setTimeout(() => {
      srcUoPi.fireEvent({}, "nodeDataChanged");
      dstBlock.fireEvent({}, "nodeDataChanged");
      this.engine.repaintCanvas();
    }, 0)
  }

  /**
   * 
   * @param {NodeModel} srcBlock 
   * @param {NodeModel} dstUoPi 
   * @param {object} dstMessagePort 
   * @param {event} mouseEvent 
   */
  connectBlockToUopi = (srcBlock, dstUoPi, srcPortGuid, dstMessagePortGuid, mouseEvent) => {
    const dstMessagePort = this.getDiagramStormData(dstMessagePortGuid);
    let outPortData = null;

    if (!dstMessagePort) {
      return;
    }

    // srcPortGuid was selected from the DataType Modal
    if (srcPortGuid) {
      outPortData = this.getDiagramStormData(srcPortGuid);
    }

    // special case for Composed Port
    if (["im:ComposedInPort", "im:ComposedOutPort"].includes(srcBlock.getXmiType())) {
      return receiveWarnings("Cannot connect a Composed Port to a UoP Instance.");
    }

    //Make a new out port
    if (srcBlock.getXmiType() === "im:FanOut") {
      outPortData = this.props.$manager.createChildStormData("im:OutPort", srcBlock.getStormData());  
      if (isStormData(outPortData)) {
        this.makePortCopy(srcBlock, outPortData);
      }
    }

    //Make a new out port
    if (srcBlock.getXmiType() === "im:Generic") {
      outPortData = this.props.$manager.createChildStormData("im:OutPort", srcBlock.getStormData());  
      if (isStormData(outPortData)) {
        this.addStormPort(srcBlock, outPortData);
      }
    }

    const srcPort = srcBlock.getImOutPort(outPortData?.getGuid());
    const dstPort = this.findOrCreateUoPiInputPort(dstUoPi, dstMessagePort.getData());

    if (!srcPort || !dstPort) {
      return;
    }

    const sourcePoint = srcBlock.getCenterPoint();
    const targetPoint = mouseEvent ? this.engine.getRelativeMousePoint(mouseEvent) : dstUoPi.getCenterPoint();
    const lineEquation = calculateLineEquation(sourcePoint, targetPoint);

    // reposition port if this is the first connection
    if (!srcPort.countLinks()) {
      const srcPos = findIntersectingPoint(lineEquation, srcBlock, targetPoint);
      srcPort.setPosition(srcPos.portPosition.x, srcPos.portPosition.y);
      srcPort.setWidgetPosition(srcPos.widgetPosition);
    }

    // reposition port if this is the first connection
    if (!dstPort.countLinks()) {
      const dstPos = findIntersectingPoint(lineEquation, dstUoPi, sourcePoint);
      dstPort.setPosition(dstPos.portPosition.x, dstPos.portPosition.y);
      dstPort.setWidgetPosition(dstPos.widgetPosition);
    }

    this.createLink(srcPort, dstPort);

    const viewGuid = extractDataTypeFromDestinationMessagePort(dstMessagePort.getData());
    const view = this.props.viewList.find(v => v.guid === viewGuid);
    srcPort.setDataType(view);

    // slight delay is needed or else the line positions will be slightly off
    setTimeout(() => {
      srcBlock.fireEvent({}, "nodeDataChanged");
      dstUoPi.fireEvent({}, "nodeDataChanged");
      this.engine.repaintCanvas();
    }, 0)
  }


  makePortCopy(nodeModel, childData) {
      if (!nodeModel || !childData) {
        return null;
      }
  
      let newPort = null;
      switch(childData.getXmiType()) {
        case "im:InPort":
          newPort = nodeModel.setInPort(childData);
          break;
  
        case "im:OutPort":
          newPort = nodeModel.setOutPort(childData);
          break;
      }
  
      if (!newPort) {
        return null;
      }

      // find existing port to get Type and DataType/TemplateType
      const port = Object.values(nodeModel.getPorts()).find(p => p.getName() !== 'draw-tool');
      if (!port) {
        return newPort;
      }
  
      newPort.setDefaultPosition();
      switch (port.getType()) {
        case "Default":
          const view = this.props.viewList.find(v => v.guid === port.getDataType());
          newPort.setDataType(view);
          break;
  
        case "Templated":
          newPort.setTemplateType(port.getTemplateType());
          break;
      }
  
      return newPort;
    }

  /**
   * Output Endpoint is dynamically created
   *    
   * @param {NodeModel} srcUoPi 
   * @param {object} srcMessagePort 
   * @returns existing port or creates a new port
   */
  findOrCreateUoPiOutputPort = (srcUoPi, srcMessagePort) => {
    // find child with matching connection
    let endpointData = srcUoPi.findChild(srcMessagePort.guid, false);

    // create child if not found
    if (!endpointData) {
      endpointData = this.props.$manager.createChildStormData("im:UoPOutputEndPoint", srcUoPi.getStormData(), { connection: srcMessagePort.guid });
    }

    // find port or create port
    let srcPort = srcUoPi.getPort(endpointData.getGuid());
    if (!srcPort) srcPort = srcUoPi.createImOutPort(endpointData);
    return srcPort;
  }

  /**
   * Input Endpoint is dynamically created
   * 
   * @param {NodeModel} dstUoPi 
   * @param {object} dstMessagePort 
   * @returns existing port or creates a new port
   */
  findOrCreateUoPiInputPort = (dstUoPi, dstMessagePort) => {
    // find child with matching connection
    let endpointData = dstUoPi.findChild(dstMessagePort.guid, true);

    // create child if not found
    if (!endpointData) {
      endpointData = this.props.$manager.createChildStormData("im:UoPInputEndPoint", dstUoPi.getStormData(), { connection: dstMessagePort.guid });
    }

    // find port or create port
    let dstPort = dstUoPi.getPort(endpointData.getGuid());
    if (!dstPort) dstPort = dstUoPi.createImInPort(endpointData);
    return dstPort;
  }

  /**
   * In the context of a ComposedBlock, the ComposedInPort is a top level node and have an outbound connection.
   *  When viewed from a ComposedBlockInstance perspective, the ComposedInPort will act like a regular input field
   *  (yeah name is confusing)
   * 
   * @param {NodeModel} ComposedInPortNodeModel 
   * @returns BasePort
   */
  findOrCreateOutPort_for_ComposedInPort = (ComposedInPortNodeModel) => {
    if (ComposedInPortNodeModel?.getXmiType() !== "im:ComposedInPort") {
      return;
    }

    const outPortData = ComposedInPortNodeModel.getStormData();
    let outPort = ComposedInPortNodeModel.getPort(outPortData.getGuid());

    if (!outPort && isStormData(outPortData)) {
      return this.addStormPort(ComposedInPortNodeModel, outPortData);
    }
  }

  /**
   * In the context of a ComposedBlock, the ComposedOutPort is a top level node and have an inbound connection.
   *  When viewed from a ComposedBlockInstance perspective, the ComposedOutPort will act like a regular output field
   *  (yeah name is confusing)
   * 
   * @param {NodeModel} ComposedInPortNodeModel 
   * @returns BasePort
   */
  findOrCreateInPort_for_ComposedOutPort = (ComposedOutPortNodeModel) => {
    if (ComposedOutPortNodeModel?.getXmiType() !== "im:ComposedOutPort") {
      return;
    }

    const inPortData = ComposedOutPortNodeModel.getStormData();
    let inPort = ComposedOutPortNodeModel.getPort(inPortData.getGuid());

    if (!inPort && isStormData(inPortData)) {
      return this.addStormPort(ComposedOutPortNodeModel, inPortData);
    }
  }



  // ------------------------------------------------------------
  // 15. Drag and Drop (Adding Nodes to Diagram)
  // ------------------------------------------------------------
  /**
   * Loops through children and adds diagram ports to canvas
   * 
   * @param {NodeModel} nodeModel 
   */
  addStormPorts = (nodeModel) => {
    const stormData = nodeModel.getStormData();
    
    switch (stormData.getXmiType()) {
      default:
        for (let childGuid of stormData.getChildren()) {
          const childData = this.getDiagramStormData(childGuid);
          this.addStormPort(nodeModel, childData);
        }
    }
  }

  /**
   * Add individual port to canvas
   * 
   * @param {NodeModel} nodeModel 
   * @param {StormData} childData 
   * @returns void
   */
  addStormPort = (nodeModel, childData) => {
    if (!isStormData(childData)) return;
    let port;

    switch (childData.getXmiType()) {
      case "im:InPort":
      case "im:ComposedOutPort":          // name is reversed on purpose
      case "im:ComposedInputEndpoint":
        port = nodeModel.setInPort(childData);
        break;
      case "im:OutPort":
      case "im:ComposedInPort":           // name is reversed on purpose
      case "im:ComposedOutputEndpoint":
        port = nodeModel.setOutPort(childData);
        break;
      // skip UoPInputEndPoint and UoPOutputEndPoint
      default:
        return;
    }

    port && port.setDefaultPosition();
    return port;
  }


  /**
   * Add color attribute to NodeModel
   *    originally this was handled by the node's contructor, but it fails when reloading the diagram because the constructor triggers before the deserialization step
   * 
   * @param {NodeModel} nodeModel 
   */
  addStormColor = (nodeModel) => {
    const stormData = nodeModel.getStormData();
    nodeModel.setNodeColor(diagramColors[stormData.getXmiType()] || diagramColors["default"]);
  }


  /**
   * Handles Drag and Drop from stencil box
   * 
   * @param {event} e 
   * @returns void
   */
  addStencil = (e) => {
    const stencilType = e.dataTransfer.getData("stencil-item");
    if (stencilType.trim() === "") return;
    const mouse_point = this.engine.getRelativeMousePoint(e);

    switch (stencilType) {
      case "im:ComposedInPort":
      case "im:ComposedOutPort": 
        const context = this.getContextData();

        const size = 30;
        var x = mouse_point.x - size / 2;
        var y = mouse_point.y - size / 2;

        var nodeData = this.props.$manager.createComposedStormData(stencilType, context);
        if (!isStormData(nodeData)) return;
        var nodeModel = new ImComposedPortBlockModel({ nodeData }, this);
        break;

      default:
        var nodeData = this.createStormData(stencilType);
        if (!isStormData(nodeData)) return;
        var nodeModel = new ImBlockNodeModel({ nodeData }, this);
        var {x, y} = centerCoordinatesOnDragEnd(mouse_point.x, mouse_point.y, 200);
    }

    if (!nodeModel) {
      return;
    }

    nodeModel.setPosition(x, y);
    this.addStormColor(nodeModel);
    this.model.addNode(nodeModel);
    this.engine.repaintCanvas();

    // model.addNode() is asynchronous
    // if addStormPorts is called synchronously, the nodeModel does not have the proper width and height yet
    setTimeout(() => {
      this.addStormPorts(nodeModel);
      this.model.clearSelection();
      nodeModel.setSelected(true);
    }, 0);
  }

  /**
   * Handles Drag and Drop from NavTee
   * 
   * @param {object} node 
   * @param {array} pos x, y coordindates
   * @param {object} config extra drag and drop options
   * @returns NodeModel if successfully created, undefined otherwise;
   */
  addNode = async (node, pos, config={}) => {
    // invalid node
    if (!node?.guid || !node?.xmiType) {
      return;
    }

    const { useRelativePoint=true, centerNodeOnDrop=true, showDialog=false } = config;
    let point = useRelativePoint ? this.engine.getRelativePoint(pos[0], pos[1]) : { x: pos[0], y: pos[1] };

    if (showDialog) {
      BasicAlert.show(`Adding ${node.name || "node"}`, "Adding node", false);
    }

    // Node was already added
    let nodeModel = this.findNodeModel(node.guid);
    if (nodeModel) {
      return nodeModel;
    }

    // create a new nodeModel
    // and place in the diagram
    switch (node.xmiType) {
      case "im:UoPInstance":
        var nodeData = await this.props.$manager.getOrFetchDiagramStormData(node);
        if (isStormData(nodeData)) {
          nodeModel = new ImUopInstanceNodeModel({ nodeData }, this);
        }
        break;

      case "im:ComposedInPort":
      case "im:ComposedOutPort":
        var nodeData = await this.props.$manager.getOrFetchDiagramStormData(node);
        if (isStormData(nodeData)) {
          nodeModel = new ImComposedPortBlockModel({ nodeData }, this);
        }
        break;

      // SPECIAL CASE
      case "im:ComposedBlock":
        var nodeData = await this.props.$manager.fetchStormData(node);
        if (isStormData(nodeData)) {
          nodeModel = new ImBlockNodeModel({ nodeData }, this);
        }
        break;

      default:
        var nodeData = await this.props.$manager.getOrFetchDiagramStormData(node);
        if (isStormData(nodeData)) {
          nodeModel = new ImBlockNodeModel({ nodeData }, this);
        }
    }

    // something went wrong
    if (!nodeModel) {
      return;
    }

    // centers the node relative to the mouse
    if (centerNodeOnDrop) {
      const adjusted = centerCoordinatesOnDragEnd(point.x, point.y, 200);
      point.x = adjusted.x;
      point.y = adjusted.y;
    }

    nodeModel.setPosition(point.x, point.y);
    this.addStormColor(nodeModel);
    this.model.addNode(nodeModel);

    // model.addNode() is asynchronous
    // if addStormPorts is called synchronously, the nodeModel does not have the proper width and height yet
    setTimeout(() => {
      this.addStormPorts(nodeModel);
      this.model.clearSelection();
      nodeModel.setSelected(true);
    }, 0);

    this.forceUpdate();
    return nodeModel;
  }

  /**
   * Handles Drag and Drop from NavTee
   * 
   * @param {object} nodes single node or an array of nodes
   * @param {array} pos x, y coordindates
   * @param {object} config extra drag and drop options
   * @returns NodeModel if successfully created, undefined otherwise;
   */
  addNodes = async (nodes, pos, config={}) => {
    if (!Array.isArray(nodes)) nodes = [nodes];
    const { useRelativePoint=true, centerNodeOnDrop=true, showDialog=false } = config;
    const diagramNodes = [];
    let point = useRelativePoint ? this.engine.getRelativePoint(pos[0], pos[1]) : { x: pos[0], y: pos[1] };

    if (nodes.find(n => n.guid === this.getContextGuid())) {
      return receiveErrors("Cannot add Context to itself");
    }

    if (showDialog) {
      let noun = nodes.length > 1 ? "Nodes" : "Node";
      BasicAlert.show(`Adding ${nodes.length} ${noun}`, `Adding ${noun}`, false);
    }

    for(let node of nodes) {
      // invalid node
      if (!node?.guid || !node?.xmiType) {
        continue;
      }

      // if user dragged in multiple nodes at once. Need to set new coords for next nodes
      const prevNode = diagramNodes[diagramNodes.length - 1];
      if (prevNode) {
        point.x = prevNode.getX() + nodeProps.width;
        point.y = prevNode.getY();

      } else if (centerNodeOnDrop) {
        const adjusted = centerCoordinatesOnDragEnd(point.x, point.y, 200);
        point.x = adjusted.x;
        point.y = adjusted.y;
      }

      const nodeModel = await this.addNode(node, [point.x, point.y], { useRelativePoint: false, centerNodeOnDrop: false, showDialog: false });
      if (!nodeModel) {
        continue;
      }

      diagramNodes.push(nodeModel);
    }

    BasicAlert.hide();
    this.engine.repaintCanvas();
    this.resizeCanvas();
    return diagramNodes;
  };



  // ------------------------------------------------------------
  // 20. Context
  // ------------------------------------------------------------
  getContextData = () => {
    return this.state.context;
  }

  setContextData = (contextData) => {
    isStormData(contextData) && this.setState({ context: contextData });
  }

  getContextGuid = () => {
    return this.getContextData().getGuid();
  }

  updateContextName = (name="") => {
    this.getContextData().setAttr("name", name);
    this.props.$manager.updateTabProps("fileName", name, this.props.tabIdx);
  }

  updateContextType = (isComposed=false) => {
    const xmiType = isComposed ? "im:ComposedBlock" : "im:IntegrationContext";
    this.getContextData().setAttr("xmiType", xmiType);

    if (isComposed) {
      this.addComposedBorders();
    } else {
      this.removeComposedBorders();
    }

    this.forceUpdate();
  }




  // ------------------------------------------------------------
  // 25. Side Bar
  // ------------------------------------------------------------
  getSidebarState = () => {
    return this.sidebarState;
  }

  setSidebarState = (state={}) => {
    for (let key in state) {
      this.sidebarState[key] = state[key];
    }
    this.forceUpdate();
  }

  setSidebarNodeModel = (nodeModel) => {
    this.setSidebarState({ nodeModel });
  }

  forceSidebarUpdate = () => {
    this.sidePanelRef.current.forceUpdate();
  }




  // ------------------------------------------------------------
  // 50. Helper
  // ------------------------------------------------------------
  findNode = (guid) => {
    const node = this.getDiagramStormData(guid) || NavTree.getLeafNode(guid);
    return node?.getData();
  }

  findNodeModel = (guid) => {
      return this.engine.getModel().getNodes().find(node => node.getStormData()?.getGuid() === guid);
  }

  updateZoomLevel = (zoom) => {
    this.model.setZoomLevel(zoom);
    this.forceUpdate();
  }

  autoLayout = () => {
    this.dagreEngine.redistribute(this.model);
    this.engine
        .getLinkFactories()
        .getFactory(PathFindingLinkFactory.NAME)
        .calculateRoutingMatrix();
    this.engine.repaintCanvas();
    this.forceUpdate();
  }

  handleOnDrop = (event) => {
    const rawTreeData = event.dataTransfer.getData("treeNodes");
    const treeNodes = rawTreeData && JSON.parse(rawTreeData);
    if (treeNodes && treeNodes.every(node => ["im:UoPInstance", "im:ComposedBlock"].includes(node.xmiType))) {
      return this.addNodes(treeNodes, [event.clientX, event.clientY]);
    }

    const stencilType = event.dataTransfer.getData("stencil-item");
    if (stencilType) {
      return this.addStencil(event);
    }
  }


  // ------------------------------------------------------------
  // 55. Action - Modal Methods
  // ------------------------------------------------------------


  // ------------------------------------------------------------
  // # Renders
  // ------------------------------------------------------------
  renderStencilBar = () => {
    const isComposed = this.getContextData().getXmiType() === "im:ComposedBlock";

    return <div className="storm-stencilbox">
      <StencilItem id="stencil-source"
                   xmiType="im:SourceNode"
                   text="Source"
                   bgColor={diagramColors["im:SourceNode"]} />
      <StencilItem id="stencil-sink"
                   xmiType="im:SinkNode"
                   text="Sink"
                   bgColor={diagramColors["im:SinkNode"]} />

      <div className="stencil-blank" />

      <StencilItem id="stencil-transform"
                   xmiType="im:TransformNode"
                   text="Transform"
                   bgColor={diagramColors["im:TransformNode"]} />
      <StencilItem id="stencil-filter"
                   xmiType="im:FilterNode"
                   text="Filter"
                   bgColor={diagramColors["im:FilterNode"]} />
      <StencilItem id="stencil-transporter"
                   xmiType="im:ViewTransporterNode"
                   text="Transporter"
                   bgColor={diagramColors["im:ViewTransporterNode"]} />
      <StencilItem id="stencil-fanin"
                   xmiType="im:FanIn"
                   text="FanIn"
                   bgColor={diagramColors["im:FanIn"]} />
      <StencilItem id ="stencil-fanout"
                   xmiType="im:FanOut"
                   text="FanOut"
                   bgColor={diagramColors["im:FanOut"]} />
      <StencilItem id="stencil-generic"
                   xmiType="im:Generic"
                   text="Generic"
                   bgColor={diagramColors["im:Generic"]} />

      <div className="stencil-blank" />

      <StencilItem id="stencil-simadapter"
                   xmiType="im:SIMAdapter"
                   text="Sim Adapter"
                   bgColor={diagramColors["im:SIMAdapter"]} />
      <StencilItem id="stencil-queuingadapter"
                   xmiType="im:QueuingAdapter"
                   text="Queuing Adapter"
                   bgColor={diagramColors["im:QueuingAdapter"]} />
      <StencilItem id="stencil-datapump"
                   xmiType="im:DataPump"
                   text="Data Pump"
                   bgColor={diagramColors["im:DataPump"]} />

      <div className="stencil-blank" />

      {isComposed && <>
        <StencilItemImage id="stencil-composed-inport" 
                          xmiType="im:ComposedInPort"
                          text="Composed In-Port"
                          bgColor={diagramColors["default"]}
                          img={PortNoneImg}
                          offset={[21, 21]} />
        <StencilItemImage id="stencil-composed-outport" 
                          xmiType="im:ComposedOutPort"
                          text="Composed Out-Port"
                          bgColor={diagramColors["default"]}
                          img={PortNoneImg}
                          offset={[21, 21]} />
      </>}
    </div>
  }


  renderToolBar = () => {
    const { showUncommitted, showConnectorLines } = this.state;

    return <div className="diagram-toolbar">
      <button id="tool-clear-diagram"
              className="toolbar-icon-btn fas fa-eraser"
              title="Clear Diagram"
              onClick={() => {
                BasicConfirm.show(`Are you sure you want to clear this diagram?`, () => {
                  this.model.getNodes().forEach(node => node.remove());
                  this.model.getLinks().forEach(link => link.remove());
                  this.engine.repaintCanvas();
                  this.setDiagramUnsavedStatus();
                })
              }} />

      <div className="toolbar-separator" />

      <DiagramZoomLevel id="tool-zoom"
                        zoomLevel={this.model.getZoomLevel()}
                        updateZoomLevel={this.updateZoomLevel} />

      <div className="toolbar-section" data-title="Diagram">
        <button id="load-diagram"
                className="toolbar-icon-btn fas fa-file-import"
                title="Load Diagram"
                onClick={() => this.props.$manager.showDialog("load_context")} />
        <button id="save-diagram"
                className="toolbar-icon-btn fas fa-save"
                title="Save Diagram"
                onClick={this.saveDiagram} />
        <button id="export-diagram"
                className="toolbar-icon-btn fas fa-file-export"
                title="Export Diagram"
                onClick={() => this.props.$manager.exportDiagramWithCropping(this.props.tabIdx)} />
      </div>

      <div className="toolbar-separator" />

      <div className="toolbar-section" data-title="Model">
        <button id="commit-context"
                className="toolbar-text-btn"
                onClick={this.saveContext}>Commit</button>
      </div>

      <div className="toolbar-separator" />

      <div className="toolbar-section" data-title="Create New">
        <button id="create-uop"
                className="toolbar-text-btn"
                onClick={() => this.setModalType("uopi")}>UoP Instance</button>
      </div>

      <div className="toolbar-separator" />

      <div className="toolbar-section" data-title="Highlight Uncommitted Changes"
            style={{
              width: 140,
              justifyContent: "center",
              fontSize: 10,
            }}>
        <Toggle id="toggle-uncommitted" 
                options={["OFF", "ON"]}
                startingPosition={showUncommitted ? 1 : 0 }
                style={{
                  fontSize: 12,
                  width: 75,
                }}
                toggleFunction={(bool) => {
                  this.setState((prevState) => ({ showUncommitted: !prevState.showUncommitted }), () => {
                    this.refresh();
                  })
                }} />
      </div>

      <div className="toolbar-separator" />

      <div className="toolbar-section" data-title="Toggle Connector Labels"
            style={{
              width: 120,
              justifyContent: "center",
              fontSize: 10,
            }}>
        <Toggle id="toggle-connectors" 
                options={["OFF", "ON"]}
                startingPosition={showConnectorLines ? 1 : 0 }
                style={{
                  fontSize: 12,
                  width: 75,
                }}
                toggleFunction={(bool) => {
                  this.setState((prevState) => ({ showConnectorLines: !prevState.showConnectorLines }), () => {
                    this.refresh();
                  })
                }} />
      </div>
    </div>
  }
  

  render() {
    let canvasClasses = ["srd-demo-canvas"];

    return (
      <div className="storm-diagram">
        <div className="storm-header">
          { this.renderToolBar() }
        </div>

        { this.renderStencilBar() }

        <div className="storm-canvas" style={{background: "#F6F6F6"}}>
          <div id={this.phenomId.genPageId("graph-container")}
                className="storm-container"
                onDragOver={(e) => e.preventDefault()}
                onDrop={this.handleOnDrop}
                onClick={this._handleContextClick}
                ref={el => this.containerDOM = el}>
            <FadingDirections idCtx={this.phenomId.genPageId()} text="Drag and drop transport nodes from the Stencil to represent the way data flows through your system. Create UoP Instances on the toolbar."/>
            <CanvasWidget className={canvasClasses.join(" ")} engine={this.engine} smartRouting={true} />
          </div>
        </div>

        <ImSidePanel $app={this}
                     $manager={this.props.$manager}
                      id={this.phenomId.genPageId()}
                      contextData={this.getContextData()}
                      selectedNode={this.getSidebarState().nodeModel}
                      imPackageList={this.props.imPackageList}
                      transportChannelList={this.props.transportChannelList}
                      viewList={this.props.viewList}
                      ref={this.sidePanelRef} />


        {this.state.modalType === "uopi" &&
        <UoPIModal close={() => this.setModalType(null)} /> }

        <DataTypeModal $app={this}
                       ref={el => this.dataTypeModalRef = el} />
      </div>
    );
  }
}



// ------------------------------------------------------------
// 60. Action - Custom Delete Action
// ------------------------------------------------------------
class CustomDeleteItemsAction extends Action {
    constructor(options = {}) {
        options = {
            keyCodes: [46],
            ...options
        };

        super({
            type: InputType.KEY_DOWN,
            fire: (event) => {
                if (options.keyCodes.indexOf(event.event.keyCode) !== -1) {
                    const { $app } = this.engine;
                    const selectedEntities = this.engine.getModel().getSelectedEntities();

                    if (selectedEntities.find(e => e.isLocked())){
                      return;
                    }

                    $app.removeNodeModelsFromDiagram(selectedEntities);
                }
            }
        });
    }
}



// ------------------------------------------------------------
// 61. Action - Custom Mouse Up Action
// ------------------------------------------------------------
class CustomMouseUpAction extends Action {
  constructor() {
    super({
      type: InputType.MOUSE_UP,
      fire: (e) => {
        const {event} = e;
        const element = this.engine.getMouseElement(event);
        if(!element || event.button !== 2) return;

        const offset = { left: event.pageX + 5, top: event.pageY };
        const { $app } = this.engine;
        const menuItems = [];

        // ------------------------------------------------------------
        // NodeModel
        // ------------------------------------------------------------
        if (element instanceof BaseNodeModel) {
          menuItems.push({
            text: "Change node color",
            type: "colorInput",
            dontCloseMenu: true,
            colorChange: (hexColor) => {
              element.setNodeColor(hexColor)
            },
          })

          menuItems.push({
            text: element instanceof ImUopInstanceNodeModel ? "Remove" : "Delete",
            func: () => $app.removeNodeModelsFromDiagram([ element ]),
          })

        // ------------------------------------------------------------
        // LinkModel
        // ------------------------------------------------------------
        } else if (element instanceof DefaultLinkModel) {
          menuItems.push({
            text: "Change color",
            type: "colorInput",
            dontCloseMenu: true,
            colorChange: (hexColor) => {
              element.setLinkColor(hexColor)
            },
          })

          menuItems.push({
            text: "Delete",
            func: () => {
              element.remove();
              this.engine.repaintCanvas();
            },
          })
        }

        // render context menu
        if(menuItems.length) {
          ContextMenu.show(menuItems, offset);
        }
      }
    })
  }
}



// ------------------------------------------------------------
// 62. Action - Custom Mouse Down Action
// ------------------------------------------------------------
class CustomMouseDownAction extends Action {
    constructor() {
        super({
            type: InputType.MOUSE_DOWN,
            fire: (e) => {
                const {event} = e;
                const element = this.engine.getMouseElement(event);
                if(element) return;

                switch(event.button) {
                    case 2:
                        this.startCanvasDrag(event);
                        break;
                }
            }
        });
        this.start_point = {};
        this.curr_point = {};
    }

    // =================================
    // CANVAS DRAG
    // =================================
    startCanvasDrag = (e) => {
        // if(e.preventDefault) e.preventDefault();
        // if(e.stopPropagation) e.stopPropagation();

        // perform canvas drag if and only if nothing was selected
        const element = this.engine.getMouseElement(e);
        if(element) return;

        // let start_point = {x: e.clientX, y: e.clientY};
        // let curr_point;

        this.start_point = {x: e.clientX, y: e.clientY};

        window.addEventListener("mousemove", this.moveCanvas);
        window.addEventListener("mouseup", this.stopMoveCanvas);
    }

    moveCanvas = (e) => {
        const {canvas} = this.engine;
        // curr_point = {x: e.clientX, y: e.clientY};
        let curr_point = {x: e.clientX, y: e.clientY};

        const diff_x = this.start_point.x - curr_point.x;
        const diff_y = this.start_point.y - curr_point.y;

        canvas.parentElement.scrollLeft += diff_x;
        canvas.parentElement.scrollTop += diff_y;

        this.start_point = curr_point;
    }

    stopMoveCanvas = () => {
        window.removeEventListener("mousemove", this.moveCanvas);
        window.removeEventListener("mouseup", this.stopMoveCanvas);
    }
}






class UoPIModal extends React.Component {
  state = {
    animateIn: true,
  }

  close = () => {
    this.setState({ animateIn: false }, () => {
      setTimeout(this.props.close, 300);
    })
  }

  render() {
    return (
      <Portal>
        <Modal2 show={this.state.animateIn}>
          <div style={{ width: 450 }}>
            <UOPInstanceManager dialog={true}
                                closeDialog = {this.close}
                                match={{params: {guid: "new"}}}
                                idm={true} />
          </div>
        </Modal2>
      </Portal>
    )
  }
}
