import { PhenomToggle, DeprecatedWarning, PhenomComboBox, PhenomInput, PhenomSelect, PackageComboBox, PhenomTextArea, PhenomLabel } from "../util/stateless";
import DeletionConfirm2 from "../../components/dialog/DeletionConfirm2";
import {NodeHistory2} from "./node-history";
import {Button, Toolbar, ToolbarItem} from "@progress/kendo-react-buttons";
import React from "react";
import {createPhenomGuid, isPhenomGuid} from "../util/util";
import PhenomId from "../../requests/phenom-id";
import { withPageLayout } from "./node-layout";
import { cloneDeep } from "lodash";
import ChangeSetPicker from "../widget/ChangeSetPicker";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { useState } from "react";
import NodeDetails from "./node-details";


export class PortableComponentManager extends React.Component {
    static defaultProps = {
      newNode: {
        name: "",
        xmiType: "uop:PortableComponent",
        description: "",
        partitionType: "POSIX",
        faceProfile: "GeneralPurpose",
        transportAPILanguage: "C",
        inboundLCMPort: null,
        outboundLCMPort: null,
        notes: "",
        children: [],
        subModelId: undefined,
      },
      nodeAddenda: {
        coreAddenda: ["childrenMULTI"],
        coreAddendaChildren: ["messageType", "requestType", "responseType", "lcmMessageType"]
      },
      nodesOfTypeAddenda: {
        "platform:View": {
          type: "platform:View",
          Filter: {
            firstPass: {
              event: "topLevelViewsWithComposite",
            }
          }
        }
      },
    }
    constructor(props) {
      super(props);
      this.phenomId = new PhenomId("edit-portable-component");
    }

    defaultState = {
      children: [],
    }
    state = {
      ...cloneDeep(this.defaultState),
    }

    pagination = {
      skip: 0,
      take: 25,
    }

    messagingPatternOptions = [
      { value: "pub-sub", text: "Publish/Subscribe" },
      { value: "Client", text: "Client" },
      { value: "Server", text: "Server" },
    ]

    partitionTypeOptions = [
      { value: "POSIX", text: "POSIX" },
      { value: "ARINC653", text: "ARINC653" },
    ]

    faceProfileOptions = [
      { value: "GeneralPurpose", text: "General Purpose" },
      { value: "Security", text: "Security" },
      { value: "SafetyBase", text: "Safety Base" },
      { value: "SafetyExtended", text: "Safety Extended" },
    ]

    transportAPIOptions = [
      { value: "C", text: "C" },
      { value: "CPP", text: "C++" },
      { value: "Java", text: "Java" },
      { value: "Ada", text: "Ada" },
    ]

    componentDidMount() {
      this.initNodeState();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.node !== this.props.node) {
        this.initNodeState();
      }
    }

    initNodeState = () => {
      const { node } = this.props;
      const nodeChildren = node?.children || [];
      let inboundLCMPort, outboundLCMPort, children = [];

      for (let child of nodeChildren) {
        if (child.xmiType === "uop:LifeCycleManagementPort" && child.messageExchangeType === "InboundMessage") {
          inboundLCMPort = child;

        } else if (child.xmiType === "uop:LifeCycleManagementPort" && child.messageExchangeType === "OutboundMessage") {
          outboundLCMPort = child;

        } else {
          children.push(child);
        }
      }

      children.sort((a, b) => a.name.localeCompare(b.name));

      this.setState({
        ...cloneDeep(this.defaultState),
        ...node,
        inboundLCMPort,
        outboundLCMPort,
        children,
      });
    }

    generateNode = () => {
      const { inboundLCMPort, outboundLCMPort, children } = this.state;

      const portableComponent = {
        guid: this.state.guid || createPhenomGuid(),
        name: this.state.name,
        xmiType: this.state.xmiType,
        partitionType: this.state.partitionType,
        transportAPILanguage: this.state.transportAPILanguage,
        faceProfile: this.state.faceProfile,
        notes: this.state.notes,
        parent: this.state.parent,
        description: this.state.description,
        children: [],
        subModelId: this.state.subModelId,
      }

      // create shallow copy and do not mutate lcmMessage
      if (inboundLCMPort?.edited) {
        portableComponent.children.push({
          ...inboundLCMPort,
          lcmMessageType: inboundLCMPort.lcmMessageType?.guid,
        })
      }

      // create shallow copy and do not mutate lcmMessage
      if (outboundLCMPort?.edited) {
        portableComponent.children.push({
          ...outboundLCMPort,
          lcmMessageType: outboundLCMPort.lcmMessageType?.guid,
        })
      }

      // add edited children
      for (let msgPort of children) {
        if (!msgPort.edited) {
          continue;
        }

        const copy = {};
        const attrs = ["guid", "name", "description", "xmiType", "messagingPattern", "period", "parent"];

        // Publish/Subscribe
        if (msgPort.messagingPattern === "pub-sub") {
          attrs.push("messageExchangeType", "synchronizationStyle", "communicationStyle");
          copy.messageType = msgPort.messageType?.guid;     // view's guid

        } else {
          // Client or Server
          attrs.push("synchronizationStyle");
          copy.requestType = msgPort.requestType?.guid;     // view's guid
          copy.responseType = msgPort.responseType?.guid;   // view's guid
        }

        attrs.forEach(attr => copy[attr] = msgPort[attr]);
        portableComponent.children.push(copy);
      }

      return portableComponent;
    }

    updateLCMPort = (type, view) => {
      let lcmPort = this.state[type];
      let copy;
      
      if (!lcmPort) {
        copy = this.createNewLCMPort(type === "outboundLCMPort");
      } else {
        copy = { ...lcmPort };
      }

      copy.lcmMessageType = view;
      this.setState({ [type]: copy });
    }

    deleteLCMPort = (type) => {
      let lcmPort = this.state[type];
      if (!lcmPort) return;

      const callback = () => {
        this.setState({ [type]: null });
      }

      if (isPhenomGuid(lcmPort.guid)) {
        return callback();
      }

      DeletionConfirm2.show(lcmPort.guid, lcmPort.name, callback, true);
    }

    createNewLCMPort = (out) => {
      const lcmPort = {
        guid: createPhenomGuid(),
        lcmMessageType: "",
        xmiType: "uop:LifeCycleManagementPort",
        messageExchangeType: out ? "OutboundMessage" : "InboundMessage",
        edited: true,
      }

      return lcmPort;
    }

    pageChange = (event) => {
      this.pagination = {
        skip: event.page.skip,
        take: event.page.take,
      }
  
      this.forceUpdate();
    }

    updateMessagePort = (guid, key, value) => {
      const children = [...this.state.children];
      const idx = children.findIndex(msgPort => msgPort.guid === guid);
      if (idx < 0) return;

      const copy = { ...children[idx] };
      copy[key] = value;
      copy["edited"] = true;
      children[idx] = copy;
      this.setState({ children });
    }

    addNewMessagePort = () => {
      const children = [...this.state.children];
      children.push({
        guid: createPhenomGuid(),
        name: "",
        xmiType: "uop:MessagePort",
        description: "",
        messagingPattern: "pub-sub",
        messageExchangeType: "OutboundMessage",
        messageType: {},
        synchronizationStyle: "Blocking",
        communicationStyle: "Queuing",
        requestType: {},
        responseType: {},
        period: "0.0",
        parent: this.state.guid,
        edited: true,
      })

      // jump to the last page
      const pageIndex = Math.floor((children.length - 1) / this.pagination.take);
      this.pagination = {
        ...this.pagination,
        skip: pageIndex * this.pagination.take,
      }

      this.setState({ children });
    }

    removeMessagePort = (guid) => {
      const children = [...this.state.children];
      const childIdx = children.findIndex(c => c.guid === guid);

      if (childIdx < 0) {
        return;
      }

      const msgPort = children[childIdx];
      const callback = (status) => {
        if (status.deleted) {
          children.splice(childIdx, 1);

        } else if (status.deprecated) {
          children[childIdx] = {
            ...children[childIdx],    // creating a new object so componentDidUpdate will fire
            deprecated: "true",       // this is a string because this is how it's returned from backend
          }
        }
        this.setState({ children });
      }

      if (isPhenomGuid(guid)) {
        return callback({ deleted: true });
      }
      DeletionConfirm2.show(msgPort.guid, msgPort.name, callback, true);
    }

    renderRow = (_, cellProps) => {
      const { editable, nodesOfType } = this.props;
      const viewOptions = nodesOfType["platform:View"] || [];
      const child = cellProps.dataItem;

      return <tr>
              <InlineMessagePort node={child}
                                  phenomId={this.phenomId}
                                  editable={editable}
                                  viewOptions={viewOptions}
                                  messagingPatternOptions={this.messagingPatternOptions}
                                  removeChild={this.removeChild}
                                  updateMessagePort={this.updateMessagePort}
                                  removeMessagePort={this.removeMessagePort} />
      </tr>
    }

    render() {
        const { editable, nodesOfType } = this.props;
        const phenomId = this.phenomId;

        const viewOptions = nodesOfType["platform:View"] || [];

        return (
            <div className="edit-form">
                <div className="p-row">
                  <div className="p-col">
                    <PhenomInput label="UoP"
                                  id={this.phenomId.genPageId("name")}
                                  value={this.state.name}
                                  disabled={!editable}
                                  onChange={(e) => this.setState({ name: e.target.value })} />

                    <PackageComboBox label="Parent Package"
                                     xmiType="face:UoPModel"
                                     nodeGuid={this.state.guid}
                                     selectedGuid={this.state.parent}
                                     placeholder="<Default>"
                                     disabled={!editable}
                                     onChange={(parent) => this.setState({ parent: parent.guid })} />
                  </div>
                  <div className="edit-side-bar">
                        <NodeHistory2 ref={ele => this.historyRef = ele} guid={this.state.guid} idCtx={phenomId.genPageId()}/>
                        <NodeDetails guid={this.state.guid}/>
                        {/* <ChangeSetPicker id={phenomId.genPageId()}
                                         disabled={!editable}
                                         label="Change Set" /> */}
                    </div>
                </div>

                <PhenomTextArea label="Description"
                                id={this.phenomId.genPageId("description")}
                                value={this.state.description}
                                disabled={!editable}
                                onChange={(e) => this.setState({ description: e.target.value })} />

                <div className="p-row">
                  <div className="p-col">
                    <PhenomSelect label="Partition Type"
                                  id={this.phenomId.genPageId("partitionType")}
                                  value={this.state.partitionType}
                                  data={this.partitionTypeOptions}
                                  textField="text"
                                  dataItemKey="value"
                                  disabled={!editable}
                                  onChange={(e) => this.setState({ partitionType: e.target.value})} />
                  </div>
                  <div className="p-col">
                    <PhenomSelect label="Profile"
                                  id={this.phenomId.genPageId("faceProfile")}
                                  value={this.state.faceProfile}
                                  data={this.faceProfileOptions}
                                  textField="text"
                                  dataItemKey="value"
                                  disabled={!editable}
                                  onChange={(e) => this.setState({ faceProfile: e.target.value})} />
                  </div>
                  <div className="p-col">
                    <PhenomSelect label="Transport API Language"
                                  id={this.phenomId.genPageId("transportAPILanguage")}
                                  value={this.state.transportAPILanguage}
                                  data={this.transportAPIOptions}
                                  textField="text"
                                  dataItemKey="value"
                                  disabled={!editable}
                                  onChange={(e) => this.setState({ transportAPILanguage: e.target.value})} />
                  </div>
                </div>

                <div id={this.phenomId.genPageId("message-ports")}>
                  <PhenomLabel text="Message Ports" />
                  <Grid data={this.state.children.slice(this.pagination.skip, this.pagination.take + this.pagination.skip)}
                        pageable={this.state.children.length > this.pagination.take}
                        skip={this.pagination.skip}
                        take={this.pagination.take}
                        total={this.state.children.length}
                        onPageChange={this.pageChange}
                        rowRender={this.renderRow}>
                    <GridNoRecords>
                      Message Port(s) not found.
                    </GridNoRecords>
                    <GridColumn title="" />
                  </Grid>

                  {editable && <Toolbar>
                      <ToolbarItem>
                          <Button iconClass="fa fa-plus fa-fw"
                                  id={this.phenomId.genPageId("add-new-msg-port")}
                                  onClick={this.addNewMessagePort}
                                  className="k-button"
                                  disabled={!editable}>Add New Message Port</Button>
                      </ToolbarItem>
                  </Toolbar>}
                </div>

                <div>
                  <PhenomLabel text="Life Cycle Management Ports" />

                  <div className="p-row">
                    <div className="p-col">
                      <PhenomComboBox label="Inbound"
                                      value={this.state.inboundLCMPort?.lcmMessageType}
                                      data={viewOptions}
                                      dataItemKey="guid"
                                      textField="name"
                                      placeholder="Select an Inbound"
                                      disabled={!editable}
                                      onChange={(view) => this.updateLCMPort("inboundLCMPort", view)}
                                      onClickTrashIcon={() => this.deleteLCMPort("inboundLCMPort")} />
                    </div>

                    <div className="p-col">
                      <PhenomComboBox label="Outbound"
                                      value={this.state.outboundLCMPort?.lcmMessageType}
                                      data={viewOptions}
                                      dataItemKey="guid"
                                      textField="name"
                                      placeholder="Select an Outbound"
                                      disabled={!editable}
                                      onChange={(view) => this.updateLCMPort("outboundLCMPort", view)}
                                      onClickTrashIcon={() => this.deleteLCMPort("outboundLCMPort")} />
                    </div>
                  </div>
                </div>
            </div>
        );
    }
}




const InlineMessagePort = (props) => {
  const { node, editable, viewOptions, messagingPatternOptions, updateMessagePort, removeMessagePort  } = props;
  const [collapsed, setCollapsed] = useState(!isPhenomGuid(node?.guid));

  const labelStyle = {
    display: "block",
    fontSize: 14,
  }

  const columnStyle = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flex: "0 1 140px",
  }

  const toggleStyle = {
    "--toggle-width": "120px",
    "--toggle-height": "29px",
  }

  if (!node?.guid) {
    return null;
  }

  const { guid, messagingPattern, deprecated } = node;
  const isEditable = editable && deprecated !== "true";

  return <div style={{ display: "flex", flexDirection: "column", gap: 15, padding: 10 }}>
    <div className="phenom-form-row" style={{ display: "flex", flex: 1, gap: 10, alignItems: "center" }}>

      <div style={{ flex: "0 1", alignSelf: "flex-start" }}>
          <Button icon={collapsed ? "arrow-chevron-right" : "arrow-chevron-down"}
                  onClick={() => setCollapsed((prevCollapsed) => !prevCollapsed)} />
      </div>

      <div>
        <label style={labelStyle}>
          <DeprecatedWarning deprecated={deprecated} style={{ marginRight: 5 }} />
          Name
        </label>
        <PhenomInput value={node.name}
                     disabled={!isEditable}
                     onChange={(e) => updateMessagePort(guid, "name", e.target.value)} />
      </div>

      <div>
        <label style={labelStyle}>Messaging Pattern</label>
        <PhenomSelect value={messagingPattern}
                      data={messagingPatternOptions}
                      dataItemKey="value"
                      textField="text"
                      disabled={!isEditable}
                      onChange={(e) => updateMessagePort(guid, "messagingPattern", e.target.value)} />
      </div>

      {messagingPattern === "pub-sub" && <>
        <div style={columnStyle}>
          <label style={labelStyle}>Exchange Type</label>
          <PhenomToggle checked={node.messageExchangeType === "InboundMessage"}
                        data={["Outbound", "Inbound"]}
                        style={toggleStyle}
                        disabled={!isEditable}
                        onChange={(e) => {
                          const msgExchangeType = e.target.checked ? "InboundMessage" : "OutboundMessage";
                          updateMessagePort(guid, "messageExchangeType", msgExchangeType);
                        }} />
        </div>
        <div>
          <label style={labelStyle}>Message Type</label>
          <PhenomComboBox data={viewOptions} 
                          value={node.messageType}
                          dataItemKey="guid"
                          textField="name"
                          placeholder="Select a Message Type"
                          disabled={!isEditable}
                          onChange={(view) => updateMessagePort(guid, "messageType", view)} />
        </div>
      </>}

      {(messagingPattern === "Client" ||  messagingPattern === "Server") && <>
        <div>
          <label style={labelStyle}>Request Type</label>
          <PhenomComboBox data={viewOptions} 
                          value={node.requestType}
                          dataItemKey="guid"
                          textField="name"
                          placeholder="Select a Request Type"
                          disabled={!isEditable}
                          onChange={(view) => updateMessagePort(guid, "requestType", view)} />
        </div>

        <div>
          <label style={labelStyle}>Response Type</label>
          <PhenomComboBox data={viewOptions} 
                          value={node.responseType}
                          dataItemKey="guid"
                          textField="name"
                          placeholder="Select a Response Type"
                          disabled={!isEditable}
                          onChange={(view) => updateMessagePort(guid, "responseType", view)} />
        </div>
      </>}

      <div style={{ flex: "0 1", alignSelf: "flex-start" }}>
        <Button icon="trash" 
                disabled={!isEditable}
                onClick={() => removeMessagePort(guid)} />
      </div>
    </div>


    {/* COLLAPSED SECTION */}
    {!collapsed && 
      <div className="phenom-form-row" style={{ display: "flex", flex: 1, gap: 10, }}>
        <div>
          <label style={labelStyle}>Description</label>
          <PhenomInput value={node.description}
                       disabled={!isEditable}
                       onChange={(e) => updateMessagePort(guid, "description", e.target.value)} />
        </div>

        {!isPhenomGuid(node.guid) && 
          <div style={{flex: 1, overflowWrap: "break-word", wordBreak: "break-word"}}>
            <label style={labelStyle}>GUID</label>
            {node.guid}
          </div>}

        <div style={columnStyle}>
          <label style={labelStyle}>Synchronization</label>
          <PhenomToggle checked={node.synchronizationStyle === "NonBlocking"}
                        data={["Blocking", "Non-Blocking"]}
                        style={toggleStyle}
                        disabled={!isEditable}
                        onChange={(e) => {
                          const syncStyle = e.target.checked ? "NonBlocking" : "Blocking";
                          updateMessagePort(guid, "synchronizationStyle", syncStyle);
                        }} />
        </div>

        {messagingPattern === "pub-sub" && 
          <div style={columnStyle}>
            <label style={labelStyle}>Communication Style</label>
            <PhenomToggle checked={node.communicationStyle === "SingleInstanceMessaging"}
                          data={["Queuing", "Single Instance Messaging"]}
                          style={toggleStyle}
                          disabled={!isEditable}
                          onChange={(e) => {
                            const commStyle = e.target.checked ? "SingleInstanceMessaging" : "Queuing";
                            updateMessagePort(guid, "communicationStyle", commStyle);
                          }} />
          </div> }

        <div style={{ flex: "0 1 100px" }}>
          <label style={labelStyle}>Period(s)</label>
          <PhenomInput value={node.period}
                        type="number"
                        disabled={!isEditable}
                        onChange={(e) => updateMessagePort(guid, "period", e.target.value)} />
        </div>
      </div>
    }
  </div>

}


export const EditPortableComponentManager = withPageLayout(PortableComponentManager);
