import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { acceptUserSettings, getLicense } from '../../requests/sml-requests';
import { StyledLicenseContent } from '../../views/License';

// Steps to add a new splash
// 1. Add splash key with title & expiration date to splashMap in this file
// 2. Add <splash_key>.html into the public/splashes directory
// 3. Add <splash_key> to backend (accept-user-settings-handler)

class Splash extends React.Component {
  state = {
    license_confirmed: false,
    license_version: "0",
    license_text: "",

    activeSplash: null,
  }

  componentDidMount() {
    this.fetchLicense();
    this.fetchSplashes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.fetchLicense();
      this.fetchSplashes();
    }
  }

  // =========================================
  // LICENSE
  // =========================================
  fetchLicense() {
    const { user } = this.props;
    if (!user?.authenticated) return;

    getLicense().then(response => {
      if (response.data === "#void") return;
      const { settings } = user.userIdentity;

      // show license popup
      if (!settings.license_version || settings.license_version < response.license_version) {
        this.setState({
          license_confirmed: false,
          license_version: response.data.license_version,
          license_text: response.data.license_text
        })
      } else {
        // hide license popup
        this.setState({ license_confirmed: true });
      }
    })
  }

  acceptLicense = () => {
    acceptUserSettings("license_version", this.state.license_version).then((res) => {
      if (res.status === "success") {
        this.setState({ license_confirmed: true });
      } 
    })
  }

  // =========================================
  // GENERIC SPLASH
  // =========================================
  fetchSplashes = async () => {
    const { user } = this.props;
    if (!user?.authenticated) return;
    const { settings } = user?.userIdentity;
  
    const splashData = await Promise.all(Object.keys(splashMap).map(async (splashKey) => {
      const { expirationDate, title } = splashMap[splashKey];
      const currentDate = new Date();

      const splashConfirmed = !!settings[splashKey];
      const splashExpired = currentDate >= expirationDate;
  
      if (splashConfirmed || splashExpired) {
        return null;
      }
  
      const splashHtml = await this.getSplashHtml(splashKey);
      return { splashKey, splashHtml, splashTitle: title };
    }));
  
    splashData.forEach(data => {
      if (data) {
        this.setState(prevState => ({
          ...prevState,
          activeSplash: prevState.activeSplash ? prevState.activeSplash : data.splashKey, 
          [data.splashKey]: {
            splashConfirmed: false,
            splashTitle: data.splashTitle,
            splashHtml: data.splashHtml,
            onConfirm: () => this.setSplashConfirmed(data.splashKey)
          }
        }));
      }
    });
  }

  getSplashHtml = async (splashKey) => {
    try {
      const res = await fetch(`/splashes/${splashKey}.html`);
      if (!res.ok) {
        throw new Error('Failed to fetch the HTML content');
      }
      const text = await res.text();
      return text;
    } catch (err) {
      console.error('Error fetching HTML:', err);
      return null;
    }
  }

  setSplashConfirmed = (splashKey) => {
    acceptUserSettings(splashKey, 1).then(() => {
      this.setState({
        [splashKey]: {
          ...this.state[splashKey],
          splashHtml: null,
          splashConfirmed: true,
        }
      }, this.setActiveSplash)
    });
  }

  setActiveSplash = () => {
    const unconfirmed = Object.keys(splashMap).find(
      key => !this.state[key]?.splashConfirmed
    );

    if (unconfirmed) {
      this.setState({ activeSplash: unconfirmed });
    } else {
      this.setState({ activeSplash: null });
    }
  };


  // =========================================
  // RENDER METHODS
  // =========================================

  render() {
    const { activeSplash, license_confirmed } = this.state;
    const { user } = this.props;

    if (!user?.authenticated) return null;

    // Must render License Agreement first
    if (!this.state.license_confirmed) {
      return <LicenseConfirm text={this.state.license_text}
                             onConfirm={this.acceptLicense} />
    }

    // Render rest of generic splashes one at a time
    const activeSplashProps = this.state[activeSplash];
    if (license_confirmed && activeSplashProps && !activeSplashProps.splashConfirmed) {
      return <GenericSplash {...activeSplashProps} />;
    }

    return null;
  }
}





const LicenseConfirm = (props) => {
  const [confirm, setConfirm] = useState(false);

  if (!props.text) return null;

  return <Dialog title="Terms of Use" className="dialog-80vh dialog-80vw dialog-no-exit">
          <StyledLicenseContent>
            <div dangerouslySetInnerHTML={{ __html: props.text }} />
          </StyledLicenseContent>
          <div>
              <input type="checkbox" 
                     id="licence-checkbox" 
                     checked={confirm}
                     style={{ marginRight: 5 }}
                     onChange={() => setConfirm(!confirm)} />
              <label htmlFor="checkbox">{ "I have read and agree to the Terms of Use" }</label>
          </div>
          <DialogActionsBar>
                <button className='k-button'
                        id="licence-accept" 
                        disabled={!confirm}
                        onClick={props.onConfirm}>
                          Accept
                </button>
          </DialogActionsBar>
  </Dialog>
}

const GenericSplash = (props) => {
  if (!props.splashHtml) return null;

  return <Dialog title={props.splashTitle} className="dialog-80vh dialog-80vw dialog-no-exit">
          <StyledLicenseContent>
            <div dangerouslySetInnerHTML={{ __html: props.splashHtml }} />
          </StyledLicenseContent>

          <DialogActionsBar>
                <button className='k-button'
                        id="licence-accept" 
                        onClick={props.onConfirm}
                >
                          Accept
                </button>
          </DialogActionsBar>
  </Dialog>
}

const msp = (state) => ({
  user: state.user,
})



export default connect(msp)(Splash);


// add new splashes here - view steps at top of file
const splashMap = {
  "merge-review": {
    title: "Update Notice",
    expirationDate: new Date("June 26, 2025"),
  },
}