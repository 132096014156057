import React from "react";
import { connect } from "react-redux";
import { PhenomLabel } from "../../util/stateless";
import {MergeErrorConfirm} from "./merge_error_confirm"
import LoaderButton from "../../widget/LoaderButton";
import PhenomId from "../../../requests/phenom-id";
import { ManageSubMenu } from "./model_manage";
import ChangeSetManager from "./sections/ChangeSetManager";
import NavTree from "../../tree/NavTree";
import * as actionCreators from "../../../requests/actionCreators";
import { KbButton } from "../../util/stateless";
import MergeSummary from "./merge_summary";
import { cloneDeep } from "lodash";
import { RequestReviewDetails } from "./sections/RequestReviewDetails";
import { getMergeRequestReview } from "../../../requests/sml-requests";
import { BasicAlert } from "../../dialog/BasicAlert";


class Pull extends React.Component {
    constructor(props) {
        super(props);

        this.defaultState = {
          loading: false,
        };

        this.state = cloneDeep(this.defaultState);

        this.noticeRef = undefined;
        this.parentBranchName = undefined;
        this.branchName = undefined;
        this.neededNodes = undefined;
    }

    componentDidMount() {
      NavTree.collapseNavTree(false);
      this.fetchPullTree();
    }

    fetchPullTree = (modelId=false) => {
      const getPullReviewProjectTree = (modelId) => {
        NavTree.reset(false, modelId).then(() => {
          NavTree.expandAllReviewNodes();
          window.dispatchEvent(new CustomEvent('ADD_MERGE_CANDIDATES'));
          BasicAlert.hide();
        });
      }

      BasicAlert.show("One moment please. Retrieving the needed data.", "Loading...", false);
      if (modelId) { // modelId passed in already, fetch review project
        getPullReviewProjectTree(modelId);
      } else { // modelId not passed in already, check for review project first
        getMergeRequestReview().then((res) => {
          let fetchedModelId = res?.data?.reviewProjectId;
            if (fetchedModelId) {
              getPullReviewProjectTree(fetchedModelId);
            } else {
              NavTree.fetchTreeWithMergeCandidates().then(() => {
                window.dispatchEvent(new CustomEvent('ADD_MERGE_CANDIDATES'));
                BasicAlert.hide();
              });
            }
        })
      }
    }

    selectNecessaryNodes = () => {
      NavTree.selectMergeCandidates( this.neededNodes.map(n => n.guid) );
    }
  
    selectSingleNode = (guid) => {
      NavTree.selectMergeCandidates([ guid ]);
    }

    handlePull = (deprecateDeletes, deprecateMoves) => {
      this.setState({loading: true});

      const rejected = (errText) => {
        this.setState({loading: false});
        typeof errText === "string" && actionCreators.receiveErrors(errText);
      }
  
      const resolved = (response) => {
        if (response.status === "success") {
          actionCreators.receiveLogs("Pull Completed");
          this.fetchPullTree();
          this.setState({...this.defaultState});
        } else {
          this.setState({loading: false});

          if(response.errors.length) {
            if (typeof response.errors[0] === 'string') {
              actionCreators.receiveErrors(response.errors);
            } else {
              const fixables = ["references", "unreachable"];
              const allFixable = response.errors.every(err => fixables.includes(err.method));

              if(allFixable) {
                this.setNeededNodes(response.errors);
                MergeErrorConfirm.show(this.neededNodes, this.selectNecessaryNodes, this.selectSingleNode);
              } else {
                actionCreators.receiveErrors(Array.from(new Set(response.errors.map(err => err.text))));
              }
            }
          } else {
            actionCreators.receiveErrors(["Failed to perform merge."]);
          }
        }
      }
  
      return NavTree.pull(deprecateDeletes, deprecateMoves).then(resolved, rejected);
    }

    // this logic is from the original code
    getAbsentParents = () => {
      NavTree.getSelectedMergeCandidates().forEach(leaf => {
        const parentLeaf = leaf.getParentLeaf();

        if (parentLeaf && parentLeaf.isMergeCandidate() && !parentLeaf.isMarkedForMerge()) {
          const node = {};
          node["name"] = parentLeaf.getName();
          node["guid"] = parentLeaf.getGuid();
          node["error_type"] = "PARENT_ABSENT";
          node["error_name"] = leaf.getName();
          node["error_guid"] = leaf.getGuid();
          node["failure"] = false
          this.neededNodes.push(node);
        }
      })
    }

    // this logic is from the original code
    //    - "neededNode" is a guid
    setNeededNodes(response) {
      this.neededNodes = [];
      response.filter(er => er.method === "references" || er.method === "unreachable").forEach((err, i) => {
          const neededGuid = err.value;
          const neededLeaf = NavTree.getLeafNode(neededGuid);

              const failure = !neededLeaf;
              const node = {};
              node["name"] = !failure ? neededLeaf.getName() : "Not Found";
              node["guid"] = neededGuid;
              node["error_type"] = err.method
              node["error_name"] = err.name;
              node["error_guid"] = err.guid;
              node["failure"] = failure;
              node["deleted"] = neededLeaf?.isDeletedForMerge();
              this.neededNodes.push(node);
      })
    }

    render() {
      const { loading } = this.state;
      const { userRole, userIdentity, history, isReviewProject } = this.props;
      const phenomId = new PhenomId("pull", this.props.idCtx);
    
      return (
        <div className="phenom-content-wrapper">
          <ManageSubMenu isReviewProject={isReviewProject}>
            <KbButton />
          </ManageSubMenu>
          <MergeErrorConfirm idCtx={phenomId.gen("init")} />
    
          <div id={phenomId.gen("", "wrapper")} className="branch-wrapper" style={{ flexGrow: 1, overflowY: "auto" }}>
            <PhenomLabel text="Pull" />
            <p>Select the changes to pull from the nav tree on the left and then click Pull to pull from the parent branch. Pulling is immediate and will overwrite your current nodes. This action cannot be undone.</p>
    
            <div className="p-row">
              <RequestReviewDetails
                    loading={loading}
                    userRole={userRole}
                    userIdentity={userIdentity}
                    history={history}
                    type={"pull"}
                    handleCreateRequest={this.handlePull}
                    actionCallback={this.fetchPullTree}
              />
            </div>
            {/*TEMP condition!!! - remove this when it comes time to represent state of review nodes externally*/}
            <MergeSummary page={"pull"} />
            {/* <div className="branch-wrapper" style={{ flex: 1, display: "flex", flexDirection: "column", overflow: "hidden" }}>
                    <PhenomLabel text="Pull Change Sets" style={{ marginBottom: 10 }} />
                    <ChangeSetManager mergeMode="pull" />
                  </div> */}
          </div>
        </div>
      );
    }
}



const msp = (state) => ({
  userRole: state.user.userRole,
  userIdentity: state.user.userIdentity,
  isReviewProject: state.user.isReviewProject,
})


export default connect(msp)(Pull)
