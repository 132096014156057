import React from "react";
import { withRouter } from "react-router-dom";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { Button, Toolbar, ToolbarItem } from "@progress/kendo-react-buttons";
import { Checkbox } from '@progress/kendo-react-inputs';
import { cloneDeep } from 'lodash'
import loadingIcon from "../../images/Palette Ring-1s-200px.gif";
import { modelPermsDefault, redeclareSubModels, setModelPerms } from "../../requests/sml-requests";
import { PhenomLabel } from "../util/stateless";
import { receiveResponse, receiveErrors } from "../../requests/actionCreators";

export class PermissionsGrid extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            defaultRoles: false,
            permsData: [],
            extPermsData: [],
            editing: true,
            extEditing: true,
        }
    }

    init() {
        const { perms, extPerms, editToggle } = this.props;
        const defaultRoles = [...cloneDeep(perms), ...cloneDeep(extPerms || [])];
        this.setState({
            permsData: this.sortAlphabeticalPermissions(perms),
            extPermsData: this.sortAlphabeticalPermissions(extPerms),
            defaultRoles,
            editing: !editToggle,
            extEditing: !editToggle,
        });
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.perms !== this.props.perms && prevProps.extPerms !== this.props.extPerms) {
            this.init();
        }
    }

    sortAlphabeticalPermissions(arr) {
        return [...arr].sort((a, b) => 
            (b.role.includes('r') - a.role.includes('r')) || 
            (a.role.includes('r') === b.role.includes('r') ? a.user.localeCompare(b.user) : 0)
        );
    }

    sortPerms = (role) => {
        const ownerPerms = /.*o.*/.test(role);
        const adminPerms = /.*a.*/.test(role);
        const writePerms = /.*w.*/.test(role);
        const readPerms = /.*r.*/.test(role);
        
        let sorted = "";
        if (ownerPerms) sorted += "o";
        if (adminPerms) sorted += "a";
        if (writePerms) sorted += "w";
        if (readPerms) sorted += "r";
        return sorted;
    }

    isEdited = () => {
        const { permsData, extPermsData, defaultRoles } = this.state;
    
        const isStandardEdited = permsData.some(newPerm => {
          const prevRole = defaultRoles.find(perm => perm.user === newPerm.user).role;
          return prevRole !== newPerm.role;
        })
    
        const isExternalEdited = extPermsData.some(newPerm => {
            const prevRole = defaultRoles.find(perm => perm.user === newPerm.user).role;
            return prevRole !== newPerm.role;
        })
    
        return isStandardEdited || isExternalEdited;
      }

    serialize(returnDefaults=false) {
        const { permsData, extPermsData, defaultRoles } = this.state;
        const { id, projectPerms, modelPerms } = this.props;
        const data = [];

        permsData.forEach(perm => {
            const defaultRole = defaultRoles.find(defaultPerm => defaultPerm.user === perm.user).role;
            const sortedRole = this.sortPerms(perm.role);
            if (sortedRole !== defaultRole || returnDefaults) {
                if (projectPerms) {
                    data.push({ modelId: id, username: perm.user, role: sortedRole });
                } else if (modelPerms) {
                    data.push({ subModelId: id, username: perm.user, role: sortedRole });
                }
            }
        });
        extPermsData.forEach(perm => {
            const defaultRole = defaultRoles.find(defaultPerm => defaultPerm.user === perm.user).role;
            const sortedRole = this.sortPerms(perm.role);
            if (sortedRole !== defaultRole || returnDefaults) {
                if (projectPerms) {
                    data.push({ modelId: id, username: perm.user, role: sortedRole });
                } else if (modelPerms) {
                    data.push({ subModelId: id, username: perm.user, role: sortedRole });
                }
            }
        });

        return data;
    }

    toggleEditing = () => {
        const { editing, permsData, defaultRoles } = this.state;
        if (editing) {
            this.setState({ permsData: permsData.map((data) => { return { user: data.user, role: defaultRoles.find(perms => perms.user === data.user).role }})});
        }
        this.setState({ editing: !editing });
    }

    toggleExternalEditing = () => {
        const { extEditing, extPermsData, defaultRoles } = this.state;
        if (extEditing) {
            this.setState({ extPermsData: extPermsData.map((data) => { return { user: data.user, role: defaultRoles.find(perms => perms.user === data.user).role }})});
        }
        this.setState({ extEditing: !extEditing });
    }

    editPerms(user, role) {
        const { permsData, extPermsData } = this.state;
        const userPerms = permsData.find(perm => perm.user === user) ||
                          extPermsData.find(perm => perm.user === user);
        
        userPerms.role = userPerms.role.includes(role) ? userPerms.role.replace(role, "") : userPerms.role + role;

        if (!/.*r.*/.test(userPerms.role) && /.*[oaw].*/.test(userPerms.role)) {
            userPerms.role += "r";
        }

        if (!/.*a.*/.test(userPerms.role) && /.*o.*/.test(userPerms.role)) {
            userPerms.role += "a";
        }

        this.setState({ permsData, extPermsData });
    }

    readDisabled(perm) {
        const { checkboxRules, assigner } = this.props;
        const { role, user } = perm;
        const ownerPerms = /.*o.*/.test(role);
        const adminPerms = /.*a.*/.test(role);
        const writePerms = /.*w.*/.test(role);

        return checkboxRules.includes("read_lock") ||
            checkboxRules.includes("oaw_lock_read") && (writePerms || adminPerms || ownerPerms) ||
            checkboxRules.includes("assigner_lock") && user === assigner;
    }

    writeDisabled(perm) {
        const { checkboxRules, assignerRole, assigner } = this.props;
        const { role, user } = perm;
        const assignerIsAdmin = /.*a.*/.test(assignerRole);
        const assignerIsOwner = /.*o.*/.test(assignerRole);

        return checkboxRules.includes("non_admin_cannot_edit") && !(assignerIsAdmin || assignerIsOwner) ||
            checkboxRules.includes("assigner_lock") && user === assigner;
    }

    adminDisabled(perm) {
        const { checkboxRules, assignerRole, assigner } = this.props;
        const { role, user } = perm;
        const ownerPerms = /.*o.*/.test(role);
        const assignerIsAdmin = /.*a.*/.test(assignerRole);
        const assignerIsOwner = /.*o.*/.test(assignerRole);

        return checkboxRules.includes("owner_lock_admin") && ownerPerms ||
            checkboxRules.includes("non_admin_cannot_edit") && !(assignerIsAdmin || assignerIsOwner) ||
            checkboxRules.includes("assigner_lock") && user === assigner;
    }

    ownerDisabled(perm) {
        const { checkboxRules, assignerRole, assigner } = this.props;
        const { extPermsData, defaultRoles } = this.state;
        const { role, user} = perm;
        const userDefault = defaultRoles.find(perms => perms.user === user).role;
        const externalUser = !!extPermsData.find(perms => perms.user === user);
        const alreadyOwner = /.*o.*/.test(userDefault)
        const assignerIsOwner = /.*o.*/.test(assignerRole);

        return checkboxRules.includes("owners_stay") && alreadyOwner ||
            checkboxRules.includes("non_admin_cannot_edit") && !assignerIsOwner ||
            checkboxRules.includes("assigner_lock") && user === assigner ||
            externalUser;
    }

    renderPermsRow = (_, props) => {
        const { extPermsData, editing, extEditing } = this.state;
        const { user, role } = props.dataItem;
        const externalUser = extPermsData.find(perm => perm.user === user);

        const ownerPerms = /.*o.*/.test(role);
        const adminPerms = /.*a.*/.test(role);
        const writePerms = /.*w.*/.test(role);
        const readPerms = /.*r.*/.test(role);

        const editingGrid = (externalUser && extEditing) || (!externalUser && editing);

        return <tr id={`perm-${user}`}>
            <td>{ user }</td>
            <td style={{textAlign: "center", verticalAlign: "middle"}}>
                { editingGrid ? 
                  <Checkbox label=""
                          checked={readPerms}
                          onChange={() => this.editPerms(user, "r")}
                          disabled={this.readDisabled(props.dataItem)} />
                  : readPerms ? "✓" : "" }
                
            </td>
            <td style={{textAlign: "center", verticalAlign: "middle"}}>
                { editingGrid ?
                  <Checkbox label=""
                            checked={writePerms}
                            onChange={() => this.editPerms(user, "w")}
                            disabled={this.writeDisabled(props.dataItem)} />
                  : writePerms ? "✓" : "" }
            </td>
            <td style={{textAlign: "center", verticalAlign: "middle"}}>
                { editingGrid ?
                  <Checkbox label=""
                            checked={adminPerms}
                            onChange={() => this.editPerms(user, "a")}
                            disabled={this.adminDisabled(props.dataItem)} />
                  : adminPerms ? "✓" : "" }
            </td>
            {!!externalUser ? <td/> : <td style={{textAlign: "center", verticalAlign: "middle"}}>
                { editingGrid ?
                  <Checkbox label=""
                            checked={ownerPerms}
                            onChange={() => this.editPerms(user, "o")}
                            disabled={this.ownerDisabled(props.dataItem)} />
                  : ownerPerms ? "✓" : "" }
            </td>}
        </tr>
    }

    render() {
        const { assignerRole, editToggle, customLabel } = this.props;
        const { permsData, extPermsData, editing, extEditing } = this.state;

        const assignerIsAdmin = /.*a.*/.test(assignerRole);
        const assignerIsOwner = /.*o.*/.test(assignerRole);
        const renderToolbar = editToggle && assignerIsAdmin;

        // 25px - label
        // 38px - footer
        let heightVal = renderToolbar ? "calc(100% - 38px - 25px)" : "calc(100% - 25px)";
        let maxHeightVal = null;
        let minHeightVal = "150px";

        if (!editToggle) {
            maxHeightVal = "250px";
        }

        return <>
            {!!customLabel && <PhenomLabel text={customLabel} />}
            <div style={{ display: "flex", gap: 10, overflow: "hidden", height: "100%" }}>
                <div>
                    {!customLabel && <PhenomLabel text="Permission Manager" />}
                    <Grid data={permsData}
                            rowRender={this.renderPermsRow}
                            style={{ height: heightVal, maxHeight: maxHeightVal, minHeight: minHeightVal }}>
                        <GridNoRecords>No users on account</GridNoRecords>
                        <GridColumn title='USER' field='username' />
                        <GridColumn title='READ' field='read' width="70px" headerClassName="centered-col-header"/>
                        <GridColumn title='WRITE' field='write' width="70px" headerClassName="centered-col-header"/>
                        <GridColumn title='ADMIN' field='admin' width="70px" headerClassName="centered-col-header"/>
                        <GridColumn title='OWNER' field='owner' width="70px" headerClassName="centered-col-header"/>
                    </Grid>
                    { renderToolbar && <Toolbar>
                        <ToolbarItem>
                            <Button onClick={ this.toggleEditing }>{ editing ? "Undo Changes" : "Modify Permissions"}</Button>
                        </ToolbarItem>
                    </Toolbar> }
                </div>

                { extPermsData.length !== 0 && <div>
                    {!customLabel && <PhenomLabel text="External Permission Manager" />}
                    <Grid data={extPermsData}
                            rowRender={this.renderPermsRow}
                            style={{ height: heightVal, maxHeight: maxHeightVal, minHeight: minHeightVal }}>
                        <GridNoRecords>No users on account</GridNoRecords>
                        <GridColumn title='EXTERNAL USER' field='username' />
                        <GridColumn title='READ' field='read' width="70px" headerClassName="centered-col-header"/>
                        <GridColumn title='WRITE' field='write' width="70px" headerClassName="centered-col-header"/>
                        <GridColumn title='ADMIN' field='admin' width="70px" headerClassName="centered-col-header"/>
                        <GridColumn title='OWNER' field='owner' width="70px" headerClassName="centered-col-header"/>
                    </Grid>
                    { renderToolbar && <Toolbar>
                        <ToolbarItem>
                            <Button onClick={ this.toggleExternalEditing }>{ extEditing ? "Undo Changes" : "Modify External Permissions"}</Button>
                        </ToolbarItem>
                    </Toolbar> }
                </div> }
            </div>
        </>
    }
}

export class PermissionsDialogConfig {
    constructor() {
        this.modelIds = [];
        this.addedId = false;
        this.alreadyAdded = false;
        this.postSuccessFn = ()=>{};
    }

    setModelIds(ids) {
        if (Array.isArray(ids)) this.modelIds = ids;
    }

    getModelIds() {
        return this.modelIds;
    }

    setAddedId(id) {
        this.addedId = id;
    }

    getAddedId() {
        return this.addedId;
    }

    setAlreadyAdded(bool) {
        this.alreadyAdded = !!bool;
    }

    getAlreadyAdded() {
        return this.alreadyAdded;
    }

    setPostSuccessFn(fn) {
        this.postSuccessFn = fn;
    }

    getPostSuccessFn() {
        return this.postSuccessFn;
    }
}

export class PermissionsDialog extends React.Component {
    
    constructor(props) {
        super(props);
        PermissionsDialog.__instance = this;
        this.permsGridRef = React.createRef();
        
        this.state = {
            visible: false,
            loading: false,
            saving: false,
            redeclaredIds: false,
            addedId: false,
            alreadyAdded: false,
            models: [],
            postSuccessFn: ()=>{},
        };
    }

    static show(permsDialogConfig) {
        PermissionsDialog.__instance.setState({
            visible: true,
            loading: true,
            redeclaredIds: permsDialogConfig.getModelIds(),
            addedId: permsDialogConfig.getAddedId(),
            alreadyAdded: permsDialogConfig.getAlreadyAdded(),
            postSuccessFn: permsDialogConfig.getPostSuccessFn(),
        }, PermissionsDialog.__instance.determinePermsDefault);
    }

    __hide() {
        PermissionsDialog.__instance.setState({
            visible: false,
            loading: false,
            saving: false,
            redeclaredIds: [],
            addedId: false,
            alreadyAdded: false,
            models: [],
            postSuccessFn: ()=>{},
        });
    }

    determinePermsDefault() {
        const { redeclaredIds, alreadyAdded } = this.state;
        modelPermsDefault(redeclaredIds, alreadyAdded).then((res) => {
            // modelPermsDefault will return only the changed models and their data
            const modelData = res.data.models;
            this.setState({
                models: modelData,
                loading: false,
            });
            if (modelData.filter(data => data.type !== "new-sdm").length === 0) {
                this.doRedeclare();
            }
        });
    }

    doRedeclare = () => {
        this.setState({
            loading: true,
            saving: true,
        });
        
        const { redeclaredIds, addedId } = this.state;
        const permsData = this.permsGridRef.current ? this.permsGridRef.current.serialize(true) : [];
        redeclareSubModels(redeclaredIds, addedId, permsData).then((res) => {
            if (res.errors) {
                receiveErrors(res);
            } else {
                const { postSuccessFn, redeclaredIds } = this.state;
                postSuccessFn(redeclaredIds);
                receiveResponse(res);
            }
            this.__hide();
        }).catch(() => this.__hide());
    }

    doEditPerms = () => {
        this.setState({
            loading: true,
            saving: true,
        });

        const { redeclaredIds, postSuccessFn } = this.state;
        const permsData = this.permsGridRef.current ? this.permsGridRef.current.serialize() : [];
        if (permsData.length === 0) {
            postSuccessFn(redeclaredIds);
        } else {
            setModelPerms(permsData).then((res) => {
                if (res.errors) {
                    receiveErrors(res);
                } else {
                    postSuccessFn(redeclaredIds);
                    receiveResponse(res);
                }
            }).catch(() => this.__hide());
        }
        this.__hide();
    }

    renderModelPerms(modelData) {
        if (modelData.type === "new-sdm") return <></>;
        let dateString = "";
        if (modelData.created) {
            const date = new Date(modelData.created);
            dateString = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'numeric', day: 'numeric', }).format(date);
            dateString = " [pub. " + dateString + "]";
        }
        const label = modelData.modelName + dateString;
        return <div style={{ margin: "10px 0" }}>
            <PermissionsGrid id={modelData.id}
                             modelPerms={true}
                             perms={modelData.perms}
                             extPerms={modelData.extPerms}
                             assigner={modelData.assigner}
                             assignerRole={modelData.assignerRole}
                             checkboxRules={["read_lock","owner_lock_admin","owners_stay","assigner_lock"]}
                             customLabel={label}
                             returnDefaults={this.state.alreadyAdded}
                             ref={this.permsGridRef}/>
        </div>
    }

    render() {
        if(!this.state.visible) return null;
        const { loading, saving, models, alreadyAdded } = this.state;
        
        return <Dialog title={ loading ? ( saving ? "Saving..." : "Fetching Permissions" ) : "Confirm Permissions For New Models" }
                       onClose={() => PermissionsDialog.__instance.__hide()}
                       closeIcon={!alreadyAdded}>
            {loading ?
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", minWidth: "400px"}}>
                    <img 
                        id="loading-spinner"
                        style={{ width: 100, height: 100 }}
                        src={loadingIcon} 
                    />
                    {saving ? 
                    (alreadyAdded ?
                        <p>Adjusting permissions of new model...</p>
                    :
                        <p>Restructuring Project and adjusting permissions...</p>)
                    :
                        <p>Fetching default permissions settings...</p>
                    }
                </div>
            :
                <div style={{display: "flex", flexDirection: "column", maxWidth: "1100px", maxHeight: "750px", overflow: "auto"}}>
                    {alreadyAdded && <p>
                        Performing this action has caused a model to be added to the project. Adjust permissions to the desired level for this new model.    
                    </p>}
                    <p>
                        PLEASE NOTE: For any live models listed below, the default permissions setting is a given user's existing permissions to the model.
                        If a user does not have permissions to the model, their default permissions will match their permissions to the project.
                    </p>
                    {models.map(model => this.renderModelPerms(model))}
                </div>
            }
            <DialogActionsBar>
                <button id="confirm-save"
                        className="k-button"
                        onClick={alreadyAdded ? this.doEditPerms : this.doRedeclare}
                        disabled={loading}>
                    Confirm
                </button>
                {!alreadyAdded && <button id="confirm-cancel"
                        className="k-button"
                        onClick={this.__hide}
                        disabled={loading}>
                    Cancel
                </button>}
            </DialogActionsBar>
        </Dialog>
    }
}

export default withRouter(PermissionsDialog);
