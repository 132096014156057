export const accountLicenses = ["COMMERCIAL", "SBIR"]
export const accountRoles = ["MODELER", "CINCWORKS", "FULL"]
export const cincVersions = ["cinc-2.0.0", "cinc-2.0.1", "cinc-2.1.0", "cinc-2.1.1"]


/*
Babylon models for measurement systems. Should map guids to filenames. Try: "default":"wgs84.glb" to test .
*/
export const BABYLON_MODELS = {
    "default": false,
    "_vZiY2Ag5EeSFspy8Kj3F4Q": "wgs84.glb"
};



/**
 * Collection of site-wide urls
 * 
 */
export const PHENOM_URL = {
    "dashboard": "/dashboard",
    "edit": "/edit/details",
    "integration": "/integration/details",
    "generate": "/generate",
    "manage": "/manage",
    "review": "/manage/review"
}


export const primitiveTypeList = [
  "platform:Boolean",
  "platform:Char",
  "platform:WChar",
  "platform:String",
  "platform:WString",
  "platform:Double",
  "platform:LongDouble",
  "platform:Float",
  "platform:Long",
  "platform:LongLong",
  "platform:ULong",
  "platform:ULongLong",
  "platform:Short",
  "platform:UShort",
  "platform:Octet",
  "platform:Enumeration",
  "platform:Fixed",
  "platform:CharArray",
  "platform:WCharArray",
  "platform:IDLStruct",
  "platform:IDLArray",
  "platform:IDLSequence",
  "platform:BoundedString",
  "platform:BoundedWString",
].sort();


export const logicalValueTypeList = [
  "logical:Boolean",
  "logical:Character",
  "logical:Enumerated",
  "logical:Integer",
  "logical:Natural",
  "logical:NonNegativeReal",
  "logical:Real",
  "logical:String",
].sort();

export const constraintsToPrimitives = {
  "platform:RegularExpressionConstraint": ["platform:Char", "platform:String", "platform:WChar", "platform:WString"],
  "platform:RealRangeConstraint": ["platform:Double", "platform:Float", "platform:LongDouble"],
  "platform:IntegerRangeConstraint": ["platform:Octet", "platform:UShort", "platform:ULong", "platform:Short", "platform:Long", "platform:ULongLong", "platform:LongLong"],
}

export const KB_URLS = {
  "package": "https://kb.phenomportal.com/index.php/Package",
  "observable": "https://kb.phenomportal.com/index.php/Observable",
  "entity": "https://kb.phenomportal.com/index.php/Entity_and_Association",
  "platform_type": "https://kb.phenomportal.com/index.php/Platform_Type_and_Enumeration",
  "constraint": "https://kb.phenomportal.com/index.php/Constraint",
  "view": "https://kb.phenomportal.com/index.php/View",
  "characteristic": "https://kb.phenomportal.com/index.php/View_Attribute",
  "measurement": "https://kb.phenomportal.com/index.php/Measurement",
  "measurement_system": "https://kb.phenomportal.com/index.php/Measurement_System_and_Coordinate_System#Creating_&_Editing_Measurement_System",
  "measurement_system_axis": "https://kb.phenomportal.com/index.php/Measurement_System_and_Coordinate_System#Creating_&_Editing_Measurement_System_Axis",
  "coordinate_system": "https://kb.phenomportal.com/index.php/Measurement_System_and_Coordinate_System#Creating_&_Editing_Coordinate_System",
  "coordinate_system_axis": "https://kb.phenomportal.com/index.php/Measurement_System_and_Coordinate_System#Creating_&_Editing_Coordinate_System_Axis",
  "reference_point": "https://kb.phenomportal.com/index.php/Reference_Point",
  "scratchpad": "https://kb.phenomportal.com/index.php/Diagram",
  "idm": "https://kb.phenomportal.com/index.php/Integration_Model_Editor",
  "model-manage": "https://kb.phenomportal.com/index.php/Projects_and_Models_Management",
  "push": "https://kb.phenomportal.com/index.php/Push,_Pull,_and_Approve_Process",
  "pull": "https://kb.phenomportal.com/index.php/Push,_Pull,_and_Approve_Process",
  "approve": "https://kb.phenomportal.com/index.php/Push,_Pull,_and_Approve_Process",
  "permission_tokens": "https://kb.phenomportal.com/index.php/Permissions#Assign_Permissions_to_External_Users",
  "portable_component": "https://kb.phenomportal.com/index.php/Service_/_UOP_%26_Message_Port",
  "transport_channel": "https://kb.phenomportal.com/index.php/Transport_Channel",
  "model_gen": "https://kb.phenomportal.com/index.php/Data_Model",
  "cinc_gen": "https://kb.phenomportal.com/index.php/CinC",
  "templates": "https://kb.phenomportal.com/index.php/Templates",
  "health_check": "https://kb.phenomportal.com/index.php/Healthcheck",
  "meridian_map": "https://kb.phenomportal.com/index.php/Meridian_Map",
  "view_trace": "https://kb.phenomportal.com/index.php/View_Trace",
  "users": "https://kb.phenomportal.com/index.php/Subscription",
  "model_stats": "https://kb.phenomportal.com/index.php/Analytics",
  "import_csv" : "https://kb.phenomportal.com/index.php/CSV",
  "add_placeholders" : "https://kb.phenomportal.com/index.php/Import",
  "merge" : "https://kb.phenomportal.com/index.php/Import",
  "measurement_axis": "https://kb.phenomportal.com/index.php/Measurement#Measurement_Axis",
  "finalize-merge": "https://kb.phenomportal.com/index.php/Review_Project#Finalize_Merge",
  "commit": "https://kb.phenomportal.com/index.php/Review_Project#Commits",
  "update-review": "https://kb.phenomportal.com/index.php/Review_Project#Update_Review",
  "import": "https://kb.phenomportal.com/index.php/Import",
}