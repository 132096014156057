import React from "react";
import ReactTooltip from "react-tooltip";
import {BlockPageScroll, CadetInput, LineLabel, CadetTextArea, Card, SingleSelect, MultiSelect, CommaLinks} from "../util/stateless";
import {elementDetail, modelRemoveNode, getDeletableStatus, getNodesOfType, getNodeWithAddenda} from "../../requests/sml-requests";
import {NavLink} from "react-router-dom";
import {Button} from "@progress/kendo-react-buttons";
import {Grid, GridColumn as Column, GridNoRecords} from "@progress/kendo-react-grid";
import $ from "jquery";
import {cloneDeep} from "lodash";
import {NodeHistory2} from "./node-history";
import {BasicConfirm} from "../dialog/BasicConfirm";
import {deGuidify} from "../util/util";
import {BABYLON_MODELS} from "../../global-constants";
import Draggable from "react-draggable";
import {MeasurementSystemAxisManager} from "../util/measurement_system_axis";
import styled from "@emotion/styled";
import {Notifications2} from "./notifications";
import PhenomId from "../../requests/phenom-id";
import NodeLayout, { withPageLayout, StyledGrid, StyledInput, StyledSidebar, StyledContent, StyledCardGroup } from './node-layout'
import { ConversionsDisplay } from "../util/conversions_display";
import { createNodeUrl } from "../../requests/type-to-path";
import { getActiveChangeSetId } from "../../requests/actionCreators";
import {createPhenomGuid} from "../util/util"
import ChangeSetPicker from "../widget/ChangeSetPicker";
import NavTree from "../tree/NavTree";
import DeletionConfirm2 from "../dialog/DeletionConfirm2";
import { _ajax, smmSaveNodes } from "../../requests/sml-requests";
import NodeDetails from "./node-details";


const SwitchMode = styled.span`
    padding: 5px 10px;
    background-color: #409b9e;
    color: white;
    border-radius: 5px;
    box-shadow: 3px 3px 3px #7e7e7e;
    cursor: pointer;
`

const labelStyle = {margin:"0 0 7px", lineHeight:"normal"};
const inputStyle = {margin:"0 0 15px"}

let msaStyle = {
    position: "relative",
    border: "2px solid #121212"
}

let removeStyle = {
    position:"absolute",
    top:0,
    right:0,
    zIndex:1
}

export class MeasurementSystemManager extends React.Component {
    original = {};
    newMSARefs = {};
    topBtnRef = React.createRef();
    parentRef = React.createRef();
    csRef = React.createRef();
    msaRef = React.createRef();

    defaultMeasurementSystem = {
        guid: null,
        name: "",
        description: "",
        orientation: "",
        externalStandardReference: "",
        coordinateSystem: "",
        measurementSystemAxis: "",
        measurementSystemAxes: {},
        referenceStandard: "",
        urlToStandard: "",
        parent: "",
        parents: {},
        msaGuids: [],
        children: [],
        related_measurements: [],
        sortRP: [],
        sourcedBy: {
            nodes: []
        },
        targetedBy: {
            nodes: []
        },
        addedMsaData: {},
        addedMsaGuids: [],
        coordinateSystemAxes: {},
        newConstraint: null,
        newConstraints: [],
        dummyMsaGuid: 1,
        dummyConstraintGuid: 1,
        babylon_model: false,
        editable: true,
        createStandMeasurement: false,
    };

    state = {
        ...cloneDeep(this.defaultMeasurementSystem),
    };

    componentDidMount() {
        if (this.props.match.params.guid === "new") {
            this.initNewNode();
        } else {
            this.initExistingNode();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.guid !== prevProps.match.params.guid) {
            this.setState({...cloneDeep(this.defaultMeasurementSystem)});

            if (this.props.match.params.guid === "new") {
                this.initNewNode();
            } else {
                this.initExistingNode();
            }
        }

        if (prevState.subModelId !== this.state.subModelId ||
            prevProps.subModels !== this.props.subModels) {
                this.setEditingStatus();
        }
    }

    createScene = (babylon_model_filename) => {
        const BABYLON = window.BABYLON;
        const canvas = document.getElementById("animationViewerCanvas");
        const engine = new BABYLON.Engine(canvas, true);
        const scene = new BABYLON.Scene(engine);
        scene.clearColor = new BABYLON.Color3(1, 1, 1);

        //Lights
        new BABYLON.HemisphericLight("light1", new BABYLON.Vector3(1, 1, 0), scene);
        new BABYLON.PointLight("light2", new BABYLON.Vector3(0, 1, -1), scene);

        //Camera
        const camera = new BABYLON.ArcRotateCamera("Camera", Math.PI / 2.3, 3 * Math.PI / 5, 10, BABYLON.Vector3.Zero(), scene);
        camera.attachControl(canvas, true);
        camera.lowerRadiusLimit = 5;
        camera.upperRadiusLimit = 20;

        //Action
        BABYLON.SceneLoader.ImportMesh(null, "/babylon_assets/", babylon_model_filename, scene, function (newMeshes, ps, skel, animationGroups) {
            animationGroups.forEach(anim => anim.play(true));

            //Create the initial rotation before rendering
            /*            var xNode = new BABYLON.TransformNode("offset_node");
                        newScene.meshes[0].parent = xNode;
                        scene.registerBeforeRender(function () {*/
            //xNode.rotate(BABYLON.Axis.Y, 0.0025, BABYLON.Space.LOCAL);
            //});

        });

        engine.runRenderLoop(function () {
            scene.render();
        });
    };

    initNewNode = async () => {
        const res = await Promise.all([
            getNodesOfType("logical:MeasurementSystemAxis"),
            this.getCoordinateSystems(),
            getNodesOfType("face:LogicalDataModel"),
        ])

        const measurementSystemAxes = deGuidify(res[0].data.nodes);
        const coordinateSystems = deGuidify(res[1].data.nodes);
        const parents = deGuidify(res[2].data.nodes);

        this.original = {};
        this.setState({
            measurementSystemAxes,
            coordinateSystems,
            parents,
            createStandMeasurement: false,
        });
    }

    initExistingNode = async () => {
        const res = await Promise.all([
            getNodesOfType("logical:MeasurementSystemAxis"),
            this.getCoordinateSystems(),
            elementDetail(this.props.match.params.guid),
            this.getMeasurementSystem(),
            this.fetchSourceEquations(),
            this.fetchTargetEquations()
        ])

        const measurementSystemAxes = deGuidify(res[0].data.nodes);
        const coordinateSystems = deGuidify(res[1].data.nodes);
        const details = res[2].data;
        const measurementSystem = res[3];

        if (this.props.updateTemplateNode) {
            this.props.updateTemplateNode(measurementSystem);
        }

        const msaGuids = measurementSystem.measurementSystemAxis.split(" ");

        this.original = {...measurementSystem};
        this.setState({
            ...measurementSystem,
            ...details,
            measurementSystemAxes,
            coordinateSystems,
            msaGuids,
            sourcedBy: res[4].data,
            targetedBy: res[5].data
        }, () => {
            this.setEditingStatus();
        });

        let babylon_model = BABYLON_MODELS[measurementSystem.guid] || BABYLON_MODELS["default"];
        if (babylon_model) {
            this.createScene(babylon_model);
            this.setState({babylon_model: true});
        }
    }

    getMeasurementSystem = () => {
        return getNodeWithAddenda(this.props.match.params.guid, {
            coreAddenda: ["childrenMULTI"],
        })
    };

    getCoordinateSystems = () => {
        return _ajax({
            url: "/index.php?r=/node/model-nodes-of-type",
            method: "get",
            data: {
                type: "logical:CoordinateSystem",
                coreAddenda: ["axisMULTI"],
            }
        });
    }

    fetchSourceEquations = () => {
        return _ajax({
            url: "/index.php?r=/measurement-system-conversion/model-nodes-of-type",
            method: "get",
            data: {
                coreAddenda: ["source"],
                Filter: {
                    includes: {source: this.props.match.params.guid}
                }
            }
        });
    };

    fetchTargetEquations = () => {
        return _ajax({
            url: "/index.php?r=/measurement-system-conversion/model-nodes-of-type",
            method: "get",
            data: {
                coreAddenda: ["target"],
                Filter: {
                    includes: {target: this.props.match.params.guid}
                }
            }
        });
    };

    handleSave = () => {
        let errors = new Set();

        let requestData = {
            guid: this.state.guid || createPhenomGuid(),
            name: this.state.name,
            description: this.state.description,
            changeSetId: getActiveChangeSetId(),
            parent: this.state.parent,
        }



        if (this.state.createStandMeasurement) {
            requestData = {
                ...requestData,
                xmiType: "logical:StandardMeasurementSystem",
                referenceStandard: this.state.referenceStandard,
                urlToStandard: this.state.urlToStandard,
            }
        } else {
            requestData = {
                ...requestData,
                xmiType: "logical:MeasurementSystem",
                orientation: this.state.orientation,
                externalStandardReference: this.state.externalStandardReference,
                coordinateSystem: this.state.coordinateSystem,
            }

            // New Measurement Systems can have inline MSAs
            // convert MSAs into an array of nodes
            if (this.props.match.params.guid === "new" && this.state.addedMsaGuids.length) {
                const newNodes = [];

                this.state.msaGuids.forEach(guid => {
                    newNodes.push(this.state.measurementSystemAxes[guid]);
                })

                this.state.addedMsaGuids.forEach(guid => {
                    const node = this.newMSARefs[guid].current.generateNode();
                    if (node.errors) {
                        node.errors.forEach(err => errors.add(err));
                    }
                    newNodes.push(node);
                })

                requestData.measurementSystemAxis = newNodes;

            // otherwise keep MSAs as GUIDs
            } else {
                requestData.measurementSystemAxis = this.state.msaGuids.join(" ");
            }
        }

        if (errors.size) {
            return Notifications2.parseErrors(errors);
        }

        const request = {
            node: requestData,
            returnTypes: [ requestData.xmiType ],
            returnAddenda: {
                [requestData.xmiType]: {
                    coreAddenda: ["childrenMULTI"],
                }
            },
        }

        return smmSaveNodes(request).then(res => {
            const response = res.data;
            Notifications2.parseResponse(response);

            if (response.nodes?.[0]) {
                NavTree.addNodes(response.nodes);
                const node = response.nodes[0];
                window["resetCache"]();

                if (this.props.match.params.guid === "new") {
                    return this.props.history.push( createNodeUrl(node) );
                }

                this.initExistingNode();
            }
        });
    };

    setEditingStatus = () => {
        const { subModels={}, setParentEditingStatus } = this.props;
        const { subModelId } = this.state;
        const currSubModel = subModels[subModelId];
        this.setState({ editable: !currSubModel?.created }, () => {
            setParentEditingStatus && setParentEditingStatus(!currSubModel?.created)
        });
    };

    removeRp = (guid) => {
        // TODO: Change Set dropdown needs to be moved
        // const changeSetId = this.topBtnRef.current.state.changeSetId !== "0" ?
        //                             this.topBtnRef.current.state.changeSetId : null;

        BasicConfirm.show("Are you sure you want to remove this Reference Point?", () => {
            getDeletableStatus(guid).then(res => {
                const response = res.data;

                if (response.deletable) {
                    modelRemoveNode(guid).then(result => {
                        if (result) {
                            const children = this.state.children.filter(rpp => rpp.guid !== guid);
                            this.setState({children: children});
                        }
                    });
                } else {
                    Notifications2.parseErrors(["This reference point has been merged into another model and cannot be deleted at this time."]);
                }
            });
        });
    }

    toggleViewerVisibility = () => {
        this.setState({
            viewerVisible: !this.state.viewerVisible
        });
    };

    addNewMeasSysAxis = () => {
        const newNode = {
            guid: createPhenomGuid(),
            name: "",
            description: "",
        }

        this.state.addedMsaData[newNode.guid] = newNode;
        this.state.addedMsaGuids.push(newNode.guid);
        this.forceUpdate();
    }

    removeMeasSysAxis = async (guid) => {
        BasicConfirm.show("Are you sure you want to remove the Measurement System Axis?\n(note: click SAVE to commit the change)", async () => {
            let addedMsaGuids = [...this.state.addedMsaGuids];

            // force unmount
            this.state.addedMsaGuids = [];
            await this.forceUpdate();

            addedMsaGuids = addedMsaGuids.filter(id => guid !== id);
            await this.setState({ addedMsaGuids });
        })
    }

    saveNewMsaState = (newState) => {
        if(newState.guid in this.state.addedMsaData) {
            this.state.addedMsaData[newState.guid] = newState;
        }
        this.forceUpdate();
    }

    handleCoordinateSystemChange = (e) => {
        if (e.target.value) {
            const cs = this.state.coordinateSystems[e.target.value];

            const coordinateSystemAxes = deGuidify(cs.axis) || {};
            this.setState({coordinateSystemAxes, coordinateSystem: e.target.value})
        }
        this.forceMsaUpdate();
    }

    forceMsaUpdate = async () => {
        let addedMsaGuids = [...this.state.addedMsaGuids];

        this.state.addedMsaGuids = [];
        await this.forceUpdate();

        this.setState({ addedMsaGuids });
    }

    createNewConstraint = (constraint) => {
        if (constraint) {
            const newConstraints = cloneDeep(this.state.newConstraints);
            newConstraints.push(constraint);

            this.setState((prevState) => {
                return {
                    newConstraint: constraint,
                    newConstraints,
                    dummyConstraintGuid: prevState.dummyConstraintGuid + 1
                }});
        }
    }

    handleDelete = () => {
        DeletionConfirm2.show(this.state.guid, this.state.name);
    }

    render() {
        const phenomId = new PhenomId("edit-measurement-system");
        const { guid, name, editable, deprecated } = this.state;

        return (
        <NodeLayout guid={guid}
                    name={name}
                    editable={editable}
                    deprecated={deprecated}
                    idCtx={phenomId.genPageId()}
                    onSave={this.handleSave}
                    topBtnRef={this.topBtnRef}>

            <StyledGrid>
                <StyledInput>
                    <LineLabel text={this.state.createStandMeasurement ? "STANDARD MEASUREMENT SYSTEM" : "MEASUREMENT SYSTEM"} style={labelStyle} idCtx={phenomId.gen("details","name")}/>
                    <CadetInput text={this.state.name} style={inputStyle}
                        disabled={!this.state.editable}
                        onChange={(e) => this.setState({name: e.target.value})}
                        idCtx={phenomId.gen("details","name")}/>

                    <LineLabel text="DESCRIPTION" style={labelStyle} idCtx={phenomId.gen("details","description")}/>
                    <CadetTextArea text={this.state.description} style={inputStyle}
                        disabled={!this.state.editable}
                        onChange={(e) => this.setState({description: e.target.value})}
                        idCtx={phenomId.gen("details","description-input")}/>

                    {this.props.match.params.guid === "new" &&
                    <div style={{margin: "5px 0 25px"}}>
                        <SwitchMode onClick={() => this.setState((prevState, props) => {
                                return {createStandMeasurement: !prevState.createStandMeasurement};
                            })}
                            id={phenomId.gen("details","standard-measurement-switch")}>
                            {this.state.createStandMeasurement ? "Switch to Measurement System" : "Switch to Standard Measurement System"}
                        </SwitchMode>
                    </div>} </StyledInput>

                <StyledSidebar>
                    <NodeHistory2 guid={this.state.guid} idCtx={phenomId.genPageId()}/>
                    <NodeDetails guid={this.state.guid}/>
                    {/* <ChangeSetPicker id={phenomId.genPageId()}
                                     disabled={!this.state.editable}
                                     label="Change Set" /> */}
                </StyledSidebar>

                <StyledContent>
                    <Draggable enableUserSelectHack={false} handle=".dialog-box-header"
                              positionOffset={{x: this.state.viewerVisible ? 0 : -100000, y: 0}}>
                        <div className="dialog-box" style={{zIndex: 200}}>
                            <span className="dialog-box-header">Animation Viewer
                                <button onClick={this.toggleViewerVisibility} id={phenomId.gen(["details","animation-viewr"],"close-button")}>X</button></span>
                            <div style={{padding: "0 15px"}}>
                                <BlockPageScroll>
                                    <div className="flex-v"
                                        id="animation"
                                        style={{
                                            flexGrow: 1,
                                            height: (this.state.babylon_model ? "auto" : 0),
                                            overflow: "hidden"
                                        }}>
                                        <canvas id="animationViewerCanvas" touch-action="none" width="310" height="345"
                                                tabIndex="1"></canvas>
                                    </div>
                                </BlockPageScroll>
                            </div>
                        </div>
                    </Draggable>

                    {this.state.createStandMeasurement && <>
                    <div>
                        <LineLabel text="Reference Standard" style={labelStyle} idCtx={phenomId.gen(["details","create-standard-measurement"],"reference")}/>
                        <CadetInput text={this.state.referenceStandard} style={inputStyle}
                                    disabled={!this.state.editable}
                                    onChange={(e) => this.setState({referenceStandard: e.target.value})}
                                    idCtx={phenomId.gen("create-standard-measurement","reference")}/></div>

                    <div>
                        <LineLabel text="URL to External Standard Definition (optional)" style={labelStyle} idCtx={phenomId.gen("create-standard-measurement","url")}/>
                        <CadetInput text={this.state.urlToStandard} style={inputStyle}
                                    disabled={!this.state.editable}
                                    onChange={(e) => this.setState({urlToStandard: e.target.value})}
                                    idCtx={phenomId.gen("create-standard-measurement","url")}/></div>
                    </>}

                    {!this.state.createStandMeasurement && <>
                    <div>
                        <LineLabel text="Orientation" style={labelStyle} idCtx={phenomId.gen("details","orientation")}/>
                        <CadetInput text={this.state.orientation} style={inputStyle}
                                    disabled={!this.state.editable}
                                    onChange={(e) => this.setState({orientation: e.target.value})}
                                    idCtx={phenomId.gen("details","orientation")}/></div>

                    <div>
                        <LineLabel text="External Standard Reference" style={labelStyle} idCtx={phenomId.gen("details","external-reference")}/>
                        <CadetInput text={this.state.externalStandardReference} style={inputStyle}
                                    disabled={!this.state.editable}
                                    onChange={(e) => this.setState({externalStandardReference: e.target.value})}
                                    idCtx={phenomId.gen("details","external-reference")}/></div>
                    </>}

                    {this.props.match.params.guid === "new" &&
                    <div>
                        <LineLabel text="Parent Package" style={labelStyle} idCtx={phenomId.gen(["details","new"],"parent-package")}/>
                        <div style={{marginBottom:10}}>
                            <Button icon="select-box" onClick={() => this.parentRef.current.toggleCollapse()}
                              id={phenomId.gen("new","select-parent-confirm-button")}>Select Parent</Button>
                        </div>
                        <SingleSelect data={this.state.parents}
                                    id={phenomId.gen("new","parent-select")}
                                    selectedGuid={this.state.parent}
                                    required={false}
                                    optionNoneText="No Package Selected / Use Default"
                                    collapse={this.props.match.params.guid !== "new"}
                                    onChange={(e) => this.setState({parent: e.target.value})}
                                    editable={this.state.editable}
                                    ref={this.parentRef} />

                        {this.state.parents &&
                        <StyledCardGroup columns={3}>
                            <Card node={this.state.parents[this.state.parent]} id={phenomId.gen("new","parents-card")}/></StyledCardGroup>} </div>}

                    {!this.state.createStandMeasurement && <>
                    <div>
                        <LineLabel text="Coordinate System" style={labelStyle} idCtx={phenomId.gen(["new","coordinate-system"],"name")}/>
                        <div style={{marginBottom:10}}>
                            <Button icon="select-box" onClick={() => this.csRef.current.toggleCollapse()} style={{marginRight:10}}
                              id={phenomId.gen("coordinate-system","select-button")}>Select Coordinate System</Button>
                        </div>
                        <SingleSelect data={this.state.coordinateSystems}
                                    selectedGuid={this.state.coordinateSystem}
                                    required={true}
                                    idCtx={phenomId.gen("coordinate-system","single-select")}
                                    collapse={this.props.match.params.guid !== "new"}
                                    onChange={this.handleCoordinateSystemChange}
                                    editable={this.state.editable}
                                    ref={this.csRef} />

                        {this.state.coordinateSystems &&
                            <StyledCardGroup columns={3}>
                                <Card node={this.state.coordinateSystems[this.state.coordinateSystem]} id={phenomId.gen("coordinate-system","card")}/></StyledCardGroup>} </div>

                    <div>
                        <LineLabel text="Measurement System Axes" style={labelStyle} idCtx={phenomId.gen(["new","axes"],"name")}/>
                        <div style={{marginBottom:10}}>
                            <Button icon="select-box" onClick={() => this.msaRef.current.toggleCollapse()} style={{marginRight:10}}
                              id={phenomId.gen("axes","select-existing-button")}>Select existing Measurement System Axis</Button>

                            {this.props.match.params.guid === "new" &&
                            <Button icon="add"
                                    id={phenomId.gen("axes","add-new-button")}
                                    onClick={this.addNewMeasSysAxis}>
                                        Add new Measurement System Axis</Button>}
                        </div>

                        <MultiSelect data={this.state.measurementSystemAxes}
                                    idCtx={phenomId.gen("axes","multi-select")}
                                    selectedGuids={this.state.msaGuids}
                                    required={true}
                                    collapse={this.props.match.params.guid !== "new"}
                                    onChange={(msaGuids) => this.setState({msaGuids})}
                                    editable={this.state.editable}
                                    ref={this.msaRef} />

                        {this.state.measurementSystemAxes &&
                            <StyledCardGroup columns={3} id={phenomId.gen("axes","card-grid")}>
                                {this.state.msaGuids.map((guid,cIdx) => {
                                    return <Card node={this.state.measurementSystemAxes[guid]} id={phenomId.gen("axes",`${cIdx}-card`)}/>})}</StyledCardGroup>} </div>
                    </>}

                    {this.props.match.params.guid === "new" && !this.state.createStandMeasurement &&
                    <div>
                        <StyledCardGroup columns={2} id={phenomId.gen(["new",`measurement-system`],"card-grid")}>
                            {this.state.addedMsaGuids.map((guid) => {
                                let node = this.state.addedMsaData[guid];
                                this.newMSARefs[guid] = React.createRef();

                                return <div style={msaStyle}>
                                    {this.state.editable && <>
                                        <Button icon="close" look="bare" style={removeStyle} data-for="removeMSA" data-tip="Remove Measurement System Axis"
                                                onClick={() => this.removeMeasSysAxis(guid)}
                                                id={phenomId.gen("measurement-system","remove-msa-button")}/>
                                        <ReactTooltip id='removeMSA' type='error' place='left' getContent={(el) => el} />
                                    </>}
                                    <MeasurementSystemAxisManager key={guid}
                                                idCtx={phenomId.gen("measurement-system")}
                                                node={node}
                                                look="component"
                                                match={{params: {guid: guid}}}
                                                parent={this.state.parent}
                                                dummyConstraintGuid={this.state.dummyConstraintGuid}
                                                newConstraints={this.state.newConstraints}
                                                createNewConstraint={this.createNewConstraint}
                                                coordinateSystemAxes={this.state.coordinateSystemAxes}
                                                saveState={this.saveNewMsaState}
                                                removeNode={this.removeMeasSysAxis}
                                                ref={this.newMSARefs[guid]} />
                                </div>


                            })}
                        </StyledCardGroup> </div>}

                    {!this.state.createStandMeasurement &&
                    <div>
                        <LineLabel text="Reference Points" style={labelStyle} idCtx={phenomId.gen(["details","reference-points"],"")}/>
                        {this.props.match.params.guid !== "new" &&
                        <div style={{marginBottom:20}}>
                            <Button icon="add"
                                    id={phenomId.gen("reference-points","add-button")}
                                    disabled={!this.state.editable}
                                    data-tip
                                    data-for="addRp"
                                    onClick={() => {this.props.history.push(`/edit/details/reference_point/new/${this.state.guid}/${this.state.name}/`)}}>
                                        Add Reference Point
                            </Button>
                            <ReactTooltip id="addRp">
                                <span>This will redirect you to the create Reference Point editor</span>
                            </ReactTooltip>
                        </div>}


                        {this.props.match.params.guid === "new" ?
                            <span style={{fontSize:"80%"}}>Reference Points can be added after the Measurement System is created</span>
                        :
                            <StyledCardGroup columns={3} id={phenomId.gen("reference-points","card-grid")}>
                                {this.state.children.map((rp,rpIdx) => {
                                    return <Card node={rp}
                                                onRemove={this.state.editable ? this.removeRp : null}
                                                id={phenomId.gen(["reference-points",`${rpIdx}`],"card")}/>
                                })}
                            </StyledCardGroup>
                        }
                    </div>}

                    {this.props.match.params.guid !== "new" && <>
                    <div>
                        <LineLabel text="Measurements" style={labelStyle} idCtx={phenomId.gen(["details","measurements"],"")}/>
                        <CommaLinks data={this.state.related_measurements || []}
                          id={phenomId.gen("measurements","comma-links")}/></div>

                    <div>
                        <LineLabel text="Sourced By" style={labelStyle} idCtx={phenomId.gen(["details","sourced-by"],"")}/>
                        <Grid
                            id={phenomId.gen("sourced-by","grid")}
                            data={this.state.sourcedBy.nodes}
                            className="editorTable"
                            resizable>
                            <GridNoRecords>
                                No Data Is Available For This Table
                            </GridNoRecords>
                            <Column title="Name" cell={(props,pIdx) => {
                                return (<td>
                                    <NavLink className="cadet-anchor normal-anchor"
                                            to={`/edit/details/conversion/${props.dataItem.guid}`}
                                            id={phenomId.gen(["sourced-by",`${pIdx}`],"link")}>{props.dataItem.name}</NavLink>
                                </td>);
                            }}/>
                            <Column title="Equation" field="equation"/>
                        </Grid></div>

                    <div>
                        <LineLabel text="Targeted By" style={labelStyle} idCtx={phenomId.gen(["details","targeted-by"],"")}/>
                        <Grid
                            data={this.state.targetedBy.nodes}
                            id={phenomId.gen("targeted-by","grid")}
                            className="editorTable"
                            resizable>
                            <GridNoRecords>
                                No Data Is Available For This Table
                            </GridNoRecords>
                            <Column title="Name" cell={(props,pIdx) => {
                                return (<td>
                                    <NavLink className="cadet-anchor normal-anchor"
                                            to={`/edit/details/conversion/${props.dataItem.guid}`}
                                            id={phenomId.gen(["targeted-by",`${pIdx}`],"link")}>{props.dataItem.name}</NavLink>
                                </td>);
                            }}/>
                            <Column title="Equation" field="equation"/>
                        </Grid>
                    </div></>}
                    <ConversionsDisplay xmiType={"logical:MeasurementSystemConversion"} refGuid={this.state.guid}/>
                </StyledContent>
            </StyledGrid>
        </NodeLayout>
        );
    }
}



export const EditMeasurementSystemManager = withPageLayout(MeasurementSystemManager, { renderResetBtn: false });
