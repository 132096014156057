import React from 'react';
import PhenomId from '../../../../requests/phenom-id';
import { ModelLeaf, ProjectLeaf } from '../../../tree/leaf/ModelLeaf';
import { PhenomCollapsable } from '../../../util/stateless';
import { ModelDetail } from './ModelDetail';
import { ProjectCopyAddOn, ProjectDetail, ProjectDetailModelFamily, ProjectDetailModelSelect, ProjectMergeRequest } from './ProjectDetail';
import $ from 'jquery';
import { BasicAlert } from '../../../dialog/BasicAlert';
import { PermissionsDialog, PermissionsDialogConfig, PermissionsGrid } from '../../../dialog/PermissionsDialog';
import { receiveErrors, receiveLogs, receiveResponse, receiveWarnings, updateBranchName } from '../../../../requests/actionCreators';
import { isPhenomGuid } from '../../../util/util';
import ProjectFilter from './ProjectFilter';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import NavTree from '../../../tree/NavTree';
import ReactTooltip from 'react-tooltip';
import { MANAGE_OPTIONS } from '../model_manage';
import { _ajax, setProjectPerms, setModelPerms } from '../../../../requests/sml-requests';





export class ManageDetail extends React.Component {

  detailRef = React.createRef();
  familyRef = React.createRef();
  selectNewModelRef = React.createRef();
  permissionRef = React.createRef();
  projectFilterRef = React.createRef();


  state = {
    fixupModels: [],
  }


  save = async () => {
    const { leaf, createPublishModel, createPublishProject, createFilteredProject, manage_option } = this.props;
    const isNew = isPhenomGuid( leaf.getId() );

    if (isNew) {
      if (leaf instanceof ProjectLeaf) {
        await this.saveNewProject();

      } else if (leaf instanceof ModelLeaf) {
        await this.saveNewModel();
      }

    } else {
      if (leaf instanceof ProjectLeaf) {

        if (manage_option === MANAGE_OPTIONS.newCopyProject) {
          await this.saveCopyProject();
        } else if (createFilteredProject) {
          await this.saveFilteredProject();
        } else if (createPublishProject) {
          await this.publishNewProject();
        } else {
          await this.saveExistingProject();
        }

      } else if (leaf instanceof ModelLeaf) {
        if (createPublishModel) {
          await this.publishNewModel();
        } else {
          await this.saveExistingModel();
        }
      }
    }
  }

  /**
   * New Model
   * 
   */
  saveNewModel = async () => {
    const data = this.detailRef.current.serialize();
    const requestForm = new FormData();

    requestForm.append("name", data.name);
    requestForm.append("description", data.description);
    requestForm.append("file", data.files[0]?.getRawFile());
    requestForm.append("checks", data.checks);
    requestForm.append("retained_types", data.retained_types);

    BasicAlert.show(`Saving '${data.name}'`, "Processing request", false);
    
    _ajax({
      url: "/index.php?r=/sub-model/create-from-import",
      method: "POST",
      data: requestForm,
      processData: false,     // needed because of the Raw file
      contentType: false,     // needed because of the Raw file
      error: (jqXHR, textStatus, thrownError) => {
        BasicAlert.hide();
        // case: ERR_EMPTY_RESPONSE
        if (jqXHR.status === 0) {
          receiveErrors("Your upload is taking longer than expected. You can check back later to see if it has completed.");
        }
      }
    }).then(res => {
      BasicAlert.hide();
      const response = res.data;
      let selectModelId;
      if (Array.isArray(response.bref_ids) && response.bref_ids.length) {
        if (response.bref_ids.length === 1) {
          response.logs = [`Model '${data.name}' was successfully created.`];
        } else {
          response.logs = ["Models were successfully created, they are now present in your Model Tree."];
        }
        
        selectModelId = response.bref_ids[0];
      }

      receiveResponse(response);
      this.props.refreshTrees(selectModelId);
    }).catch(err => {
      BasicAlert.hide();
    })
  }



  /**
   * New Project
   * 
   */
  saveNewProject = async () => {
    const data = this.detailRef.current.serialize();
    const { selectedSubmodelIds, blankModels, newModels, newModelFiles, } = this.selectNewModelRef.current.serialize();

    // validating it here or else it'll require a rework in Yii
    if (!this.validateNewProjectData()) return;

    const requestForm = new FormData();
    requestForm.append("name", data.name);
    requestForm.append("description", data.description);
    requestForm.append("subModelIds", JSON.stringify(selectedSubmodelIds || []));

    // Note: we're attaching multiple files to the request, but they can't be nested as a single object for Form Data
    //        -- the file attributes will be broken up and then reconstructing in Yii
    newModels.length && requestForm.append("newModels", JSON.stringify(newModels));
    newModelFiles.length && newModelFiles.forEach(file => requestForm.append("file[]", file));

    BasicAlert.show(`Saving '${data.name}'`, "Processing request", false);

    _ajax({
      url: "/index.php?r=/referencing-model/create-project-and-models",
      method: "POST",
      data: requestForm,
      processData: false,     // needed because of the Raw file
      contentType: false,     // needed because of the Raw file
      error: (jqXHR, textStatus, thrownError) => {
        BasicAlert.hide();
        // case: ERR_EMPTY_RESPONSE
        if (jqXHR.status === 0) {
          receiveErrors("Your upload is taking longer than expected. You can check back later to see if it has completed.");
        }
      }
    }).then(res => {
      BasicAlert.hide();
      receiveResponse(res);
      const response = res.data;

      if (response.model_id) {
        this.props.refreshTrees(null, response.model_id);
      }
    }).catch(err => {
      BasicAlert.hide();
    })
  }

  /**
   * Publish New Project
   * 
   */
     publishNewProject = async () => {
      const { leaf } = this.props;
      const data = this.detailRef.current.serialize();
  
      BasicAlert.show(`Saving '${data.name}'`, "Processing request", false);
      _ajax({
        url: "/index.php?r=/referencing-model/publish",
        method: "POST",
        data: {
          name: data.name,
          description: data.description,
          checks: data.checks,
          copyPerms: data.copyPerms,
          project_id: leaf.getId(),
        },
      }).then(res => {
        BasicAlert.hide();
        const response = res.data;
        let selectProjectId;
    
        if (Number.isInteger(response)) {
          receiveLogs(`Successfully created ${data.name}`);
          selectProjectId = response;
        }
    
        this.props.refreshTrees(null, selectProjectId);
      }).catch(err => {
        BasicAlert.hide();
      }) 
    }

  /**
   * Publish New Model
   * 
   */
  publishNewModel = async () => {
    const { leaf } = this.props;
    const data = this.detailRef.current.serializeImport();

    BasicAlert.show(`Saving '${data.name}'`, "Processing request", false);
    _ajax({
      url: "/index.php?r=/sub-model/publish",
      method: "POST",
      data: {
        name: data.name,
        description: data.description,
        checks: data.checks,
        referencedSubModel: leaf.getId(),
      },
    }).then(res => {
      BasicAlert.hide();
      const response = res.data;
      let selectModelId;

      if (Number.isInteger(response)) {
        receiveLogs(`Successfully created ${data.name}`);
        selectModelId = response;

      } else {
        receiveResponse(response);
      }

      this.props.refreshTrees(selectModelId);
    }).catch(err => {
        BasicAlert.hide();
    })    
  }

  /**
   * Existing Model
   * 
   */
  saveExistingModel = async () => {
    const { leaf } = this.props;

    // 1) Update Model Name/Description
    // ----------------------------------------------
    if (this.detailRef.current?.isEdited()) {
      BasicAlert.show("Saving - Model info", "Processing request", false);
      const detail = this.detailRef.current.serializeImport();
      
        this.pushDetails(detail).then(res => {
          leaf.updateData(detail);
          receiveLogs(res.logs);
        }).catch(err => {
          BasicAlert.hide();
        });
    }

    // 2) Update Permissions
    // ----------------------------------------------
    if (this.permissionRef.current?.isEdited()) {
      const permissions = this.permissionRef.current.serialize();
      this.updatePermissions(permissions).then(response => {
        receiveResponse(response);

        if (response.status === 'success') {
        const newPerms = {}
        permissions.forEach(perm => newPerms[perm.username] = perm);
        leaf.setPermissions(newPerms);
        } else {
          return BasicAlert.hide();
        }
      }).catch(err => {
        BasicAlert.hide();
      });
    }

    await this.props.refreshTrees(leaf.getId());
    BasicAlert.hide();
  }

  /**
   * Existing Project
   * 
   */
  saveExistingProject = async () => {
    const { leaf, modelIndex, activeProjectId } = this.props;

    // 1) Update Project Name/Description
    // ----------------------------------------------
    if (this.detailRef.current?.isEdited()) {
      BasicAlert.show("Saving - Project info", "Processing request", false);
      const detail = this.detailRef.current.serialize();
      
        this.pushDetails(detail).then(res => {
          leaf.updateData(detail);
          activeProjectId === leaf.getId() && updateBranchName(detail.name);
          receiveLogs(res.logs);
        }).catch(err => {
          BasicAlert.hide();
        });
    }

    // 2) Update Permissions
    // ----------------------------------------------
    if (this.permissionRef.current?.isEdited()) {
      const permissions = this.permissionRef.current.serialize();
      this.updatePermissions(permissions).then(response => {
        receiveResponse(response);

        if (response.status === 'success') {
          const newPerms = {}
          permissions.forEach(perm => newPerms[perm.username] = perm);
          leaf.setPermissions(newPerms);
        } else {
          return BasicAlert.hide();
        }
      }).catch(err => {
        BasicAlert.hide();
      })
    }

    // 3) Update Submodels or add new model
    // ----------------------------------------------
    const familyData = this.familyRef.current?.serialize();
    if (familyData?.redeclaredIds) {
      const permsConfig = new PermissionsDialogConfig();
      permsConfig.setModelIds(familyData.redeclaredIds);
      if (familyData.addedId) permsConfig.setAddedId(familyData.addedId);
      permsConfig.setAlreadyAdded(false);
      permsConfig.setPostSuccessFn(this.refreshLeaf);
      PermissionsDialog.show(permsConfig);
    } else {
      await this.props.refreshTrees(null, leaf.getId());
    }
    BasicAlert.hide();
  }

  refreshLeaf = async (newModelIds) => {
    const { leaf, modelIndex } = this.props;
    leaf.setModelLeaves(newModelIds.map(id => modelIndex[id]));
    NavTree.reset();
    this.props.refreshTrees(null, leaf.id);
  }

  saveCopyProject = async () => {
    const { leaf, manage_option } = this.props;

    if (manage_option !== MANAGE_OPTIONS.newCopyProject) {
      return;
    }

    // 1) Update Project Name/Description
    // ----------------------------------------------
    BasicAlert.show("Saving - Project info", "Processing request", false);
    const detail = this.detailRef.current.serialize();

      _ajax({
        url: "/index.php?r=/referencing-model/copy-project",
        method: "post",
        data: {
          projectId: leaf.getId(),
          ...detail,
        }
      }).then(response => {
        if (response?.status === "success") {
          receiveLogs(`Successfully copied project ${leaf.getName()}`);
          const new_project_id = response.data.project_id;
          this.props.refreshTrees(null, new_project_id);
        }
      }).catch(err => {
        BasicAlert.hide();
      })
  }

  pushDetails = async (detail={}) => {
    const { leaf, activeProjectId } = this.props;
    const url = leaf instanceof ProjectLeaf ? "/index.php?r=/referencing-model/rename-project" : "/index.php?r=/sub-model/rename-branch-ref";

    return await _ajax({
      url,
      method: "POST",
      data: {
        pModelId: leaf.getId(),
        newName: detail.name,
        newDescription: detail.description || "",
      }
    })
  }

  updatePermissions = async (permissions=[]) => {
    const { leaf } = this.props;
    return leaf instanceof ProjectLeaf ? setProjectPerms(permissions) : setModelPerms(permissions);
  }



  /**
   * Filtered Projects
   * 
   */
  saveFilteredProject = async () => {
    BasicAlert.show("Creating filtered project. This may take a few minutes", "Processing request", false);
    const data = this.projectFilterRef.current.serialize();

    const response = await _ajax({
      url: "/index.php?r=/referencing-model/save-filtered-project/",
      method: "post",
      data,
      error: (jqXHR, textStatus, thrownError) => {
        BasicAlert.hide();
        // case: ERR_EMPTY_RESPONSE
        if (jqXHR.status === 0) {
          receiveErrors("The request is taking longer than expected. You can check back later to see if it has completed.");
        }
      }
    })

    // case: timeout
    if (response.error === "The server responded with error code timeout. Please contact Skayl support for more help.") {
      receiveWarnings("The request has timed out, but that does not mean that " +
                      "the operation has failed - filtered model creation may take some time. " + 
                      "Please come back later and refresh your Model Manage page to see if your " + 
                      "model filter request has completed.");
    }

    if (response.status === "success") {
      receiveLogs(response.logs)
      await this.props.refreshTrees(null, response.data?.model_id);
      return BasicAlert.hide();
    }

    BasicAlert.hide();
  }

  validateNewProjectData = () => {
    const projectData = this.detailRef.current.serialize();
    const { selectedSubmodelIds, blankModels, newModels, newModelFiles, } = this.selectNewModelRef.current.serialize();
    const errors = [];

    if (!projectData.name) {
      errors.push("Project name is required");
    }

    if (!selectedSubmodelIds.length && !blankModels.length && !newModels.length) {
      errors.push("At least one model must be included when creating a new project");
    }

    if (blankModels.some(data => !data.name) || newModels.some(data => !data.name)) {
      errors.push("Model name is required");
    }

    if (newModelFiles.some(file => !file)) {
      errors.push("A model is missing a file. Please attach a model file and try again.");
    }

    if (errors.length) {
      receiveErrors(errors);
    }

    return !errors.length;
  }

  /**
   * Fixup Wizard
   * 
   */
  handleFixupCheckbox = (idx, checked) => {
    const fixupModels = [...this.state.fixupModels];
    // toggle checked
    fixupModels[idx].checked = !fixupModels[idx].checked;
    this.setState({ fixupModels })
  }

  saveFixupModels = async () => {
    BasicAlert.show("Performing auto fixup", "Processing Request", false);
    const fixups = this.state.fixupModels.filter(fixup => fixup.checked);
    this.setState({ fixupModels: [] });

    const promises = fixups.map((fixup) => {
      return $.ajax({
        url: "/index.php?r=/sub-model/fixup-submodel",
        method: "POST",
        data: { subModelId: fixup.modelId },
      })
    })

    BasicAlert.hide();
    Promise.all(promises).then(res => {
      if (res.every(result => result === "#true")) {
        receiveLogs("Poof! Skayl Magic Complete!");
      } else {
        receiveErrors("Something went wrong. Please contact Skayl customer support.")
      }
    })
  }

  cancelFixupModels = () => {
    this.setState({ fixupModels: [] });
  }

  render() {
    const isCopyProject = this.props.manage_option === MANAGE_OPTIONS.newCopyProject;
    const permsObj = this.props.leaf?.data.permissions;
    const permsData = permsObj && Object.keys(permsObj).map(user => { return { user: user, role: permsObj[user] }; });
    const extPermsObj = this.props.leaf?.data.externalPerms;
    const extPermsData = extPermsObj && Object.keys(extPermsObj).map(user => { return { user: user, role: extPermsObj[user] }; }).filter(perm => perm.user !== this.props.user.username);

    if (this.props.leaf instanceof ProjectLeaf) {

      if (this.props.createFilteredProject) {
        return <ProjectFilter projectLeaf={this.props.leaf}
                              createFilteredProject={this.props.createFilteredProject}
                              ref={this.projectFilterRef} />
      }

      return <div className="project-detail">
              <FixUpWizard fixupModels={this.state.fixupModels} 
                           onCheckbox={this.handleFixupCheckbox}
                           onSave={this.saveFixupModels}
                           onCancel={this.cancelFixupModels} />


              <ProjectDetail leaf={this.props.leaf}
                             ref={this.detailRef} 
                             manage_option={this.props.manage_option}
                             createPublishProject={this.props.createPublishProject} />
  
              <ProjectDetailModelFamily leaf={this.props.leaf}
                                        activeProjectId={this.props.activeProjectId}
                                        projectIndex={this.props.projectIndex}
                                        modelIndex={this.props.modelIndex}
                                        refreshTrees={this.props.refreshTrees}
                                        ref={this.familyRef}
                                        manage_option={this.props.manage_option}
                                        createPublishProject={this.props.createPublishProject} />

              <ProjectMergeRequest leaf={this.props.leaf}/>
  
              { !this.props.createPublishProject && !isCopyProject &&
                <ProjectDetailModelSelect leaf={this.props.leaf}
                                          modelIndex={this.props.modelIndex}
                                          branchIndex={this.props.branchIndex}
                                          ref={this.selectNewModelRef} /> }
              
              { !isPhenomGuid(this.props.leaf.data.id) && !isCopyProject &&
                <PermissionsGrid id={this.props.leaf.data?.id}
                                 projectPerms={true}
                                 perms={permsData}
                                 extPerms={extPermsData}
                                 assignerRole={permsData.find(perm => perm.user === this.props.user.username)?.role}
                                 editToggle={true}
                                 checkboxRules={["oaw_lock_read","owner_lock_admin","non_admin_cannot_edit"]}
                                 ref={this.permissionRef} /> }
             </div>

    } else if (this.props.leaf instanceof ModelLeaf) {
      return <div className="project-detail">
                <FixUpWizard fixupModels={this.state.fixupModels}
                             onCheckbox={this.handleFixupCheckbox}
                             onSave={this.saveFixupModels}
                             onCancel={this.cancelFixupModels} />

                <ModelDetail leaf={this.props.leaf}
                             activeProjectId={this.props.activeProjectId}
                             projectIndex={this.props.projectIndex}
                             modelIndex={this.props.modelIndex}
                             createPublishModel={this.props.createPublishModel}
                             ref={this.detailRef} />

                { !this.props.leaf.isNewLeaf() && 
                  <PermissionsGrid id={this.props.leaf.data?.id}
                                   modelPerms={true}
                                   perms={permsData}
                                   extPerms={extPermsData}
                                   assignerRole={permsData.find(perm => perm.user === this.props.user.username)?.role}
                                   editToggle={true}
                                   checkboxRules={["oaw_lock_read","owner_lock_admin","non_admin_cannot_edit"]}
                                   ref={this.permissionRef} /> }
             </div>
    }

    return null;
  }
}





export class ImportCheckList extends React.Component {
  constructor(props) {
    super(props);
    this.phenomId = new PhenomId("import-checklist");
  }

  state = {
    assertUid: true,
    fixPathsNoObs: true,
    fixCharNoRoleName: true,
    checkAllChecks: true,
  }

  importCheckList = {
    // checkUnique: ["check-name-collisions", "Check for Name Collisions."],
    assertUid: ["assert-uid", "Assert IDs"],
    fixPathsNoObs: ["check-paths-no-obs", "Fix paths not ending in Observable"],
    fixCharNoRoleName: ["", "Fix View Characteristics w/o name"]
  }

  serialize = () => {
    return JSON.stringify(this.state);      // needs to be stringified for FormData and Yii expects it to be a string
  }

  handleCheckAllBox = (e) => {
    const checkList = {};

    for (let key of Object.keys(this.importCheckList)) {
      checkList[key] = e.target.checked;
    }

    this.setState({ ...checkList });
  }

  renderCreateHeading() {
    return <div className="flex-h" 
                style={{ flex:1, flexDirection:"row", justifyContent: "right", paddingRight:"10px" }}>
              <div className="flex-h">
                <input type="checkbox" 
                       checked={Object.keys(this.importCheckList).every(key => this.state[key] === true)} 
                       onChange={this.handleCheckAllBox}
                       id="import-checklist-check-all" 
                       onClick={(e) => {
                        e.stopPropagation();
                       }}/>
                <div style={{ display:"flex", paddingLeft: "0.3em" }}>All </div>
              </div>
          </div>
  }

  render() {
    const phenomId = this.phenomId;
    
    return <PhenomCollapsable label="Import Checks/Fixes"
                              headerChildren={this.renderCreateHeading()}>
    <div id={phenomId.gen(["render-create", "createChecks"],"wrapper")}>
      {Object.keys(this.importCheckList).map((key) => {
        return <div style={{ display: "flex", alignItems: "center", gap: 5, margin: "5px 0" }}>
                <label>{ this.importCheckList[key][1] }</label>
                <input type="checkbox" 
                      checked={this.state[key]} 
                      onChange={(e) => this.setState({[key]: e.target.checked})}
                      id={`import-checklist-${key}`}/>
              </div>
      })}
    </div>
  </PhenomCollapsable>
  }
}



const FixUpWizard = (props) => {

  if (!props.fixupModels?.length) return null;


  return <Dialog title="Model Fixup Wizard" className="dialog-no-exit">
            <div style={{
              maxWidth: "calc(100vh - 300px)",
              maxHeight: "calc(80vh - 340px)",
              overflowY: "auto"
            }}>
              <h3 style={{ margin: "0 0 10px" }}><b>Generalization-Specialization Inconsistencies Detected</b></h3>
              <p>
                Some specializing elements do not contain all attributes required by their specialization relationship.
                This may be resolved by either adding new matching attributes to these elements or declaring existing attributes as specializers.
                PHENOM can automatically add the appropriate matching attributes now.
              </p>
              <p>
                Alternatively, you will be able to later review these issues in more detail and manually resolve them after running a PHENOM health-check.
              </p>
              <p>
                If you perform the auto-fix, the below attributes will be created.
                Additionally, similar attributes will be created in elements specializing those listed.
              </p>

              <label>Specialized Attribute Additions:</label>

              {props.fixupModels.map((fixup, idx) => {
                if (!fixup.genSpecIssues) return null;

                return <div>
                        <label style={{ display: "inline-flex", alignItems: "center", gap: 5, marginLeft: 12, fontSize: 16, fontWeight: 400 }}>
                          <input type="checkbox" checked={fixup.checked} onChange={() => { props.onCheckbox(idx) }} />
                          { fixup.modelName }
                        </label>

                        <ul>
                          {fixup.genSpecIssues.map((entry, jdx) => {
                            if (jdx % 2 == 0) return null;
                            return <li>{entry}.{fixup.genSpecIssues[jdx - 1]}</li>
                          })}
                        </ul>
                      </div>
              })}
            </div>
            <DialogActionsBar>
                <button id="fixup-wizard-cancel"
                        className="k-button"
                        onClick={props.onCancel}>
                    Resolve Issues Later
                </button>
                <button id="fixup-wizard-confirm"
                        className="k-button"
                        onClick={props.onSave}>
                          Perform Auto Fix
                </button>
            </DialogActionsBar>

  </Dialog>
}
