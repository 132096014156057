import React, { useState } from "react";
import loadingIcon from "../../images/Palette Ring-1s-200px.gif";


export function SubMenuLeft({ children, ...restProps }) {
  const [processingRequest, setProcessingRequest] = useState(false);

  const handleClick = async (callback) => {
    try {
      setProcessingRequest(true);
      callback && await callback();
      setProcessingRequest(false);
      
    } catch (error) {
      setProcessingRequest(false);
    }
  }

  return <div className="sub-menu-left" role="left sub menu" {...restProps}>
            {React.Children.map(children, (child) => {
              if (!child) return null;
              return React.cloneElement(child, {
                ...child.props,
                onClick: () => handleClick(child.props.onClick),
                disabled: child.props.disabled || processingRequest,
              })
            })}

            {processingRequest &&
            <img id="loading-spinner"
                  style={{ width: 30 }}
                  src={loadingIcon} /> }
          </div>
}

export function SubMenuRight({ children, ...restProps }) {
  const [processingRequest, setProcessingRequest] = useState(false);

  const handleClick = async (callback) => {
    try {
      setProcessingRequest(true);
      callback && await callback();
      setProcessingRequest(false);

    } catch (error) {
      setProcessingRequest(false);
    }
  }

  return <div className="sub-menu-right" role="right sub menu" {...restProps}>
            {processingRequest &&
            <img id="loading-spinner"
                  style={{ width: 30 }}
                  src={loadingIcon} /> }

            {React.Children.map(children, (child) => {
              if (!child) return null;
              return React.cloneElement(child, {
                ...child.props,
                onClick: (callback) => child.props.onClick ?  handleClick(child.props.onClick) : handleClick(callback),
                disabled: child.props.disabled || processingRequest,
              })
            })}
          </div>
}
