import $ from 'jquery'
import { _ajax } from './sml-requests'
import store from '../store/store'
import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'


 // INDEX
 // ------------------------------------------------------------
 // # User
 // # App
 // ## Nav Tree
 // ## Data
 // ------------------------------------------------------------


// ------------------------------------------------------------
// # User
// ------------------------------------------------------------
/**
 * Log in
 *
 */
 export function getCriticalDetails() {
  
  return $.ajax({
    url: "/index.php?r=site/critical-details",
    method: "get",
  }).then(res => {
    const details = JSON.parse(res);
    $.ajaxSetup({
      headers: { "x-csrf-token": details._csrf },
      timeout: 0,
      error: (jqXHR, exception) => {
        try {
          const permissions = JSON.parse(jqXHR.responseText);
          permissions.errors && actionCreators.receiveErrors(permissions.errors);
        } catch (_) {
          jqXHR.responseText && console.error(jqXHR.responseText);
        }
      }
    });

    const { errors, userIdentity } = details;
    delete details["errors"];  // remove from critical details

    // userIdentity is undefined when logged out
    if (userIdentity) {
      userIdentity.id = parseInt(userIdentity.id);
      userIdentity.activeProjectId = parseInt(userIdentity.branchId);
    }

    store.dispatch({
      type: actionTypes.RECEIVE_CRITICAL_DETAILS,
      details,
    })

    if (Array.isArray(errors) && errors.length) {
      actionCreators.receiveErrors(errors);
    }

    return details;
  })
}

export function logout() {
  return $.ajax({
    url: "/index.php?r=/site/logout",
    method: "post",
  }).always(() => {
    store.dispatch({ type: actionTypes.CLEAR_CRITICAL_DETAILS });
    sessionStorage.clear();
  })
}



// ------------------------------------------------------------
// # App
// ------------------------------------------------------------
export function resetApp() {
  store.dispatch({ type: actionTypes.RESET_APP });
  //getActiveChangeSets();
  getCurrentModelRefIndex();  // fetch list of submodels - used by NavTree and Detail pages
  window["resetCache"] && window["resetCache"]();   // remove this after window variables are refactored
  return getCriticalDetails();  // fetch user details
}

export function getActiveChangeSets() {
  return _ajax({
    url: "/index.php?r=change-set/get-active-change-sets",
    method: "get"
  }).then(res => {
    const response = res.data;

    store.dispatch({
      type: actionTypes.RECEIVE_CHANGE_SETS,
      changeSets: response.changeSets || [],
    })
  })
}




// ------------------------------------------------------------
// ## Data
// ------------------------------------------------------------
export const runHealthReport = (useAndTags=true, includeTags=[], excludeTags=[], runReportConfig, issues) => {
  const startTime = Date.now();

  // Issues correspond to the report names in health_check.jsx and their respective backend functions
  const issueToTestMap = {
    "entity_issues" : "entity_uniqueness",
    "attr_issues" : "attribute_observable_uniqueness",
    "obs_issues" : "attribute_observable_uniqueness",
    "name_issues" : "name_uniqueness",
    "circular_issues" : "circular_containment",
    "traceability_issues" : "path_traceability",
    "chars_using_ent_pholder_issues" : "placeholder_usage",
    "chars_using_obs_pholder_issues" : "placeholder_usage",
    "ents_typing_pholder_issues" : "placeholder_usage",
    "chars_using_pholder_meas_issues" : "placeholder_usage",
    "uniqueid_issues" : "entity_uniqueid_presence",
    "deprecation_issues" : "deprecation_issues",
    "pdm_enum_issues" : "pdm_enum_issues",
    "pdm_empty_views_issues" : "pdm_empty_views",
    "composite_nesting_issues" : "composite_nesting_issues",
    "gen_spec_issues" : "gen_spec_issues",
    "model_conformance_issues" : "model_conformance_issues",
    "platform_type_measurement_issues" : "platform_type_measurement_issues",
    "chars_are_optional_issues" : "chars_are_optional_issues",
    "im_context_issues" : "im_context_issues",
    "conversion_issues" : "conversion_issues",
    "bounds_issues" : "bounds_issues",
    "nested_view_issues" : "nested_view_issues",
    "composed_block_issues" : "composed_block_issues"
  }

  // push backend tests to run
  let tests = new Set();
  issues.forEach(issue => tests.add(issueToTestMap[issue]));

  return _ajax({
    url: "/index.php?r=/detail/run-report",
    method: "post",
    data: {
      tests: [...tests].join(" "),
      useAndTags,
      includeTags,
      excludeTags,
      runReportConfig,
    },
  })
  .then((res) => {
    const healthReport = {
      config: runReportConfig,
      issuesData: res.data,
    }
    const totalTime = ((Date.now() - startTime) / 1000).toFixed(1);
    
    // set react redux info for processReport()
    healthReport.issuesData.totalTime = totalTime;
    healthReport.issuesData.prevSearched = issues;
    healthReport.issuesData.testsRun = issues.length;

    store.dispatch({
      type: actionTypes.RECEIVE_HEALTH_REPORT,
      health_report: healthReport,
    })
  })
}


export function getCurrentModelRefIndex() {
  return _ajax({
    url: "/index.php?r=/sub-model/current-model-ref-index",
    method: "get",
  })
  .then((res) => {
    const subModels = res.data

    store.dispatch({
      type: actionTypes.RECEIVE_SUBMODELS,
      subModels,
    })
  })
}