import React from 'react'

import { addNodesToNodesOfType, clearNodesOfType, receiveErrors, receiveLogs, receiveWarnings, removeGuidsFromNodesOfType } from "../../requests/actionCreators"
import { CreateOptions } from './sections/TreeActions';
import NodeLeaf, { convertNodeToLeaf } from './leaf/NodeLeaf';
import { createNodeUrl } from '../../requests/type-to-path';
import SmallNavTree from './SmallNavTree';
import { getNodeWithAddenda } from '../../requests/sml-requests';
import { BasicConfirm } from '../dialog/BasicConfirm';
import { BasicAlert } from '../dialog/BasicAlert';
import { PermissionsDialog, PermissionsDialogConfig } from '../dialog/PermissionsDialog';
import { connect } from 'react-redux';
import { retrieveNestedNodes, getShortenedStringRepresentationOfXmiType, stopBubbleUp } from '../util/util';
import { getCurrentModelRefIndex } from '../../requests/actionThunks';
import { _ajax } from '../../requests/sml-requests';
import DependencyMapper from '../manage/branch/sections/DependencyMapper';
import { findNodeLeafParents } from '../widget/PhenomLink';


class NavTree extends React.Component {
  constructor(props) {
    super(props);
    NavTree.__singleton = this;
    window["treeRef"] = this;

    this.prevRequests = [];
    this.treeRef = React.createRef();
  }

  state = {
    isCollapsed: false,
    allTags: [],
  }

  modeWhitelist = ["edit", "integration", "generate", "manage"];
  mergeSubPages = ["push", "pull", "update-review"];
  mainPageWhitelist = ["cinc_gen"];
  subPageWhitelist = ["fix_up", ...this.mergeSubPages];

  // Indices
  showCheckboxesFor = new Set();
  mergeCandidates = new Set();
  unreachableCandidates = new Set();
  nodeIndex = {
    "root": new NodeLeaf({ guid: "root", name: "<Root>", xmiType: "#" })
  };
  diagramIndex = {
    "root": new NodeLeaf({ guid: "root", name: "root", xmiType: "#" })
  };
  leavesOfTypeWhitelist = new Set([
    "datamodel:DataModel",
    "face:ConceptualDataModel",
    "face:LogicalDataModel",
    "face:PlatformDataModel",
    "face:UoPModel",
    "skayl:IntegrationModel",
    "skayl:DeploymentModel",
    "skayl:DiagramModel",
    "skayl:IntegrationModel",
    "conceptual:Entity",
    "conceptual:BasisEntity",
    "conceptual:Association",
    "conceptual:Observable",
    "logical:MeasurementAxis",
    "logical:Measurement",
    "im:TransportChannel",
  ]);



  // ==========================================================================================================
  // STATIC METHODS
  // ==========================================================================================================

  static setShowCheckboxesFor = (xmiTypes) => {
    if (!Array.isArray(xmiTypes)) return;
    const that = NavTree.__singleton;
    xmiTypes.forEach(xmiType => that.showCheckboxesFor.add(xmiType));

    for (let guid in that.nodeIndex) {
      const leaf = that.nodeIndex[guid];
      if (that.showCheckboxesFor.has(leaf.getXmiType())) {
        leaf.setParentExpanded(true);
      }
    }

    that.forceUpdate();
  }

  static clearShowCheckboxesFor = () => {
    const that = NavTree.__singleton;
    that.showCheckboxesFor = new Set();
    that.forceUpdate();
  }

  static clearCheckedNodes = () => {
    const that = NavTree.__singleton;
    
    for (let guid in that.nodeIndex) {
      const leaf = that.nodeIndex[guid];
      if (!leaf) continue;
      leaf.setChecked(false);
    }

    that.forceUpdate();
  }

  static checkLeafNodes = (guids, bool) => {
    if (!Array.isArray(guids) || typeof bool !== 'boolean') {
      return;
    }

    const that = NavTree.__singleton;
    for (let guid of guids) {
      const leaf = that.nodeIndex[guid];
      if (!leaf) continue;
      leaf.setChecked(bool);
    }

    that.forceUpdate();
  }
  
  static reset = (isResetFilter=false, modelId=false) => {
    return NavTree.__singleton.resetTreeData(isResetFilter, modelId);
  }

  static refresh  = () => {
    NavTree.__singleton.forceUpdate();
  }

  /**
   *
   * @param {string} guid
   * @returns Leaf Node
   */
  static getLeafNode = (guid) => {
    const that = NavTree.__singleton;
    return that.findLeaf(guid);
  }


  static getLeafNodeList = (xmiTypes=[]) => {
    const that = NavTree.__singleton;
    const leaves = [];

    for (let guid in that.nodeIndex) {
      const leaf = that.nodeIndex[guid];
      if (!leaf) continue;
      if (xmiTypes.includes(leaf.getXmiType())) {
        leaves.push(leaf);
      }
    }
    return leaves;
  }

  static getReviewNodeDataList = () => {
    const that = NavTree.__singleton;
    const dataArray = [];

    for (let guid in that.nodeIndex) {
      const leaf = that.nodeIndex[guid];
      if (!leaf) continue;

      if (leaf.isReviewNode()) {
        const data = leaf.getData();
        dataArray.push(data);
        data.parents = findNodeLeafParents(leaf.getGuid());
      }
    }
    
    return dataArray;
  }

  static getReviewNodeChildrenDataList = (guid) => {
    const that = NavTree.__singleton;
    const leaf = that.findLeaf(guid);
    if (!leaf) return [];
  
    const reviewChildren = leaf.getReviewChildren();
        
    return reviewChildren.map(leaf => leaf.getData());
  }

  // accepted true for toggle status, false for reject
  static updateReviewStatus = (guid, thisNodeOnly, accepted) => {
    const that = NavTree.__singleton;
    const leaf = that.findLeaf(guid);
    if (!leaf) return;
  
    const currentStatus = leaf.getReviewStatus();
    if (currentStatus === "Rejected") return;
  
    const leafData = {...leaf.getData()};
    const statusMap = accepted ? {
      Under_Review: "Accepted",
      Accepted: "Under_Review"
    } : {
      Under_Review: "Rejected",
      Accepted: "Rejected"
    };
  
    if (thisNodeOnly) {
      leafData.reviewStatus = statusMap[currentStatus];
      leaf.updateData(leafData);
    } else {
      leafData.reviewStatus = statusMap[currentStatus];
      const reviewChildren = leaf.getReviewChildren();
      leaf.updateData(leafData);
      reviewChildren.forEach((child) => {
        const childData = {...child.getData()};
        childData.reviewStatus = statusMap[currentStatus];
        child.updateData(childData);
      })
    }

    that.forceUpdate();
  }

  /**
   *
   * @param {string} guid
   * @returns Object
   */
  static getNodeData = (guid) => {
    const that = NavTree.__singleton;
    const leaf = that.findLeaf(guid);
    return leaf?.getData();
  }

  static getNodeDataList = (xmiTypes=[]) => {
    return NavTree.getLeafNodeList(xmiTypes).map(leaf => leaf.getData());
  }

  static scrollToLeaf = (guid) => {
    const that = NavTree.__singleton;
    that.treeRef.current && that.treeRef.current.searchLeafByGuid(guid);
  }

  // ==========================================================================================================
  // NAV TREE FILTER
  // ==========================================================================================================

  /**
   * Add nodes to NavTree, including nested nodes
   *
   * @param {array} nodes
   * @returns Promise
   */
  static addNodes = async (nodes=[]) => {
    if (!Array.isArray(nodes)) nodes = [nodes];
    const that = NavTree.__singleton;
    const { subModels } = that.props;

    // remove bad nodes
    nodes = nodes.filter(n => !!n?.guid);

    // reveal node after save
    let revealGuid;
    if (nodes.length === 1) {
      revealGuid = nodes[0]?.guid;
    }

    // if node has orderable children, assign childIndex before updating
    nodes.forEach(node => {
      if (['platform:View', 'conceptual:Entity', 'conceptual:Association'].includes(node.xmiType)) {
        node?.children?.length && node.children.forEach((child, idx) => {
          if (typeof child === 'object') {
            child.childIndex = idx;
          }
        })
      }
    })

    // recursively add missing parents to tree
    const addMissingParents = async (parentGuids, parentLeaves=[]) => {
      if (!Array.isArray(parentGuids)) {
        parentGuids = [];
      }

      // fetch missing parent nodes
      const missingGuids = parentGuids.filter(parentGuid => !that.findLeaf(parentGuid)).map(parentGuid => getNodeWithAddenda(parentGuid));
      const moreMissingGuids = new Set();

      const resList = await Promise.all(missingGuids);
      for (let parent of resList) {
        if (!parent?.guid) continue;    // in case an error was returned

        // add to nodeIndex
        const leaf = that.addNodeToTree(parent);
        parentLeaves.push(leaf);

        const grandParentGuid = leaf.getParentGuid();
        if (!that.findLeaf(grandParentGuid)) {
          moreMissingGuids.add(grandParentGuid);
        }
      }

      if (moreMissingGuids.size) {
        await addMissingParents([...moreMissingGuids], parentLeaves);
      }

      return parentLeaves;
    }

    // all nodes including nested nodes
    const nodeList = retrieveNestedNodes(nodes);

    // add nodes to tree
    for (let node of nodeList) {
      that.addNodeToTree(node);
    }

    const parentGuids = new Set();
    nodes.forEach(node => {
      node?.parent && parentGuids.add(typeof node.parent === 'string' ? node.parent : node.parent?.guid);
    });
    const missingParentLeaves = await addMissingParents([...parentGuids]);
    const nodeHash = {};
    let refetchSubModels = false;

    // update parents in tree
    for (let parentLeaf of missingParentLeaves) {
      if (!parentLeaf) continue;
      that.updateNodeParentInTree(parentLeaf.getData());

      const xmiType = parentLeaf.getXmiType();
      if (that.leavesOfTypeWhitelist.has(xmiType)) {
        if (!nodeHash[xmiType]) nodeHash[xmiType] = [];
        nodeHash[xmiType].push(parentLeaf.getData());
      }

      if (!subModels[parentLeaf.getModelId()]) {
        refetchSubModels = true;
      }
    }

    // add nodes to tree
    for (let node of nodeList) {
      const leaf = that.findLeaf(node.guid);
      if (!leaf) continue;
      that.updateNodeParentInTree(node);

      const xmiType = leaf?.getXmiType();
      if (that.leavesOfTypeWhitelist.has(xmiType)) {
        if (!nodeHash[xmiType]) nodeHash[xmiType] = [];
        nodeHash[xmiType].push(leaf.getData());
      }

      if (!subModels[node.subModelId]) {
        refetchSubModels = true;
      }
    }

    // add nodes to redux nodesOfType
    Object.keys(nodeHash).length && addNodesToNodesOfType(nodeHash);
    that.forceUpdate();
    that.applyFilter();

    // new model was created - need to refresh subModel list
    if (refetchSubModels) {
      getCurrentModelRefIndex();
    }

    // reveal guid after save
    if (revealGuid) {
      that.searchLeafByGuid(revealGuid);
    }
  }

  static insertNodeIndex = (nodes={}) => {
    const that = NavTree.__singleton;
    that.insertNodeIndex(nodes, false);
    that.forceUpdate();
  }

  static addMergeCandidates = (candidates={}, hideCandidateCheckbox=false) => {
    const that = NavTree.__singleton;
    that.insertNodeIndex(candidates, true, hideCandidateCheckbox);
    that.forceUpdate();
  }

  static getAllTags = () => {
    return NavTree.__singleton.state.allTags;
  }

  static getTagFilters = () => {
    const tree = NavTree.__singleton.treeRef.current;
    if (!tree) return;
    const filter = tree.getFilters();
    return { useAndTags: filter.useAndTags, includeTags: filter.includeTags, excludeTags: filter.excludeTags }
  }

  static assignPageFilters = (pageWhitelist={}, pageBlacklist={}) => {
    const tree = NavTree.__singleton.treeRef.current;
    return tree && tree.setPageFilters(pageWhitelist, pageBlacklist);
  }

  static clearPageFilters = () => {
    const tree = NavTree.__singleton.treeRef.current;
    return tree && tree.setPageFilters({}, {});
  }

  static assignPresetPageFilters = (type) => {
    let pageWhitelist = {type: [], name: []};
    let pageBlacklist = {type: [], name: []};

    switch (type) {
      case "data":
        pageWhitelist.type = [
          "face:ConceptualDataModel",
          "datamodel:DataModel",
          "face:LogicalDataModel",
          "face:PlatformDataModel",
          "skayl:DiagramModel",
          "conceptual:Entity",
          "conceptual:Association",
          "conceptual:Composition",
          "conceptual:AssociatedEntity",
          "conceptual:Observable",
          "conceptual:BasisEntity",
          "conceptual:Domain",
          "logical:Measurement",
          "logical:MeasurementAxis",
          // "logical:MeasurementConstraint",
          "logical:EnumerationConstraint",
          "logical:MeasurementConversion",
          "logical:MeasurementSystem",
          "logical:MeasurementSystemConversion",
          "logical:StandardMeasurementSystem",
          "logical:MeasurementSystemAxis",
          "logical:CoordinateSystem",
          "logical:CoordinateSystemAxis",
          "logical:Unit",
          "logical:Landmark",
          "logical:ReferencePoint",
          "logical:ValueTypeUnit",
          "logical:Boolean",
          "logical:Character",
          "logical:Enumerated",
          "logical:Integer",
          "logical:Natural",
          "logical:NonNegativeReal",
          "logical:Real",
          "logical:String",
          "platform:View",
          "platform:CharacteristicProjection",
          "platform:Boolean",
          "platform:Octet",
          "platform:Char",
          "platform:WChar",
          "platform:CharArray",
          "platform:WCharArray",
          "platform:String",
          "platform:WString",
          "platform:BoundedString",
          "platform:BoundedWString",
          "platform:Short",
          "platform:UShort",
          "platform:Long",
          "platform:LongLong",
          "platform:ULong",
          "platform:ULongLong",
          "platform:Double",
          "platform:LongDouble",
          "platform:Float",
          "platform:Fixed",
          "platform:IDLArray",
          "platform:IDLSequence",
          "platform:IDLStruct",
          "platform:Enumeration",
          "platform:RegularExpressionConstraint",
          "platform:RealRangeConstraint",
          "platform:IntegerRangeConstraint",
          "skayl:DiagramContext",
        ];
        pageBlacklist.name = ["Skayl_Unit_Transforms"];
        break;

      case "integration":
        pageWhitelist.type = [
          "face:UoPModel",
          "skayl:DeploymentModel",
          "skayl:MessageDataModel",
          "skayl:IntegrationModel",
          "platform:View",
          "platform:CharacteristicProjection",
          "uop:PortableComponent",
          // "uop:MessagePort", //OBE
          "im:TransportChannel",
          "im:UoPInstance",
          "ddm:MainProgram",
          "pedm:ProcessingElement",
          "uop:PlatformSpecificComponent",
          "message:Type",
          "im:IntegrationContext",
          "im:ComposedBlock",
          "im:ComposedBlockInstance",
          "im:SourceNode",
          "im:SinkNode",
          "im:FanIn",
          "im:FanOut",
          "im:TransformNode",
          "im:FilterNode",
          "im:ViewTransporterNode",
          "im:SIMAdapter",
          "im:QueuingAdapter",
          "im:DataPump",
          "im:UoPInputEndPoint", 
          "im:UoPOutputEndPoint",
          "im:ComposedInPort",
          "im:ComposedOutPort",
          "im:NodeConnection"
        ];
        break;

      case "views":
        pageWhitelist.type = ["face:PlatformDataModel", "platform:View", "platform:CharacteristicProjection"];
        break;

      case "uops":
        pageWhitelist.type = ["face:UoPModel", "uop:PortableComponent", "uop:PlatformSpecificComponent"];
        break;

      case "mps":
        pageWhitelist.type = ["skayl:IntegrationModel", "ddm:MainProgram"];
        break;

      case "sp":
        pageWhitelist.type = ["conceptual:Entity", "conceptual:Association", "platform:View", "skayl:DiagramModel", "skayl:DiagramContext"];
        break;
    }

    return NavTree.assignPageFilters(pageWhitelist, pageBlacklist);
  }

  static collapseNavTree = (bool) => {
    NavTree.__singleton.setState({ isCollapsed: bool })
  }

  // ==========================================================================================================
  // MODEL GEN METHODS
  // ==========================================================================================================

  static getSelectedForModelGen = () => {
    const that = NavTree.__singleton;
    const guids = [];

    for (let guid in that.nodeIndex) {
      const leaf = that.nodeIndex[guid];
      if (leaf && leaf.isChecked()) {
        guids.push(guid);
      }
    }

    return guids;
  }

  static selectAllForModelGen = () => {
    const that = NavTree.__singleton;

    for (let guid in that.nodeIndex) {
      const leaf = that.nodeIndex[guid];
      !leaf.isFilteredOut() && leaf.setChecked(true);
    }

    that.forceUpdate();
  }

  static deselectAllForModelGen = () => {
    const that = NavTree.__singleton;

    for (let guid in that.nodeIndex) {
      const leaf = that.nodeIndex[guid];
      leaf.setChecked(false);
    }

    that.clearSelection();
    that.forceUpdate();
  }

  // ==========================================================================================================
  // PUSH/PULL/APPROVE/UPDATE-REVIEW METHODS
  // ==========================================================================================================
  static expandAllReviewNodes = () => {
    const that = NavTree.__singleton;

    for (let guid in that.nodeIndex) {
      const leaf = that.nodeIndex[guid];
      if (leaf.isReviewNode()) {
        leaf.setParentExpanded(true);
      }
    }

    that.forceUpdate();
  }

  static getSelectedMergeCandidates = () => {
    return [...NavTree.__singleton.mergeCandidates].filter(leaf => leaf.isMarkedForMerge());
  }

  static getAllMergeCandidates = () => {
    return NavTree.__singleton.mergeCandidates;
  }

  static selectMergeCandidates = (guids, select = true) => {
    const that = NavTree.__singleton;

    for (let guid of guids) {
      if (guid === "root" || !that.nodeIndex[guid].isMergeCandidate()) continue;
      const leaf = that.nodeIndex[guid];

      let relations = new Set();
      that.findAllRelationCandidates(leaf, relations);
      
      let leaves = leaf.getAllChildrenLeaves();
      for (let child of leaves) {
        child.isMergeCandidate() && that.findAllRelationCandidates(child, relations);
      }

      let ancestors = new Set(leaf.getAllParentLeaves());
      for (let rel of relations) {
        rel.getAllParentLeaves().forEach(p => ancestors.add(p));
      }

      leaf.setChecked(select);
      for (let l of [...leaves, ...relations]) {
        l.setChecked(select);
      }

      // reorder the ancestors and check their status
      let orderedAncestors = [];
      const queue = [...ancestors.difference(relations)];
      while (queue.length) {
        const anc = queue.shift();
        const ancChildren = new Set(anc.getChildrenLeaves());

        if (!ancChildren.size || !queue.find(q => ancChildren.has(q))) {
          orderedAncestors.push(anc);
        } else {
          queue.push(anc);
        }
      }

      // check the status of parent nodes
      for (let anc of orderedAncestors) {
        if (!anc || anc.getGuid() === "root") continue;
        anc.updateCheckedStatus();
      }
    }

    window.dispatchEvent(new CustomEvent('CHECKED_CANDIDATES'));
    that.applyFilter();
    that.forceUpdate();
  }

  static selectAllMergeCandidates = () => {
    const that = NavTree.__singleton;

    for (let leaf of that.mergeCandidates) {
      leaf.isMergeCandidate() && !leaf.isFilteredOut() && leaf.markForMerge(true);
    }

    window.dispatchEvent(new CustomEvent('CHECKED_CANDIDATES'));
    that.applyFilter();
    that.forceUpdate();
  }

  static isMovedInNode = (guid) => {
    const that = NavTree.__singleton;
    return !!that.nodeIndex[guid + " m"];
  }

  findAllRelationCandidates = (leaf, result=new Set()) => {
    const mergeGuid = leaf.getGuid();
    const moved = /.* m/.test(mergeGuid);
    const deleted = /.* d/.test(mergeGuid);
    const plainGuid = moved || deleted ? mergeGuid.slice(0, -2) : mergeGuid;

    // add the other moved version of the node if it exists
    this.findCorrespondingMovedNode(leaf, result);

    // add nodes on the relationsMap
    const relation = this.relationsMap.findLeaf([plainGuid]);

    if (relation) {
      relation.getDependencies(this.nodeIndex).forEach(relGuid => {
        const guid = moved ? relGuid + " m" : deleted ? relGuid + " d" : relGuid;
        const node = this.nodeIndex[guid];
        if (node && node.isMergeCandidate() && node.isCreatedForMerge() && !result.has(node)) {
          result.add(node);
          this.findCorrespondingMovedNode(node, result);
        }
      });
    }

    return result;
  }

  findCorrespondingMovedNode = (leaf, result=new Set()) => {
    const guid = leaf.getGuid();
    const isMovedGuid = /.* m/.test(guid);

    const otherMovedNode = isMovedGuid ? this.nodeIndex[guid.slice(0, -2)] : this.nodeIndex[guid + " m"];
    if (otherMovedNode && otherMovedNode.isMergeCandidate() && !result.has(otherMovedNode)) {
      result.add(otherMovedNode);
      this.findAllRelationCandidates(otherMovedNode, result);
    }
  }

  static merge = (mergeType, checked = false, deprecateDeletes = false, deprecateMoves = false, name=false, description=false, requestId=false) => {
    const requestMap = this.getRequestMap();
    if (!requestMap) return Promise.reject("Please make a selection and try again.");

    const requestData = {
      name: name,
      description: description,
      requestId: requestId,
      type: mergeType,
      allNodes: false,    
      requests: requestMap,
      checked,
      deprecateDeletes,
      deprecateMoves
    }

    // note: object is stringified because
    // requests: {modelId: {6: []}} is viewed as null by Yii
    return _ajax({
      url: `/index.php?r=/referencing-model/merge/`,
      method: "post",
      data: { params: JSON.stringify(requestData) },
    });
  }

  static push = (name, description) => {
    return NavTree.merge("push", false, false, false, name, description, false);
  }

  static pull = (deprecateDeletes, deprecateMoves) => {
    return NavTree.merge("pull", false, deprecateDeletes, deprecateMoves, false, false, false, false);
  }

  static approve = (deprecateDeletes, deprecateMoves, requestId) => {
    return NavTree.merge("approve", false, deprecateDeletes, deprecateMoves, false, false, requestId)
  }

  static mergeReviewPull = (deprecateDeletes, deprecateMoves) => {
    return NavTree.merge("mergeReviewPull", false, deprecateDeletes, deprecateMoves, false, false, false, false);
  }

  static fetchTreeWithMergeCandidates = (modelId=false) => {
    return NavTree.__singleton.fetchTreeMergeNodes(modelId);
  }

  static getRequestMap = () => {
    const that = NavTree.__singleton;
    const selected = [...that.mergeCandidates].filter(l => l.isMarkedForMerge());
    if (!selected.length) {
      return false;
    }

    const requestMap = {};

    for (let leaf of selected) {
      const modelId = leaf.getModelId();
      const leafGuid = leaf.isDeletedForMerge() ? leaf.getGuid() + " d" : leaf.getGuid();

      if (!requestMap[modelId]) {
        requestMap[modelId] = [];
      }

      requestMap[modelId].push(leafGuid);
    }

    for (let modelId in requestMap) {
      requestMap[modelId] = requestMap[modelId].join(",");
    }

    return requestMap;
  }


  static fetchDependencyMap = async (modelId=false) => {
    return NavTree.__singleton.fetchRelationsMap(modelId);
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.saveSessionStorageWindowUnload);
    window.addEventListener('DELETED_NODES', this.removeNodesListener);
    window.addEventListener('UPDATE_NODE_TAGS', this.updateTagsListener);

    if (!this.mainPageWhitelist.includes(this.props.match.params.main_page) && !this.subPageWhitelist.includes(this.props.match.params.sub_page)) {
      Promise.all([this.fetchTreeData(), this.fetchAvailableTags()])
             .finally(() => {
              this.clearPrevRequests();
            })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const currParams = this.props.match.params;
    const prevParams = prevProps.match.params;
    const currMode = currParams.mode;
    const prevMode = prevParams.mode;
    const currSubPage = currParams.sub_page;
    const prevSubPage = prevParams.sub_page;

    // entering existing node page, expand and select leaf in nav tree
    if (currParams.guid !== undefined) {
      this.autoSelect(currParams.guid);
    } else{
      this.clearAutoSelectLeaf();
    }

    // do not refresh the tree when entering push/pull because it needs to fetch the relational map and show a modal (push/pull/approve will make the fetch request)
    // refresh the navtree when navigating from push/pull to any other page.
    if (prevSubPage !== currSubPage && (!this.mergeSubPages.includes(currSubPage) && currSubPage !== "finalize-merge") && this.mergeSubPages.includes(prevSubPage)) {
      this.fetchTreeMergeNodes();

    } else if (prevMode !== currMode) {
      this.saveSessionStorage(prevMode);
      this.loadSessionStorage(currMode);
    }
  }

  componentWillUnmount() {
    this.cancelPrevRequests();
    this.saveSessionStorage(this.props.match.params.mode);
    window.removeEventListener('beforeunload', this.saveSessionStorageWindowUnload);
    window.removeEventListener('DELETED_NODES', this.removeNodesListener);
    window.removeEventListener('UPDATE_NODE_TAGS', this.updateTagsListener);
  }

  /**
   *
   * Used by componentDidUpdate() to expand and select navigated node in NavTree
   * 
   * @param {string} guid leaf to auto select in tree on navigation
   * @returns {void}
   */
  autoSelect = (guid) => {
    const nodeGuid = guid;
    const leaf = this.findLeaf(nodeGuid);

    if (leaf !== undefined) {
      const parentLeaf = leaf.getParentLeaf();
      parentLeaf && parentLeaf.setExpanded(true);
      this.autoSelectLeaf(leaf);
      this.treeRef.current.forceUpdate();
    } 
  }

    clearAutoSelect = () => {
      this.clearAutoSelectLeaf();
      this.treeRef.current.forceUpdate();
    }

  /**
   *
   * @param {string} guid
   * @returns Leaf node
   */
  findLeaf = (guid) => {
    return this.nodeIndex[guid];
  }

  clearTreeData = () => {
    clearNodesOfType();
    this.nodeIndex = {
      "root": new NodeLeaf({ guid: "root", name: "<Root>", xmiType: "#" })
    }

    // this.diagramIndex = {
    //   "root": new NodeLeaf({ guid: "root", name: "root", xmiType: "#" }),
    // }

    this.showCheckboxesFor = new Set();
    this.mergeCandidates = new Set();
    this.unreachableCandidates = new Set();
  }

  clearTreeFilters = () => {
    if (this.treeRef?.current) {
      this.treeRef.current.setXmiTypeFilters([]);
      this.treeRef.current.setTagFilters([], [], true);
    }
  }

  clearPrevRequests = () => {
    this.prevRequests = [];
  }

  cancelPrevRequests = () => {
    this.prevRequests.forEach((jqXHR) => {
      // readyState 4 == done
      jqXHR.readyState < 4 && jqXHR.abort();
    });
    this.clearPrevRequests();
  }

  fetchTreeMergeNodes = (modelId=false) => {
    const cleanupPreviousRequests = new Promise((resolve) => {
      this.cancelPrevRequests();
      this.saveSessionStorage(this.props.match.params.mode);
      this.clearTreeData();
      resolve();
    });

    return cleanupPreviousRequests
      .then(() => {
        const reqs = [this.fetchTreeData(modelId), this.fetchAvailableTags(), this.fetchRelationsMap(modelId)];
        return Promise.all(reqs)
    }).finally(() => {
      this.clearPrevRequests();
    });
  }

  resetTreeData = (isResetFilter=false, modelId=null) => {
    const cleanupPreviousRequests = new Promise((resolve, reject) => {
      this.cancelPrevRequests();
      this.saveSessionStorage(this.props.match.params.mode);
      this.clearTreeData();
      resolve();
    });

    return cleanupPreviousRequests
    .then(this.fetchAvailableTags)
    .then(() => this.fetchTreeData(modelId))
    .then(() => {if (isResetFilter) this.clearTreeFilters()})
    .finally(() => {this.clearPrevRequests()});
  }

  fetchTreeData = (modelId=false) => {
    let subPage = this.props.match.params.sub_page;
    if (subPage === "pull" && modelId) subPage = undefined;

    return _ajax({
      url: "/index.php?r=/tree/load/",
      data: { 
        pageName: subPage,
        modelId: modelId || undefined,
      },
      beforeSend: (jqXHR) => {
        this.prevRequests.push(jqXHR);
      }
    }).then(response => {
      if (!response.data?.baseNodes) return;
      
      // nodes are still jsonified for performance optimization
      const { baseNodes, mergeNodes } = response.data;
      
      this.clearTreeData();
      this.insertNodeIndex(JSON.parse(baseNodes), false);
      this.insertNodeIndex(JSON.parse(mergeNodes), true);
      this.loadSessionStorage(this.props.match.params.mode);
    })
  }

  fetchAvailableTags = () => {
    return _ajax({
      url: "/index.php?r=/detail/available-tags",
      beforeSend: (jqXHR) => {
        this.prevRequests.push(jqXHR);
      },
    }).then(res => {
      this.setState({
        allTags: res.data.tags
      })
    })
  }

  fetchRelationsMap = (modelId=false) => {
    let subPage = this.props.match.params.sub_page;
    if (![...this.mergeSubPages, "approve"].includes(subPage)) return;

    return _ajax({
      url: "/index.php?r=/referencing-model/node-relations-map/",
      method: "post",
      data: { 
        modelId: modelId || undefined,
        mergeType: subPage,
      },
      beforeSend: (jqXHR) => {
        this.prevRequests.push(jqXHR);
      },
    }).then(response => {
      const dependencyMap = response?.data || {};
      this.relationsMap = new DependencyMapper(dependencyMap, true);
    })
  }

  insertNodeIndex = (nodeMap, addToMergeCandidates=false, hideCandidateCheckbox=false) => {
    const nodeHash = {};
    this.nodeIndex = { ...this.nodeIndex }  // force componentDidUpdate in SmallNavTree

    // add all nodes to tree
    // add nodes to nodesOfType if conditions are met
    for (let guid in nodeMap) {
      if (guid === "root") continue;
      const node = nodeMap[guid];

      // nodes that have xmiType names need to be converted into string representation
      if (node.n.includes(":")) node.n = getShortenedStringRepresentationOfXmiType(node.n);
      node.g = addToMergeCandidates;
      const leaf = this.addNodeToTree(node, guid);
      const xmiType = leaf?.getXmiType();

      if (!addToMergeCandidates && this.leavesOfTypeWhitelist.has(xmiType)) {
        if (!nodeHash[xmiType]) nodeHash[xmiType] = [];
        nodeHash[xmiType].push(leaf.getData());
      }
    }

    // connect leaf to parent leaf
    for (let guid in nodeMap) {
      const node = nodeMap[guid];
      this.updateNodeParentInTree(node, false);
    }

    // mark nodes as merge candidates
    if (addToMergeCandidates) {
      const root = this.nodeIndex["root"];
      const unreachable = new Set();

      for (let guid in nodeMap) {
        const leaf = this.nodeIndex[guid];
        if (!leaf) continue;

        if (!leaf.getParentLeaf()) {
          unreachable.add(leaf);
          root.setChildLeaf(leaf);
          leaf.setParentLeaf(root);
          leaf.setReachable(false);

          leaf.getAllChildrenLeaves().forEach(child => unreachable.add(child));
        }
      }

      for (let guid in nodeMap) {
        const leaf = this.nodeIndex[guid];
        if (!leaf || unreachable.has(leaf)) { 
          continue;
        }

        leaf.setReachable(true);
        const node = nodeMap[guid];
        this.addNodeToMergeCandidates(node, hideCandidateCheckbox);
      }
    }

    // now that parents are updated, set them to expanded
    if (addToMergeCandidates)
    for (let guid in nodeMap) {
      const leaf = this.nodeIndex[guid];
      leaf.setParentExpanded(true);
    }

    // add to redux's nodesOfType
    Object.keys(nodeHash).length && addNodesToNodesOfType(nodeHash);
    this.forceUpdate();
  }


  // used by SkratchpadManager
  insertDiagramIndex = (baseNodes) => {
    // initialize tree
    for (let guid in baseNodes) {
      if (guid === "root") continue;
      const node = baseNodes[guid];

      // initially the node does not contain the guid attribute
      node.guid = guid;
      const childLeaf = this.diagramIndex[guid] || new NodeLeaf(node);
      const parentLeaf = this.diagramIndex[childLeaf.getParentGuid()];

      if (!parentLeaf) {
        continue;
      }

      childLeaf.updateData(node);

      // set parent-children relationship
      childLeaf.setParentLeaf(parentLeaf);
      parentLeaf.setChildLeaf(childLeaf);

      childLeaf.show();
      this.diagramIndex[guid] = childLeaf;
    }

    this.forceUpdate();
  }

  saveSessionStorageWindowUnload = () => {
    this.saveSessionStorage(this.props.match.params.mode);
  }

  saveSessionStorage = (mode) => {
    try {
      const currSubPage = this.props.match.params.sub_page;

      // exit if treeRef is not mounted yet
      // exit if mode is invalid
      // exit if page is push/pull/approve
      if (!this.treeRef.current || !this.modeWhitelist.includes(mode) || this.mergeSubPages.includes(currSubPage)) {
        return;
      }

      const session = this.treeRef.current.saveSessionStorage();
      const navTree = document.querySelector("#phenom-navtree");
      const rect = navTree.getBoundingClientRect();
      session.width = rect.width;

      sessionStorage.setItem(`${mode}-navtree`, JSON.stringify(session));
    } catch (error) {
      console.error("Exceeded local storage limit.");
    }
  }

  loadSessionStorage = (mode) => {
    const currSubPage = this.props.match.params.sub_page;

    // exit if treeRef is not mounted yet
    // exit if mode is invalid
    // exit if page is push/pull/approve
    if (!this.treeRef.current || !this.modeWhitelist.includes(mode) || this.mergeSubPages.includes(currSubPage)) {
      return;
    }

    const sessionRaw = sessionStorage.getItem(`${mode}-navtree`);

    // exit if session doesn't exist
    if (!sessionRaw) return;
    const session = JSON.parse(sessionRaw);
    
    // triggers only when the nodeIndex has nodes - this also triggers when nodeIndex is reset and has zero nodes
    if (Object.keys(this.nodeIndex).length > 1) {
      this.treeRef.current.loadSessionStorage(session);
      sessionStorage.removeItem(`${mode}-navtree`);
    }

    if (session.width) {
      const navTree = document.querySelector("#phenom-navtree");
      navTree.style.width = session.width + "px";
    }
  }

  handleResize = (dMouse) => {
    const navTree = document.querySelector("#phenom-navtree");
    const rect = navTree.getBoundingClientRect();
    const newWidth = Math.max(rect.width - dMouse, 230);
    navTree.style.width = newWidth + "px";
    // sessionStorage.setItem("navTreeSize", newWidth);
  }

  toggleCollapse = () => {
    this.setState((prevState) => ({
      isCollapsed: !prevState.isCollapsed,
    }))
  }

  

  /**
   * Add single node to tree data
   *
   * @param {node} node
   * @param {string} guid
   * @returns Leaf node when successfully added to tree, undefined otherwise
   */
  addNodeToTree = (node, guid) => {
    if (!node) return;
  
    // initially the node does not contain the guid attribute
    if (guid) {
      node.guid = guid;
    }

    if (node.xmiType) {
      node = convertNodeToLeaf(node);
    }

    let leaf = this.nodeIndex[node.guid];
    if (leaf) {
      leaf.updateData(node);
    } else {
      leaf = new NodeLeaf(node);
      this.nodeIndex[node.guid] = leaf;
    }

    return leaf;
  }

  updateNodeParentInTree = (node, forceUpdate=true) => {
    // invalid node
    if (!node?.guid || !this.nodeIndex[node.guid]) {
      return;
    }

    const currentLeaf = this.nodeIndex[node.guid];
    const currentParentLeaf = currentLeaf.getParentLeaf();
    var   newParentLeaf = this.nodeIndex[currentLeaf.getParentGuid()];

    // check if the parent was changed:
    //    -> currentParentLeaf and newParentLeaf must exist
    if (currentParentLeaf && newParentLeaf && currentParentLeaf !== newParentLeaf) {
      currentParentLeaf.removeChild(currentLeaf, forceUpdate);
    }

    // check if this node and its parent are moved out nodes on the merge tree
    if (currentLeaf.isMovedOutForMerge()) {
      // check if the old version of leaf is not a merge candidate
      // if it is not, it should not appear in the tree
      const subPage = this.props.match.params.sub_page;
      const oldLeaf = this.nodeIndex[node.guid.slice(0, -2)];
      if (subPage === "approve" && !oldLeaf.isMergeCandidate()) {
        const oldParentLeaf = oldLeaf.getParentLeaf();
        oldParentLeaf.removeChild(oldLeaf, forceUpdate);
      }

      const parentMovedLeaf = this.nodeIndex[currentLeaf.getParentGuid() + " m"];
      if (parentMovedLeaf) {
        newParentLeaf = parentMovedLeaf;
      }
    }

    // connect leaf to newParentLeaf
    //    -> check if newParentLeaf exists - approve page populated the NavTree without a parent
    if (newParentLeaf) {
      currentLeaf.setParentLeaf(newParentLeaf);
      newParentLeaf.setChildLeaf(currentLeaf, forceUpdate);
    }
  }

  updateTagsListener = (e) => {
    if (!Array.isArray(e.detail)) return;

    // update each leaf node
    for (let node of e.detail) {
      const leaf = this.nodeIndex[node?.guid];
      if (!leaf) continue;
      leaf.updateTags(node.tags);
    }

    // reset all tags
    this.fetchAvailableTags();
  }

  removeNodesListener = (e) => {
    let guids = e?.detail?.guids;

    // invalid
    if (!Array.isArray(guids)) {
      return;
    }

    const guidHash = {};

    for (let guid of guids) {
      const leaf = this.findLeaf(guid);
      if (!leaf) continue;

      this.removeNodeFromTree(guid);

      const xmiType = leaf.getXmiType();
      if (!guidHash[xmiType]) guidHash[xmiType] = [];
      guidHash[xmiType].push(guid);
    }

    removeGuidsFromNodesOfType(guidHash);
    this.forceUpdate();
  }

  removeNodeFromTree = (guid) => {
    if (!guid) return;
    const leaf = this.findLeaf(guid);
    if (leaf) {
      leaf.remove();                // disconnect parent-child relationship
      delete this.nodeIndex[guid];  // delete from tree data
    }
  }

  addNodeToMergeCandidates = (node, hideCheckbox=false) => {
    if (!node?.guid) return;

    let currentNode = this.nodeIndex[node.guid];
    if (!currentNode) return;

    currentNode.setMergeCandidate();
    currentNode.setHideCheckbox(hideCheckbox);
    this.mergeCandidates.add(currentNode);

    currentNode = currentNode.getParentLeaf();

    while (currentNode) {
        currentNode.setHasMergeCandidateChildren(true)
        currentNode.setHideCheckbox(hideCheckbox);
        currentNode = currentNode.getParentLeaf();
    }
  }

  // used by SkratchpadManager
  removeDiagramLeaf = (guid) => {
    const diagramLeaf = this.diagramIndex[guid];
    if (!diagramLeaf) return;
    diagramLeaf.remove();
    delete this.diagramIndex[guid];
    this.forceUpdate();
  }

  // update tree if existing model is added to existing project
  updateModelsListener = () => {
    this.fetchData()
  }

  // ==========================================================================================================
  // MOUSE EVENTS
  // ==========================================================================================================
  onDoubleClick = (leaf) => {
    const { match } = this.props;

    if (this.mergeSubPages.includes(match.params.sub_page) || match.params.main_page === "model_gen" || match.params.sub_page === "approve") return;

    let url;
    switch (leaf.getXmiType()) {
      case "im:IntegrationContext":
      case "im:ComposedBlock":
      case "skayl:DiagramContext":
        if (match.params.main_page === "idm" || match.params.main_page === "scratchpad") {
          return window.dispatchEvent(new CustomEvent('LOAD_CONTEXT', { detail: leaf.getData() }));
        }

      default:
        url = createNodeUrl(leaf.getData());
    }

    // url does not exist for this node
    if (!url || url === "/") return;
    this.props.history.push(url);
  }


  // ==========================================================================================================
  // DRAG EVENTS
  // ==========================================================================================================
  isLeafDraggable = () => {
    const { match } = this.props;
    return match.params.sub_page !== "push" &&
           match.params.sub_page !== "pull" &&
           match.params.sub_page !== "approve" && 
           match.params.sub_page !== "update-review";
  }

  onDrop = (event, targetLeaf) => {
    event.preventDefault();
    event.stopPropagation();
    const { subModels } = this.props;
    const rawData = event.dataTransfer.getData("treeNodes");
    const rawNodes = rawData && JSON.parse(rawData);
    if (!rawNodes) {
      return;
    }

    const leaves = rawNodes.map(ele => this.nodeIndex[ele.guid])
                           .filter(leaf => !!leaf);     // remove any possible undefined nodes

    if (!leaves.length) {
      return;
    }

    if (leaves.every(leaf => leaf && leaf.getModelId() === targetLeaf.getModelId())) {
      return this.moveNodes(targetLeaf, leaves);
    }

    if (targetLeaf.getGuid() === "root") {
      return BasicConfirm.show('This will result in the creation of a new model', () => {
        this.moveNodesWithAlert(targetLeaf, leaves);
      })
    }

    const targetModelId = targetLeaf.getModelId();
    const model = subModels[targetModelId];
    const modelName = model ? `- ${model.name}` : "";

    return BasicConfirm.show(`You are about to move the node(s) to a different model ${modelName}`, () => {
      this.moveNodesWithAlert(targetLeaf, leaves);
    })
  }

  moveNodes = (targetLeaf, treeNodes) => {
    const customErrors = {
      500: "The request to move an element has timed out. This doesn't mean the request has failed, " +
           "just that it's taking longer than normal. If the changes are not present after a successful " + 
           "refresh, please contact Skayl Support."
    }
    return _ajax({
      url: "/index.php?r=/edit/move-node",
      method: "post",
      data: {
        treeNodes: treeNodes.map(node => node.getGuid()),
        new_parent_guid: targetLeaf.getGuid(),
      }
    }, customErrors).then(res => {

      if (res.data?.newModels) {
        const permsConfig = new PermissionsDialogConfig();
        permsConfig.setModelIds(res.data.newModels);
        permsConfig.setAlreadyAdded(true);
        PermissionsDialog.show(permsConfig);
      }

      receiveLogs(res.logs);
      receiveWarnings(res.warnings);
      this.resetTreeData().then(() => {
        const guids = treeNodes.map(node => node.data.guid);
        return window.dispatchEvent(new CustomEvent('MOVED_NODES', { detail: { guids } }));
      })
    }).catch(err => {
      BasicAlert.hide();
    })
  }

  moveNodesWithAlert = (targetLeaf, treeNodes) => {
    BasicAlert.show("Moving nodes", "One moment", false);

    // forces the BasicAlert popup to stay up for a minumum seconds
    //  -> if the request finishes before the timer (min_time), then it will auto close via setTimeout
    const min_time = 0;    // 3 seconds
    const start = Date.now();

    const closeThrottleAlert = () => {
      const end = Date.now();
      const elapsed_time = end - start;
      const diff_time = min_time - elapsed_time;
      
      setTimeout(() => {
        BasicAlert.hide();
      }, Math.max(diff_time, 0));
    }

    this.moveNodes(targetLeaf, treeNodes)
        .then(() => closeThrottleAlert());
  }

  // =====================================================
  // OLD METHODS
  // =====================================================
  fetchData = () => {
    this.fetchTreeData();
  }

  resetContents = () => {
    this.resetTreeData();
  }

  clearSelection = () => {
    this.treeRef.current.clearSelectedLeaves();
  }

  getSelectedGuids = () => {
    const selectedLeaves = this.treeRef.current.selectedLeaves;
    return [...selectedLeaves].map(leaf => leaf.getGuid());
  }

  searchLeafByGuid = (guid) => {
    this.revealAndScrollTo(guid);
  }

  revealAndScrollTo = (guid) => {
    this.treeRef.current.searchLeafByGuid(guid);
  }

  applyFilter = () => {
    this.treeRef.current.applyFilter();
  }

  autoSelectLeaf = (guid) => {
    this.treeRef.current.autoSelectLeaf(guid);
  }

  clearAutoSelectLeaf = (guid) => {
    if (this.treeRef.current) this.treeRef.current.clearAutoSelectLeaf(guid);
  }

  
  render() {
    const { isCollapsed, allTags } = this.state;
    const { match } = this.props;
    
    // do not render for Dashboard
    if (!this.modeWhitelist.includes(match.params.mode)) {
      return null;
    }

    return <section id="phenom-navtree" className={"navtree" + (isCollapsed ? " collapsed" : "")} tabIndex={0} onKeyDown={stopBubbleUp}>
            <div className='navtree-menu' role="tree menu">
              <CreateOptions />
            </div>

            <SmallNavTree id="main-navtree"
                          mode={match.params.mode}
                          main_page={match.params.main_page}
                          page_guid={match.params.guid}
                          sub_page={match.params.sub_page}
                          nodeIndex={this.nodeIndex}
                          diagramIndex={this.diagramIndex}
                          allTags={allTags}
                          history={this.props.history}
                          isDraggable={this.isLeafDraggable()}
                          isCollapsed={isCollapsed}
                          showResizeBar={!isCollapsed}
                          onReset={this.resetTreeData}
                          onToggleCollapse={this.toggleCollapse}
                          onDoubleClick={this.onDoubleClick}
                          onDrop={this.onDrop}
                          onResize={this.handleResize}
                          findAllRelationCandidates={this.findAllRelationCandidates}
                          ref={this.treeRef}
                          mergeSubPages={this.mergeSubPages}
                          showCheckboxesFor={this.showCheckboxesFor}
                          />
    </section>
  }
}






const msp = (state) => {
  return {
    subModels: state.app.subModels,
  }
}


export default connect(msp)(NavTree);
