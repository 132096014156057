import React from "react";
import { clone, cloneDeep } from "lodash"
import {Button, Toolbar, ToolbarItem} from "@progress/kendo-react-buttons";
import {DropdownSelect2, PackageComboBox, PhenomComboBox, PhenomInput, PhenomLabel, PhenomSelect, PhenomToggle} from "./stateless";
import {ConstraintManager} from "../edit/edit-constraint";
import {Modal} from './Modal';
import {createPhenomGuid, deGuidify, isPhenomGuid, SKAYL_GUIDS, validateNodeFields} from "./util";
import ReactTooltip from "react-tooltip";
import styled from "@emotion/styled";
import PhenomId from "../../requests/phenom-id";
import { withNestedLayout } from "../edit/node-layout";
import { ConstraintManager2 } from "../edit/edit-constraint-manager";
import ModalNodeBuilder from "../dialog/ModalNodeBuilder";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { BasicAlert } from "../dialog/BasicAlert";
import { PhenomButtonLink } from "../widget/PhenomLink";



const Collapsible = styled.div`
    height: ${props => props.collapse ? "0" : props.height + "px"};
    overflow: hidden;
    transition: height 200ms ease-out;
`

const Chevron = styled.span`
    display: inline-block;
    font-family: "WebComponentsIcons";
    font-weight: 900;
    margin-right: 5px;
    transform: ${({collapse}) => collapse ? null : "rotate(90deg)"};
    transition: transform 200ms ease-out;

    &:before {
        content: "\\e014";
    }
`

const Container = styled.div`
    display: grid;
    gap: 10px;
    grid-template-columns: ${({look}) => look !== "component" ? "repeat(3, auto)" : null};
`

export class VtuPicker2 extends React.Component {
    collapseRef = React.createRef();
    state = {
        collapse: true,
        valueType: {},
        unit: {},
        valueTypeUnits: {},
        constraint: {},
        show_modal: false,
    }

    componentDidMount() {
        this.setup()
            .then(() => window["cachedRequest"]("newVTUS"))
            .then(this.setValueTypeUnits)
            .then(this.setPossibleConstraints);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.valueTypeUnit !== this.props.valueTypeUnit) {
            this.setup()
                .then(() => window["cachedRequest"]("newVTUS"))
                .then(this.setValueTypeUnits)
                .then(this.setPossibleConstraints);
        }

        if (prevProps.newConstraints !== this.props.newConstraints) {
            this.setPossibleConstraints();
        }
    }

    componentWillUnmount() {
        if(this.props.saveState) {
            this.props.saveState(this.state);
        }
    }

    setup = async () => {
        const vtu = this.props.valueTypeUnit || {};
        let valueType = vtu.valueType ? vtu.valueType : {};
        let constraint = vtu.constraint ? vtu.constraint :
                         vtu.children && vtu.children.length ? vtu.children[0] : {};

//        let isLiteralEnum = valueType.xmiType === "logical:Enumerated";

        return await this.setState({
            ...vtu,
            valueType,
            constraint,
//            isLiteralEnum
        });
    }

    setValueTypeUnits = async (res) => {
        const data = cloneDeep(res);
        const units = data.units;
        const valueTypes = data.value_types;
        const valueTypeUnits = data.value_type_units;

        const vt = this.state.valueType || {};
        for (let key in valueTypes) {
            if (key !== vt.guid &&
                ((this.props.isLiteralEnum && valueTypes[key].xmiType !== "logical:Enumerated") ||
                (!this.props.isLiteralEnum && valueTypes[key].xmiType === "logical:Enumerated"))) {
                delete valueTypes[key];
            }
        }

        return await this.setState({units, valueTypes, valueTypeUnits});
    }

    setPossibleConstraints = async () => {
        let possible_constraints = [];
        if (this.state.valueType.guid && this.state.unit.guid) {
            possible_constraints = Object.values(this.state.valueTypeUnits).filter(vtu => {
                                          return this.state.valueType.guid === vtu.valueType.guid &&
                                                 this.state.unit.guid === vtu.unit.guid &&
                                                 vtu.children.length > 0;
                                    }).map(vtu => vtu.children[0]);
        }

        if(this.props.newConstraints) {
            possible_constraints = possible_constraints.concat(this.props.newConstraints);
        }
        return await this.setState({ constraints: deGuidify(possible_constraints) });
    }

    toggleCollapse = () => {
        this.setState({collapse: !this.state.collapse});
    }


    handleVtuSelection = async (field, value) => {
        switch (field) {
            case "valueType":
                await this.setState({valueType: this.state.valueTypes[value] || {}});
                break;
            case "unit":
                await this.setState({unit: this.state.units[value] || {}});
                break;

            default:
        }

        return await this.setPossibleConstraints();
    }

    handleConstraintSelection = async (guid) => {
        if (guid === "") {
            this.setState({ constraint: {} });
        } else {
            this.setState({ constraint: this.state.constraints[guid] || {} });
        }
    }

    closeNewConstraintModal = async (constraint) => {
        if (constraint) {
            constraint.guid = this.props.dummyConstraintGuid;
            this.setState({ constraint, show_modal: false });
            if (this.props.createNewConstraint) this.props.createNewConstraint(constraint);
        } else {
            this.setState({ show_modal: false });
        }
    }

    generateNode = () => {
        let errors = new Set();
        let data = undefined;
        let {unit, valueType} = this.state;

        if (!unit.guid && valueType.guid) {
            errors.add("Bad combination of Unit and Value Type");
            this.setState({unitError: true});
        }

        if (!this.props.isLiteralEnum && unit.guid && !valueType.guid) {
            errors.add("Bad combination of Unit and Value Type");
            this.setState({valueTypeError: true});
        }

        if(!unit.guid && !valueType.guid) {
            return {
                data: null,
            }
        }

        if (errors.size > 0) return {errors: [...errors]};

        const search_vt_guid = valueType.guid;
        const search_u_guid = unit.guid;
        const search_cons_guid = this.state.constraint.guid || null;
        const existingVtu = Object.values(this.state.valueTypeUnits).find(vtu => {
            const match = search_vt_guid === vtu.valueType.guid && search_u_guid === vtu.unit.guid;
            const vtuConstraintGuid = Array.isArray(vtu.children) && vtu.children[0] ? vtu.children[0].guid : null;

            return match && search_cons_guid === vtuConstraintGuid;
        })


        // If existing VTU exist then return it bc backend needs the guid, or else it might attempt to create a new vtu
        if(existingVtu) {
            data = cloneDeep(existingVtu);
            data.valueType = data.valueType.guid;
            data.unit = data.unit.guid;

        } else {
            data = {
                guid: createPhenomGuid(),
                xmiType: "logical:ValueTypeUnit",
                name: this.createVtuName(),
                description: "",
                valueType: valueType.guid,
                unit: unit.guid,
                parent: null,
            }

            if (this.state.constraint.guid) {
                let constraint = clone(this.state.constraint);
                constraint.guid = typeof constraint.guid === "string" ? constraint.guid : null;
                data.children = [constraint];
            }
        }

        return { data };
    }


    createVtuName = () => {
        if(this.props.isLiteralEnum) {
            return this.props.getMeasurementName() + "_VTU";
        } else {
            let valueTypeName = this.state.valueType.name || "";
            let unitName = this.state.unit.name || "";
            let constraintName = this.state.constraint.name || "";
            return valueTypeName + unitName + constraintName;
        }
    }
    
    render() {
        const phenomId = new PhenomId("vtu-picker",this.props.idCtx);
        const labelStyle = {margin:0, lineHeight:"normal", fontSize:"80%"};

        let title = "ValueType Unit";
        if (this.props.isLiteralEnum) {
            title = this.state.name;
        } else if (this.state.valueType.guid && this.state.unit.guid) {
            title = [this.state.valueType.name, this.state.unit.name, this.state.constraint.name].join(" ");
        }

        let height = 0;
        if (this.collapseRef.current) height = this.collapseRef.current.scrollHeight;

        return(
            <div style={{position:"relative"}} id={phenomId.gen("","wrapper")}>
                <div id={phenomId.gen("","toggle")} onClick={this.toggleCollapse} style={{display:"inline-block", fontSize:14, cursor:"pointer", marginBottom:10}}>
                    <Chevron collapse={this.state.collapse} id={phenomId.gen("","chevron")}/>
                    <span id={phenomId.gen("","title")}>{title}</span>
                </div>
                {this.props.editable && !this.props.isLiteralEnum && this.props.removeNode &&
                    <Button iconClass="fa fa-times-circle fa-fw"
                            style={{position:"absolute", right:0}}
                            look="bare"
                            onClick={() => this.props.removeNode(this.state.guid)}
                            data-tip
                            data-for="removeValueTypeUnit"
                            id={phenomId.gen("","remove-button")} />
                }

                <Collapsible ref={this.collapseRef} collapse={this.state.collapse} height={height} id={phenomId.gen("","collapsible")}>

                    <Container look={this.props.look}>
                        <div>
                            <label style={labelStyle} id={phenomId.gen("","type")}>Value Type</label>
                            <DropdownSelect2 data={this.state.valueTypes}
                                            noValueName={this.props.isLiteralEnum ? "Create New ValueType" : false}
                                            selectedGuid={this.state.valueType.guid}
                                            onChange={(e) => this.handleVtuSelection("valueType", e.target.value)}
                                            editable={this.props.editable}
                                            idCtx={phenomId.gen("","type")} >
                                            { this.state.valueType?.guid && 
                                            <div style={{alignSelf: 'center'}}>
                                            <PhenomButtonLink node={ this.state.valueType } /> 
                                            </div> }
                                            </DropdownSelect2>
                        </div>
                        <div>
                            <label style={labelStyle} id={phenomId.gen("","units")}>Unit</label>
                            <DropdownSelect2 data={this.state.units}
                                            selectedGuid={this.state.unit.guid}
                                            onChange={(e) => this.handleVtuSelection("unit", e.target.value)}
                                            editable={this.props.editable && !this.props.isLiteralEnum}
                                            idCtx={phenomId.gen("","units")} />
                        </div>
                        <div>
                            <label style={labelStyle} id={phenomId.gen("","constraint")}>Constraint</label>
                            <DropdownSelect2 data={this.state.constraints}
                                            selectedGuid={this.state.constraint.guid}
                                            onChange={(e) => this.handleConstraintSelection(e.target.value)}
                                            editable={this.props.editable && !this.props.isLiteralEnum}
                                            idCtx={phenomId.gen("","constraint")} />
                        </div>
                    </Container>

                    {this.state.valueType?.guid && 
                      <div style={{display: "flex", flexDirection: 'column'}}>
                        <label style={labelStyle} id={phenomId.gen("","type")}>GUID</label>
                        {this.state.valueType.guid}
                      </div>}

                    {!this.props.isLiteralEnum &&
                        <div style={{margin:"10px 5px"}}>
                            <button className="cadet-anchor"
                                    disabled={!this.props.editable}
                                    onClick={() => this.setState({ show_modal: true })}>
                                <i className="fa fa-plus-circle" style={{marginRight:"5px"}}/>
                                Add Constraint
                            </button>
                        </div>
                    }
                </Collapsible>

                <ReactTooltip id='removeValueTypeUnit' type='error' place='left'>
                    <span>Remove Value Type Unit</span>
                </ReactTooltip>

                {this.state.show_modal &&
                    <Modal closeModal={this.closeNewConstraintModal}
                        componentToShow={<ConstraintManager match={{params: {guid: "new", constraintType: "logical"}}}
                                                         closeModal={this.closeNewConstraintModal}
                                                         primitive={null} />}/>}
            </div>
        )
    }
}



export class VtuManager extends React.Component {
  phenomId = new PhenomId("vtu-manager", this.props.id);

  // ------------------------------------
  // State
  // ------------------------------------
  vtuRefs = {}
  state = {
    notSupported: false,
    isEnumerated: false,

    activeVtus: [],
    valueTypeUnitList: [],
    enumValueTypeUnitList: [],

    enumLabels: [],

    autoExpandGuid: null,     // when the child component changes the vtus, it will collapse.  this is to prevent it.
  }

  // ------------------------------------
  // Life Cycle Methods
  // ------------------------------------
  componentDidMount() {
    this.initLists();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.vtus !== this.props.vtus ||
        prevProps.parentEnumerated !== this.props.parentEnumerated) {
          this.initLists();
    }

    // 1) assume VTU is not enumerated.
    // 2) update parent component if VTU is enumerated
    // note: after the parent updates, the above props/condition will trigger again
    if (prevState.isEnumerated !== this.state.isEnumerated) {
        this.props.setEnumerated && this.props.setEnumerated(this.state.isEnumerated);
    }
  }

  // ------------------------------------
  // Initial Setup
  // ------------------------------------
  initLists = () => {
    const { vtus=[], parentEnumerated, parentMeasurementSystemAxis = SKAYL_GUIDS["measurementSystemAxis"] } = this.props;

    window["cachedRequest"]("newVTUS").then(data => {
      const activeVtus = [];
      const valueTypeUnitList = [];
      const enumValueTypeUnitList = [];

      // populate main lists
      for (let vtuGuid in data["value_type_units"]) {
        const vtu = data["value_type_units"][vtuGuid];
        const vt = vtu.valueType;

        if (vtus.find(el => el.guid === vtuGuid)) {
          activeVtus.push(vtu);
          continue;
        }

        if (vt.xmiType === "logical:Enumerated") {
          enumValueTypeUnitList.push(vtu);
        } else {
          valueTypeUnitList.push(vtu);
        }
      }

      // used for Enumerated Measurement Axis
      const isEnumerated = parentEnumerated || activeVtus.some(vtu => vtu.valueType.xmiType === "logical:Enumerated");
      const unitNotSupported = activeVtus.some(vtu => vtu.unit.guid !== SKAYL_GUIDS["unit"]);
      const msaNotSupported = parentMeasurementSystemAxis !== SKAYL_GUIDS["measurementSystemAxis"];

      if (isEnumerated && !activeVtus.length) {
        activeVtus.push(this.createBlankVTU());
      }
      
      this.setState({ 
        isEnumerated, 
        notSupported: isEnumerated && (unitNotSupported || msaNotSupported),
        activeVtus, 
        valueTypeUnitList, 
        enumValueTypeUnitList,
      });
    })
  }

  // ------------------------------------
  // Getters
  // ------------------------------------
  createBlankVTU = () => {
    return {
      guid: createPhenomGuid(),
      name: "",
      xmiType: "logical:ValueTypeUnit",
      description: "",
      unit: {},
      valueType: {},
      children: [],
      parent: "",
    }
  }

  // ------------------------------------
  // Setters
  // ------------------------------------
  addNewVtu = () => {
    const activeVtus = [...this.state.activeVtus];
    activeVtus.push(this.createBlankVTU())

    this.setState({ activeVtus });
  }

  removeVtu = (removeIdx) => {
    if (typeof removeIdx !== 'number') return;

    const activeVtus = [...this.state.activeVtus];
    removeIdx > -1 && activeVtus.splice(removeIdx, 1);
    this.setState({ activeVtus });
  }

  handleChangeVtu = (newVtu, idx) => {
    // invalid idx
    if (!newVtu || typeof idx !== 'number') {
      return;
    }

    const activeVtus = [...this.state.activeVtus];
    const prevVtu = activeVtus[idx];
          activeVtus[idx] = newVtu;

    if (this.state.isEnumerated) {
      const enumValueTypeUnitList = [...this.state.enumValueTypeUnitList];
      if (!isPhenomGuid(prevVtu.guid)) {
        enumValueTypeUnitList.push(prevVtu);
      }

      const removeIdx = enumValueTypeUnitList.findIndex(vtu => vtu.guid === newVtu.guid);
            removeIdx > -1 && enumValueTypeUnitList.splice(removeIdx, 1);

      this.setState({ activeVtus, enumValueTypeUnitList, autoExpandGuid: newVtu.guid });

    } else {
      const valueTypeUnitList = [...this.state.valueTypeUnitList];
      if (!isPhenomGuid(prevVtu.guid)) {
        valueTypeUnitList.push(prevVtu);
      }

      const removeIdx = valueTypeUnitList.findIndex(vtu => vtu.guid === newVtu.guid);
            removeIdx > -1 && valueTypeUnitList.splice(removeIdx, 1);
      
      this.setState({ activeVtus, valueTypeUnitList, autoExpandGuid: newVtu.guid });
    }
  }

  // ------------------------------------
  // Called by parent component
  // ------------------------------------
  handleReset = () => {
    this.initLists();
  }

  generateNode = () => {
    return this.state.activeVtus.map((vtu) => {
      const vtuRef = this.vtuRefs[vtu.guid];
      return vtuRef.generateNode();
    })
  }

  validateNode = () => {
    let valid = true;

    this.state.activeVtus.forEach(vtu => {
      const vtuRef = this.vtuRefs[vtu.guid];
      if (vtuRef && !vtuRef.validateNode()) { 
        valid = false;
      }
    })

    return valid;
  }
 
  // ------------------------------------
  // Render methods
  // ------------------------------------
  render() {
    const { label, editable, parentName, parentPackage, parentNewPage } = this.props;
    const { isEnumerated, notSupported, activeVtus, valueTypeUnitList, enumValueTypeUnitList, autoExpandGuid } = this.state;

    return <div>
      <PhenomLabel text={label} />

      {!isEnumerated &&
      <div style={{ marginBottom: 10 }}>
        <Button icon="add"
                disabled={!editable}
                onClick={this.addNewVtu}>
                  Add Value Type Unit
        </Button>
      </div> }

      <div className="p-col">
        {activeVtus.map((vtu, idx) => {
          let onClickCancelIcon;

          if (!isEnumerated) {
            onClickCancelIcon = () => this.removeVtu(idx);
          }

          return <NestedVtuPicker key={vtu.guid}
                                  node={vtu}
                                  editable={editable}
                                  id={this.phenomId.genPageId(idx)}
                                  notSupported={notSupported}
                                  parentNewPage={parentNewPage}
                                  parentName={parentName}
                                  parentEnumerated={isEnumerated}
                                  parentPackage={parentPackage}
                                  valueTypeUnitList={valueTypeUnitList}
                                  enumValueTypeUnitList={enumValueTypeUnitList}
                                  autoExpand={autoExpandGuid === vtu.guid}
                                  handleChangeVtu={(node) => this.handleChangeVtu(node, idx)}
                                  onClickCancelIcon={onClickCancelIcon}
                                  ref={el => this.vtuRefs[vtu.guid] = el} />
        })}
      </div>
    </div>
  }
}


class VtuPicker3 extends React.Component {
  // ------------------------------------
  // State
  // ------------------------------------
  allLabels = {}
  state = {
    selectedVTU: {},

    createNewVtu: false,
    createNewVt: false,
    unitList: [],
    valueTypeList: [],
    constraintList: [],

    enumLabels: [],
    availableLabels: [],
    sortEnumLabelsConfig: [],
    draggingGuid: null,
  }

  // "required" checks for empty field as the input changes (user is typing)
  // "errorRef" checks for empty field after pressing the save button
  requiredStateFields = {
    selectedVTU: {
      required: true,
      errorRef: React.createRef(),
    },
  }
  requiredVtuFields = {
    parent: {
      required: true,
      errorRef: React.createRef(),
    },
    valueType: {
      required: !this.props.parentEnumerated,
      errorRef: React.createRef(),
    },
    unit: {
      required: true,
      errorRef: React.createRef(),
    },
  }

  constructor(props) {
    super(props);

    this.verifyEnumNames = this.debounceVerifyEnumNames(1000);
    this.phenomId = new PhenomId("vtu-picker", this.props.id);
  }

  // ------------------------------------
  // Life Cycle Methods
  // ------------------------------------
  componentDidMount() {
    this.initVtuState();
  }

  componentDidUpdate(prevProps, prevState) {
    const { parentPackage, parentName, parentEnumerated} = this.props;
    const { selectedVTU, enumLabels, createNewVtu } = this.state;

    if (isPhenomGuid(selectedVTU.guid) && createNewVtu && parentPackage !== prevProps.parentPackage) {
      this.setState((prevState) => ({
        selectedVTU: {
          ...prevState.selectedVTU,
          parent: parentPackage,
        }
      }))
    }

    if (selectedVTU.valueType !== prevState.selectedVTU.valueType) {
      this.initEnumList();
      this.initConstraintList();

    } else if (selectedVTU.children !== prevState.selectedVTU.children) {
      this.initEnumList();
    }

    // if a label was added but a enum valueType doesn't exist yet, then create a new one
    if (enumLabels.length !== prevState.enumLabels.length && !selectedVTU.valueType?.guid) {
      this.setState((prevState) => ({
        selectedVTU: {
          ...prevState.selectedVTU,
          valueType: this.createNewEnumValueType(),
        }
      }))
    }

    // regenerate enum vtu name if it hasn't been altered
    if (parentEnumerated && createNewVtu && ((prevState.selectedVTU?.children[0]?.name !== selectedVTU?.children[0]?.name) || 
                                             (prevState.selectedVTU?.valueType?.name !== selectedVTU?.valueType?.name))) {
                            
        if (this.state.selectedVTU.name === this.createValueTypeUnitName(prevState.selectedVTU)) {
        this.setState({
          selectedVTU: {
            ...selectedVTU,
            name: this.createValueTypeUnitName(),
          }
        });
      }
    }
  }

  // ------------------------------------
  // Initial Setup
  // ------------------------------------
  initVtuState = () => {
    const { node, parentPackage } = this.props;
    const { createNewVtu } = this.state;
    const clone = cloneDeep(node);

    if (createNewVtu && isPhenomGuid(clone.guid)) {
      clone.parent = parentPackage;
    }

    this.setState({ selectedVTU: clone }, () => {
      this.initVtuList();
    });
  }

  initVtState = () => {
    const { selectedVTU } = this.state;

    selectedVTU.valueType = this.createNewEnumValueType();
    selectedVTU.children[0] = this.createNewEnumConstraint();

    this.setState({ selectedVTU: selectedVTU })
  }

  initVtuList = () => {
    const { parentEnumerated } = this.props;

    // set lists
    window["cachedRequest"]("newVTUS").then(data => {
      let unitList = Object.values(data.units);
      let valueTypeList = Object.values(data.value_types);

      if (parentEnumerated) {

        // filter out normal value types
        valueTypeList = valueTypeList.filter(valueType => valueType.xmiType === "logical:Enumerated")

        unitList = unitList.filter(unit => unit.guid === SKAYL_GUIDS["unit"]);

      } else {
        // filter out enumerated value types
        valueTypeList = valueTypeList.filter(valueType => valueType.xmiType !== "logical:Enumerated")
      }

      this.setState({ 
        unitList, 
        valueTypeList: valueTypeList.sort((n1, n2) => n1.name.localeCompare(n2.name)),
      })
    })
  }

  initEnumList = () => {
    const { parentEnumerated, notSupported } = this.props;
    const { selectedVTU, enumLabels } = this.state;
    if (!parentEnumerated || notSupported) {
      return;
    }

    const vt = selectedVTU.valueType;
    const constraint = selectedVTU.children[0];
    
    // separate the labels into two lists
    let newEnumLabels = constraint?.allowedValue || [];
    let enumLabelGuids = new Set(newEnumLabels.map(label => label.guid));
    let labels = vt?.children || [];

    const blankLabel = this.createNewEnumLabel();
          blankLabel.guid = "";
          blankLabel.name = "--select one--";

    const availableLabels = labels.filter(label => !enumLabelGuids.has(label.guid));
          availableLabels.unshift(blankLabel);

    // edge case - if a label was added but a enum valueType doesn't exist yet, then a new VT was created
    if (isPhenomGuid(vt.guid)) {
      const previouslyAddedPhenomLabels = enumLabels.filter(l => isPhenomGuid(l.guid));
      newEnumLabels = newEnumLabels.concat(previouslyAddedPhenomLabels)
      labels = labels.concat(previouslyAddedPhenomLabels);
    }

    this.allLabels = cloneDeep(deGuidify(labels));
    this.setState({
      enumLabels: newEnumLabels,
      availableLabels,
    })
  }

  initConstraintList = () => {
    window["cachedRequest"]("newVTUS").then(data => {
      const { parentEnumerated } = this.props;
      const { selectedVTU } = this.state;
      const valueTypeGuid = selectedVTU.valueType?.guid;
      const unitGuid = selectedVTU.unit?.guid;

      const vtus = Object.values(data["value_type_units"]).filter(vtu => vtu.valueType.guid === valueTypeGuid && vtu.unit.guid === unitGuid);
      const constraintList = vtus.reduce((constraints, currVtu) => constraints.concat(currVtu.children), []);

      this.setState({ constraintList })
    })
  }

  // ------------------------------------
  // Getters
  // ------------------------------------
  createValueTypeUnitName = (VTU) => {
    const selectedVTU = VTU || this.state.selectedVTU;
    const { valueType, unit, children } = selectedVTU;
    const constraint = children?.[0];

    const vt_name = valueType?.name || this.createValueTypeName();
    const unit_name = unit?.name || "unitless";
    const constraint_name = constraint?.name || "";
    let vtu_name = `${vt_name}_${unit_name}_`;
    vtu_name += `${constraint_name}`;

    return vtu_name;
  }

  createValueTypeName = () => {
    return `General_${this.props.parentName || ""}_States_VT`
  }

  createConstraintName = (valueType) => {
    if (!valueType) valueType = this.state.selectedVTU?.valueType;
    if (valueType?.name) {
      return `Constrained_${valueType?.name}`;
    } else {
      return "Constrained_";
    }
  }

  createNewEnumValueType = () => {
    return {
      guid: createPhenomGuid(),
      name: "",
      description: "",
      xmiType: "logical:Enumerated",
      children: [],
    }
  }

  createNewEnumConstraint = () => {
    return {
      guid: createPhenomGuid(),
      name: "",
      description: "",
      xmiType: "logical:EnumerationConstraint",
      allowedValue: [],
    }
  }

  createNewEnumLabel = () => {
    return {
      guid: createPhenomGuid(),
      name: "",
      description: "",
      xmiType: "logical:EnumerationLabel",
    }
  }

  isLabelEdited = (label) => {
    const original = this.allLabels[label?.guid];
    if (!label?.guid || !original?.guid) return;

    // new label
    if (isPhenomGuid(label.guid)) return true;

    // compare attributes with original
    return ["name", "description"].some(attr => label[attr] !== original[attr]);
  }

  createBlankVTU = () => {
    return {
      guid: createPhenomGuid(),
      name: "",
      xmiType: "logical:ValueTypeUnit",
      description: "",
      unit: {},
      valueType: {},
      children: [],
      parent: "",
    }
  }

  // ------------------------------------
  // Setters
  // ------------------------------------
  updateVTU = (key, value) => {
    this.setState((prevState) => ({
      selectedVTU: {
        ...prevState.selectedVTU,
        [key]: value,
      }
    }))
  }

  updateVT = (key, value) => {
    const { selectedVTU } = this.state;
    const valueType = selectedVTU.valueType;

    const newValueType = {
      ...valueType,
      [key]: value,
    }

    this.setState({ selectedVTU: {
      ...selectedVTU,
      valueType: newValueType
    }});
  }

  updateConstraint = (key, value) => {
    const { selectedVTU } = this.state;
    const constraint = selectedVTU?.children[0];
    
    const updatedConstraint = {
      ...constraint,
      [key]: value,
    }
    const newChildren = [updatedConstraint];

    this.setState({ selectedVTU: {
      ...selectedVTU,
      children: newChildren
    } });
  }

  handleValueTypeUnitChange = (newVTU) => {
    const { handleChangeVtu } = this.props;

    // the "new vtu" was populated by VTUManager (parent component)
    const createNewVtu = !!isPhenomGuid(newVTU.guid);

    // Change to existing VTU
    if (!createNewVtu) {
      return handleChangeVtu && handleChangeVtu(newVTU);
    }

    // Create new VTU
    this.setState({ createNewVtu }, () => {
      this.setNewVTU(newVTU);
    })
  }

  handleValueTypeChange = (newVT) => {
    const { parentEnumerated } = this.props;
    const { createNewVtu } = this.state;
    const selectedVTU = { ...this.state.selectedVTU }
    selectedVTU.children = [];

    if (newVT?.guid) {
      // Use existing ValueType
      selectedVTU.valueType = newVT;

    } else {
      // Create new Enumerated ValueType
      selectedVTU.valueType = this.createNewEnumValueType();
    }

    if (parentEnumerated) {
      selectedVTU.children.push(this.createNewEnumConstraint());

      // need to regenerate enum vtu name on vt selection <- extra case
      if (this.createValueTypeUnitName() === this.state.selectedVTU.name) {
        selectedVTU.name = this.createValueTypeUnitName(selectedVTU);
      }
    }

    this.setState({ selectedVTU }, () => {
      if (parentEnumerated && createNewVtu) {
        this.updateConstraint("name", this.createConstraintName());
      }
    });
  }

  handleConstraintChange = (con) => {
    const { parentEnumerated } = this.props;
    const { createNewVtu } = this.state;
    const selectedVTU = { ...this.state.selectedVTU }
    selectedVTU.children = [];

    // clicked the 'remove' button
    if (!con) {
      return this.setState({ selectedVTU });
    }

    const isPlaceholderConstraint = !con.guid;

    // creating a new enumerated VTU and selected a constraint from the dropdown
    if (createNewVtu && parentEnumerated) {
      const newConstraint = isPlaceholderConstraint ? this.createNewEnumConstraint() : cloneDeep(con);
            newConstraint.guid = createPhenomGuid();
      if (isPlaceholderConstraint) newConstraint.name = "";

      selectedVTU.children.push(newConstraint);
      return this.setState({ selectedVTU });
    }

    // selected a non-enumerated constraint
    // selected a constraint from the dropdown and not creating a new enumerated VTU
    !isPlaceholderConstraint && selectedVTU.children.push(con);
    return this.setState({ selectedVTU });
  }

  removeConstraint = () => {
    const selectedVTU = { ...this.state.selectedVTU }
    selectedVTU.children = [];
    this.setState({ selectedVTU });
  }

  setNewVTU = (newVTU) => {
    const { parentEnumerated, parentPackage } = this.props;
    const { unitList, createNewVtu, createNewVt } = this.state;

    // Create new VTU
    const selectedVTU = newVTU ? cloneDeep(newVTU) : this.createBlankVTU();
          selectedVTU.guid = createPhenomGuid();
          selectedVTU.name = parentEnumerated && createNewVtu ? this.createValueTypeUnitName() : "";
          selectedVTU.children = [];

    // Fill out parent field if creating new VTU
    if (createNewVtu) {
      selectedVTU.parent = parentPackage;
    }

    // Generate correct data for fields depending on toggles
    if (parentEnumerated) {
      selectedVTU.children.push( this.createNewEnumConstraint() );
      selectedVTU.unit = unitList.find(unit => unit.guid === SKAYL_GUIDS["unit"]) || unitList[0] || {}
      if (createNewVtu) {
        if (createNewVt) {
          const newVt = this.createNewEnumValueType();
          newVt.name = this.createValueTypeName();
          selectedVTU.valueType = newVt;
        }
        selectedVTU.children[0].name = this.createConstraintName(selectedVTU.valueType);
      }
    }

    this.setState({ selectedVTU });
  }

  setNewVT = () => {
    const { parentEnumerated } = this.props;
    const { selectedVTU, createNewVt } = this.state;
    const newVt = this.createNewEnumValueType();
    selectedVTU.valueType = newVt;

    // Generate correct data for fields depending on toggles
    if (parentEnumerated) {
      if (createNewVt) {
        selectedVTU.valueType.name = this.createValueTypeName();
        selectedVTU.name = this.createValueTypeUnitName(selectedVTU);
      }
    }

    this.setState({ selectedVTU }, () => {
      if (parentEnumerated) {
        this.updateConstraint("name", this.createConstraintName());
      }
    });
  }

  toggleCreateVtu = (e) => {
    // change to "Existing VTU"
    if (!e.target.checked) {
      return this.setState({ createNewVtu: false }, () => {
        this.setNewVTU();
      });
    }

    return this.setState({ createNewVtu: true }, () => {
        const {updateLayoutNode} = this.props;
        const vtu = this.createBlankVTU();
        updateLayoutNode(vtu)
        this.setNewVTU();
      })
  }

  toggleCreateVt = (e) => {
    // change to "Existing VT"
    if (!e.target.checked) {
      return this.setState({ createNewVt: false }, () => {
        this.setNewVT();
      });
    }

    this.setState({ createNewVt: true }, () => {
      this.setNewVT();
    });
  }
  
  addNewEnumLabel = () => {
    const enumLabels = [...this.state.enumLabels];
    const newLabel = this.createNewEnumLabel();

    this.allLabels[newLabel.guid] = newLabel;
    enumLabels.push(newLabel);

    this.setState({ enumLabels });
  }

  /**
   * Finds the label in availableLabels and move it into enumLabels
   * 
   */
  addExistingEnumLabel = (e) => {
    const availableLabels = [...this.state.availableLabels];
    const enumLabels = [...this.state.enumLabels];

    const existingLabel = this.allLabels[e.target.value];
    const labelIdx = availableLabels.findIndex(l => l.guid === e.target.value);

    if (labelIdx > -1 && existingLabel) {
      enumLabels.push( cloneDeep(existingLabel) );
      availableLabels.splice(labelIdx, 1);
    }

    this.setState({
      availableLabels,
      enumLabels,
    })
  }

  removeEnumLabel = (label) => {
    if (!label?.guid) return;

    const existingLabel = this.allLabels[label.guid];
    const availableLabels = [...this.state.availableLabels];
    const enumLabels = [...this.state.enumLabels];

    const removeIdx = enumLabels.findIndex(l => l.guid === label.guid);
    if (removeIdx > -1 && existingLabel) {
      if (!isPhenomGuid(existingLabel.guid)) availableLabels.push( cloneDeep(existingLabel) );
      enumLabels.splice(removeIdx, 1);
    }

    this.setState({ availableLabels, enumLabels });
  }

  addNewConstraint = () => {
    ModalNodeBuilder.show({
      component: ConstraintManager2,
      isLocalSaveOnly: true,
      additionalProps: {
        isLogical: true
      },
      onSaveCallback: (newConstraint) => {
        this.setState((prevState) => {
          return {
            selectedVTU: {
              ...prevState.selectedVTU,
              children: [ newConstraint ],
            }
          }
        })
      },
    })
  }

  editConstraint = () => {
    const { selectedVTU } = this.state;
    if (!selectedVTU.children.length) return;

    const constraintNode = selectedVTU.children[0];

    ModalNodeBuilder.show({
      component: ConstraintManager2,
      node: constraintNode,
      isLocalSaveOnly: true,
      additionalProps: {
        isLogical: true
      },
      onSaveCallback: (newConstraint) => {
        this.setState((prevState) => {
          return {
            selectedVTU: {
              ...prevState.selectedVTU,
              children: [ newConstraint ],
            }
          }
        })
      },
    })
  }

  reorder = (label) => {
    if (this.state.draggingGuid === label.guid) return;
    let prevIndex = this.state.enumLabels.findIndex(ele => ele.guid === this.state.draggingGuid);
    let nextIndex = this.state.enumLabels.findIndex(ele => ele.guid === label.guid);

    if (prevIndex < 0 || nextIndex < 0) return;

    const splicedLabeled = this.state.enumLabels[prevIndex];
    this.state.enumLabels.splice(prevIndex, 1);
    this.state.enumLabels.splice(nextIndex, 0, splicedLabeled);
    this.setState({ draggingGuid: this.state.draggingGuid })
  }

  debounceVerifyEnumNames = (interval) => {
    let timeout;

    return (label) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => this.checkEnumNames(label), interval);
    }
  }

  checkEnumNames = (label) => {
    if (!label?.guid) return;
    const matchFound = this.state.availableLabels.find(l => l.name === label.name);

    if (matchFound) {
      BasicAlert.show(`The name, ${label.name}, already exist.\nPlease change the name or consider choosing it from the drop down list`, "Name Collision Detected")
      label.customErrorMsg = "Name already exist";
      this.forceUpdate();
    }
  }


  // ------------------------------------
  // Called by parent component
  // ------------------------------------
  handleResetVTU = () => {
    this.initVtuState();
  }

  validateNode = () => {
    const validState = validateNodeFields(this.requiredStateFields, this.state);
    const validVtu = validateNodeFields(this.requiredVtuFields, this.state.selectedVTU);
    return validState && validVtu;
  }

  generateNode = () => {
    const { parentEnumerated } = this.props;
    const { enumLabels } = this.state;
    const editedLabels = enumLabels.filter(label => this.isLabelEdited(label));
    const vtuData = cloneDeep(this.state.selectedVTU);
    const { valueType, unit, children } = vtuData;
    const constraint = children?.[0];

    // flatten Unit to a guid to prevent issues with reserved names
    vtuData.unit = unit?.guid;

    if (parentEnumerated) {
      // update enum labels
      valueType.children = editedLabels;

      // update new value type
      if (isPhenomGuid(valueType?.guid)) {
        valueType.parent = vtuData.parent;
        valueType.deconflictName = true;
        if (!valueType.name) valueType.name = this.createValueTypeName();
      }
    } else {
      // flatten VT to a guid to prevent issues with reserved names
      vtuData.valueType = valueType?.guid;
    }


    // update new constraint name
    if (constraint && isPhenomGuid(constraint.guid)) {
      constraint.deconflictName = true;
      if (!constraint.name || parentEnumerated) {
        constraint.name = this.createConstraintName();
      }

      if (parentEnumerated) {
        constraint.allowedValue = enumLabels.map(label => label.guid);
      }
    }

    // VTU name is blank - auto generate for user
    // naming convention: <VT_name>_<Unit_name>_<Constraint_name>
    if (isPhenomGuid(vtuData.guid)) {
      if (!vtuData.name) {
        vtuData.name = this.createValueTypeUnitName();
        vtuData.deconflictName = true;
      }
    }

    return vtuData;
  }


  // ------------------------------------
  // Render Methods
  // ------------------------------------
  renderVtuCell = () => {
    const { parentEnumerated, parentNewPage, valueTypeUnitList=[], enumValueTypeUnitList=[], editable } = this.props;
    const { selectedVTU, createNewVtu } = this.state;

    // disable for existing enumerated vtu
    const isExistingEnumeratedParent = parentEnumerated && !parentNewPage;
    const isDisabled = !editable || isExistingEnumeratedParent;

    return <div className="p-col p-col-5">
      {createNewVtu 
        ? <PhenomInput label="New Value Type Unit"
                        value={selectedVTU.name}
                        id={this.phenomId.genPageId("valuetypeunit-selection")}
                        disabled={isDisabled}
                        onChange={(e) => this.updateVTU("name", e.target.value)} 
                        config={this.requiredStateFields["selectedVTU"]} />
        : <PhenomComboBox label="Value Type Unit"
                          value={selectedVTU}
                          id={this.phenomId.genPageId("valuetypeunit-name")}
                          placeholder="Select a Value Type Unit"
                          data={parentEnumerated ? enumValueTypeUnitList : valueTypeUnitList}
                          dataItemKey="guid"
                          textField="name"
                          disabled={isDisabled}
                          onChange={(vtu) => this.handleValueTypeUnitChange(vtu)}
                          config={this.requiredStateFields["selectedVTU"]} />
      }
    </div>
  }

  renderVtuToggleCell = () => {
    const { editable, parentEnumerated, parentNewPage } = this.props;
    const { createNewVtu } = this.state;

    // disable for existing enumerated vtu
    const isExistingEnumeratedParent = parentEnumerated && !parentNewPage;
    const isDisabled = !editable || isExistingEnumeratedParent;

    return <div className="p-col p-col-fixed">
      <div>
        <PhenomLabel text="Existing/New VTU" />
        <PhenomToggle checked={createNewVtu}
                      data={["Existing", "New"]}
                      disabled={isDisabled}
                      id={this.phenomId.genPageId("vtu-toggle")}
                      onChange={this.toggleCreateVtu} />
      </div>
    </div>
  }

  renderVtToggleCell = () => {
    const { editable, parentEnumerated, parentNewPage } = this.props;
    const { createNewVt, createNewVtu } = this.state;

    // disable for existing enumerated vtu
    const isExistingEnumeratedParent = parentEnumerated && !parentNewPage;
    const isDisabled = !editable || isExistingEnumeratedParent || !createNewVtu;

    return <div className="p-col p-col-fixed">
    
    <div>
            <PhenomLabel text="Existing/New VT" />
            <PhenomToggle checked={createNewVt}
                          data={["Existing", "New"]}
                          disabled={isDisabled}
                          id={this.phenomId.genPageId("vt-toggle")}
                          onChange={this.toggleCreateVt} />
            </div>
            </div>
  }

  renderParentCell = () => {
    const { editable, parentEnumerated, parentNewPage } = this.props;
    const { selectedVTU, createNewVtu } = this.state;

    // disable for existing enumerated vtu
    const isExistingEnumeratedParent = parentEnumerated && !parentNewPage;
    const isDisabled = !editable || !createNewVtu || isExistingEnumeratedParent;

    return <div className="p-col p-col-5">
      <PackageComboBox label="Package"
                        xmiType="face:LogicalDataModel"
                        nodeGuid={selectedVTU.guid}
                        selectedGuid={selectedVTU.parent}
                        disabled={isDisabled}
                        id={this.phenomId.genPageId("parent")}
                        onChange={(parent) => this.updateVTU("parent", parent.guid)}
                        config={!isDisabled && this.requiredVtuFields["parent"]} />
    </div>
  }

  renderValueTypeCell = () => {
    const { editable, notSupported, parentEnumerated, parentNewPage } = this.props;
    const { selectedVTU, valueTypeList, createNewVt, createNewVtu } = this.state;
    const title = createNewVt ? "New Value Type" : "Value Type";
    
    // disable for existing enumerated vtu
    const isExistingEnumeratedParent = parentEnumerated && !parentNewPage;
    const isDisabled = !editable || isExistingEnumeratedParent || !createNewVtu || notSupported || !isPhenomGuid(selectedVTU?.guid);

    return <div className="p-col p-col-3">
            {createNewVt ?
              <PhenomInput label="New Value Type"
              value={selectedVTU.valueType.name}
              id={this.phenomId.genPageId("valuetype-input")}
              disabled={isDisabled}
              onChange={(e) => this.updateVT("name", e.target.value)}
              config={!isDisabled && this.requiredVtuFields["valueType"]} />
              :
              <PhenomComboBox label={title}
                              value={selectedVTU.valueType}
                              data={valueTypeList}
                              dataItemKey="guid"
                              textField="name"
                              id={this.phenomId.genPageId("valueType")}
                              disabled={isDisabled}
                              placeholder={createNewVtu ? "Select a Value Type" : ""}
                              onChange={(vt) => this.handleValueTypeChange(vt)}
                              config={!isDisabled && this.requiredVtuFields["valueType"]} >
                                <PhenomButtonLink node={ selectedVTU.valueType } className='cadet-node-link' /> 
                </PhenomComboBox>
            }
      </div>
  }

  renderUnitCell = () => {
    const { parentEnumerated, parentNewPage, notSupported, editable } = this.props;
    const { selectedVTU, unitList, createNewVtu } = this.state;
    const title = isPhenomGuid(selectedVTU?.unit?.guid) ? "New Unit" : "Unit";

    // disable for existing enumerated vtu
    const isExistingEnumeratedParent = parentEnumerated && !parentNewPage;
    const isDisabled = !editable || parentEnumerated || isExistingEnumeratedParent || !createNewVtu || notSupported;

    return <div className="p-col p-col-2"><PhenomComboBox label={title}
                            value={selectedVTU.unit}
                            data={unitList}
                            dataItemKey="guid"
                            id={this.phenomId.genPageId("unit")}
                            onChange={(unit) => this.updateVTU("unit", unit)}
                            disabled={isDisabled}
                            config={!isDisabled && this.requiredVtuFields["unit"]} />
    </div>
  }

  renderConstraintCell = () => {
    const { parentEnumerated, parentNewPage, notSupported, editable } = this.props;
    const { selectedVTU, constraintList, createNewVtu, createNewVt } = this.state;
    const { children=[] } = selectedVTU;
    const constraint = children[0];
    const title = createNewVtu ? "New Constraint" : "Constraint";
    let onClickPlusIcon, onClickPencilIcon, onClickCancelIcon;
    
    // disable for existing enumerated vtu
    const isExistingEnumeratedParent = parentEnumerated && !parentNewPage;
    const isDisabled = !editable || isExistingEnumeratedParent || notSupported || !createNewVtu;

    // conditionally render the 'plus' button
    // -> render for non-enumerated VTU
    if (!parentEnumerated) {
      onClickPlusIcon = this.addNewConstraint;
    }

    // conditionally render the 'pencil' button
    // -> render for non-enumerated VTU
    if (!parentEnumerated && constraint) {
      onClickPencilIcon = this.editConstraint;
    }

    // conditionally render the 'clear' button
    // -> render for non-enumerated VTU
    if (!parentEnumerated) {
      onClickCancelIcon = this.removeConstraint;
    }

    return <div className="p-col p-col-3">
            {parentEnumerated ?
              <PhenomInput label={title}
              value={constraint?.name || ""}
              id={this.phenomId.genPageId("constraint-input")}
              disabled={isDisabled}
              onChange={(e) => this.updateConstraint("name", e.target.value)} />
              :
              <PhenomComboBox label={title}
                              value={constraint}
                              data={constraintList}
                              placeholder={parentEnumerated && this.createConstraintName()}
                              dataItemKey="guid"
                              textField="name"
                              id={this.phenomId.genPageId("constraint")}
                              onChange={this.handleConstraintChange}
                              disabled={isDisabled}
                              onClickPlusIcon={onClickPlusIcon}
                              onClickPencilIcon={onClickPencilIcon}
                              onClickCancelIcon={onClickCancelIcon} >
                                <PhenomButtonLink node={ constraint } className='cadet-node-link' /> 
                </PhenomComboBox>

            }
    </div>
  }

  renderEnumNameCell = (cellProps) => {
    const { parentEnumerated, parentNewPage, editable, notSupported } = this.props;
    const { createNewVtu } = this.state;

    // disable for existing enumerated vtu
    const isExistingEnumeratedParent = parentEnumerated && !parentNewPage;
    const isDisabled = !editable || notSupported || !createNewVtu || isExistingEnumeratedParent;

    return <td>
      <PhenomInput value={cellProps.dataItem.name}
                   disabled={isDisabled}
                   customErrorMsg={cellProps.dataItem.customErrorMsg}
                   onChange={(e) => {
                    cellProps.dataItem.name = e.target.value;
                    cellProps.dataItem.customErrorMsg = "";
                    this.verifyEnumNames(cellProps.dataItem);
                    this.forceUpdate();
                   }} />
    </td>
  }

  renderEnumDescriptionCell = (cellProps) => {
    const { parentEnumerated, parentNewPage, editable, notSupported } = this.props;
    const { createNewVtu } = this.state;

    // disable for existing enumerated vtu
    const isExistingEnumeratedParent = parentEnumerated && !parentNewPage;
    const isDisabled = !editable || notSupported || !createNewVtu || isExistingEnumeratedParent;

    return <td>
      <PhenomInput value={cellProps.dataItem.description}
                   disabled={isDisabled}
                   onChange={(e) => {
                    cellProps.dataItem.description = e.target.value;
                    this.forceUpdate();
                   }} />
    </td>
  }

  renderEnumRemoveCell = (cellProps) => {
    const { parentEnumerated, parentNewPage, editable, notSupported } = this.props;
    const { createNewVtu } = this.state;

    // disable for existing enumerated vtu
    const isExistingEnumeratedParent = parentEnumerated && !parentNewPage;
    const isDisabled = !editable || notSupported || !createNewVtu || isExistingEnumeratedParent;

    if (isDisabled) {
      return <td />
    }

    return <td style={{textAlign:"center", padding:"0 5px"}}>
      <Button icon="close" 
              look="bare"
              onClick={() => this.removeEnumLabel(cellProps.dataItem)} />
    </td>
  }

  renderEnumRow = (_, cellProps) => {
    const { parentEnumerated, parentNewPage, editable } = this.props;
    const { createNewVtu } = this.state;

    // disable for existing enumerated vtu
    const isExistingEnumeratedParent = parentEnumerated && !parentNewPage;
    const isDisabled = !editable || !createNewVtu || isExistingEnumeratedParent;
    const isEdited = this.isLabelEdited(cellProps.dataItem);

    return <tr style={{ height: 50, background: isEdited ? "hsl(var(--skayl-sky-hs) 86%)" : null  }}
               draggable={!isDisabled}
               onDragStart={(e) => {
                this.setState({ draggingGuid: cellProps.dataItem.guid });
                e.dataTransfer.setData("dragging", "");
               }}
               onDragOver={() => {
                this.reorder(cellProps.dataItem);
               }}>
      { this.renderEnumNameCell(cellProps) }
      { this.renderEnumDescriptionCell(cellProps) }
      { this.renderEnumRemoveCell(cellProps) }
    </tr>
  }

  renderEnumFooter = () => {
    const { parentEnumerated, parentNewPage, editable, notSupported } = this.props;
    const { availableLabels, createNewVtu } = this.state;

    // disable for existing enumerated vtu
    const isExistingEnumeratedParent = parentEnumerated && !parentNewPage;
    const isDisabled = !editable || !parentEnumerated || notSupported || !createNewVtu || isExistingEnumeratedParent;

    return <Toolbar>
            <ToolbarItem>
              <Button icon="plus"
                      disabled={isDisabled}
                      id={this.phenomId.genPageId("add-new-label-btn")}
                      onClick={this.addNewEnumLabel}>Add new label</Button>
            </ToolbarItem>
            <ToolbarItem>
              <div>
                <label style={{ fontSize: 12 }}>
                  Add existing label:</label>
                <PhenomSelect value=""
                              data={availableLabels}
                              dataItemKey="guid"
                              dataDisabled={[""]}
                              onChange={this.addExistingEnumLabel}
                              disabled={isDisabled}
                              id={this.phenomId.genPageId("add-existing-label")}
                              containerProps={{
                                style: { maxWidth: 300 }
                              }} />
              </div>

            </ToolbarItem>
    </Toolbar>
  }

  render() {
      const { parentEnumerated, notSupported } = this.props;
      const { enumLabels, sortEnumLabelsConfig, createNewVtu } = this.state;
      return <div className="edit-form">
              <div className="p-row">
                { this.renderVtuCell() }
                { this.renderVtuToggleCell() }
                { this.renderParentCell() }
              </div>

              <div className="p-row">
                { this.renderValueTypeCell() }
                <div className="p-col">
                  <div className="p-row">
                    { parentEnumerated && createNewVtu && this.renderVtToggleCell() }
                    { this.renderUnitCell() }
                  </div>
                </div>
                { this.renderConstraintCell() }
              </div>

              {parentEnumerated &&
                <div id={this.phenomId.genPageId("enum-labels")}>
                  <PhenomLabel text="Allowed Labels" />
                  <Grid className="editorTable without-box-shadow"
                        data={sortEnumLabelsConfig.length ? orderBy(enumLabels, sortEnumLabelsConfig) : enumLabels}
                        sortable
                        sort={sortEnumLabelsConfig}
                        onSortChange={(e) => {
                          this.setState({ sortEnumLabelsConfig: e.sort })
                        }}
                        rowRender={this.renderEnumRow}>
                          <GridNoRecords>
                            {notSupported ? "Currently this Enumeration is not supported." : "No Data is available for this table."}
                          </GridNoRecords>
                          <GridColumn title="Name" field="name" />
                          <GridColumn title="Description" field="description" />
                          <GridColumn title="Remove" width="55px" />
                  </Grid>
                  { this.renderEnumFooter() }
                </div> }
            </div>
  }
}


export const NestedVtuPicker = withNestedLayout(VtuPicker3);
