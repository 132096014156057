import React from 'react'
import { _ajax } from '../../requests/sml-requests';
import { KbButton, PhenomInput, PhenomLabel, PhenomSelect } from '../util/stateless';
import { SubMenuRight } from '../edit/edit-top-buttons';
import styled from '@emotion/styled';
import { Checkbox } from '@progress/kendo-react-inputs';
import ReactTooltip from 'react-tooltip';
import { TreeView } from '@progress/kendo-react-treeview';
import { TREE_ICON_MAP } from '../tree/tree_constants';
import { PhenomLink } from '../widget/PhenomLink';
import { getDateTimeForFilename, scrubBooleanStrings } from '../util/util';
import { receiveLogs, receiveResponse, setProjectSettings } from '../../requests/actionCreators';
import { connect } from 'react-redux';
import LoaderButton from "../widget/LoaderButton";
import NavTree from '../tree/NavTree';


const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 200px minmax(200px, 300px);
  align-items: center;
  // margin: 0 auto 0 5em;
  gap: 1.2em;
  padding: 1em;
  // border: 1px solid #eaeaea;

  .inner {
    padding-left: 1em;
  }

  .version-description {
    display: flex;
    height: 100%;
    align-items: flex-end;

    p {
      font-style: italic;  
    }
  }
`


class CincGen extends React.Component {

  artifactOptions = [
    {label: "CinC",     fileType: "face3_cinc_code",  description: "Generate CinC content based on UoP"},
    {label: "Mock UoP", fileType: "cinc_im_from_uop", description: "FACE 3.0 CinC. Select your Main Program(s) and UoPs."},
    {label: "PinC",     fileType: "pinc",             description: "FACE 3 Passthrough Infrastructure Capability. Generate based on UoPs."},
  ]

  architectureOptions = [
    {label: "Debian 9", type: "debian_9"},
  ]

  state = {
    // fileType: "face3_cinc_code",
    reportBy:    "Main_Program",
    namespace:   "DSDM",
    system_name: "Test_System",
    
    selectionType: "mains_uops",
    selectedArtifact: this.artifactOptions[0],
    selectedArchitecture: this.architectureOptions[0],
    selectedCincVersion: null,
    selectedFaceVersion: null,
    selectedLanguage: null,

    cinc_version_options: [],
    main_program_options: [],
    uop_options: [],
    licenseMap: {},
    namespaceOverride: false,
    include_tss_skeleton: true,
    include_model: true,
  }



  componentDidMount() {
    NavTree.assignPresetPageFilters("views");
    NavTree.reset()
           .then(this.getVersionOptions)
           .then(this.getCincNodes)
           .then(this.restoreGenerateSettings);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedArtifact !== this.state.selectedArtifact) {
      this.getVersionOptions()
          .then(this.restoreGenerateSettings);
    } else if (prevState.selectionType !== this.state.selectionType) {
      if (this.state.selectionType === "views") {
        NavTree.setShowCheckboxesFor(["platform:View"]);
      } else {
        NavTree.clearShowCheckboxesFor();
      }
    }
  }

  componentWillUnmount() {
    NavTree.clearCheckedNodes();
    NavTree.clearShowCheckboxesFor();
  }

  getVersionOptions = () => {
    const { selectedArtifact } = this.state;

    return _ajax({
      url: "/index.php?r=/generate/get-cinc-options",
      method: "post", 
      data: {
          "artifact": selectedArtifact.fileType
      }
    }).then((response) => {
      let options = Array.isArray(response.data?.options) ? response.data.options : [];

      let cincVersion = options[0] || null;
      
      let faceVersion = null;
      if (cincVersion && Array.isArray(cincVersion["face-versions"])) {
        let faceVersions = cincVersion["face-versions"];
        faceVersion = faceVersions[faceVersions.length - 1];
      }
      
      let language = null;
      if (cincVersion && Array.isArray(cincVersion["languages"])) {
        let languages = cincVersion.languages || [];
        language = languages[0];
      }

      this.setState({
        cinc_version_options: options,
        selectedCincVersion: cincVersion,
        selectedFaceVersion: faceVersion,
        selectedLanguage: language,
      }, () => {
        ReactTooltip.rebuild();
      });
    })
  }

  getCincNodes = () => {
    return Promise.all([
      _ajax({
        url: "/index.php?r=/generate/get-main-program-options",
        method: "post"
      }),
      _ajax({
        url: "/index.php?r=/node/model-nodes-of-type",
        method:"get",
        data: {
            type:["uop:PortableComponent", "uop:PlatformSpecificComponent", "im:UoPInstanceToMainProgram", "im:UoPInstance"]
        }
      })

    ]).then((res) => {

      if (!Array.isArray(res[0].data?.options) || !Array.isArray(res[1].data?.nodes)) {
        return;
      }

      const nodeMap = {
        "uop:PortableComponent": {},
        "uop:PlatformSpecificComponent": {},
        "ddm:MainProgram": {},
        "im:UoPInstanceToMainProgram": {},
        "im:UoPInstance": {}
      }

      res[1].data.nodes.forEach(n => {
        nodeMap[n.xmiType][n.guid] = n;
      });

      // format for Kendo TreeView
      res[0].data.options.forEach(mp => {
        mp["text"] = mp["name"];
        mp["items"] = [];
        mp["selectedLicense"] = mp["default_license"];

        nodeMap["ddm:MainProgram"][mp.guid] = mp;
      });

      // format for Kendo TreeView
      let uops = Object.values(nodeMap["uop:PortableComponent"]).concat(Object.values(nodeMap["uop:PlatformSpecificComponent"]));
      uops.forEach(n => {
        n["text"] = n["name"];
        n["items"] = [
          { text: "Include main" }
        ];
      })

      // Resolve associations
      Object.values(nodeMap["im:UoPInstanceToMainProgram"]).forEach(associationNode => {
        let instanceNode = nodeMap["im:UoPInstance"][associationNode.UoP_Instance_Guid];
        let mpNode = nodeMap["ddm:MainProgram"][associationNode.Main_Program_Guid];
        
        let uopNode = null;
        if (instanceNode) {
          uopNode = uops.find(n => n.guid === instanceNode.realizes);
        }

        if (!uopNode || !mpNode) {
          return;
        }

        if (mpNode["items"].findIndex(uop => uop.guid === uopNode.guid) === -1) {
          const copy = {...uopNode};
          delete copy["items"]          // remove "Include main"
          mpNode["items"].push(copy);
        }
      })


      this.setState({
        licenseMap: res[0].data.licenses,
        main_program_options: Object.values(nodeMap["ddm:MainProgram"]),
        uop_options: uops,
      })
    })
  }

  requestGeneratedFile = () => {
    const { selectedArtifact, selectionType, reportBy, uop_options, main_program_options, 
            include_tss_skeleton, include_model, namespace, system_name,
            selectedCincVersion, selectedFaceVersion, selectedLanguage, namespaceOverride } = this.state;

    if (!selectedArtifact) {
      return;
    }

    let selectedNodes = [];
    let { fileType } = selectedArtifact;

    if (selectionType === "views") {
      NavTree.getSelectedForModelGen().forEach(g => {
        let item = {guid: g, xmiType: "platform:View"};
        selectedNodes.push(item);
      });

    } else {
      if (["cinc_im_from_uop", "pinc"].includes(fileType)) {
        uop_options.forEach(n => {
          if (n.checked && n.items) {
            let item = {guid: n.guid, xmiType: "uop:PortableComponent", include_main:n.items[0].checked, expanded:n.expanded};
            selectedNodes.push(item);
          }
        });
  
      } else if (fileType === "face3_cinc_code") {
        main_program_options.forEach(n => {
          if (n.checked && n.items) {
            let item = {guid: n.guid,
                        xmiType: "ddm:MainProgram",
                        expanded: n.expanded,
                        include_uops:n.items.filter(c => c.checked)
                                            .map(c => c.guid),
                        selected_license: n.selectedLicense};
            selectedNodes.push(item);
          }
        })
      }
    }

    let generationConfig = {
      include_tss_skeleton: fileType === "pinc" || include_tss_skeleton,
      cinc_version: selectedCincVersion["config-name"],
      face_version: selectedFaceVersion,
      language: selectedLanguage,
      namespace: namespaceOverride ? namespace : false,
      system_name: system_name !== "" ? system_name : "Test_System",
      include_model,
      selectionType,
    }

    return _ajax({
      url: "/index.php?r=/generate/generate-model-file",
      method: "post",
      data: {
        fileType,
        reportBy,
        template: [],
        selectedNodes,
        generationConfig,
        includeTags: [],
        excludeTags: [],
      }
    }).then((response) => {
      receiveResponse(response);

      if (response.data.filename) {
          receiveLogs(`${selectedArtifact.label} generated.`);
          const newName = `${this.props.userIdentity.branchName}_${fileType}${getDateTimeForFilename()}.${response.data.filename.match(/(?!\.)\w+$/)}`;
          window.location.href = `${process.env.NODE_ENV === "development" ? "http://localhost" : ""}/index.php?r=/generate/download-file&file=${response.data.filename}&newName=${newName}`;

          //store mains/uops and licenses in 'generate' react redux
          setProjectSettings({
            ...this.props.project_settings,
            generate: {
              ...this.props.project_settings.generate,
              [fileType]: {
                selection: selectedNodes,
                generateConfig: generationConfig,
              }
            }
          })
        }
    })
  }

  restoreGenerateSettings = () => {
    const { selectedArtifact, main_program_options, uop_options, cinc_version_options=[] } = this.state;
    const { project_settings } = this.props;

    NavTree.clearCheckedNodes();

    if (!selectedArtifact || !project_settings?.generate) {
      return;
    }

    const generateSettings = project_settings.generate[selectedArtifact.fileType];
    if (!generateSettings) {
      return;
    }
    
    const { selection, generateConfig } = scrubBooleanStrings(generateSettings);
    if (!generateConfig || !Array.isArray(selection)) {
      return;
    }

    const viewGuids = selection.map(data => NavTree.getLeafNode(data.guid))
                               .filter(v => !!v && v.getXmiType() === "platform:View")
                               .map(v => v.getGuid());
    
    if (viewGuids.length) {
      NavTree.checkLeafNodes(viewGuids, true);
    }
    
    switch (selectedArtifact.fileType) {
      case "face3_cinc_code":
        selection.forEach(data => {
          const mp = main_program_options.find(ele => ele.guid === data.guid);
          if (!mp) {
            return;
          }

          mp.checked = true;
          mp.expanded = !!data.expanded;
          mp.selectedLicense = data.selected_license;

          if (Array.isArray(data.include_uops)) {
            data.include_uops.forEach(uopGuid => {
              const uop = mp.items.find(ele => ele.guid === uopGuid);
              if (uop) {
                uop.checked = true;
              }
            });
          }
        });
        break;

      case "cinc_im_from_uop":
      case "pinc":
        selection.forEach(data => {
          const uop = uop_options.find(ele => ele.guid === data.guid);
          if (!uop) {
            return;
          }

          uop.checked = true;
          uop.expanded = !!data.expanded;
          if (data.include_main) {
            uop.items[0].checked = true;
          }
        });
        break;

      default:
    }

    this.setState({
      ...generateConfig,
      selectedCincVersion: cinc_version_options.find(cv => cv["config-name"] === generateConfig.cinc_version) || cinc_version_options[0] || null,
      selectedFaceVersion: generateConfig.face_version || null,
      selectedLanguage: generateConfig.language || null, 
      uop_options: [...uop_options],
      main_program_options: [...main_program_options],
      namespace: generateConfig?.namespace || "DSDM",  // original code sends back false.
      namespaceOverride: !!generateConfig.namespace,
    });
  }

  renderCincForm() {
    const { selectedArtifact, selectedCincVersion, selectedArchitecture, selectedFaceVersion, selectedLanguage, namespace, system_name, include_tss_skeleton, include_model, cinc_version_options=[], namespaceOverride } = this.state;
    const { pincRole } = this.props;

    if (!selectedArtifact || !selectedCincVersion) {
      return null;
    }

    const faceVersions = selectedCincVersion["face-versions"] || [];
    const languages = selectedCincVersion.languages || [];
    const architecture = selectedArchitecture?.type;

    return <StyledGrid>
        <label>CinC Version</label>
        <PhenomSelect 
            data={cinc_version_options}
            value={selectedCincVersion["config-name"]}
            textField="name"
            dataItemKey="config-name"
            onChange={(e) => {
                const _cincVersion = cinc_version_options.find((ele) => ele["config-name"] === e.target.value);
                const _faceVersions = _cincVersion["face-versions"];
                const _languages = _cincVersion["languages"]
                
                // this logic was from the original code
                this.setState({ 
                  selectedCincVersion: _cincVersion,
                  selectedFaceVersion: _faceVersions[_faceVersions.length - 1],
                  selectedLanguage: _languages[_languages.length - 1],
                });
            }} />

        <label>Description</label>
        <p>{ selectedCincVersion.description }</p>

        <label>FACE Version</label>
        <PhenomSelect
            data={faceVersions}
            value={selectedFaceVersion}
            onChange={(e) => this.setState({ selectedFaceVersion: e.target.value})} />

        <label>Language</label>
        <PhenomSelect
            data={languages}
            value={selectedLanguage}
            onChange={(e) => this.setState({ selectedLanguage: e.target.value})} />

        <label htmlFor='namespace-override'>Override namespaces?
          <span className="fas fa-info-circle"
                data-tip="Forces a global namespace overriding top-level model names."
                data-for="hoverTip"
                data-place="right"
                style={{ marginLeft: 10 }}/>
        </label>
        <Checkbox label=""
                  id="namespace-override"
                  checked={namespaceOverride}
                  onChange={() => this.setState((prevState) => ({ namespaceOverride: !prevState.namespaceOverride }))} />
        
        {namespaceOverride && <>
        <label className="inner">Namespace</label>
        <PhenomInput
            value={namespace}
            autoFocus
            onChange={(e) => {
                let value = e.target.value.match(/[0-9A-Za-z_]*/)[0];
                this.setState({ namespace: value });
            }} /> </>}
        
        <label>System Directory</label>
        <PhenomInput
            value={system_name}
            onChange={(e) => {
                let value = e.target.value.match(/[0-9A-Za-z_]*/)[0];
                this.setState({ system_name: value });
            }} />
        
        {/* note: original code did nothing with the Target Architecture.  meaning it was never sent to the backend.  */}
        {pincRole && <>
        <label>Target Architecture</label>
        <PhenomSelect
            data={this.architectureOptions}
            value={selectedArchitecture?.type}
            textField="label"
            dataItemKey="value"
            onChange={(e) => {
              const arch = this.architectureOptions.find(ele => ele.type === e.target.value);
              this.setState({ selectedArchitecture: arch});
            }} /></> }

        {selectedArtifact.fileType !== "pinc" && <>
        <label htmlFor="include-cinc-source">Include CinC Source?</label>
        <Checkbox label=""
                  id="include-cinc-source"
                  checked={include_tss_skeleton}
                  onChange={() => this.setState((prevState) => ({ include_tss_skeleton: !prevState.include_tss_skeleton }))} />
        </>}

        <label htmlFor="include-model-files">Include Model files?</label>
        <Checkbox label=""
                  id="include-model-files"
                  checked={include_model}
                  onChange={() => this.setState((prevState) => ({ include_model: !prevState.include_model }))} />
    </StyledGrid>
  }

  treeItem = (props) => {
    // render "Include Main" checkbox
    if (!props.item.xmiType) {
      return <span>{ props.item.text }</span>
    }

    return <>
      <img src={TREE_ICON_MAP[props.item.xmiType]} style={{paddingRight:"5px"}} />
      <PhenomLink node={props.item} />
    </>
  }

  renderTreeView() {
    const { uop_options, main_program_options, selectedArtifact, selectionType } = this.state;
    const text = "If nothing is selected then we will generate Data Types and TS Interfaces for your entire model.";

    if (selectionType !== "mains_uops") {
      return <div>
          <label>Select your Views in the NavTree</label>
          <p style={{ fontStyle: 'italic' }}>{text}</p>
      </div>
    }

    const isMockUoP = ["cinc_im_from_uop", "pinc"].includes(selectedArtifact.fileType);
    let title = "Select your Mains and UoPs";
    if (isMockUoP) {
      title = "Select your Mocked UoPs and Mains";
    }

    const data = isMockUoP ? uop_options : main_program_options;

    return <div>
      <label>{ title }</label>
      <p style={{ fontStyle: 'italic' }}>{text}</p>

      <TreeView 
        data={data}
        item={this.treeItem}
        checkboxes
        expandIcons
        onExpandChange={(event) => {
          event.item.expanded = !event.item.expanded;
        }}
        onCheckChange={(event) => {
          let { item } = event;
          let bool = !item.checked;
          item.checked = bool;
          item.expanded = bool;

          // const data = isMockUoP ? uop_options : main_program_options;
          const parent = data.find(x => x.items.includes(item));
          if (item.checked && parent) {
              parent.checked = true;
          }
          if (item.items) {
            item.items.forEach(c => c.checked = event.item.checked)
          }
          this.forceUpdate()
        }}
      />

    </div>
  }


  render() {
    const { selectionType, selectedArtifact, cinc_version_options } = this.state;
    const { expired } = this.props;

    return <div className="phenom-content-wrapper">
      <nav className="sub-menu-actions flex-end" aria-label='form actions' > 
        <SubMenuRight> 
          <KbButton /> 
        </SubMenuRight> 
      </nav>

      <div className="flex-container phenom-content-scrollable subview-wrapper">
        <div className="p-row">
          <div className="p-col p-col-4">
            <PhenomSelect 
                label="CinC Artifact"
                data={this.artifactOptions}
                value={selectedArtifact.fileType}
                textField="label"
                dataItemKey="fileType"
                onChange={(e) => {
                  const artifact = this.artifactOptions.find((ele) => ele.fileType === e.target.value);
                  this.setState({ selectedArtifact: artifact });
                }} />
          </div>
          <div className="p-col p-col-8">
            <div>
              <PhenomLabel text="Description" />
              <p>{ selectedArtifact.description }</p>
            </div>
          </div>
        </div>

        { this.renderCincForm() }

        <div>
          <PhenomLabel text="Selection Type" />
          <div style={{ width: 200 }}>
            <PhenomSelect
                value={selectionType}
                data={[
                  { name: "Mains and UoPs", type: "mains_uops" },
                  { name: "Views",          type: "views" },
                ]}
                textField="name"
                dataItemKey="type"
                onChange={(e) => this.setState({ selectionType: e.target.value })} />
          </div>
        </div>

        { this.renderTreeView() }

        <div>
          <LoaderButton
              className="filled-button2"
              text="GENERATE"
              onClick={this.requestGeneratedFile}
              disabled={expired || !cinc_version_options.length} />
        </div>
      </div>

    </div>
  }
}


const msp = (state) => {
  const { userRole="" } = state.user;

  return {
    project_settings: state.app.project_settings,
    userIdentity: state.user.userIdentity,
    expired: state.user.expired,
    pincRole: userRole.includes('p'),
  }
}


export default connect(msp)(CincGen);