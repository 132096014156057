import React from "react";
import { KbButton, PhenomLabel, FinalizeReviewActions, PhenomInput } from "../../util/stateless";
import ListPaneView from "../../edit/list-pane-view";
import NavTree from "../../tree/NavTree";
import { validateNodeFields, getShortenedStringRepresentationOfXmiType } from "../../util/util";
import { PhenomLink } from "../../widget/PhenomLink";
import { finalizeMergeReview, toggleReviewNodeStatus, rejectReviewNodes, unlockProject, isProjectLocked, getRejectedReviewNodes, saveNewCommit, rebaseReview } from "../../../requests/sml-requests";
import { receiveResponse } from "../../../requests/actionCreators";
import { resetApp } from "../../../requests/actionThunks";
import { ReviewSubMenu } from "../review";
import { cloneDeep } from "lodash";
import { BasicConfirm } from "../../dialog/BasicConfirm";
import { connect } from "react-redux";
import loadingIcon from "../../../images/Palette Ring-1s-200px.gif"
import PhenomLoadButton from "../../widget/LoaderButton";
import { BasicAlert } from "../../dialog/BasicAlert";
import * as actionCreators from "../../../requests/actionCreators"
import { NoSpecialCharsRegex } from "../../../hooks/useInputError";
import { removeNodes } from "../../../requests/actionCreators";
import NodeLeaf from "../../tree/leaf/NodeLeaf";

export function acceptReviewNode(node, thisNodeOnly, callback=false) { // wrapper function for handling tree nodes & calback after accepting
    const guid = node.guid;
    return toggleReviewNodeStatus(guid, thisNodeOnly, false).then((res) => {
        if (!res.data) {
            receiveResponse(res);
            NavTree.updateReviewStatus(guid, thisNodeOnly, true);
            callback && callback();
        } else if (res.data?.edited) {
            const { edited } = res.data;
            const single = edited.length === 1;
            let message = (single ? `Node '${edited.at(0)}' has been edited. ` : `The following nodes have been edited:\n - ${edited.join("\n - ")}\n\n`) +
            //let message = "Node" + (single ? "" : "s") + " '" + edited.join("', '") + "' " + (single ? "has" : "have") + " been edited. " + 
                        "Would you like to undo changes done to " + (single ? "this node to put it" : "these nodes to put them") + " back under review?\n\n" +
                        "WARNING: Changes that are undone are lost permanently and cannot be recovered, even by rolling back commits.";
            BasicConfirm.show(
            message, 
            () => {
                toggleReviewNodeStatus(guid, thisNodeOnly, true).then((res) => {
                    receiveResponse(res);
                    NavTree.updateReviewStatus(guid, thisNodeOnly, true);
                    node.undo = true;
                    callback && callback();
                })
            },
            () => {},
            "Please Confirm",
            {width: "fit-content", maxWidth: "400px"}
            )
        }
        return res;
    });
}

export function rejectReviewNode(guid, thisNodeOnly, skipValidation, commitBefore, callback=false) { // wrapper function to handle tree nodes & callback after rejection
    return rejectReviewNodes(guid, thisNodeOnly, skipValidation, commitBefore).then((res) => {
        receiveResponse(res);
        
        // add/update/remove rejected nodes
        if (!res.data.errors) {
            const rejectedNodes = JSON.parse(res.data);
            let addNodes = {};
            let deleteNodes = {};
    
            for (const [guid, node] of Object.entries(rejectedNodes)) {
                const existingLeaf = NavTree.getLeafNode(guid);

                if (existingLeaf && node) { // rejected move- remove moved in node
                    if (existingLeaf.data.modelId !== node.m) {
                        deleteNodes[guid] = node;
                    } else { // rejected edit - intra model move
                        if (existingLeaf.data.parent !== node.p) {
                            node.guid = guid;
                            node.r = "Rejected";
                            addNodes[guid] = node;
                            deleteNodes[guid] = node;
                        } else { // rejected edit - revert changes
                            existingLeaf.updateData(node);
                            existingLeaf.forceParentUpdate();
                        }
                    }
                    
                    continue;
                }

                if (guid.endsWith(" m")) { // rejected move - add moved out node
                    const cleanGuid = guid.slice(0, -2);
                    node.guid = cleanGuid;
                    node.r = "Rejected";
                    delete node.v
                    addNodes[cleanGuid] = node;
                    continue
                }
                
                if (node) { // rejected deletion - restore node
                    node.guid = guid;
                    node.r = "Rejected";
                    addNodes[guid] = node;
                } else { // rejected creation - delete node
                    deleteNodes[guid] = node;
                }
            }
    
            removeNodes(Object.keys(deleteNodes));
            NavTree.insertNodeIndex(addNodes);
            NavTree.updateReviewStatus(guid, thisNodeOnly, false);
            callback && callback();
        }

        return res;
    });
}



export class FinalizeMerge extends React.Component {
    constructor(props) {
        super(props);

        this.defaultState = {
            commitName: null,
            guidToNodeMap: {},
            guidToActionConfigMap: {},
            loading: true,
            lists: [{ 
                collapsible: false,
                data: [],
                headerOnly: true,
                headerClass: "main",
                columns: [{header: "Review Nodes"}]
            }]
        }

        this.defaultRequiredFields = {
            ["name"]: {
              required: true,
              checkFirstChar: true,
              regexPattern: NoSpecialCharsRegex,
              errorRef: React.createRef(),
            },
        }

        this.state={...this.defaultState}
        this.requiredFields={...this.defaultRequiredFields}
    }


    // ------------------------------------------------------------
    // Life Cycle Methods
    // ------------------------------------------------------------

    componentDidMount() {
        NavTree.collapseNavTree(false);
        NavTree.reset().then(() => {
            this.init();
            NavTree.expandAllReviewNodes();
        });
    }
    
    componentDidUpdate(prevProps, prevState) {
    }

    componentWillUnmount(){
    }


    // ------------------------------------------------------------
    // Initial Setup
    // ------------------------------------------------------------

    init = () => {
        const typeToNodeMap = {};
        const guidToNodeMap = {};
        const guidToActionConfigMap = cloneDeep(this.state.guidToActionConfigMap);

        // 1. gather underReview/accepted then append rejected nodes
        cloneDeep(NavTree.getReviewNodeDataList()).forEach((node) => guidToNodeMap[node.guid] = node);
        getRejectedReviewNodes().then((res) => {
            if (res.data?.nodes && res.data.nodes.length) {
                res.data.nodes.forEach((node) => {
                    node.in_model = false;
                    guidToNodeMap[node.guid] = node;
                });
            }
            if(!Object.keys(guidToNodeMap).length) return this.setLoading(false);

            // 2. format nodes & create type map
            Object.values(guidToNodeMap).forEach((node) => {
                if (node.reviewStatus === 'Under_Review') {
                    node.reviewStatus = 'Pending'
                }

                if (typeToNodeMap[node.xmiType]) { // build temp typeToNodeMap for lists
                    typeToNodeMap[node.xmiType].push(node);
                } else {
                    typeToNodeMap[node.xmiType] = [node];
                }

                if (!node.name && !node.rolename) node.xmiType = getShortenedStringRepresentationOfXmiType(node.xmiType);

                const parentGuid = typeof node.parent === "string" ? node.parent : node.parent.guid; 
                if (guidToNodeMap[parentGuid] && !guidToActionConfigMap[parentGuid]) { // build guidToActionConfig map, first pass: has children
                    guidToActionConfigMap[parentGuid] = {
                        actionType: "This Node & All Children",
                        actions: ["This Node & All Children", "This Node Only"],
                    };
                }
            });

            Object.values(guidToNodeMap).forEach(node => { // finish building guidToActionConfig map, second pass: no children
                if (!guidToActionConfigMap[node.guid]) { 
                    guidToActionConfigMap[node.guid] = {
                        actionType: "This Node Only",
                        actions: ["This Node Only"],
                    };
                }
            });

            // 3a. start list setup with initial header list
            const lists = [{ 
                collapsible: false,
                data: [cloneDeep(Object.values(guidToNodeMap))],
                headerOnly: true,
                headerClass: "main",
                columns: [{header: "Review Nodes"}]
            }]

            // 3b. create & sort lists for each xmiType
            Object.keys(typeToNodeMap).sort().forEach((key) => {
                const findName = (n) => n["name"] || n["rolename"] || n["xmiType"];

                typeToNodeMap[key].sort((n1, n2) => {
                  let name1 = findName(n1);
                  if (Array.isArray(n1.parents)) {
                    name1 = n1.parents.map(n => findName(n)).concat(findName(n1)).join(" ");
                  }
                  
                  let name2 = findName(n2);
                  if (Array.isArray(n2.parents)) {
                    name2 = n2.parents.map(n => findName(n)).concat(findName(n2)).join(" ");
                  }
                  
                  return name1.localeCompare(name2);
                });

                lists.push(
                    { collapsible: true,
                        collapsed: false,
                        data: typeToNodeMap[key],
                        columns: [
                            {header: getShortenedStringRepresentationOfXmiType(key), 
                            render: this.renderNodeColumn,
                            flex: 3}, 
                            {header: "Review Status",  render: this.renderReviewNodeStatus, flex: 1},
                            {header: "Actions", render: this.renderReviewNodeActions , flex: 2},
                        ]
                    }
                );
            });

            this.setState({
                lists: lists,
                loading: false,
                guidToActionConfigMap: guidToActionConfigMap,
                guidToNodeMap: guidToNodeMap,
            });
        });
    }


    // ------------------------------------------------------------
    // Setter Methods
    // ------------------------------------------------------------

    setLoading = (bool) => {
        this.setState({loading: bool});
    }

    setNodeActionType = (guid, actionType) => {
        this.setState(prevState => ({
            guidToActionConfigMap: {
                ...prevState.guidToActionConfigMap,
                [guid]: {
                    ...prevState.guidToActionConfigMap[guid],
                    actionType: actionType,
                }
            }
        }));
    }


    // ------------------------------------------------------------
    // Handler Methods
    // ------------------------------------------------------------

    handleCommit = () => {
        if (!this.validateFields()) return actionCreators.receiveWarnings("Please fill in the commit name.");

        const { commitName } = this.state;
        const commitData = {
            name: commitName,
            description: "",
        }

        BasicAlert.show(`Saving '${commitName}'`, "Processing request", false);

        return saveNewCommit(commitData)
               .then((res) => {
                    receiveResponse(res);
                    // remove required fields before clearing input, since isn't being re-rendered
                    this.requiredFields = null;
                    this.setState({commitName: null}, () => this.requiredFields = {...this.defaultRequiredFields});
                })
                .always(()=> {
                    BasicAlert.hide();
                });
    }

    validateFields = () => {
        const { commitName } = this.state;
        const commitData = {
            name: commitName,
        }

        return validateNodeFields(this.requiredFields, commitData);
    }

    handleFinalizeMerge = () => {
        const { activeProjectId } = this.props;
        const afterMerge = (res) => {
            if (res.data?.errors) {
                BasicAlert.hide();
                BasicConfirm.show(
                    "The destination project has been updated during this review. To finalize the merge, a rebase of the review project is necessary.\n\nRebasing will integrate the latest changes from the destination project into this review, ensuring that the review contains the most recent updates before finalizing the merge.\n\nWould you like to proceed with the rebase now?",
                    ()=> {
                        BasicAlert.show("Please wait while the rebase is being performed.","Performing Rebase", false);
                        rebaseReview()
                            .then((res) => {
                                receiveResponse(res);
                                sessionStorage.clear();
                                resetApp();
                                NavTree.reset(true);
                                BasicAlert.hide();
                            })
                            .catch((err) => {
                                BasicAlert.hide();
                            });
                    },
                    ()=>{},
                    "Rebase Required"
                )
            } else {
                receiveResponse(res);
                sessionStorage.clear();
                resetApp();
                NavTree.reset(true);
                BasicAlert.hide();
            }
        }

        BasicAlert.show("One moment please. Retrieving the needed data.", "Loading...", false);

        isProjectLocked(activeProjectId, true)
            .then((res) => {
                const { locked_status } = res.data;
                const { guidToNodeMap } = this.state;
                const remainingPendingNodes = Object.values(guidToNodeMap).some(node => node.reviewStatus === "Pending");

                const finalizeMessage = [
                    remainingPendingNodes ? `Some nodes are still pending review; they will be accepted and merged in.` : ``,
                    locked_status ? `The destination Project is currently locked. To finalize the merge, it must be lifted.` : ``,
                    `Are you sure you want to ${locked_status ? "lift the lock and " : ""}finalize the merge? This cannot be undone.\n<b>Once the merge is finalized, this project will be deleted.</b>`
                ].filter(Boolean).join("\n\n");

                BasicAlert.hide();
                BasicConfirm.show(
                    finalizeMessage,
                    () => { //confirm
                        BasicAlert.show("Please wait while the merge is being finalized.","Processing Merge", false);
                        if(locked_status) {
                            unlockProject(activeProjectId, true)
                                .then(()=> {
                                    receiveResponse(res);
                                    finalizeMergeReview()
                                        .then((res) => afterMerge(res))
                                        .catch((err) => BasicAlert.hide());
                                }).catch((err) => BasicAlert.hide()); 
                        } else {
                            finalizeMergeReview()
                                .then((res) => afterMerge(res))
                                .catch((err) => BasicAlert.hide());
                        }
                    },
                    ()=> {}, // cancel
                    "Confirm Merge"
                );
            })
            .catch((err) => {
                BasicAlert.hide();
            });
    }

    // ------------------------------------------------------------
    // Render Methods
    // ------------------------------------------------------------

    renderNodeColumn = (item) => {
        const { guidToNodeMap } = this.state;
        const parentInModel = guidToNodeMap[item.parent.guid]?.in_model !== false;
        if(!parentInModel) item.parent.in_model = false;
        
        return <div className="ellipses-link">
          {Array.isArray(item.parents) && item.parents.map((parent, idx) => {
            return <React.Fragment>
              <PhenomLink node={parent} newTab/><span>.</span>
            </React.Fragment>
          })}
          <PhenomLink node={item} newTab/>
        </div>
    }

    renderReviewNodeStatus = (node) => {
        return (
            <p className={node.reviewStatus}>
                {node.reviewStatus}
            </p>
        );
    }

    renderReviewNodeActions = (node) => {
        const { loading, guidToActionConfigMap } = this.state;
        
        return (
            <FinalizeReviewActions 
                node={node}
                disabled={loading}
                setLoading={this.setLoading}
                callback={this.init}
                setNodeActionType={this.setNodeActionType}
                actionConfig={guidToActionConfigMap[node.guid]}
            />
        );
    }

    renderContentAboveList = () => {
        const { commitName, loading } = this.state;

        return (
            <div className="p-row">
                {/* Page Text Section */}
                <div className="p-col p-col-6" style={{gap: 0}}>
                    <PhenomLabel text="Review Changes" />
                    <p>
                        Changes under review may be accepted or rejected from this page.
                        Any accepted or pending change will be merged into the destination project when the merge is finalized.
                        Rejecting a change is an irreversible action that will revert the node back to its original state. Creating a commit (which acts as restoration point) before rejecting changes can be used as a recovery mechanism.
                    </p>
                </div>

                {/* Commit Section */}
                <div className="p-col p-col-1" style={{gap: 0, paddingBottom: "25px"}}>
                    <div className="p-row">
                        <PhenomLabel text="Create Commit" style={{width: "100%"}} />
                    </div>
                    <div className="p-row" style={{alignItems: "center"}}>
                        <PhenomInput 
                            containerProps={{style: {flex: 1}}}
                            value={commitName}
                            onChange={(e) => this.setState({commitName: e.target.value})}
                            config={this.requiredFields?.name}
                        />
                        <PhenomLoadButton 
                            style={{margin: 0}} 
                            divStyle={{height: "auto"}} 
                            text="SAVE"
                            onClick={this.handleCommit}
                            disabled={loading}
                        />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { lists, loading } = this.state;
        const { reviewRequest } = this.props;

        return (
            <div className="phenom-content-wrapper"> 
                <ReviewSubMenu reviewRequest={reviewRequest}>
                    {loading &&
                        <img id={"merge-loading-icon"}
                             style={{ display: "block", height: "30px" }}  src={loadingIcon}
                        />
                    }
                    <KbButton/>
                    <button
                        className="fak fa-solid-merge-circle-check"
                        title="Finalize Merge"
                        onClick={this.handleFinalizeMerge}
                        style={{ fontSize: "1.8em", padding: 0 }}
                        disabled={loading}
                    />
                </ReviewSubMenu>

                {/* Page Content Goes In This Div */}
                <div className="phenom-content-wrapper">
                    <div style={{margin: "20px 0px 0px 20px", overflowY: "hidden", height: "100%"}}>
                        <ListPaneView
                            mainKey={"id"}
                            lists={lists}
                            renderContentAboveList={this.renderContentAboveList}
                            minimizePane={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
}


const msp = (state) => ({
    reviewRequest: state.user.reviewRequest,
    activeProjectId: state.user.userIdentity.activeProjectId,
  });

export default connect(msp)(FinalizeMerge);
