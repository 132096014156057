import React from "react";
import { NodeHistory2 } from "./node-history";
import { PackageComboBox, PhenomInput, PhenomSelect, PhenomTextArea } from "../util/stateless";
import { isPhenomGuid, validateNodeFields } from "../util/util";
import PhenomId from "../../requests/phenom-id";
import { withPageLayout } from "./node-layout"
import ChangeSetPicker from "../widget/ChangeSetPicker";
import NodeDetails from "./node-details";



export class PackageManager extends React.Component {
    static defaultProps = {
      newNode: {
        name: "",
        xmiType: "datamodel:DataModel",
        description: "",
        parent: "root",
        subModelId: undefined,
      },
    }

    domRef = React.createRef()
    state = {}

    rootPackage = { guid: "root", name: "<Root>", xmiType: "skayl:Root" };
    additionalPackages = []
    packageOptions = []
    packageTypes = [
      { text: "Data Model",   xmiType: "datamodel:DataModel" },
      { text: "Conceptual",   xmiType: "face:ConceptualDataModel" },
      { text: "Logical",      xmiType: "face:LogicalDataModel" },
      { text: "Platform",     xmiType: "face:PlatformDataModel" },
      { text: "UoP",          xmiType: "face:UoPModel" },
      { text: "Integration",  xmiType: "skayl:IntegrationModel", },
      { text: "Deployment",   xmiType: "skayl:DeploymentModel", },
      { text: "Diagram",      xmiType: "skayl:DiagramModel", },
    ]

    // "required" checks for empty field as the input changes (user is typing)
    // "errorRef" checks for empty field after pressing the save button
    requiredFields = {
      name: {
        required: true,
        checkFirstChar: true,
        checkAllChars: true,
        errorRef: React.createRef(),
      },
      parent: {
        required: true,
        errorRef: React.createRef(),
      },
    }

    // ------------------------------------------------------------
    // Life Cycle Methods
    // ------------------------------------------------------------
    componentDidMount() {
      this.setState({ ...this.props.node }, () => {
        this.initParentNode();
      });
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.node !== this.props.node) {
        this.setState({ ...this.props.node }, () => {
          this.initParentNode();
        });

      } else if (prevProps.parentNode !== this.props.parentNode) {
        this.initParentNode();

      } else if (prevState.xmiType !== this.state.xmiType) {
        switch (this.state.xmiType) {
          case "datamodel:DataModel":
            this.packageOptions = [];
            this.additionalPackages = [];
            break;

          case "face:ConceptualDataModel":
          case "face:LogicalDataModel":
          case "face:PlatformDataModel":
            this.packageOptions = ["datamodel:DataModel", this.state.xmiType];
            this.additionalPackages = [];
            break;

          case "face:UoPModel":
          case "skayl:IntegrationModel":
          case "skayl:DeploymentModel":
            this.packageOptions = [this.state.xmiType];
            this.additionalPackages = [this.rootPackage]
            break;

          case "skayl:DiagramModel":
          case "skayl:ObjectModel":
            this.packageOptions = ["datamodel:DataModel", this.state.xmiType];
            this.additionalPackages = [this.rootPackage];
            break;


          default:
            this.packageOptions = [this.state.xmiType];
            this.additionalPackages = [];
        }

        this.forceUpdate();
      }
    }

    initNodeState = () => {
      // parentNode is found in NavTree's Package context menu - "Create Package"
      const { node, parentNode } = this.props;

      this.setState({
        ...node,
        xmiType: parentNode?.xmiType || node.xmiType,
        parent: parentNode?.guid || node.parent,
      })
    }

    initParentNode = () => {
      // parentNode is found in NavTree's Package context menu - "Create Package"
      const { parentNode } = this.props;

      // invalid if parentNode doesn't exist
      if (!parentNode?.guid) {
        return;
      }

      // NavTree's context menu is used to create a package within the selected Package.
      switch (parentNode.xmiType) {
        case "datamodel:DataModel":
          return this.setState({
            xmiType: "datamodel:DataModel",
            parent: "root",
          });

        default:
          this.setState({
            xmiType: parentNode.xmiType,
            parent: parentNode.guid,
          });
      }
    }

    getDomElement = () => {
      return this.domRef.current;
    }

    validateNode = () => {
      return validateNodeFields(this.requiredFields, this.state);
    }

    generateNode = () => {
      return {
        guid: this.state.guid,
        name: this.state.name,
        description: this.state.description,
        xmiType: this.state.xmiType,
        parent: this.state.parent,
        subModelId: this.state.subModelId,
      }
    }

    typeChange = (e) => {
      let parent = "";

      switch(e.target.value) {
        case "datamodel:DataModel":
        case "face:UoPModel":
        case "skayl:IntegrationModel":
        case "skayl:DeploymentModel":
        case "skayl:DiagramModel":
          parent = "root";
          break;

        default:
      }

      this.setState({ xmiType: e.target.value, parent });
    }

    render() {
      const { editable } = this.props;
      const original = this.props.node;
      const phenomId = new PhenomId("edit-package",this.props.idCtx);

      return <div className="edit-form" ref={this.domRef}>
          <div className="p-row">
            <div className="p-col">
              <PhenomInput label="Package"
                        id={phenomId.gen("details","name")}
                        value={this.state.name}
                        originalValue={original["name"]}
                        disabled={!editable}
                        autoFocus={true}
                        autcomplete="off"
                        onChange={(e) => this.setState({ name: e.target.value })}
                        onClickResetIcon={() => this.setState({ name: original["name"] })}
                        config={this.requiredFields["name"]} />

              <div className="p-row">
                <div className="p-col p-col-6">
                  <PhenomSelect label="Type"
                                value={this.state.xmiType}
                                data={this.packageTypes}
                                dataItemKey="xmiType"
                                textField="text"
                                disabled={!editable || !isPhenomGuid(this.state.guid)}
                                onChange={this.typeChange} />
                </div>
                <div className="p-col p-col-6">
                  {this.state.xmiType != "datamodel:DataModel" ?
                    <PackageComboBox label="Parent Package"
                                      xmiType={this.packageOptions}
                                      placeholder="<Select a Package>"
                                      nodeGuid={this.state.guid}
                                      selectedGuid={this.state.parent}
                                      originalGuid={original["parent"]}
                                      filterOutGuids={[this.state.guid]}
                                      disabled={!editable}
                                      showXmiType={true}
                                      additionalNodes={this.additionalPackages}
                                      onChange={(parent) => this.setState({ parent: parent.guid })}
                                      onClickResetIcon={() => this.setState({ parent: original["parent"] })}
                                      config={this.requiredFields["parent"]} />
                                :
                    <PhenomInput label="Parent Package"
                                id={phenomId.gen("details","parent_package")}
                                value={"<Root>"}
                                disabled={true}
                                autoFocus={true} />
                  }
                </div>
              </div>

              <PhenomTextArea label="Description"
                          value={this.state.description}
                          originalValue={original["description"]}
                          id={phenomId.gen("description")}
                          disabled={!editable}
                          onChange={(e) => this.setState({ description: e.target.value })}
                          onClickResetIcon={() => this.setState({ description: original["description"] })} />
            </div>

            <div className="edit-side">
              <NodeHistory2 guid={this.state.guid}
                            idCtx={phenomId.genPageId()}
                            ref={ele => this.historyRef = ele} />
              <NodeDetails guid={this.state.guid}/>
              {/* <ChangeSetPicker id={phenomId.genPageId()}
                               disabled={!editable}
                               label="Change Set" /> */}
            </div>
          </div>
      </div>
    }
}


export const EditPackageManager = withPageLayout(PackageManager);
