import React, { useEffect, useState } from "react";
import loadingIcon from "../../images/Palette Ring-1s-200px.gif";
import { cloneDeep } from 'lodash'
import { Notifications2 } from "./notifications";
import { NodeHistory2 } from "./node-history";
import { Button, Toolbar, ToolbarItem } from "@progress/kendo-react-buttons";
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { Checkbox } from "@progress/kendo-react-inputs";
import { deGuidify, retrieveNestedNodes, createPhenomGuid, isPhenomGuid, prepareNodesForSmmSave } from "../util/util";
import { withPageLayout } from "./node-layout";
import {  } from '../util/util'
import { CadetLink, BoundsPicker, PhenomLabel, PhenomInput, PhenomTextArea, PhenomToggle, PhenomComboBox, PackageComboBox, PhenomCollapsable, AnchoredPath } from "../util/stateless";
import { PhenomButtonLink, PhenomLink } from "../widget/PhenomLink";
import { Semantics } from "../dialog/semantics";
import DeletionConfirm2 from "../dialog/DeletionConfirm2";
import ReactTooltip from 'react-tooltip'
import styled from "@emotion/styled";
import PhenomId from "../../requests/phenom-id";
import { _ajax, smmSaveNodes, smmDeleteNodes } from "../../requests/sml-requests";
import { InlinePathBuilder, PrettyPath } from "../util/path";
import NavTree from "../tree/NavTree";
import { createNodeUrl } from "../../requests/type-to-path";
import * as actionCreators from "../../requests/actionCreators";
import { BasicConfirm } from "../dialog/BasicConfirm";
import NodeDetails from "./node-details";


export class ViewManager extends React.Component {
  static defaultProps = {
    newNode: {
      name: "",
      xmiType: "platform:View",
      description: "",
      parent: "",
      structureKind: "nesting",
      changedStructureKind: false,
      switch: "",
      projectedCharacteristic: null,
      path: "",
      pathPairs: [],
      children: [],
      faceVersion: "2.1",           // still needed?
      subModelId: undefined,
    },
    nodeAddenda: {
      coreAddenda: ["childrenMULTI", "typers", "projectedCharacteristic", "pathPairsMULTI"],
      coreAddendaChildren: ["viewType", "measurement", "projectedCharacteristic", "pathPairsMULTI", "platformType", "pathIsValid", "childrenMULTI", "unboundableField"],
      viewTypeAddenda: ["projectedCharacteristic", "pathPairsMULTI"],
      typersAddenda: ["parent", "projectedCharacteristic", "pathPairsMULTI"],
      platformTypeAddenda: ["childrenMULTI"],
    },
  }

  constructor(props) {
    super(props);
    this.phenomId = new PhenomId("edit-view-details", this.props.idCtx);
  }

  // ------------------------------------
  // State
  // ------------------------------------
  pathRef = React.createRef();
  charRefs = [];
  defaultState = {
    viewOptions: [],
    characteristics: [],
    usedByViews: [],
    showNestedViews: false,
    typerWithPath: null,

    useSwitch: false,
    selectedSwitch: null,                 // CharacteristicProjection or IntegralDiscriminator
    switchOptions: [],
    enumLiteralHash: {},                  // UnionCase have a "case" attribute and needs an instant look up
    filteredEnumLiterals: [],             // Chars can choose from this list of enum literals

    lockStructureKind: false,
    draggingChar: null,
    semanticCharGuid: null,

    codePreview: [],
    idlPreview: "",
  }
  state = {
    ...cloneDeep(this.defaultState),
    ...this.props.newNode,
  }


  // ------------------------------------
  // Life Cycle Methods
  // ------------------------------------
  componentDidMount() {
    this.initNodeState();
    ReactTooltip.rebuild();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.node !== this.props.node) {
      this.initNodeState();

    } else if (prevState.structureKind !== this.state.structureKind) {
      this.getViewsForNesting();

    } else if (prevState.selectedSwitch !== this.state.selectedSwitch) {
      setTimeout(() => {
        this.resetEnumerationLiteralOptions();
      }, 0);
    }

    if (prevState.characteristics !== this.state.characteristics) {
      setTimeout(() => {
        this.setLockStructureKind();
        ReactTooltip.rebuild()
      }, 0);
    }
  }

  // ------------------------------------
  // Initial Setup
  // ------------------------------------
  initNodeState = () => {
    const { node } = this.props;
    if (!node?.guid) {
      return;
    }
    
    // filter -> just in case a new Constraint/Node is added to View's children
    // c?.xmiType -> just in case the addenda is changed and View's children is changed to an array of strings
    const characteristics = node.children.filter(c => c?.xmiType === "platform:CharacteristicProjection");
    const switchOptions = characteristics.filter(c => c.platformType?.xmiType === "platform:Enumeration");
    const selectedSwitch = switchOptions.find(c => c.guid === node.switch);   // "switch" is a reserved word in JS

    let typerWithPath = null;
    let usedByViews = [];
    if (Array.isArray(node.typers)) {
      usedByViews = node.typers.map(c => c.parent);
      typerWithPath = node.typers.find(c => c.attributeKind === "privatelyScoped" && c.projectedCharacteristic && c.path);
    }

    this.setState({
      ...cloneDeep(this.defaultState),
      ...node,
      characteristics,
      children: [],
      usedByViews,
      typerWithPath,
      changedStructureKind: false,

      selectedSwitch,
      useSwitch: !!selectedSwitch || (node.structureKind === "composite" && !!node.switch),
      switchOptions,
      pathPairs: node.pathPairs || [],
    }, () => {
      this.getViewsForNesting();
      
      if (this.pathRef.current?.deactivateEditMode) {
        this.pathRef.current.deactivateEditMode();
      }
    });
  }

  // ------------------------------------
  // Getters
  // ------------------------------------
  loadCodePreview = () => {
    this.setState({ codePreview: [] }, () => {
      if (isPhenomGuid(this.state.guid)) {
        return;
      }

      _ajax({
        url: "/index.php?r=/node/get-node-preview",
        method: "get",
        data: {
            type: "viewQuery",
            guid: this.state.guid,
        }
      }).then((response) => {

        if ("error" in response || "errors" in response) {
            this.setState({ codePreview: "" });
            return Notifications2.parseErrors("Something went wrong. Failed to retrieve code preview.");
        }

        const templates = response.data?.templates || [];
        const queries = response.data?.queries || [];
        const reg = `^Query_${this.state.name}_`;
        const regex = new RegExp(reg);

        queries.forEach(query => {
          // adjust query name - only show the projectedChar name
          query.name = query.name.replace(regex, "");

          const template = templates.find(template => template.boundQuery === query.guid);
          if (template) query.template = template;
        })
        // used by code preview component, empty string means no data
        this.setState({ codePreview: queries.length ? queries : "" });
      })
    })
  }

  loadIdlPreview = () => {
    this.setState({ idlPreview: [] }, () => {
      if (isPhenomGuid(this.state.guid)) {
        return;
      }

      _ajax({
        url: "/index.php?r=/node/get-idl-preview",
        method: "get",
        data: {
            guid: this.state.guid,
        }
      }).then((response) => {
        if ("error" in response || "errors" in response) {
          this.setState({ idlPreview: [] });
          return Notifications2.parseErrors("Something went wrong. Failed to retrieve idl preview.");
        }
        const idlPreview = Object.values(response.data || {});
        // used by idl preview component, empty string means no data
        this.setState({ idlPreview: idlPreview.length ? idlPreview : "" });
      })
    })
  }

  getViewsForNesting() {
    if (isPhenomGuid(this.state.guid)) {
      return;
    }

    _ajax({
      url: "/index.php?r=/node/model-nodes-of-type",
      method: "get",
      data: {
        type: "platform:View",
        coreAddenda: ["childrenMULTI", "projectedCharacteristic", "pathPairsMULTI"],
        coreAddendaChildren: ["projectedCharacteristic"],
        Filter: {
          firstPass: {
            event: this.state.structureKind === "composite" ? "topLevelViewsWithComposite" : "inScopeViewTypes",
            guid: this.state.guid,
          }
        }
      },
    }).then((response) => {
      if (!Array.isArray(response.data.nodes)) {
        return;
      }

      this.setState({ 
        viewOptions: response.data.nodes.filter((view) => view.guid !== this.state.guid)
      });
    })
  }


  // ------------------------------------
  // Setters
  // ------------------------------------
  onDragStart = (draggingChar) => {
    this.setState({ draggingChar })
  }

  onDragOver = (e, charGuid) => {
    e.preventDefault();
    e.stopPropagation();
    this.reorder(charGuid);
  }

  reorder = (charGuid) => {
    const { draggingChar } = this.state;
    if (!draggingChar || draggingChar.guid === charGuid) return;

    const characteristics = [...this.state.characteristics];
    let prevIndex = characteristics.findIndex(c => c.guid === draggingChar.guid);
    let nextIndex = characteristics.findIndex(c => c.guid === charGuid);
    characteristics.splice(prevIndex, 1);
    characteristics.splice(nextIndex, 0, draggingChar);

    this.setState({ characteristics })
  }

  setSemanticCharGuid = (charGuid) => {
    if (isPhenomGuid(charGuid)) {
      return;
    }

    this.setState({ semanticCharGuid: charGuid });
  }

  resetEnumerationLiteralOptions = () => {
    const { selectedSwitch } = this.state;

    const list_of_char_children = this.charRefs.filter(cr => !!cr).map(cr => cr.getChildren());

    // need to copy platformType.children because it will be spliced/mutated
    let filteredEnumLiterals = [];
    if (selectedSwitch?.platformType?.children) {
      filteredEnumLiterals = [...selectedSwitch.platformType.children];
    }

    const enumLiteralHash = deGuidify(filteredEnumLiterals);

    // down select the available filteredEnumLiterals
    for (let char_children of list_of_char_children) {
      for (let union of char_children) {
        const idx = filteredEnumLiterals.findIndex(enumLit => enumLit.guid === union.case);
        idx > -1 && filteredEnumLiterals.splice(idx, 1);
      }
    }

    this.setState({ 
      filteredEnumLiterals: filteredEnumLiterals.sort((n1, n2) => n1.name.localeCompare(n2.name)), 
      enumLiteralHash
    });
  }

  addToEnumerationLiteralOptions = (caseGuid) => {
    const literal = this.state.enumLiteralHash[caseGuid];
    if (!literal) return;
    const filteredEnumLiterals = [ ...this.state.filteredEnumLiterals, literal ];

    this.setState({ 
      filteredEnumLiterals: filteredEnumLiterals.sort((n1, n2) => n1.name.localeCompare(n2.name))
    });
  }

  removeFromEnumerationLiteralOptions = (caseGuid) => {
    const filteredEnumLiterals = [...this.state.filteredEnumLiterals];
    const idx = filteredEnumLiterals.findIndex(lit => lit.guid === caseGuid);
    idx > -1 && filteredEnumLiterals.splice(idx, 1);
    this.setState({ filteredEnumLiterals });
  }

  setLockStructureKind = () => {
    const { usedByViews } = this.state;

    // filter out undefined
    const char_viewtypes = this.charRefs.filter(cr => !!cr).map(cr => cr.getViewType());

    // Nesting Views can have normal chars
    // Composite Views cannot have normal chars
    const hasNormalChar = char_viewtypes.some(viewType => !viewType);
    if (hasNormalChar) {
      // LOCK AS NESTING
      return this.setState({ structureKind: "nesting", lockStructureKind: true });
    }

    // Composite Views cannot be nested by Nesting Views
    const usedByNestingView = usedByViews.some(view => view.structureKind === "nesting");
    if (usedByNestingView) {
      // LOCK AS NESTING
      return this.setState({ structureKind: "nesting", lockStructureKind: true });
    }

    // Nesting Views cannot nest a Composite View (one of its fields cannot point to a Composite View)
    const hasCompositeView = char_viewtypes.some(viewType => !!viewType?.guid && viewType.structureKind === "composite");
    if (hasCompositeView) {
      // LOCK AS COMPOSITE
      return this.setState({ structureKind: "composite", lockStructureKind: true });
    }

    return this.setState({ lockStructureKind: false });
  }

  addNewNestedChar = () => {
    const newChar = {
      guid: createPhenomGuid(),
      rolename: "",
      xmiType: "platform:CharacteristicProjection",
      descriptionExtension: "",
      projectedCharacteristic: null,
      lowerBound: null,
      upperBound: null,
      path: "",
      pathPairs: [],
      platformType: null,
      measurement: null,
      viewType: {},
      attributeKind: "foreignReference",
      optional: "false",
      parent: this.state.guid,
    }
    
    const characteristics = [ ...this.state.characteristics, newChar ];
    this.setState({ characteristics });
  }

  deleteChar = (charGuid) => {
    const characteristics = [...this.state.characteristics];
    const idx = characteristics.findIndex(c => c.guid === charGuid);
    if (idx < 0) return;

    if (isPhenomGuid(charGuid)) {
      characteristics.splice(idx, 1);
      return this.setState({ characteristics }, () => {
        setTimeout(() => {
          this.resetEnumerationLiteralOptions();
        }, 0);
      });
    }
    
    const char = characteristics[idx];
    DeletionConfirm2.show(char.guid, char.rolename, (status) => {
      if (!status.deleted) return;
      characteristics.splice(idx, 1);
      return this.setState({ characteristics }, () => {
        setTimeout(() => {
          this.resetEnumerationLiteralOptions();
        }, 0);
      });
    }, true);
  }

  // ------------------------------------
  // Used by Template
  // ------------------------------------

  // TEMP - needed for BasicConfirm to warn users about reverting their composite union view which may only be maintained not edited
  // remove when composite union view editing is implemeted
  handleSave = () => {
    const saveFunction = async () => { 
      try {
        const requestData = this.generateNode();
        if (!requestData?.guid) {
          return;
        }

        let guidsToBeDeleted = [];
        if (this.getGuidsToBeDeleted) {
          guidsToBeDeleted = this.getGuidsToBeDeleted();
        }

        if (guidsToBeDeleted.length) {
          const deletionStatus = await smmDeleteNodes(guidsToBeDeleted);

          if (deletionStatus.errors || deletionStatus.error) {
            return actionCreators.receiveErrors("Something went wrong.");
          }
        }

        const nodes = prepareNodesForSmmSave([requestData], this.props.subModels);

        if (!nodes.length) {
          return actionCreators.receiveErrors("Something went wrong. Cancelled the save request.");
        }

        return smmSaveNodes({
          nodes,
          changeSetId: actionCreators.getActiveChangeSetId(),
          returnTypes: [requestData.xmiType],
          returnAddenda: {
            [requestData.xmiType]: this.props?.nodeAddenda,
          }
        }).then((res) => {
          const response = res.data;
          actionCreators.receiveResponse(response);

          if (response.nodes?.length) {
            NavTree.addNodes(response.nodes);
            const node = response.nodes[0];

            if (this.props.history.location.pathname.endsWith('new')) {
              this.props.history.push( createNodeUrl(node) );

              this.props.updateTemplateNode(node).then(this.initNodeState)
            } else {
              this.props.updateTemplateNode(node).then(this.initNodeState)
            }
          }
        })

      } catch (error) {
        return actionCreators.receiveErrors("An unexpected error occurred. Please try again or contact Skayl Support for help.");
      }
    }

    // save handling - special case for maintaining composite union views
    const { structureKind, useSwitch } = this.state;
    if (structureKind === "composite" && useSwitch) {
      return BasicConfirm.show(
        "Currently, Phenom does not support editing Composite Unions. Therefore, the Composite View will no longer be a Union.\n\nWould you like to proceed?",
        saveFunction,
        () => null,
      )
    } else {
      return saveFunction();
    }
  }

  generateNode = () => {
    const { structureKind, useSwitch } = this.state;
    // filter out undefined
    const char_refs = this.charRefs.filter(cr => !!cr);
    const view_children = [];
    
    // setup the Chars
    char_refs.filter(cr => cr.isEdited())
             .map(cr => cr.generateNode()).forEach(char => {
      const copy = cloneDeep(char);
      delete copy["pathPairs"];     // backend tries to save too many nodes

      const queue = retrieveNestedNodes([ copy ]);

      // clean up "children" - backend throws a temper tantrum when it sees a children array of strings/guids
      // entities from pathPairs can have array of children strings
      for (let node of queue) {
        if (!Array.isArray(node.children)) {
          continue;
        }

        if (node.children.some(child => typeof child === 'string')) {
          node.children = [];
        }
      }

      view_children.push(copy);
    })

    // setup the View
    const view = {
      guid: this.state.guid,
      childOrder: char_refs.map(cr => cr.getGuid()),
    };

    for (let key of Object.keys(this.props.newNode)) {
      view[key] = this.state[key];
    }
    view.switch = this.state.selectedSwitch?.guid;
    view.children = view_children;
    view.path = this.state.path || undefined;

    // Union is toggled off, or is a composite union
    if (!useSwitch || (structureKind === "composite" && useSwitch)) {
      view.switch = "";
    }

    if (structureKind === "composite") {
      view["path"] = undefined;
      view["projectedCharacteristic"] = undefined;
    }

    return view;
  }
  
  getGuidsToBeDeleted = () => {
    const { useSwitch } = this.state;
    let guids = [];

    this.charRefs.filter(cr => !!cr).forEach(cr => {
      guids = guids.concat(cr.getGuidsToBeDeleted());

      // Switch is toggled off - delete SkaylUnion cases
      if (!useSwitch) {
        cr.getChildren().filter(child => child.xmiType === "skayl:UnionCase")
                        .forEach(child => !isPhenomGuid(child.guid) && guids.push(child.guid));
      }
    });

    return guids;
  }
  
  // ------------------------------------
  // Render
  // ------------------------------------
  CustomNestedViewCell = (props) => {
    return <td>
      <CadetLink node={props.dataItem} />
    </td>
  }

  renderPathSection = () => {
    const { editable } = this.props;
    const { guid, structureKind, pathPairs=[], projectedCharacteristic, typerWithPath } = this.state;

    if (structureKind !== "nesting" || isPhenomGuid(guid)) {
      return null;
    }

    if (typerWithPath) {
      return <div>
        <PhenomLabel text="View Perspective" />
        <div style={{ display: "flex", gap: 5 }}>
          <i class="fa-solid fa-circle-info"
             title={`Declared by ${typerWithPath.parent?.name}`} />
          <PrettyPath 
              projectedEntity={typerWithPath.projectedCharacteristic}
              pathPairs={typerWithPath.pathPairs} />
        </div>
      </div>
    }

    const original = this.props.node;

    return <div>
      <PhenomLabel text="View Perspective" />
      <InlinePathBuilder 
          pathPairs={pathPairs || []}
          projectedEntity={projectedCharacteristic}
          editable={editable}
          forward
          doNotEndOnObservable
          ref={this.pathRef}
          onPathSet={(pc, pairs) => {
            this.setState({
              projectedCharacteristic: pc,
              pathPairs: pairs,
              path: pairs.map(p => p.guid).join(" "),
            })
          }}
          onReset={() => {
            this.setState({ 
              projectedCharacteristic: original["projectedCharacteristic"],
              path: original["path"] || "",
              pathPairs: original["pathPairs"] || [],
            })
          }} />
    </div>
  }

  render() {
    const { editable } = this.props;
    const { structureKind, changedStructureKind, useSwitch, switchOptions, selectedSwitch, lockStructureKind, idlPreview, codePreview, usedByViews, showNestedViews, characteristics} = this.state;
    const original = this.props.node;
    const isNewPage = isPhenomGuid(this.state.guid);
    const isComposite = structureKind === "composite";
    const title = isComposite ? "Composite View" : "Nesting View";

      return <div className="edit-form" ref={this.domRef}>
              <div className="p-row">
                <div className="p-col">
                  <div className="p-row">
                    <div className="p-col p-col-6">
                      <PhenomInput id={this.phenomId.genPageId("name")}
                                  label={title}
                                  value={this.state.name}
                                  originalValue={original["name"]}
                                  disabled={!editable}
                                  autoFocus={true}
                                  onChange={(e) => this.setState({ name: e.target.value })}
                                  onClickResetIcon={() => this.setState({ name: original["name"] })}
                                  config={{
                                    required: true,
                                    checkFirstChar: true,
                                    checkAllChars: true,
                                  }} />
                    </div>
                    <div className="p-col p-col-6">
                      <PackageComboBox label="Package"
                                       xmiType={["face:PlatformDataModel", "face:UoPModel"]}
                                       nodeGuid={this.state.guid}
                                       selectedGuid={this.state.parent}
                                       originalGuid={original["parent"]}
                                       onChange={(p) => this.setState({ parent: p?.guid })}
                                       onClickResetIcon={() => this.setState({ parent: original["parent"] })}
                                       config={{
                                        required: true,
                                      }} />
                    </div>
                  </div>
                
                  <div className="p-row">
                    <div className="p-col p-col-6">
                      <div>
                        <PhenomLabel text="Template Type" />
                        <PhenomToggle checked={isComposite}
                                      data={["Nesting", "Composite"]}
                                      disabled={lockStructureKind || !editable}
                                      onChange={() => this.setState({
                                                        structureKind: isComposite ? "nesting" : "composite",
                                                        changedStructureKind: !changedStructureKind,
                                                      })} />
                      </div>
                    </div>

                    <div className="p-col p-col-6">
                      <div>
                          <PhenomLabel text="Union" />
                          <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                            <div>
                              <PhenomToggle checked={useSwitch}
                                            data={["No", "Yes"]}
                                            disabled={!usedByViews.length || !switchOptions.length || !editable}      // if usedByViews is empty then the View is a Top Level View. 
                                            onChange={(e) => this.setState({ useSwitch: e.target.checked })} />
                            </div>

                            {useSwitch && structureKind !== "composite" && <>
                              <span style={{ alignSelf: "center", fontSize: 14, paddingLeft: 5 }}>Switch Over:</span>
                              <PhenomComboBox 
                                    data={this.state.switchOptions}
                                    value={selectedSwitch}
                                    dataItemKey="guid"
                                    textField="rolename"
                                    placeholder="Select a Enumerated Attribute"
                                    onChange={(char) => this.setState({ selectedSwitch: char })} />
                            </>}
                            <span className="fas fa-info-circle"
                                  style={{margin: "2px 5px 0 0"}}
                                  data-tip=
                                  {structureKind === 'nesting' 
                                    ? "To be convertible to a Union, a View must:<br/><br/> 1. Be a Privately Nested Attribute in at least one other view.<br/> 2. Have at least one attribute which references an Enumeration via its Platform Type property.<br/> 3. Have all of its attributes’ paths start at the same Entity or Association.<br/><br/> To learn more about creating and editing Union Views, and FACE requirements for unions, refer to the ‘Union’ page in the KnowledgeBase, accessible by clicking the '?' button at the top right of the page."
                                    : "Creation of Composite Union Views is not currently supported in Phenom.<br/><br/> Phenom can maintain Composite Union Views that are imported. However, if you edit a Composite Union View, all Union related attributes will be deleted."
                                  }
                                  data-for="hoverTip"
                                  data-html={true}
                                  data-place="right"/>
                          </div>
                      </div>
                    </div>
                  </div>

                  <PhenomTextArea id={this.phenomId.genPageId("description")}
                                  label="Description"
                                  value={this.state.description}
                                  originalValue={original["description"]}
                                  disabled={!editable}
                                  onChange={(e) => this.setState({ description: e.target.value })}
                                  onClickResetIcon={() => this.setState({ description: original["description"] })} />
                </div>

                <div className="edit-side">
                  <NodeHistory2 guid={this.state.guid} 
                                ref={ele => this.historyRef = ele} />
                  <NodeDetails guid={this.state.guid}/>
                </div>
              </div>

              { this.renderPathSection() }

              <div>
                <PhenomLabel text="Attributes" />
                {this.state.characteristics.map((char, idx) => {
                  return <InlineCharacteristic key={char.guid}
                                               node={char}
                                               switchGuid={selectedSwitch?.guid}
                                               originalSwitchGuid={original["switch"]}
                                               viewOptions={this.state.viewOptions}
                                               enumLiteralHash={this.state.enumLiteralHash}
                                               filteredEnumLiterals={this.state.filteredEnumLiterals}
                                               isComposite={isComposite}
                                               changedStructureKind={changedStructureKind}
                                               index={idx}
                                               useSwitch={useSwitch}
                                               editable={editable}
                                               deleteChar={this.deleteChar}
                                               setSemanticCharGuid={this.setSemanticCharGuid}
                                               addToEnumerationLiteralOptions={this.addToEnumerationLiteralOptions}
                                               removeFromEnumerationLiteralOptions={this.removeFromEnumerationLiteralOptions}
                                               setParentLockStructureKind={this.setLockStructureKind}
                                               onDragStart={() => this.onDragStart(char)}
                                               onDragOver={(e) => this.onDragOver(e, char.guid)}
                                               parent={char.parent}
                                               parentCompositeUnion={(structureKind === "composite" && useSwitch)} 
                                               ref={el => this.charRefs[idx] = el} />
                })}

                {editable && <Toolbar>
                  <ToolbarItem>
                    <Button icon="add"
                            className="k-button"
                            id={"add-attribute-btn"}
                            disabled={isComposite || isNewPage || !editable}
                            onClick={() => {
                                this.props.history.push(`/edit/details/characteristic/new/${this.state.guid}/${this.state.name}/`);
                            }}>Create Attribute</Button>

                    <Button icon="add"
                            disabled={isNewPage || !editable}
                            style={{marginLeft:10}}
                            onClick={this.addNewNestedChar}
                            id={"add-nested-attribute-btn"}
                            data-tip
                            data-for="nestingInfo">
                                Add Nested Attribute</Button>

                    <ReactTooltip id='nestingInfo'>
                        <span>A new row will appear at the bottom of the list</span>
                    </ReactTooltip>
                  </ToolbarItem>
                </Toolbar>}
              </div>

              {!isPhenomGuid(this.state.guid) &&
                <ViewCodePreview data={codePreview} 
                                  onLoadData={this.loadCodePreview}
                                  characteristics={characteristics}/>
              }

              {!isPhenomGuid(this.state.guid) &&
                  <IdlCodePreview data={idlPreview}
                                    onLoadData={this.loadIdlPreview} 
                                    characteristics={characteristics}/>
              }

              {!!usedByViews.length && !isPhenomGuid(this.state.guid) &&
                <div>
                  <PhenomCollapsable label={`Nested in ${usedByViews.length === 1 ? "1 View" : usedByViews.length + " Views"}`}
                                     onClick={() => this.setState({ showNestedViews: !showNestedViews})}>
                    <Grid data={usedByViews}
                          className="editorTable">
                            <GridNoRecords>
                                No Data Is Available For This Table
                            </GridNoRecords>
                            <Column title="NAME" field="name" cell={this.CustomNestedViewCell} />
                            <Column title="DESCRIPTION" field="description"  />
                    </Grid>
                  </PhenomCollapsable>
                </div>}

              {!!this.state.semanticCharGuid &&
                <Semantics guid={this.state.semanticCharGuid} 
                           close={() => this.setSemanticCharGuid(null)} />}
      </div>
  }
}


const StyledCharacteristic = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background: ${(p) => p.edited ? "hsl(var(--skayl-sky-hs) 86%)" :
                       p.index % 2 === 0 ? "#f5f5f5" : null};
  overflow: hidden;

  .char-row {
    display: flex;
    gap: 5px;

    & > div {
      padding: 5px;
    }

    .char-col {
      display: flex;
      gap: 5px;

      & > div {
        padding: 0 5px;
      }
    }

    .size-1, .size-2, size-3 { 
      overflow: hidden; 
    }
    .size-1 { flex: 1; }
    .size-2 { flex: 2; }
    .size-3 { flex: 3; }
  }

  label {
    font-size: 14px;
  }

  .union_case {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    gap: 5px;
    padding: 2px 10px;
    background: lightgray;
    border-radius: 30px;
    cursor: pointer;

    &:hover {
      background: var(--bs-danger);
    }
  }
`


class InlineCharacteristic extends React.Component {
  domRef = React.createRef();
  pathRef = React.createRef();
  newNode = {
    rolename: "",
    xmiType: "platform:CharacteristicProjection",
    descriptionExtension: "",
    projectedCharacteristic: null,
    lowerBound: null,
    upperBound: null,
    path: "",
    pathPairs: [],
    platformType: null,
    measurement: null,
    viewType: null,
    attributeKind: "privatelyScoped",
    optional: false,
    unboundableField: false,
    children: [],
  }

  phenomId = new PhenomId("inline-char-details-" + this.props.index);

  defaultState = {
    collapsed: true,
    guidsToBeDeleted: [],
    editableBounds: true,
  }

  state = {
    ...cloneDeep(this.defaultState),
    ...cloneDeep(this.newNode),
  }

  componentDidMount() {
    this.initNodeState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.node !== this.props.node) {
      this.initNodeState();

    } else if (prevProps.switchGuid !== this.props.switchGuid) {

      // User changed Union/Switch back to the original
      if (this.props.switchGuid === this.props.originalSwitchGuid) {
        this.resetUnionCases();
      } else {
        this.clearUnionCases();
      }
    }
  }

  // ------------------------------------
  // Initial Setup
  // ------------------------------------
  initNodeState = () => {
    const { node } = this.props;
    
    this.setState({ 
      ...cloneDeep(this.defaultState),
      ...cloneDeep(node),
      path: node.path || "",
      pathPairs: node.pathPairs || [],
      optional: node.optional === "true",                       // default is a string (i.e. "true" or "false") :(
      collapsed: !isPhenomGuid(node.guid),
    }, () => {
      if (this.pathRef.current?.deactivateEditMode) {
        this.pathRef.current.deactivateEditMode();
      }
    });
  }

  // ------------------------------------
  // Getters
  // ------------------------------------
  isEdited = () => {
    if (isPhenomGuid(this.state.guid)) {
      return true;
    }

    if (this.state.guidsToBeDeleted.length) {
      return true;
    }

    if (this.state.children.find(c => isPhenomGuid(c.guid))) {
      return true;
    }

    if (this.props.changedStructureKind) {
      return true;
    }

    const original = this.props.node;

    for (let key of Object.keys(this.newNode)) {
      if (["guid", "xmiType", "parent", "children", "unboundableField"].includes(key)) {
        continue;
      }

      let edited = false;
      const currentAttr = this.state[key];
      const originalAttr = original[key];

      if (typeof currentAttr === 'string') {
        // originalAttr can be null -> consider it as empty string
        edited = currentAttr !== (originalAttr || "");

      } else if (typeof currentAttr === 'boolean') {
        // originalAttr can be null, "true", or "false" (strings) -> yup
        edited = currentAttr !== (originalAttr === "true");

      } else if (typeof currentAttr === 'number') {
        // originalAttr can be null -> consider it as 0
        edited = currentAttr !== (originalAttr || 0);

      } else if (Array.isArray(currentAttr) && Array.isArray(originalAttr)) {
        // compare array of nodes
        edited = originalAttr.some((_, i) => originalAttr[i]?.guid !== currentAttr[i]?.guid)

      } else if (currentAttr === null) {
        edited = typeof originalAttr === 'string';

      } else {
        // compare two nodes
        edited = currentAttr?.guid !== originalAttr?.guid;
      }

      // short circuit -> exit early
      if (edited) {
        return true;
      }
    }
    
    return false;
  }

  // ------------------------------------
  // Setters
  // ------------------------------------
  clearUnionCases = () => {
    const guidsToBeDeleted = [...this.state.guidsToBeDeleted];

    this.state.children.forEach(c => {
      if (isPhenomGuid(c.guid)) return;
      if (c.xmiType === "skayl:UnionCase") {
        guidsToBeDeleted.push(c.guid);
      }
    });

    this.setState({ guidsToBeDeleted, children: [] });
  }

  // User changed Union/Switch back to the original
  resetUnionCases = () => {
    const { node } = this.props;

    // invalid
    if (!this.props.originalSwitchGuid) {
      return;
    }

    this.setState({
      children: cloneDeep(node.children),     // restore original children
      guidsToBeDeleted: [],                   // reset guidsToBeDeleted
    })
  }

  setBound = (lower, upper, lowerSelector, upperSelector) => {
    this.setState({
      [lowerSelector]: lower, 
      [upperSelector]: upper
    });
  }

  onDragStart = (e) => {
    e.dataTransfer.setDragImage(this.domRef.current, 0, 35);
    this.props.onDragStart();
  }

  setPath = (projectedCharacteristic, pathPairs) => {
    this.setState({ 
        projectedCharacteristic, 
        path: pathPairs.map(pathPair => pathPair.guid).join(" "), 
        pathPairs,
        lowerBound: this.state.lowerBound,
        upperBound: this.state.upperBound,
    })
  }

  addNewUnionCase = (caseGuid) => {
    const { removeFromEnumerationLiteralOptions } = this.props;
    const newUnionCase = {
      guid: createPhenomGuid(),
      xmiType: "skayl:UnionCase",
      case: caseGuid,
    }

    const children = [...this.state.children, newUnionCase];
    this.setState({ children }, () => {
      removeFromEnumerationLiteralOptions(caseGuid);
    })
  }

  removeUnionCase = (unionCase) => {
    const { addToEnumerationLiteralOptions } = this.props;

    const children = [...this.state.children]
    const idx = children.findIndex(c => c.guid === unionCase.guid);
    if (idx < 0) {
      return;
    }
    children.splice(idx, 1);

    const guidsToBeDeleted = [...this.state.guidsToBeDeleted];
    if (!isPhenomGuid(unionCase.guid)) {
      guidsToBeDeleted.push(unionCase.guid);
    }

    this.setState({ children, guidsToBeDeleted }, () => {
      addToEnumerationLiteralOptions(unionCase.case);
    })
  }

  // set from assoc-path-hops
  setUnboundableField = (bool) => {
    this.setState({unboundableField: bool});
  }

  // ------------------------------------
  // Used by Template/Parent
  // ------------------------------------
  getGuidsToBeDeleted = () => {
    return this.state.guidsToBeDeleted;
  }

  getGuid = () => {
    return this.state.guid;
  }

  getViewType = () => {
    return this.state.viewType;
  }

  getChildren = () => {
    return this.state.children;
  }

  generateNode = () => {
    const { useSwitch, isComposite } = this.props;
    const data = {
      guid: this.state.guid,
    }

    Object.keys(this.newNode).forEach(key => data[key] = this.state[key]);

    // convert to guids
    data["measurement"] = this.state.measurement?.guid;
    data["projectedCharacteristic"] = this.state.projectedCharacteristic?.guid;
    data["platformType"] = this.state.platformType?.guid;
    
    // convert boolean back to string :(
    data["optional"] = this.state.optional ? "true" : "false";

    if (isComposite) {
      // default settings for composite Char
      data["path"] = "";
      data["attributeKind"] = "none";
      data["projectedCharacteristic"] = undefined;
      data["lowerBound"] = undefined;
      data["upperBound"] = undefined;
      data["optional"] = "false";       // smm says this attribute is required

    } else if (data["attributeKind"] === "none") {
      // default settings for nesting Char
      data["attributeKind"] = "foreignReference"
    }

    // convert node to guid
    if (data.viewType?.guid) {
      data["viewType"] = data.viewType.guid
    }

    // Switch is toggled off - do not include any SkaylUnions for Save
    if (!useSwitch) {
      data.children = [];
    }
    
    return data;
  }

  getPerspectiveType = () => {
    const { attributeKind, projectedCharacteristic, pathPairs=[] } = this.state;

    switch (attributeKind) {
      // composite char
      case "none":
        return undefined;
        
      case "foreignReference":
        if (!pathPairs.length) {
          return projectedCharacteristic?.guid;
        } else {
          const lastHop = pathPairs[pathPairs.length - 1];
          return lastHop.goingUp ? lastHop.parent?.guid : lastHop.type?.guid;
        }

      case "privatelyScoped":
      default:  
        return projectedCharacteristic?.guid;
    }
  }

  getDangerText = () => {
    const { projectedCharacteristic, attributeKind, pathPairs, viewType, unboundableField, upperBound, lowerBound } = this.state;
    const msgs = [];

    if (unboundableField && (upperBound || lowerBound)) {
      msgs.push("This field cannot be bound");
    }

    const nestedProjChar = viewType?.projectedCharacteristic;
    if (attributeKind === "privatelyScoped" && !!nestedProjChar && !!projectedCharacteristic?.guid) {
      msgs.push("This field and its nesting View both a perspective - it should be one or the other.");
    }

    return msgs;
  }


  // ------------------------------------
  // Render
  // ------------------------------------
  renderHandleBarCell = () => {
    const { editable } = this.props;
    const { collapsed } = this.state;

    const dangers = this.getDangerText();

    return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 10, fontSize: 14 }}>
      <span className='fas fa-grip-dots-vertical'
            draggable={editable}
            style={{ cursor: "grab", fontSize: "15px" }}
            onDragStart={this.onDragStart} />

      <span className={collapsed ? "fas fa-chevron-right" : "fas fa-chevron-down"}
            style={{ cursor: "pointer" }}
            id={this.phenomId.genPageId("chevron")}
            onClick={() => this.setState({ collapsed: !collapsed })} />

      {!!dangers.length &&
        <span className="fa-solid fa-triangle-exclamation" 
              style={{color: "#a94442"}}
              title={dangers.join("\n")} /> }

    </div>
  }

  renderNameCell = () => {
    const { editable } = this.props;
    const { guid, viewType } = this.state;
    const isNewChar = isPhenomGuid(guid);
    const isNestedChar = !!viewType;

    return <div className="size-1">
      <label>
        Name {isNestedChar && " (Nested)"}
      </label>
      <PhenomInput value={this.state.rolename}
                    disabled={!editable}
                    onChange={(e) => this.setState({ rolename: e.target.value })}
                    id={this.phenomId.genPageId("name")}
                    config={{
                      required: true,
                      checkFirstChar: true,
                      checkAllChars: true,
                    }}>
        {!isNewChar && !isNestedChar && <div style={{ alignSelf: "center", padding: "0 5px" }}>
          <PhenomButtonLink node={this.props.node} /> 
        </div> }
      </PhenomInput>
    </div>
  }

  renderProjectedCell = () => {
    const { viewOptions=[], editable } = this.props;
    const { viewType, measurement } = this.state;

    if (viewType) {
        return <div className="size-1">
          <label>View</label>
          <PhenomComboBox data={viewOptions}
                          id={this.phenomId.genPageId("view")}
                          value={viewType}
                          dataItemKey="guid"
                          textField="name"
                          disabled={!editable}
                          onChange={(view) => {
                            this.setState({ 
                              viewType: view,
                              projectedCharacteristic: null,
                              pathPairs: [],
                              path: "", 
                            }, () => {
                            setTimeout(() => {
                              this.props.setParentLockStructureKind();
                            }, 0);
                          })}}>

            {!!viewType?.guid && <div style={{ alignSelf: "center", padding: "0 5px" }}>
              <PhenomButtonLink node={viewType} /> 
            </div> }
          </PhenomComboBox>
        </div>
    }

    return <div className="size-1">
      <label>Measurement</label>
      <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
        {!!measurement?.guid && <PhenomLink node={measurement} />}
      </div>
    </div>
  }

  renderPathCell = () => {
    const { isComposite, editable } = this.props;
    const { guid, viewType, projectedCharacteristic, pathPairs, pathIsValid, parent, attributeKind } = this.state;
    const isNestedView = !!viewType;
    const title = isNestedView ? "Perspective" : "Path";
    const original = this.props.node;

    if (isComposite) {
      return null;
    }

    const declaredByViewType = attributeKind === "privatelyScoped" && !!viewType?.guid && !!viewType.projectedCharacteristic;

    return <div className="size-2">
      <label>{title}</label>
      <div>
        {declaredByViewType
          ? <div style={{ display: "flex", fontSize: 14, gap: 5 }}>
              <i class="fa-solid fa-circle-info"
                 title={`Declared by ${viewType?.name}`} />
              <PrettyPath 
                    projectedEntity={viewType.projectedCharacteristic}
                    pathPairs={viewType.pathPairs} />
            </div>

        : isNestedView 
          ? <InlinePathBuilder 
                pathPairs={pathPairs || []}
                projectedEntity={projectedCharacteristic}
                editable={editable}
                forward 
                doNotEndOnObservable
                // char is used to check if bounds are editable
                char={{
                  guid,
                  viewType,
                  attributeKind,
                  parent,
                }}
                ref={this.pathRef}
                onPathSet={this.setPath}
                onReset={() => {
                  this.setState({ 
                    projectedCharacteristic: original["projectedCharacteristic"],
                    path: original["path"] || "",
                    pathPairs: original["pathPairs"] || [],
                  })
                }}
                setUnboundableField={this.setUnboundableField} />

          : <AnchoredPath 
                pathHead={projectedCharacteristic}
                pathPairs={pathPairs}
                pathIsValid={pathIsValid}
                hasNormalAnchor /> }
      </div>
    </div>
  }

  renderPrivateCell = () => {
    const { isComposite, editable } = this.props;
    const { attributeKind, viewType } = this.state;
    const isPrivatelyScoped = attributeKind === "privatelyScoped";
    const isNormalChar = !viewType;

    if (isComposite || isNormalChar) {
      return null;
    }

    return <div>
      <label>Privately Scoped</label>
      <div style={{ padding: 7, textAlign: "center" }}>
        <Checkbox checked={isPrivatelyScoped}
                          label=""
                          disabled={!editable}
                          onChange={() => this.setState({ attributeKind: isPrivatelyScoped ? "foreignReference" : "privatelyScoped" })} />
      </div>
    </div>
  }

  renderUnionCaseNameCell = () => {
    const { enumLiteralHash, useSwitch, parentCompositeUnion, index } = this.props;
    const { children } = this.state;
    const literal_names = [];

    if (parentCompositeUnion) { // if parent is a composite union view, display integer case value
      return <div className="char-row" style={{ fontSize: 14, }}>
        {`Case: ${index}`}
      </div>
    }

    if (!useSwitch || !children.length) {
      return null;
    }

    for (let unionCase of children) {
      const literal = enumLiteralHash[unionCase.case];
      if (!literal) continue;
      literal_names.push(literal.name);
    }

    return <div className="char-row" style={{ fontSize: 14, }}>
      {`Case: ${literal_names.join(", ")}`}
    </div>
  }

  renderUnionCaseEditorCell = () => {
    const { editable, useSwitch, switchGuid, filteredEnumLiterals, enumLiteralHash } = this.props;
    const { children } = this.state;

    if (!useSwitch || !switchGuid) {
      return null;
    }

    return <div className="size-2">
      <label>Switch Cases</label>
      <div style={{ display: "flex", gap: 10 }}>
          <PhenomComboBox data={filteredEnumLiterals}
                          dataItemKey="guid"
                          textField="name"
                          placeholder="Select a switch case"
                          disabled={!editable}
                          onChange={(lit) => {
                            this.addNewUnionCase(lit.guid)
                          }} />

          { children.map(unionCase => {
            const literal = enumLiteralHash[unionCase.case];

            if (!literal) {
              return null;
            }

            return <span key={unionCase.guid}
                         className="union_case"
                         onClick={() => this.removeUnionCase(unionCase)}>
                  { literal.name }
                  <i class="far fa-times-circle" />
            </span>
          })}
      </div>
    </div>
  }

  renderOptionalCell = () => {
    const { isComposite, editable } = this.props;

    if (isComposite) {
      return null;
    }

    return <div style={{ textAlign: "center" }}>
      <label>Optional</label>
      <div style={{ padding: 7 }}>
        <Checkbox checked={this.state.optional}
                          label=""
                          disabled={!editable}
                          onChange={(e) => {
                            this.setState((prevState) => ({ optional: !prevState.optional }))}
                          } />
      </div>
    </div>
  }

  renderBoundsCell = () => {
    const { isComposite, editable } = this.props;
    const { unboundableField } = this.state;

    if (isComposite) {
      return null;
    }

    return <div>
      <label>Bounds</label>
      <BoundsPicker ele={this.state}
                    // idCtx={phenomId.gen("init","bounds")}
                    lowerSelector="lowerBound"
                    upperSelector="upperBound"
                    setBound={this.setBound}
                    disabled={!editable || unboundableField || isComposite}
                    editableBounds={!unboundableField}/>
    </div>
  }

  renderSemanticCell = () => {
    const { guid, viewType } = this.state;

    // ignore new nodes and nested chars
    if (isPhenomGuid(guid) || !!viewType) {
      return null;
    }

    return <div>
      <label>Semantics</label>
      <div style={{ textAlign: "center" }}>
        <Button onClick={() => this.props.setSemanticCharGuid(guid)}>Show</Button>
      </div>
    </div>
  }

  renderGuidCell = () => {
    const { guid } = this.state;

    if (isPhenomGuid(guid)) return null;

    return (
      <div style={{display: "flex", flexDirection: "column", justifyContent: "center", flex: 1, overflowWrap: "break-word", wordBreak: "break-word"}}>
        <label>GUID</label>
        {guid}
      </div>
    );
  }

  render() {
    const { index, onDragOver, editable, isComposite } = this.props;
    const { collapsed } = this.state;

    return <StyledCharacteristic index={index} edited={this.isEdited()} onDragOver={onDragOver} ref={this.domRef}>
      { this.renderUnionCaseNameCell() }

      <div className="char-row">
        { this.renderHandleBarCell() }
        { this.renderNameCell() }
        { this.renderProjectedCell() }

        {!isComposite &&
        <div className="char-col size-2">
          { this.renderPathCell() }
          { this.renderPrivateCell() }
        </div> }

        { this.renderOptionalCell() }
        { this.renderBoundsCell() }

        <div style={{ textAlign: "center" }}>
          <label>Delete</label>
          <div>
            <Button icon="trash"
                    id={this.phenomId.genPageId("delete-btn")}
                    disabled={!editable}
                    onClick={() => this.props.deleteChar(this.state.guid)} />
          </div>
        </div>
      </div>

      {!collapsed && <>
        <div className="char-row">
          <div className="size-3">
            <label>Description</label>
            <PhenomInput value={this.state.descriptionExtension}
                         disabled={!editable}
                         id={this.phenomId.genPageId("description")}
                         onChange={(e) => this.setState({ descriptionExtension: e.target.value })} />
          </div>
            
          { this.renderGuidCell() }

          { this.renderSemanticCell() }
        </div>

        <div className="char-row">
          { this.renderUnionCaseEditorCell() }
        </div>
      </> }

    </StyledCharacteristic>
  }
}



const ViewCodePreview = ({ data=[], onLoadData, characteristics }) => {
  const [activeIdx, setActiveIdx] = useState(0);
  const [isCodePreview, setIsCodePreview] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDropdownClick = () => {
    if (!data.length && !isCodePreview && data !== "") {
      loadData();
    }
    setIsCodePreview(!isCodePreview);
  }

  const loadData = () => {
    setLoading(true);
    onLoadData();
  }

  // handles loading "" means no data from response
  useEffect(() => {
    const isLoading = loading;

    if ((isLoading && data.length) || (isLoading && data === "")){
      setLoading(false)
    }

  }, [loading, data]);

  // used to re load data if characteristics change
  useEffect(() => {
    if (isCodePreview) {
      loadData();
    }
  }, [characteristics])

  const header = <div style={{display: "flex"}}>
              <span className="fas fa-info-circle"
                  style={{margin: "2px 5px 0 0"}}
                  data-tip="This is a preview of the FACE 3.0/3.1 Query and Template that would document the interface represented by this view.<br/><br/>
                  If multiple preview tabs appear, it is because the view contains some fields that do not project the same data model element - their paths start at different elements. In the FACE 3.0/3.1 format this would result in a composite Query and composite Template.<br/><br/>
                  This preview is based on the Conceptual (Entity) Data Model captured in Phenom. Upon FACE model generation, the Logical and Platform (Entity) Data Models will be generated, and the final Query and Template will be based on the platform level entities and compositions. Because the PDM level composition and participant rolenames may be slightly different from the conceptual compositions and participants they realize, the platform Query and Template may also be different to reflect those changes. Rest assured, however, that this does not affect the semantic documented in Phenom."
                  data-for="hoverTip"
                  data-html={true}
                  data-place="right"/>
           </div>;


  return <div>
            <PhenomCollapsable label="Query and Template Preview"
                                headerChildren={header}
                                onClick={handleDropdownClick}>
                <div className="code-preview">
                {isCodePreview && loading ?
                  <div className="p-col code-preview-body" style={{display: "flex", alignItems: "center", justifyContent: "center", gap: 0}}>
                  <div className="p-row">
                    <img id="loading-spinner"
                    style={{ width: 120 }}
                    src={loadingIcon} />
                  </div>
                  <div className="p-row">Preview generating...</div>
                </div>
                :
                <div>
                 {data.length > 1 &&
                 <ul className="code-preview-header">
                   {data.map((query, idx) => {
                     if (!query?.specification) return null;
 
                     return <li key={query.guid}
                               className={activeIdx === idx ? "active": null}
                               title={query.name}
                               onClick={() => setActiveIdx(idx)}>
                                 { query.name }
                             </li>
                   })}
                 </ul>}
                 <div className="code-preview-body" >
                   {!!data.length && data.map((query, idx) => {
                       if (!query?.specification) return null;
                       const { template } = query;
 
                       return <div key={query.guid}
                                   className={"code-preview-content" + (activeIdx === idx ? " active": "")}>
                               <p dangerouslySetInnerHTML={{ __html: query?.specification || "" }} />
                               <p dangerouslySetInnerHTML={{ __html: template?.specification || "" }} />
                              </div>
                     })}
                     {data === "" && 
                      <div className="p-col" style={{display: "flex", alignItems: "center", justifyContent: "center", gap: 0, height: "100%"}}>
                        <div className="p-row">No preview available.</div>
                      </div>
                     }
                  </div>
                </div>
                }
              </div>
            </PhenomCollapsable>
          </div>
}


const IdlCodePreview = ({ data="", onLoadData, characteristics }) => {
  const [activeIdx, setActiveIdx] = useState(0);
  const [isIdlPreview, setIsIdlPreview] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDropdownClick = () => {
    if (!data.length && !isIdlPreview && typeof data !== "object") {
      loadData();
    }
    setIsIdlPreview(!isIdlPreview);
  }

  const loadData = () => {
    setLoading(true);
    onLoadData();
  }

  // handles loading "" means no data from response
  useEffect(() => {
    const isLoading = loading;
    if ((isLoading && data.length) || (isLoading && data === "")){
      setLoading(false)
    }
  }, [loading, data]);

  // used to re load data if characteristics change
  useEffect(() => {
    if (isIdlPreview) {
      loadData();
    }
  }, [characteristics])

  return <div>
            <PhenomCollapsable label="IDL Preview"
                                  onClick={handleDropdownClick}>
            <div className="code-preview">
              {loading ?
                <div className="p-col code-preview-body" style={{display: "flex", alignItems: "center", justifyContent: "center", gap: 0}}>
                  <div className="p-row">
                    <img id="loading-spinner"
                    style={{ width: 120 }}
                    src={loadingIcon} />
                  </div>
                  <div className="p-row">Preview generating...</div>
                </div>
                :
                <div>
                  {data.length > 1 &&
                    <ul className="code-preview-header">
                      {data.map((view, idx) => {
                        if (!view?.fmm) return null;

                        return (
                          <li key={view.guid}
                              className={activeIdx === idx ? "active" : ""}
                              title={view.name}
                              onClick={() => setActiveIdx(idx)}>
                            {view.name}
                          </li>
                        );
                      })}
                    </ul>
                  }
                  <div className="code-preview-body">
                    {data.length > 0 && 
                      <div className={"idl-preview-content" + (data[activeIdx]?.fmm ? " active" : "")}>
                        {data[activeIdx]?.fmm}
                      </div>
                    }
                    {data === "" &&
                      <div className="p-col" style={{display: "flex", alignItems: "center", justifyContent: "center", gap: 0, height: "100%"}}>
                        <div className="p-row">No preview available.</div>
                      </div>
                    }
                  </div>  
                </div>
              }
            </div>
            </PhenomCollapsable>
  </div>
}

export const EditViewManager = withPageLayout(ViewManager);
