import React from 'react'
import { PackageComboBox, PhenomComboBox, PhenomInput, PhenomLabel, PhenomTextArea } from '../util/stateless';
import { createPhenomGuid, isPhenomGuid, getShortenedStringRepresentationOfXmiType } from '../util/util';
import ChangeSetPicker from '../widget/ChangeSetPicker';
import { NodeHistory2 } from './node-history';
import { withPageLayout } from "./node-layout";
import PhenomId from "../../requests/phenom-id";
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { _ajax } from '../../requests/sml-requests';
import { receiveErrors } from '../../requests/actionCreators';
import { orderBy } from '@progress/kendo-data-query';
import ModalNodeBuilder from '../dialog/ModalNodeBuilder';
import { ConstraintManager2 } from './edit-constraint-manager';
import { PhenomButtonLink, PhenomLink } from '../widget/PhenomLink'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import NodeDetails from './node-details';


export class ValueTypeUnitManager extends React.Component {
  static defaultProps = {
    newNode: {
      name: "",
      xmiType: "logical:ValueTypeUnit",
      description: "",
      valueType: {},
      unit: {},
      parent: "",
      children: [],
      subModelId: undefined,
    },
    nodeAddenda: {
      coreAddenda: ["valueType", "unit", "childrenMULTI", "vtuReferences"],
    }
  }

  constructor(props) {
    super(props);

    this.phenomId = new PhenomId("value-type-unit", this.props.idCtx);
  }

  // ------------------------------------
  // State
  // ------------------------------------
  state = {
    valueType: {},
    unit: {},
    children: [],
    isEnumerated: false,

    valueTypeList: [],
    unitList: [],
    constraintList: [],

    impactData: [],
    sortConfig: [],
  }

  // ------------------------------------
  // Life Cycle Methods
  // ------------------------------------
  componentDidMount() {
    this.initNodeState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.node !== this.props.node) {
      this.initNodeState();
    }
  }

  // ------------------------------------
  // Initial Setup
  // ------------------------------------
  initNodeState = () => {
    const { node } = this.props;
    const isEnumerated = node?.valueType?.xmiType === "logical:Enumerated";
    this.setState({ ...node, isEnumerated }, () => {
      this.initValueTypeList();
      this.initUnitLists();
      this.initImpactAnalysis();
    })
  }

  initValueTypeList = () => {
    if (!this.state.guid) return;

    const { isEnumerated, valueType } = this.state;
    const valueTypeGuid = valueType?.guid;
    
    window["cachedRequest"]("newVTUS").then(data => {
      let valueTypeList = [];

      if (isEnumerated) {
        valueTypeList = Object.values(data.value_types).filter(vt => vt.xmiType === "logical:Enumerated");
      } else {
        valueTypeList = Object.values(data.value_types).filter(vt => vt.xmiType !== "logical:Enumerated");
      }

      this.setState({ valueTypeList })
    })
  }

  initUnitLists = () => {
    if (!this.state.guid) return;
    const unitGuid = this.state.unit?.guid;

    window["cachedRequest"]("newVTUS").then(data => {
      let unitList = Object.values(data.units);
      this.setState({ unitList })
    })
  }

  initImpactAnalysis = () => {
    if (!this.state.guid || isPhenomGuid(this.state.guid)) {
      return this.setState({ impactData: [] });
    }

    return _ajax({
      url: "/index.php?r=/detail/get-impact-analysis-data",
      method: "get",
      data: {
          vtu: this.state.guid,
      }
    }).then(res => {
      const analysis = res.data;
      const { errors, axes=[], measurements=[], platformTypes=[] } = analysis

      if (errors) {
        return receiveErrors(errors);
      }

      const impactData = [ ...axes, ...measurements, ...platformTypes ].sort((a, b) => a.name.localeCompare(b.name));

      this.setState({ impactData: impactData });
    })
  }


  // ------------------------------------
  // Setters
  // ------------------------------------
  changeValueType = (valueType) => {
    if (!valueType?.guid) return;

    this.setState({ valueType }, () => {
      this.initValueTypeList();
    })
  }

  changeUnit = (unit) => {
    if (!unit?.guid) return;

    this.setState({ unit }, () => {
      this.initUnitLists();
    })
  }

  changeConstraint = (constraint) => {
    if (!constraint?.guid) return;

    const constraintList = [...this.state.children];
    const idx = constraintList.findIndex(con => con.guid === constraint.guid);

    // move constraint to position 0
    if (idx > -1) {
      const con = constraintList[idx];
      constraintList.splice(idx, 1);
      constraintList.unshift(con);
    }

    this.setState({ children: constraintList })
  }

  generateNode = () => {
    const data = {
      guid: this.state.guid,
      name: this.state.name,
      xmiType: "logical:ValueTypeUnit",
      description: this.state.description,
      valueType: this.state.valueType?.guid,
      unit: this.state.unit?.guid,
      parent: this.state.parent,
      children: this.state.children,
      subModelId: this.state.subModelId,
    }

    return data;
  }

  addNewConstraint = () => {
    let constraintNode;

    if (this.state.isEnumerated) {
      constraintNode = {
        guid: createPhenomGuid(),
        name: "",
        xmiType: "logical:EnumerationConstraint",
        description: "",
        parent: this.state.guid,
        allowedValue: "",
      }
    }

    ModalNodeBuilder.show({
      component: ConstraintManager2,
      node: constraintNode,
      isLocalSaveOnly: true,
      additionalProps: {
        isLogical: true,
        parentValueType: this.state.valueType?.guid,
      },
      onSaveCallback: (newConstraint) => {
        this.setState({ children: [newConstraint] })
      },
    })
  }

  editConstraint = () => {
    const { children } = this.state;
    if (!children.length) return;

    const constraintNode = children[0];

    ModalNodeBuilder.show({
      component: ConstraintManager2,
      node: constraintNode,
      isLocalSaveOnly: true,
      additionalProps: {
        isLogical: true,
        parentValueType: this.state.valueType?.guid,
      },
      onSaveCallback: (newConstraint) => {
        this.setState({ children: [newConstraint] })
      },
    })
  }


  renderConstraintCell = () => {
    const { editable } = this.props;
    
    // VTU can have at most one constraint
    const constraint = this.state.children[0];
    let onClickPlusIcon, onClickPencilIcon;


    // constraint doesn't exist - render Plus button only
    if (!constraint) {
      onClickPlusIcon = this.addNewConstraint;

    } else {
      // constraint exist - render Pencil button only
      onClickPencilIcon = this.editConstraint;
    }

    return <div>
            {editable ? 
            <PhenomComboBox label="Constraint"
                            value={constraint}
                            data={this.state.children}
                            dataItemKey="guid"
                            disabled={!editable}
                            onChange={this.changeConstraint}
                            onClickPlusIcon={onClickPlusIcon}
                            onClickPencilIcon={onClickPencilIcon} />
              :
              <PhenomComboBox label="Constraint"
                            value={constraint}
                            data={this.state.children}
                            dataItemKey="guid"
                            disabled={!editable}
                            onChange={this.changeConstraint} >
                              <PhenomButtonLink node={ constraint } className='cadet-node-link' /> 
              </PhenomComboBox>
            }
          </div>
  }

  renderValueTypeCell = () => {
    const { editable } = this.props;

    return <PhenomComboBox label="Value Type"
                            value={this.state.valueType}
                            data={this.state.valueTypeList}
                            dataItemKey="guid"
                            disabled={!editable}
                            onChange={this.changeValueType} > 
                              <PhenomButtonLink node={ this.state.valueType } className='cadet-node-link' /> 
          </PhenomComboBox>
  }

  render() {
    const { editable } = this.props;
    const { isEnumerated, impactData, sortConfig } = this.state;
    const original = this.props.node;

    let title = "Value Type Unit";
    if (isEnumerated) title = "Enumerated " + title;

    return <div className="edit-form">
            <div className="p-row">
              <div className="p-col">
                <PhenomInput label={title}
                             value={this.state.name}
                             originalValue={original["name"]}
                             disabled={!editable}
                             autoFocus={true}
                             id={this.phenomId.genPageId("name")}
                             onChange={(e) => this.setState({ name: e.target.value })}
                             onClickResetIcon={() => this.setState({ name: original["name"] })} />

                <PackageComboBox label="Package"
                                  xmiType="face:LogicalDataModel"
                                  placeholder="<Default>"
                                  nodeGuid={this.state.guid}
                                  selectedGuid={this.state.parent}
                                  originalGuid={original["parent"]}
                                  disabled={!editable}
                                  id={this.phenomId.genPageId("parent")}
                                  onChange={(parent) => this.setState({ parent: parent.guid })}
                                  onClickResetIcon={() => this.setState({ parent: original["parent"] })} />

                <PhenomTextArea label="Description"
                                value={this.state.description}
                                originalValue={original["description"]}
                                disabled={!editable}
                                id={this.phenomId.genPageId("description")}
                                onChange={(e) => this.setState({ description: e.target.value })} />
              </div>

              <div className="edit-side">
                <NodeHistory2 guid={this.state.guid}
                              ref={ele => this.historyRef = ele} />
                <NodeDetails guid={this.state.guid}/>
                {/* <ChangeSetPicker label="Change Set"
                                 disabled={!editable} /> */}
              </div>
            </div>

            <div className="p-row p-with-flex">
              { this.renderValueTypeCell() }

              <PhenomComboBox label="Unit"
                              value={this.state.unit}
                              data={this.state.unitList}
                              dataItemKey="guid"
                              disabled={!editable || isEnumerated}
                              onChange={this.changeUnit} />

              { this.renderConstraintCell() }
            </div>

            <div>
              <PhenomLabel text="Used By" />
              <Grid data={sortConfig.length ? orderBy(impactData, sortConfig) : impactData}
                    sortable
                    sort={sortConfig}
                    onSortChange={(e) => {
                      this.setState({ sortConfig: e.sort })
                    }}>
                <GridNoRecords>
                  No Data Is Available For This Table
                </GridNoRecords>
                <GridColumn title="Name" field="name" cell={(props) => <td><PhenomLink node={props.dataItem} newTab={false} /></td>}/>
                <GridColumn title="Type" field="xmi:type" cell={(props) => <td> {getShortenedStringRepresentationOfXmiType(props.dataItem["xmi:type"])} </td>}/>
                <GridColumn title="Description" field="description" />
              </Grid>
            </div>
          </div>
  }
}


export const EditValueTypeUnitManager = withPageLayout(ValueTypeUnitManager);
