import React from 'react'
import { NodeComboBox, PackageComboBox, PhenomInput, PhenomLabel, PhenomSelect, PhenomTextArea } from '../util/stateless';
import { createPhenomGuid, deGuidify, splitXmiType } from '../util/util';
import ChangeSetPicker from '../widget/ChangeSetPicker';
import { NodeHistory2 } from './node-history';
import { withPageLayout } from "./node-layout";
import PhenomId from "../../requests/phenom-id";
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Button, Toolbar } from '@progress/kendo-react-buttons';
import DeletionConfirm2 from '../dialog/DeletionConfirm2';
import { logicalValueTypeList } from '../../global-constants';
import NodeDetails from './node-details';



export class DomainManager extends React.Component {
  static defaultProps = {
    newNode: {
      name: "",
      xmiType: "conceptual:Domain",
      description: "",
      parent: "",
      basisEntity: [],
      children: [],
      subModelId: undefined,
    },
    nodeAddenda: {
      coreAddenda: ["basisEntityMULTI"]
    }
  }

  constructor(props) {
    super(props);

    this.phenomId = new PhenomId("domain", this.props.idCtx);
  }

  basisEntRef = React.createRef();
  state = {
    ...this.props.newNode,
  }

  componentDidMount() {
    this.initNodeState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.node !== this.props.node) {
      this.initNodeState();
    }
  }

  initNodeState = () => {
    const { node } = this.props;
    this.setState({ ...node })
  }

  generateNode = () => {
    const data = {
      guid: this.state.guid,
      name: this.state.name,
      xmiType: this.state.xmiType,
      description: this.state.description,
      basisEntity: this.basisEntRef.current.generateNode(),
      parent: this.state.parent || undefined,
      subModelId: this.state.subModelId,
    }

    return data;
  }
  
  render() {
    const { editable } = this.props;
    const original = this.props.node;

    return <div className="edit-form">
            <div className="p-row">
              <div className="p-col">
                <div className="p-row p-with-flex">
                  <PhenomInput label="Domain"
                               value={this.state.name}
                               originalValue={original["name"]}
                               disabled={!editable}
                               autoFocus={true}
                               id={this.phenomId.genPageId("name")}
                               onChange={(e) => this.setState({ name: e.target.value })}
                               onClickResetIcon={() => this.setState({ name: original["name"] })} />

                  <PackageComboBox label="Parent Package"
                                    xmiType="face:ConceptualDataModel"
                                    placeholder="<Default>"
                                    nodeGuid={this.state.guid}
                                    selectedGuid={this.state.parent}
                                    originalGuid={original["parent"]}
                                    disabled={!editable}
                                    id={this.phenomId.genPageId("parent")}
                                    onChange={(parent) => this.setState({ parent: parent.guid })}
                                    onClickResetIcon={() => this.setState({ parent: original["parent"] })} />
                </div>

                <PhenomTextArea label="Description"
                                value={this.state.description}
                                originalValue={original["description"]}
                                disabled={!editable}
                                id={this.phenomId.genPageId("description")}
                                onChange={(e) => this.setState({ description: e.target.value })} />
              </div>

              <div className="edit-side">
                <NodeHistory2 guid={this.state.guid}
                              ref={ele => this.historyRef = ele} />
                <NodeDetails guid={this.state.guid}/>
                {/* <ChangeSetPicker label="Change Set"
                                 id={this.phenomId.genPageId()}
                                 disabled={!editable} /> */}
              </div>
            </div>

            <BasisEntityGrid basisEntity={this.state.basisEntity}
                             editable={editable}
                             ref={this.basisEntRef} />
          </div>
  }
}



export class BasisEntityGrid extends React.Component {

  original
  state = {
    basisEntity: [],
    filterOutGuids: [],
  }
  phenomId = new PhenomId("basis-entity");

  componentDidMount() {
    const { basisEntity } = this.props;

    if (Array.isArray(basisEntity)) {
      this.setState({ 
        basisEntity,
        filterOutGuids: basisEntity.map(be => be.guid) 
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { basisEntity } = this.props;

    if (basisEntity !== prevProps.basisEntity && Array.isArray(basisEntity)) {
      this.setState({ 
        basisEntity,
        filterOutGuids: basisEntity.map(be => be.guid) 
      });
    }
  }

  generateNode = () => {
    return this.state.basisEntity;
  }

  isEdited = () => {
    const original = this.props.basisEntity;
    const current = this.state.basisEntity;

    if (!Array.isArray(original) || !Array.isArray(current)) {
      return false;
    }

    const isDifferent = (n1, n2) => {
      if (!n1 || !n2) return true;
      return Object.keys(n1).some((key) => n1[key] !== n2[key]);
    }

    return current.some((_, idx) => isDifferent(current[idx], original[idx])) ||
           original.some((_, idx) => isDifferent(original[idx], current[idx]));
  }

  addNewRow = () => {
    const basisEntity = [...this.state.basisEntity];
    basisEntity.push({
      guid: createPhenomGuid(),
      xmiType: "conceptual:BasisEntity",
      name: "",
      description: "",
    });

    this.setState({ basisEntity });
  }

  addExistingBasisEntity = (node) => {
    if (!node?.guid) {
      return;
    }

    this.setState((prevState) => {
      const basisEntity =  [...prevState.basisEntity, node];
      return { basisEntity, filterOutGuids: basisEntity.map(be => be.guid) }
    })
  }

  updateCellProps = (guid, key, value) => {
    const basisEntity = [...this.state.basisEntity];
    const idx = basisEntity.findIndex(b => b.guid === guid);
    if (idx < 0) return;
    
    basisEntity[idx] = {
      ...basisEntity[idx],
      [key]: value
    }

    this.setState({ basisEntity });
  }

  handleRemove = (node) => {
    const basisEntity = [...this.state.basisEntity];
    const idx = basisEntity.findIndex(b => b.guid === node.guid);
    idx > -1 && basisEntity.splice(idx, 1);

    this.setState({ 
      basisEntity,
      filterOutGuids: basisEntity.map(be => be.guid) 
    });
  }

  renderNameCell = (cellProps, idx) => {
    const { editable } = this.props;
    const node = cellProps.dataItem;

    return <td>
      <PhenomInput value={node.name}
                   disabled={!editable}
                   onChange={(e) => this.updateCellProps(node.guid, "name", e.target.value)}
                   id={this.phenomId.genPageId(idx + "-name")} />
    </td>
  }

  renderDescriptionCell = (cellProps, idx) => {
    const { editable } = this.props;
    const node = cellProps.dataItem;

    return <td>
      <PhenomInput value={node.description}
                   disabled={!editable}
                   onChange={(e) => this.updateCellProps(node.guid, "description", e.target.value)}
                   id={this.phenomId.genPageId(idx + "-description")} />
    </td>
  }

  renderRemoveCell = (cellProps, idx) => {
    const { editable } = this.props;
    const node = cellProps.dataItem;

    return <td style={{ textAlign: "center" }}>
      <Button icon="close"
              disabled={!editable}
              onClick={() => this.handleRemove(node)}
              id={this.phenomId.genPageId(idx + "-remove")} />
    </td>
  }

  renderRow = (_, cellProps, idx) => {
    return <tr>
      { this.renderNameCell(cellProps, idx) }
      { this.renderDescriptionCell(cellProps, idx) }
      { this.renderRemoveCell(cellProps, idx) }
    </tr>
  }

  render() {
    const { basisEntity, filterOutGuids } = this.state;
    const { editable } = this.props;
    let basisEntityIdx = 0;

    return <div>
      <PhenomLabel text="Basis Entities" />
      <Grid data={basisEntity}
            rowRender={(row, cellProps) => {
              const currentRow = this.renderRow(row, cellProps, basisEntityIdx);
              basisEntityIdx++;
              return currentRow;
            }}>
        <GridNoRecords>
          No Data is available for this table.
        </GridNoRecords>
        <GridColumn title="Name" />
        <GridColumn title="Description" />
        <GridColumn title="Remove" width="75px" />
      </Grid>
      <Toolbar>
        <Button icon="plus"
                id={this.phenomId.genPageId("new-button")}
                disabled={!editable}
                onClick={this.addNewRow}>Add new Basis Entity</Button>

        <NodeComboBox xmiType="conceptual:BasisEntity"
                      placeholder="Add existing Basis Entity"
                      disabled={!editable}
                      filterOutGuids={filterOutGuids}
                      id={this.phenomId.genPageId("existing")}
                      onChange={this.addExistingBasisEntity} />
      </Toolbar>
    </div>
  }
}


export const EditDomainManager = withPageLayout(DomainManager);
