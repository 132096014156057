import React from 'react';
import {Notifications2} from "./notifications";
import { LineLabel, PackageComboBox } from "../util/stateless";
import {UnitTransformGrid} from "../util/unit_transform_grid";
import {Tags} from "../util/tags";
import $ from "jquery";
import BasicEditor from '../util/templates/basic_editor';
import PhenomId from "../../requests/phenom-id";
import { getNodeWithAddenda, smmSaveNodes } from "../../requests/sml-requests";
import { withPageLayout } from "./node-layout";
import { createNodeUrl } from '../../requests/type-to-path';
import { getActiveChangeSetId, receiveResponse } from '../../requests/actionCreators';
import {createPhenomGuid} from "../util/util"
import NavTree from '../tree/NavTree';
import DeletionConfirm2 from '../dialog/DeletionConfirm2';
import { _ajax } from '../../requests/sml-requests';


export class UnitManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.match.params.guid === "new" ? "" : "Loading...",
            description: this.props.match.params.guid === "new" ? "" : "Loading...",
            srcNodes: [],
            dstNodes: [],
            loadingEquations: false,
            editable: true,
            guid: this.props.match.params.guid === "new" ? null : this.props.match.params.guid,
            parent: null
        };

        this.original = {};
        this.noticeRef = React.createRef();
        this.historyRef = React.createRef();
        this.phenomId = new PhenomId("edit-unit",this.props.idCtx);
    }

    componentDidMount() {
        this.loadCurrentNode();
        window.addEventListener('MOVED_NODES', this.mutateOriginalParentListener);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.guid !== prevProps.match.params.guid) {
            this.loadCurrentNode();
        }

        if (prevState.subModelId !== this.state.subModelId ||
            prevProps.subModels !== this.props.subModels) {
                this.setEditingStatus();
        }
    }

    componentWillUnmount() {
      window.removeEventListener('MOVED_NODES', this.mutateOriginalParentListener);
    }

    loadCurrentNode = () => {
        if (this.props.match.params.guid === "new") {
            this.original = {};
            this.setState({
                name: "",
                description: "",
                srcNodes: [],
                dstNodes: [],
                loadingEquations: false,
                editable: true,
                guid: null,
                parent: "",
                subModelId: undefined,
            });
        } else {
            getNodeWithAddenda(this.props.match.params.guid).then(response => {
                this.setStateFromResponse(response, () => {
                    this.loadTransformEquations();
                    this.setEditingStatus();
                });
                window.resetCache();
            });
        }
    }

    setStateFromResponse = (response, cb) => {
        this.original = response;

        if (this.props.updateTemplateNode) {
            this.props.updateTemplateNode(response);
        }
        
        this.setState({...response}, cb);
    }


    isEdited = () => {
        return this.state.name !== this.original.name ||
            this.state.description !== this.original.description ||
            this.state?.parent !== this.original.parent;
    }


    handleSave = () => {
        const noticeRef = this.noticeRef.current;
        const DEFAULT_PARENT = "assert Units&&face:LogicalDataModel";

        if (!this.isEdited()) {
          return Notifications2.parseErrors("No changes detected.");
        }
        const data = {
            name: this.state.name,
            guid: this.state.guid || createPhenomGuid(),
            xmiType: "logical:Unit",
            description: this.state.description,
            changeSetId: getActiveChangeSetId(),
            parent: this.state.parent || null,
        }

        return smmSaveNodes(data).then(res => {
            const response = res.data;
            receiveResponse(response);
            
            if (response.guid) {
                this.setStateFromResponse(response);
                this.loadTransformEquations();
                NavTree.addNodes([ response ]);
                this.props.history.push( createNodeUrl(response) );
            }
        })
    }


    handleReset = () => {
        this.setState({...this.original});
    }

    loadTransformEquations = async () => {
        await this.setState({loadingEquations: true});
        const res = await Promise.all([
            this.fetchSrcEquations(),
            this.fetchDstEquations()
        ])

        const srcNodes = res[0].data.nodes;
        const dstNodes = res[1].data.nodes;
        this.setState({srcNodes, dstNodes, loadingEquations: false});
    }


    fetchSrcEquations = () => {
        return _ajax({
            url: "/index.php?r=/node/model-nodes-of-type",
            method: "get",
            data: {
                type: "skayl:UnitTransform",
                Filter: {
                    includes: {src: this.state.name}
                }
            }
        });
    }

    fetchDstEquations = () => {
        return _ajax({
            url: "/index.php?r=/node/model-nodes-of-type",
            method: "get",
            data: {
                type: "skayl:UnitTransform",
                Filter: {
                    includes:{dst: this.state.name}
                }
            }
        })
    }

    setEditingStatus = () => {
        const { subModels={}, setParentEditingStatus } = this.props;
        const { subModelId } = this.state;
        const currSubModel = subModels[subModelId];
        this.setState({ editable: !currSubModel?.created }, () => {
            setParentEditingStatus && setParentEditingStatus(!currSubModel?.created)
        });
    };

    displayTransformEquations = () => {
        const phenomId = this.phenomId;
        if (this.state.loadingEquations) {
            return <div className="block">Loading...</div>
        }

        if (this.state.srcNodes.length < 1 &&
            this.state.dstNodes.length < 1) {
            return <div className="block">No Transform Equations Exist.</div>
        } else {
            return <React.Fragment>
                <h4 id={phenomId.gen(["transform-equations","src"],"label")}>{`${this.original.name} as the Source`}</h4>
                <UnitTransformGrid nodes={this.state.srcNodes} firstCol="dst" style={{marginBottom: "10px"}} id={phenomId.gen("src")}/>
                <h4 id={phenomId.gen(["transform-equations","dst"],"label")}>{`${this.original.name} as the Destination`}</h4>
                <UnitTransformGrid nodes={this.state.dstNodes} firstCol="src" id={phenomId.gen("dst")}/>
            </React.Fragment>
        }

    }

    handleDelete = () => {
        DeletionConfirm2.show(this.state.guid, this.state.name);
    }

    mutateOriginalParentListener = (e) => {
      // invalid
      if (!this.state.guid) {
        return;
      }

      const leaf = NavTree.getLeafNode(this.state.guid);
      if (!leaf) {
        return;
      }

      const newParentGuid = leaf.getParentGuid();
      if (this.state.parent !== newParentGuid) {
        this.original["parent"] = newParentGuid;
      }
    }

    render() {
        const editable = this.state.editable && this.props.editable;
        const phenomId = this.phenomId;

        return (
            <BasicEditor guid={this.state.guid}
                idCtx={phenomId.genPageId()}
                nodeTitle="UNIT"
                nodeName={this.state.name}
                nodeDescription={this.state.description}
                editable={editable}
                onNameChange={(val) => this.setState({name: val})}
                onDescriptionChange={(val) => this.setState({description: val})}
                saveBtn={this.handleSave}
                resetBtn={this.handleReset}
                noticeRef={this.noticeRef}
                historyRef={this.historyRef}
                smpRef={this.smpRef}>
                <PackageComboBox id={phenomId.genPageId("parent")}
                                  label="Package"
                                  xmiType="face:LogicalDataModel"
                                  placeholder="<Default>"
                                  nodeGuid={this.state.guid}
                                  selectedGuid={this.state.parent}
                                  disabled={!editable}
                                  onChange={(parent) => this.setState({ parent: parent.guid })}
                                  onClickCancelIcon={() => this.setState({ parent: undefined })} />

                <LineLabel text="Transform Equations" style={{marginBottom: 15, marginTop: 15}} idCtx={phenomId.gen("details","transform-equations")}/>

                { this.displayTransformEquations()}

                {this.state.guid &&
                    <Tags guid={this.state.guid}
                        name={this.state.name}
                        disabled={!editable}
                        idCtx={phenomId.genPageId()}/>
                }
            </BasicEditor>
        );
    }
}


export const EditUnitManager = withPageLayout(UnitManager);
