import React from "react";
import {FormatMenuHeaderCell, LineLabel} from "../../util/stateless";
import {Grid, GridColumn as Column, GridNoRecords} from "@progress/kendo-react-grid";
import {EntityChild} from "./edit-entity-child";
import {orderBy} from "@progress/kendo-data-query";
import {NavLink} from "react-router-dom";
import PhenomId from "../../../requests/phenom-id";
import { ColumnMenu } from "../../util/stateless";

export default class GenView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columnVisibilityGenView: JSON.parse(localStorage.getItem('columnVisibilityGenView')) || {
                roleName: true,
                guid: false,
                description: true,
                category: true,
                type: true,
                projectors: true,
            }
        };
    }

    componentDidMount() {
        this.traceTrail();
    }

    componentDidUpdate(prevProps, prevState) {
        const currentSorts = Object.keys(this.state).filter(e => e.endsWith("Sort"));
        let sortChanged = false;
        currentSorts.forEach(e => {
            if (prevState[e] !== this.state[e]) {
                sortChanged = true;
            }
        });
        if (sortChanged) {
            setTimeout(() => {
                this.props.getSpecTrace(undefined, undefined, false);
            });
        }
        if (this.props.specializes !== prevProps.specializes) {
            this.traceTrail();
        }
    }

    toggleColumnVisibilityGenView = (columnName) => {
        this.setState(prevState => {
            const newVisibility = {
                ...prevState.columnVisibilityGenView,
                [columnName]: !prevState.columnVisibilityGenView[columnName]
            };

            localStorage.setItem('columnVisibilityGenView', JSON.stringify(newVisibility));
            return { columnVisibilityGenView: newVisibility };
        });
    }

    traceTrail = () => {
        const generalizationTrail = [];
        if (this.props.specializes) {
            let currentHead = this.props.specializes;

            while (currentHead) {
                generalizationTrail.push("🢀");
                const copiedHead = {...currentHead};
                copiedHead.children = currentHead.children;
                generalizationTrail.push(copiedHead);
                currentHead = currentHead.specializes;
            }
            generalizationTrail.splice(0, 1);
        }

        const truncations = generalizationTrail.filter(e => e.name !== undefined).reduce((acc, cur) => {
            acc[`truncate${cur.guid}Descriptions`] = true;
            return acc;
        }, {});

        this.setState({
            generalizationTrail,
            ...truncations
        });
    };

    render() {
        const { roleName, guid, description, category, type, projectors } = this.state.columnVisibilityGenView;
        const generalizationTrail = this.state.generalizationTrail || [];
        const phenomId = new PhenomId("inherits-from", this.props.idCtx);
        const rowRenderIdx = 0;

        return (!generalizationTrail.length || <React.Fragment>
            <LineLabel text="Inherits From" style={{marginTop: 15, marginBottom: 10}} classes={"inherits-from"} idCtx={phenomId.genPageId()}/>
            {generalizationTrail.filter(e => e.name !== undefined).map((currentGeneralization, generalizationIdx) => {
                return (<div key={generalizationIdx} id={phenomId.gen(["generalization",`${generalizationIdx}`],"wrapper")}>
                    <div className="flex-h" style={{alignItems: "center", margin: "5px 0"}}>
                        {generalizationTrail.slice(0, ((generalizationIdx + 1) * 2) - 1).map((e, idx2, gtArr) => {
                            return e.name !== undefined ?
                                <span key={idx2}>{idx2 === gtArr.length - 1 ?
                                    <NavLink id={phenomId.gen([`${generalizationIdx}`,`${idx2}`],"link")}
                                      to={`/edit/details/entity/${e.guid}`}
                                         className="cadet-anchor normal-anchor"><i>{e.name}</i></NavLink> : e.name}</span> :
                                <span key={idx2} style={{margin: "0 3px"}}>{e}</span>;
                        })}
                    </div>
                    <Grid
                        id={phenomId.gen("init","entity-grid")}
                        data={orderBy(currentGeneralization.children, this.state[currentGeneralization.guid + "Sort"] || [])}
                        sortable
                        sort={this.state[currentGeneralization.guid + "Sort"] || []}
                        onSortChange={(e) => {
                            this.setState({
                                [currentGeneralization.guid + "Sort"]: e.sort
                            });
                        }}
                        className="editorTable default-table2"
                        resizable
                        rowRender={(_, props) => {
                            const child = props.dataItem;
                            return (<tr
                                ref={ele => this.props.createRowElRef(child.guid, ele)}
                                key={child.guid}>
                                <EntityChild element={child} getSpecTrace={this.props.getSpecTrace}
                                             genParentGuid={currentGeneralization.parent}
                                             truncations={{
                                                 descriptions: this.state[`truncate${currentGeneralization.guid}Descriptions`]
                                             }}
                                             columnVisibility={this.state.columnVisibilityGenView}
                                             possibleSpecializations={this.props.possibleSpecializations} readonly
                                             idCtx={phenomId.gen("init")}
                                             isGenViewChild={true}
                                             hideDrag={true}/>
                            </tr>);
                        }}>
                        <GridNoRecords>
                            No Data Is Available For This Table
                        </GridNoRecords>
                        {roleName && <Column title="ROLENAME" field="rolename" columnMenu={(props) => <ColumnMenu {...props} columnVisibility={this.state.columnVisibilityGenView} toggleColumnVisibility={this.toggleColumnVisibilityGenView}/>}/>}
                        {guid && <Column title="GUID" field="guid" width=""/>}
                        {description && <Column title="DESCRIPTION"
                                headerCell={(props) => {
                                    return <FormatMenuHeaderCell text={props.title}
                                                                 useTruncation={(val) => this.setState({[`truncate${currentGeneralization.guid}Descriptions`]: val})}/>;
                                }}/>}
                        {category && <Column title="CATEGORY"/>}
                        {type && <Column title="TYPE" field="type.name"/>}
                        {projectors && <Column title="PROJECTORS"/>}
                    </Grid>
                </div>);
            })}
        </React.Fragment>);
    }
}
