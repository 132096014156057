import React, { Component, Fragment } from "react";
import { Switch } from "react-router-dom";
import { PublicRoute, ProtectedRoute, AuthRoute, routeUrlBuilder } from "./routes";
import store from "./store/store";
import ReactTooltip from "react-tooltip";
import $ from "jquery";

import Navbar from './views/sections/Navbar'
import Header from "./views/sections/Header"
import EventLog from "./components/widget/EventLog"

import AttrMove from "./components/dialog/attr_move";
import { BasicAlert } from "./components/dialog/BasicAlert";
import { BasicConfirm } from "./components/dialog/BasicConfirm";
import { ContextMenu } from "./components/util/util";
import { FixMyModel } from "./components/util/fixMyModel";
import ModalNodeBuilder from "./components/dialog/ModalNodeBuilder";
import { Notifications2 } from "./components/edit/notifications"
import TagsEditor from "./components/dialog/TagsEditor";
import YiiToolbar from "./components/debug/YiiToolbar";

import About from './views/About'
import Dashboard from './views/Dashboard'
import DataModel from './views/DataModel'
import Generate from './views/Generate'
import License from './views/License'
import IntegrationModel from './views/IntegrationModel'
import Login from './components/login/verify2FA'
import Manage from './views/Manage'
import Settings from './components/settings/settings'
import { Commit } from "./components/util/commit";
import { PerformanceChecker } from "./components/util/performance_checker";
import { RequestPasswordReset } from "./components/login/request_password_reset"
import { PasswordReset } from "./components/login/password_reset"
import { sessionTokenExpired } from "./requests/actionCreators";
import Footer from './views/sections/Footer'
import Splash from "./components/dialog/Splash";
import { MatomoTrackPageView } from "./MatomoFuncs";
import NavTree from "./components/tree/NavTree";
import DeletionConfirm2 from "./components/dialog/DeletionConfirm2";
import PermissionsDialog from "./components/dialog/PermissionsDialog";
import { getCurrentModelRefIndex } from "./requests/actionThunks";
import LoginError from "./components/login/login_error";


const protectedRoutes = [
  {
    path: routeUrlBuilder("dashboard"),
    component: Dashboard,
  },
  {
    path: routeUrlBuilder("edit", null, null, ":guid?"),
    component: DataModel,
  },
  {
    path: routeUrlBuilder("integration", null, null, ":guid?"),
    component: IntegrationModel,
  },
  {
    path: routeUrlBuilder("generate", null, null),
    component: Generate,
  },
  {
    path: routeUrlBuilder("manage", null, null),
    component: Manage,
  },
  {
    path: routeUrlBuilder("settings"),
    component: Settings,
  },
  {
    path: routeUrlBuilder("about"),
    component: About,
  },
  {
    path: routeUrlBuilder("license"),
    component: License,
  },
  {
    path: routeUrlBuilder("commit"),
    component: Commit,
  },
  {
    path: routeUrlBuilder("performance-checker"),
    component: PerformanceChecker,
  },
]


const publicRoutes = [
  {
    path: routeUrlBuilder("request_password_reset"),
    component: RequestPasswordReset,
  },
  {
    path: routeUrlBuilder("password_reset", undefined, undefined, ":code"),
    component: PasswordReset,
  },
  {
    path: '/login-error',
    component: LoginError,
  },
  {
    path: '/',
    component: Login,
  },
]

class App extends Component {

    componentDidMount() {
      $(window["document"]).ajaxComplete((_, req, __) => {
        if(req.status === 302 && req.getResponseHeader("x-redirect").endsWith("index.php?r=site%2Flogin") && this.isUserAuthenticated()) {
          sessionTokenExpired();
        }
      });

      if (!!store.getState().user?.authenticated) {
        getCurrentModelRefIndex();
      }
    }

    isUserAuthenticated(){
      return !!store.getState().user?.authenticated;
    }

    render() {
      return (<Fragment>
                <MatomoTrackPageView />
                <Notifications2 />
                <BasicAlert/>
                <BasicConfirm/>
                <ModalNodeBuilder />
                <TagsEditor/>
                <AttrMove/>
                <FixMyModel/>
                <Splash />
                <DeletionConfirm2 />
                <PermissionsDialog />
                <YiiToolbar/>

            <main id="phenom-app">
              <Header />
              <Navbar />

              <AuthRoute path={routeUrlBuilder(null, null, null, ":guid?")} component={NavTree} />

              <Switch>
                {protectedRoutes.map((route, idx) => (
                  <ProtectedRoute key={`protected-${idx}`} exact={route.exact} path={route.path} redirectTo={route.redirectTo} component={route.component} />
                ))}

                {publicRoutes.map((route, idx) => (
                  <PublicRoute key={`public-${idx}`} exact={route.exact} path={route.path} component={route.component} />
                ))}
              </Switch>

              <div id="phenom-footer">
                <EventLog />
                <Footer />
              </div>
            </main>

            <ContextMenu />
            <ReactTooltip id='hoverTip' />
            <ReactTooltip id='diagramTip' delayShow={1000} getContent={(el) => {
              if(!el) return;
              return <div style={{maxWidth:300}}>{el}</div>
            }}/>
      </Fragment>);
  }
}



export default App
