

class FixUpNode {

  state = {
    guid: "",
    xmiType: "",
    name: "",
    parent: "",
  }
  errors = {};
  nodeData = null;    // fetched from backend
  moveTo = "";

  constructor(data) {
    this.state.guid = data.guid;
    this.state.xmiType = data.xmiType;
    this.state.name = data.name;
    this.state.parent = data.parent;

    this.addErrorData(data);
  }

  getUUID() {
    return this.state["guid"];
  }

  getName() {
    return this.state["name"];
  }

  getXmiType() {
    return this.state["xmiType"];
  }

  getParentUUID() {
    return this.state["parent"];
  }

  getErrorMessage(key) {
    return this.errors[key];
  }

  getErrorAttributes() {
    return Object.keys(this.errors);
  }

  getMoveTo() {
    return this.moveTo;
  }

  addErrorData(data) {
    if(!data?.attr || !data?.text) {
      return;
    }
    this.errors[data.attr] = data.text;
  }

  clearErrorData() {
    this.errors = {};
  }

  setNodeData(data) {
    if (!data?.guid) {
      this.nodeData = null;
    }

    this.nodeData = data;
  }

  hasError(key) {
    return !!this.getErrorMessage(key);
  }

  prepareMoveNode(newParentGuid) {
    if (this.state.parent === newParentGuid) {
      this.moveTo = "";
      return;
    }

    this.moveTo = newParentGuid;
  }

  getNodeData() {
    if (!this.nodeData) {
      return null;
    }

    return {
      ...this.nodeData,
      parent: this.moveTo || this.nodeData.parent,
    };
  }

  getFixUpData() {
    return {
      guid: this.state.guid,
      xmiType: this.state.xmiType,
      name: this.state.name,
      parent: this.state.parent,
      errors: Object.values(this.errors),
    }
  }
}


export default FixUpNode;