import React from 'react';
import { AbstractModelFactory } from '@projectstorm/react-canvas-core';

import { BasePortModel, } from "../../base/BasePort";
import { ImLinkModel } from "./ImLink";
import { extractDataTypeFromDestinationMessagePort, extractDataTypeFromSourceMessagePort, isStormData } from '../../util';

// ------------------------------------------------------------
// PORTS - REACT STORM
// ------------------------------------------------------------
export class ImPortFactory extends AbstractModelFactory {
	constructor() {
		super('im-port');
	}

	generateModel() {
		return new ImPortModel();
	}
}


export class ImPortModel extends BasePortModel {
  constructor(options = {}) {
		super({
			...options,
      type: 'im-port',
		});

    this.registerListener({
      dataTypeChanged: (e) => {
        this.getNode().fireEvent(e, 'dataTypeChanged');
      },
      templateTypeChanged: (e) => {
        this.getNode().fireEvent(e, 'templateTypeChanged');
      },
    })
	}

  /**
   * Required by React Storm
   */
  createLinkModel() {
		return new ImLinkModel();
	}

  // ------------------------------------------------------------
  // Getters
  // ------------------------------------------------------------

  getType() {
    const attrData = this.getAttrData();
    return attrData.getAttr("type");
  }

  isTemplated() {
    return this.getType() === "Templated";
  }

  isRealizing_a_TemplatedPort() {
    const attrData = this.getAttrData();
    return attrData.getAttr("realized_type") === "Templated";
  }

  /**
   * The dataType attribute depends on the node type
   *    -> Endpoint's dataType is retrieved from the connected message port ($app is needed to dereference the connection)
   * 
   * @returns dataType
   */
  getDataType() {
    // edge case: if parent was deleted but port is still displayed in side panel
    if (!this.getNode()) {
      return "";
    }

    const $app = this.getNode()?.$app;
    if (!$app) return "";

    const attrData = this.getAttrData();

    switch (this.getAttrXmiType()) {
      case "im:UoPOutputEndPoint":
        let srcMessagePortData = $app.getDiagramStormData(attrData.getAttr("connection"));
        let srcMessagePort = isStormData(srcMessagePortData) ? srcMessagePortData.getData() : {};
        return extractDataTypeFromSourceMessagePort(srcMessagePort);
        
      case "im:UoPInputEndPoint":
        let dstMessagePortData = $app.getDiagramStormData(attrData.getAttr("connection"));
        let dstMessagePort = isStormData(dstMessagePortData) ? dstMessagePortData.getData() : {};
        return extractDataTypeFromDestinationMessagePort(dstMessagePort);
        
      default:
        return attrData.getAttr("dataType") || "";
    }
  }

  getTemplateType() {
    // edge case: if parent was deleted but port is still displayed in side panel
    if (!this.getNode()) {
      return "";
    }

    const attrData = this.getAttrData();
    return attrData.getAttr("templateType" || "");
  }

  getRealizedTemplateType() {
    // edge case: if parent was deleted but port is still displayed in side panel
    if (!this.getNode()) {
      return "";
    }

    const attrData = this.getAttrData();
    return attrData.getAttr("realized_templateType" || "");
  }

  // ------------------------------------------------------------
  // Setters
  // ------------------------------------------------------------
  /**
   * Sets the dataType attribute without redirecting the reference pointer
   * Event listeners are triggered based on the parent node
   *    -> UoPInstance - endpoints do not have a dataType. sends the given dataType through the connected link
   *    -> TransformNode - sets the current attribute's dataType and sends the dataType through the connected link
   *    -> Block Nodes - sets the current attribute's dataType, sends the dataType through the connected link and sends the dataType to the parent node.
   *                     the parent node will loop through each of its ports and fire off event listeners 
   * 
   * @param {node} view 
   * @returns void/undefined
   */
  setDataType(view={}) {
    const dataType = view?.guid || "";

    // edge case: if parent was deleted but port is still displayed in side panel
    if (!this.getNode()) {
      return;
    }

    // exit if data type already matches - to prevent event listener from firing
    if (this.getDataType() === dataType && this.getAttrData().getAttr("type") === "Default") {
      return;
    }

    const event = { view, isIn: this.getOptions().in };
    const attrData = this.getAttrData();
    const parentNodeModel = this.getNode();
    const xmiType = parentNodeModel.getXmiType();

    switch (xmiType) {
      // 1. update the attribute's dataType
      // 2. send dataType across connected links and attempt to update the other attribute's dataType 
      case "im:TransformNode":
      case "im:Generic":
        attrData.setAttr("dataType", dataType);
        attrData.setAttr("type", "Default");
        attrData.setAttr("templateType", "");
        Object.values(this.getLinks()).forEach(l => l.fireEvent(event, 'dataTypeChanged'));
        parentNodeModel.fireEvent({}, 'nodeDataChanged');
        return;

      // 1. do not update the attribute's dataType
      // 2. send dataType across connected links and attempt to update the other attribute's dataType 
      case "im:UoPInstance":
        parentNodeModel.fireEvent({}, 'nodeDataChanged');
        return;

      case "im:ComposedBlockInstance":
        if (attrData.getAttr("realized_type") === "Templated") {
          attrData.setAttr("dataType", dataType);
        }
        parentNodeModel.fireEvent({}, 'nodeDataChanged');
        break;

      // 1. update the attribute's dataType
      // 2. send dataType across connected links and attempt to update the other attribute's dataType 
      // 3. send dataType to parent block and attempt to update the sibling attribute's dataType
      default:
        attrData.setAttr("dataType", dataType);
        attrData.setAttr("type", "Default");
        attrData.setAttr("templateType", "");
        this.fireEvent(event, 'dataTypeChanged');
        Object.values(this.getLinks()).forEach(l => l.fireEvent(event, 'dataTypeChanged'));
        parentNodeModel.fireEvent({}, 'nodeDataChanged');
    }
  }


  setTemplateType = (text="") => {

    // edge case: if parent was deleted but port is still displayed in side panel
    if (!this.getNode()) {
      return;
    }

    // exit if template type already matches - to prevent event listener from firing
    if (this.getTemplateType() === text && this.getAttrData().getAttr("type") === "Templated") {
      return;
    }

    const xmiType = this.getNode().getXmiType();
    const event = { text, isIn: this.getOptions().in };
    const attrData = this.getAttrData();
    const parentNodeModel = this.getNode();

    switch (xmiType) {
      // 1. update the attribute's templateType
      // 2. send templateType across connected links and attempt to update the other attribute's templateType 
      case "im:TransformNode":
      case "im:Generic":
        attrData.setAttr("templateType", text);
        attrData.setAttr("type", "Templated");
        attrData.setAttr("dataType", "");
        Object.values(this.getLinks()).forEach(l => l.fireEvent(event, 'templateTypeChanged'));
        parentNodeModel.fireEvent({}, 'nodeDataChanged');
        return;

      // 1. do not update the attribute's templateType
      // 2. send templateType across connected links and attempt to update the other attribute's templateType 
      case "im:UoPInstance":
      case "im:ComposedBlockInstance":
        Object.values(this.getLinks()).forEach(l => l.fireEvent(event, 'templateTypeChanged'));
        parentNodeModel.fireEvent({}, 'nodeDataChanged');
        return;

      // 1. update the attribute's templateType
      // 2. send templateType across connected links and attempt to update the other attribute's templateType 
      // 3. send templateType to parent block and attempt to update the sibling attribute's templateType
      default:
        attrData.setAttr("templateType", text);
        attrData.setAttr("type", "Templated");
        attrData.setAttr("dataType", "");
        this.fireEvent(event, 'templateTypeChanged');
        Object.values(this.getLinks()).forEach(l => l.fireEvent(event, 'templateTypeChanged'));
        parentNodeModel.fireEvent({}, 'nodeDataChanged');
    }
  }

  /**
   * In Ports can have at most one incoming link
   * 
   * @returns boolean
   */
  validLinkCount() {
    return this.getOptions().in && this.countLinks() < 1;
  }
}