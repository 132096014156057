import React from "react";
import {Grid, GridColumn as Column, GridNoRecords} from "@progress/kendo-react-grid";
import { PhenomLabel } from "../util/stateless";

const rawAttrTopPriority = {
  'xmi:id': 0, 'guid': 0,
  'xmi:type': 1, 'xmiType': 1,
  'name': 2, 'rolename': 2,
  'parent': 3,
  'description': 4, 'descriptionExtension': 4
};

const rawAttrBottomPriority = {
  'model': 1,
};


const highPriorityMax = Math.max(...Object.values(rawAttrTopPriority));

function assertAsStringArr(value) {
  let values;
  if (typeof value === 'string') {
    values = value.split(' ');
  } else if (Array.isArray(value) && value.every(el => typeof el === 'string')) {
    values = value;
  } else if (typeof value === 'number') {
    values = [ value.toString() ];
  } else if (typeof value === 'boolean') {
    values = [ value.toString() ];
  } else {
    throw "Cannot process node values that are not strings or arrays of strings.";
  }
  return values;
}

function derefAndJoin(values, nodeDereffer, derefKind) {
  let hasDerefs = false;

  const derefVals = values.map(value => {
    const deref = nodeDereffer(value, derefKind);
    return deref ? deref : value;
  });

  return derefVals.join(hasDerefs ? "\n" : " ");
}

function getDisplayAttrs(node, nodeDereffer, derefKind, hiddenAttrs = new Set()) {
  return Object.entries(node)
                .filter(([k,v]) => !hiddenAttrs.has(k))
                .map(([k,v]) => {
                    if(k !== "xmi:id" && k !== "guid"){
                      v = derefAndJoin(assertAsStringArr(v), nodeDereffer, derefKind);
                     }
                    return {k,v};
                });
}

function RawAttrGrid({node, nodeDereffer, derefKind, hiddenAttrs = new Set(), renderCell = null}) {
  const displayAttrs = getDisplayAttrs(node, nodeDereffer, derefKind, hiddenAttrs);

  displayAttrs.sort((attr1, attr2) => {
    // determine if attribute is in top, bottom priority or unspecified (middle)
    const priorityType1 = attr1.k in rawAttrTopPriority ? 'top' : attr1.k in rawAttrBottomPriority ? 'bottom' : 'middle';
    const priorityType2 = attr2.k in rawAttrTopPriority ? 'top' : attr2.k in rawAttrBottomPriority ? 'bottom' : 'middle';
  
    // first sort by type of priority (top > middle > bottom)
    if (priorityType1 === 'top' && priorityType2 !== 'top') return -1;
    if (priorityType1 !== 'top' && priorityType2 === 'top') return 1;
    if (priorityType1 === 'bottom' && priorityType2 !== 'bottom') return 1;
    if (priorityType1 !== 'bottom' && priorityType2 === 'bottom') return -1;
  
    if (priorityType1 === priorityType2) { // if both attributes are of the same type, sort according to specific rules
      if (priorityType1 === 'top') {
        return rawAttrTopPriority[attr1.k] - rawAttrTopPriority[attr2.k];
      } else if (priorityType1 === 'bottom') {
        return rawAttrBottomPriority[attr1.k] - rawAttrBottomPriority[attr2.k];
      } else {
        return attr1.k.localeCompare(attr2.k); // sort alphabetically if both are middle
      }
    }
  });

  return (
    <Grid data={displayAttrs} 
          resizable scrollable="none" 
          style={{ tableLayout: "fixed" }} >
      <GridNoRecords>No data is available for this node.</GridNoRecords>
      <Column field="k" title="Attribute" width="100px" 
              cell={renderCell ? props => renderCell(props) : undefined} />
      <Column field="v" title="Value"
              cell={renderCell ? props => renderCell(props) : undefined} />
    </Grid>
  );
}

export default RawAttrGrid;


// RawAttrGrid variant used specifically for review project preview popup
export function PreviewRawAttrGrid({originalNode, candidateNode, nodeDereffer, hiddenAttrs = new Set(), originalLabel=false, candidateLabel=false}) {
  const originalDisplayAttrs = getDisplayAttrs(originalNode, nodeDereffer, "original", hiddenAttrs);
  const candidateDisplayAttrs = getDisplayAttrs(candidateNode, nodeDereffer, "candidate", hiddenAttrs);
  const boldAttrMap = {};

  // setup boldAttrMap
  if(!originalNode) { // no original node, all candidate attrs bold
    Object.keys(candidateNode).forEach((k) => {
      boldAttrMap[k] = true;
    });
  } else { // bold candidate attrs if extra key or different value
    const originalAttrValues = {};
    originalDisplayAttrs.forEach(attr => {
      originalAttrValues[attr.k] = attr.v;
    });

    candidateDisplayAttrs.forEach(attr => {
      if (!(attr.k in originalAttrValues) || originalAttrValues[attr.k] !== attr.v) {
        boldAttrMap[attr.k] = true;
      }
    });
  }

  const CandidateCell = props => {
    const isBold = boldAttrMap[props.dataItem.k];

    return (
      <td>
        {isBold ? <strong>{props.dataItem[props.field]}</strong> : props.dataItem[props.field]}
      </td>
    );
  }

  return (
    <>
      <div>
        {originalLabel && <PhenomLabel text={originalLabel}/>}
        <RawAttrGrid 
          node={originalNode}
          nodeDereffer={nodeDereffer}
          derefKind={"original"}
        />
      </div>
      <div>
        {candidateLabel && <PhenomLabel text={candidateLabel}/>}
        <RawAttrGrid 
          node={candidateNode}
          nodeDereffer={nodeDereffer}
          derefKind={"candidate"}
          renderCell={CandidateCell}
        />
      </div>
    </>
  );
}