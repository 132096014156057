import React from "react";
import { connect } from "react-redux";
import {MergeErrorConfirm} from "./merge_error_confirm"
import PhenomId from "../../../requests/phenom-id";
import { ManageSubMenu } from "./model_manage";
import ChangeSetManager from "./sections/ChangeSetManager";
import NavTree from "../../tree/NavTree";
import * as actionCreators from "../../../requests/actionCreators";
import { _ajax, deleteMergeRequest, getMergeRequestReview, fetchMergeRequest } from "../../../requests/sml-requests";
import { debounce, throttle } from "lodash";
import { BasicAlert } from "../../dialog/BasicAlert";
import MergeSummary from "./merge_summary";
import ListPaneView from "../../edit/list-pane-view"
import { formatDate } from "../../util/util";
import { BasicConfirm } from "../../dialog/BasicConfirm";
import { PhenomInput, PhenomTextArea, PhenomLabel, KbButton, RequestReviewProjectDetails } from "../../util/stateless";
import ReactTooltip from "react-tooltip";
import { RequestReviewDetails } from "./sections/RequestReviewDetails";
import { Button } from "@progress/kendo-react-buttons";
import loadingIcon from "../../../images/Palette Ring-1s-200px.gif";

class Approve extends React.Component { 
  constructor(props) {
    super(props);

    this.newReviewProject = {
      name: "",
      description: "",
      lockDstModel: true,
      requestId: null,
    }

    this.state = {
      pendingRequests: [],
      completedRequests: [],
      deletedRequests: [],

      activeRequest: null,

    };
    
    this._isMounted = true;
    this.noticeRef = undefined;
    this.origBranchName = undefined;
    this.neededNodes = undefined;
    this.listViewRef = React.createRef();

    this.requiredFields = {
      ["name"]: {
        required: true,
        checkFirstChar: true,
        checkAllChars: true,
        errorRef: React.createRef(),
      },
    }
  }

  componentDidMount() {
    NavTree.collapseNavTree(false);
    this.getPendingItems();
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeRequest } = this.state;

    if(prevProps.activeRequest !== activeRequest && activeRequest) {
      NavTree.refresh();
    }
  }

  componentWillUnmount() {
    const { activeRequest } = this.state;
    this._isMounted = false;
    activeRequest && NavTree.reset();
  }

  selectNecessaryNodes = () => {
    NavTree.selectMergeCandidates( this.neededNodes.map(n => n.guid) );
  }

  selectSingleNode = (guid) => {
    NavTree.selectMergeCandidates([ guid ]);
  }

  toggleDeleteDeprecation = () => {
    this.setState({ deprecateDeletes: !this.state.deprecateDeletes})
  }

  toggleMoveDeprecation = () => {
    this.setState({ deprecateMoves: !this.state.deprecateMoves})
  }

  getPendingItems() {
    this.setLoading(true);

    const customErrors = {
      500: "There was an error retrieving pending merge requests, please try again in a few minutes"
    }

    return _ajax({
      url: "/index.php?r=/referencing-model/pending-merge-requests",
    }, customErrors).then((res) => {
      const { pending, completed, deleted } = res.data;

      this.setState({
        pendingRequests: this.formatMergeRequests(pending),
        completedRequests: this.formatMergeRequests(completed),
        deletedRequests: this.formatMergeRequests(deleted),
      });
    }).always(() => {
      this.setLoading(false);
    });
  }

  formatMergeRequests = (mergeRequests = []) => {
    let formattedMergeRequests = [...mergeRequests];

    // sort by completed or deleted or created date - do preliminary check
    if (formattedMergeRequests.some((mr) => !!mr.completed)) {
      formattedMergeRequests = formattedMergeRequests.sort((a, b) => {
        const dateA = new Date(a.completed);
        const dateB = new Date(b.completed);
        return dateB - dateA;
      });
      formattedMergeRequests.forEach((request => {
        request.completed = formatDate(request.completed, true);
        request.created = formatDate(request.created, true);
      }));

    } else if (formattedMergeRequests.some((mr) => !!mr.deleted)) {
      formattedMergeRequests = formattedMergeRequests.sort((a, b) => {
        const dateA = new Date(a.deleted);
        const dateB = new Date(b.deleted);
        return dateB - dateA;
      });
      formattedMergeRequests.forEach((request => {
        request.deleted = formatDate(request.deleted, true);
        request.created = formatDate(request.created, true);
      }));

    } else {
      formattedMergeRequests = formattedMergeRequests.sort((a, b) => {
        const dateA = new Date(a.created);
        const dateB = new Date(b.created);
        return dateB - dateA;
      });

      formattedMergeRequests.forEach((request => {
        request.created = formatDate(request.created, true);
      }));
    }

    return formattedMergeRequests;
  }

  setActiveRequest = (newRequest=null) => {
    const { activeRequest } = this.state;

    // new active commit is selected - set active
    if (newRequest?.id !== activeRequest?.id) {
      this.setState({
        activeRequest: newRequest ? {...newRequest} : null,
      });
      ReactTooltip.rebuild();
    // same active commit is selected - clear active
    } else {
      this.setState({
        activeRequest: null,
      });
    }
  }

  throttledSelectMergeRequest = throttle((request) => {
    const { activeRequest } = this.state;

    if (request?.completed || request?.deleted) {
      NavTree.reset().catch(() => {});
      this.setActiveRequest(request);
      window.dispatchEvent(new CustomEvent('ADD_MERGE_CANDIDATES'));
      return;
    }
    else if (!request || !request.id || request.id === activeRequest?.id) {
      return NavTree.reset().finally(() => {
          this.setActiveRequest(null);
          window.dispatchEvent(new CustomEvent('ADD_MERGE_CANDIDATES'));
      });
    } else {
      this.setActiveRequest(request);
      this.getMergeRequest(request);
    }

  }, 100, { leading: true, trailing: false });

  getMergeRequest = (request) => {
    const { userIdentity } = this.props;

    BasicAlert.show("One moment please. Retrieving the needed data.", "Loading...", false);
    getMergeRequestReview(request.id).then((res) => {
      let modelId = res?.data?.reviewProjectId ?? false;

      NavTree.reset(false, modelId)
        .finally(() => {
          window.dispatchEvent(new CustomEvent('ADD_MERGE_CANDIDATES'));
            this.setState({ loading: true }, () => {
              const requests = [fetchMergeRequest(request.id)];
              requests.push(NavTree.fetchDependencyMap(modelId || userIdentity.branchId));
              Promise.all(requests)
                    .then((res) => {
                      if (!res[0]?.data) return;
                      NavTree.addMergeCandidates(JSON.parse(res[0].data), !!modelId);
                      if (modelId) NavTree.expandAllReviewNodes();
                    })
                    .finally(() => {
                      window.dispatchEvent(new CustomEvent('ADD_MERGE_CANDIDATES', { detail: { hideCheckbox: !!modelId }}));
                      this.setLoading(false);
                      BasicAlert.hide();
                    })
            }
          );
        });
    })
  }

  //resets the list of pending merges
  reset = () => {
    this.getPendingItems();
  }

  setLoading = (bool) => {
    this.setState({loading: bool});
  }

  handleApprove = (deprecateDeletes, deprecateMoves) => {
    const { activeRequest } = this.state

    if (!activeRequest || !activeRequest.id) return;
    this.setLoading(true);

    const rejected = (errText) => {
      typeof errText === "string" && actionCreators.receiveErrors(errText);
      this.setLoading(false);
    }

    const resolved = (response) => {
        if (response.status === "success") {
          NavTree.reset();
          actionCreators.receiveLogs("Merge Completed");
          window.dispatchEvent(new CustomEvent('ADD_MERGE_CANDIDATES'));
          this.setActiveRequest(null);
          this.reset();
          this.setLoading(false);
        } else {
          if(response.errors.length) {
            if (typeof response.errors[0] === 'string') {
              actionCreators.receiveErrors(response.errors);
              this.setLoading(false);
            } else {
              const fixables = ["references", "unreachable"];
              const allFixable = response.errors.every(err => fixables.includes(err.method));
              if(allFixable) {
                this.setNeededNodes(response.errors);
                MergeErrorConfirm.show(this.neededNodes, this.selectNecessaryNodes, this.selectSingleNode);
              } else {
                actionCreators.receiveErrors(Array.from(new Set(response.errors.map(err => err.text))));
              }
              this.setLoading(false);
            }
          } else {
            actionCreators.receiveErrors(["Failed to perform merge."]);
            this.setLoading(false);
          }
        }
    }

    return NavTree.merge("approve", false, deprecateDeletes, deprecateMoves, false, false, activeRequest.id).then(resolved, rejected);
  }

  // pass in requestId or else gets grabbed from state
  handleDeleteRequest = (request) => {
    if (this.state.loading || !request || !request.id) return;

    BasicConfirm.show("Are you sure you want to delete this Merge Request?", 
      () => {
        this.setLoading(true);
        deleteMergeRequest(request.id).then((res) => {
          actionCreators.receiveResponse(res);
        }).always(() => {
          this.throttledSelectMergeRequest(null);
          this.reset();
        });
      }, 
     () => null
    );
  }
  
  // this logic is from the original code
  getAbsentParents = () => {
    NavTree.getSelectedMergeCandidates().forEach(leaf => {
      const parentLeaf = leaf.getParentLeaf();

      if (parentLeaf && parentLeaf.isMergeCandidate() && !parentLeaf.isMarkedForMerge()) {
        const node = {};
        node["name"] = parentLeaf.getName();
        node["guid"] = parentLeaf.getGuid();
        node["error_type"] = "PARENT_ABSENT";
        node["error_name"] = leaf.getName();
        node["error_guid"] = leaf.getGuid();
        node["failure"] = false
        this.neededNodes.push(node);
      }
    })
  }

  // this logic is from the original code
  //    - "neededNode" is a guid
  setNeededNodes(response) {
    this.neededNodes = [];
    response.filter(er => er.method === "references" || er.method === "unreachable").forEach((err, i) => {
        const neededGuid = err.value;
        const neededLeaf = NavTree.getLeafNode(neededGuid);

            const failure = !neededLeaf;
            const node = {};
            node["name"] = !failure ? neededLeaf.getName() : "Not Found";
            node["guid"] = neededGuid;
            node["error_type"] = err.method
            node["error_name"] = err.name;
            node["error_guid"] = err.guid;
            node["failure"] = failure;
            node["deleted"] = neededLeaf?.isDeletedForMerge();
            this.neededNodes.push(node);
    });
  }

  renderPaneContent = () => {
    const { userRole, userIdentity, history } = this.props;
    const { activeRequest, loading } = this.state;
    if (!activeRequest) return;
  
    return (
      <div className="p-col" style={{ gap: 0 }}>
        <div className="p-row">
          <div className="p-col p-col-1">
            <PhenomInput 
              label={"Merge Request"}
              disabled={true}
              autoFocus={true}
              value={activeRequest.name}
            />
            <PhenomTextArea 
              label="Description"
              disabled={true}
              value={activeRequest.description}
              style={{ minHeight: "50px" }}
              containerProps={{ style: { marginBottom: "5px" } }}
            />
          </div>
          <div className="p-col p-col-1" style={{ gap: 0, width: "100%" }}>
            <div>
              <div className="p-col" style={{ gap: 0}}>
                <PhenomLabel text="Source" />
                <p style={{textOverflow: "ellipsis", overflow: "hidden"}}>{activeRequest.src_projects}</p>
              </div>
              <div className="p-col" style={{ gap: 0 }}>
                <PhenomLabel text="Author" />
                <p style={{textOverflow: "ellipsis", overflow: "hidden"}}>{activeRequest.author}</p>
              </div>
              <div className="p-col" style={{ gap: 0 }}>
                <PhenomLabel text="Creation Date" />
                <p style={{textOverflow: "ellipsis", overflow: "hidden"}}>{activeRequest.created}</p>
              </div>
              {activeRequest.completed && (
                <div className="p-col" style={{ gap: 0 }}>
                  <PhenomLabel text="Completion Date" />
                  <p style={{textOverflow: "ellipsis", overflow: "hidden"}}>{activeRequest.completed || null}</p>
                </div>
              )}
              {activeRequest.deleted && (
                <div className="p-col" style={{ gap: 0 }}>
                  <PhenomLabel text="Deletion Date" />
                  <p style={{textOverflow: "ellipsis", overflow: "hidden"}}>{activeRequest.deleted || null}</p>
                </div>
              )}
            </div>
          </div>
        </div>
  
        {!activeRequest.completed && !activeRequest.deleted && (
          <>
            <div className="cadet-line" style={{ marginTop: "10px", marginBottom: "16px" }} />
              <div className="p-row">
                <RequestReviewDetails 
                  loading={loading}
                  setLoading={this.setLoading}
                  userRole={userRole}
                  userIdentity={userIdentity}
                  requestId={activeRequest?.id}
                  history={history}
                  type={"approve"}
                  handleCreateRequest={this.handleApprove}
                  actionCallback={() => this.getMergeRequest(activeRequest)}
                />
                
                <div className="p-col p-col-1" style={{width: "100%"}}>
                  <div style={{overflowX: "auto"}}>
                  <MergeSummary page={"approve"} />
                  </div>
                </div>
              </div>
          </>
        )}
      </div>
    );
  }

  renderContentAboveList = () => {
    return (
      <div>
        <PhenomLabel text="Approve" />
        <p style={{marginBottom: 20}}>Select the changes from the nav tree to pull and click Approve. Pulling is immediate and will overwrite your current nodes. This action cannot be undone.</p>
      </div>
    );
  }

  renderDeleteButton = (request) => {
    return (
      <Button
        iconClass="fas fa-trash" 
        look="bare" title="Delete"  
        style={{fontSize: 18, height: "21px", width: "21px"}}
        onClick={(e) => {
            e.stopPropagation();
            this.handleDeleteRequest(request);
        }}
      />
    );
  }

  render() {
    const { activeRequest, pendingRequests, completedRequests, deletedRequests, loading } = this.state;
    const { isReviewProject } = this.props;
    const phenomId = new PhenomId("approve",this.props.idCtx);

    return <div className="phenom-content-wrapper" >
              <ManageSubMenu isReviewProject={isReviewProject}>
                {loading &&
                  <img id={"merge-loading-icon"}
                        style={{ display: "block", height: "30px" }}  src={loadingIcon}
                  />
                }
                <KbButton />
              </ManageSubMenu>
              <MergeErrorConfirm idCtx={phenomId.gen()} />

                <div id={phenomId.gen("","wrapper")} style={{overflowY: "hidden", height: "100%", margin: "20px 0px 0px 20px"}}>
                  <ListPaneView
                    ref={this.listViewRef}
                    /* Data */
                    mainKey={"id"}
                    activeItem={activeRequest}
                    /* List */
                    listWidth={48}
                    renderContentAboveList={this.renderContentAboveList}
                    lists={[
                      { collapsible: false,
                        data: [...pendingRequests, ...completedRequests, ...deletedRequests],
                        headerOnly: true,
                        headerClass: "main",
                        columns: [{header: "Merge Requests"}]},
                      { collapsible: true, 
                        collapsed: false,
                        data: pendingRequests, 
                        columns: [{header: "Pending Merge Requests", key: "name", subKey: "description", tagKey: "src_projects", flex: 10}, 
                                  {header: "Author", key: "author", flex: 3},
                                  {header: "Creation Date", key: "created", flex: 6},
                                  {header: "", render: this.renderDeleteButton , flex: 1},        
                        ]},
                      { collapsible: true, 
                        data: completedRequests, 
                        columns: [{header: "Completed Merge Requests", key: "name", subKey: "description", flex: 10, tagKey: "src_projects"}, 
                                  {header: "Author", key: "author", flex: 3},
                                  {header: "Completion Date", key: "completed", flex: 6},
                                  {header: "", key: "" , flex: 1},
                        ]},
                      { collapsible: true, 
                        data: deletedRequests, 
                        columns: [{header: "Deleted Merge Requests", key: "name", subKey: "description", flex: 10, tagKey: "src_projects"}, 
                                  {header: "Author", key: "author", flex: 3},
                                  {header: "Deletion Date", key: "deleted", flex: 6}, 
                                  {header: "", key: "" , flex: 1},  
                        ]},
                    ]}
                    /* Pane */
                    renderPaneContent={this.renderPaneContent}
                    minimizePane={!activeRequest}
                    /* Config */
                    onSelect={this.throttledSelectMergeRequest}
                  />
                </div>
              </div>
  }
}

const msp = (state) => ({
  userRole: state.user.userRole,
  userIdentity: state.user.userIdentity,
  isReviewProject: state.user.isReviewProject,
})


export default connect(msp)(Approve)
