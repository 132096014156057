import {Notifications, Notifications2} from "./notifications";
import { CadetInput, CadetTextArea, LineLabel, Toggle, BoundsPicker } from "../util/stateless";
import {NodeHistory2} from "./node-history";
import {Observables} from "../util/observables";
import {Measurement} from "../util/measurement";
import {Path} from "../util/path";
import {Axis} from "../util/axis";
import React from "react";
import {deGuidify, isPhenomGuid} from "../util/util";
import { getNodeWithAddenda } from "../../requests/sml-requests";
import {NavLink} from "react-router-dom";
import $ from "jquery";
import LoaderButton from "../widget/LoaderButton";
import {Button} from "@progress/kendo-react-buttons";
import PhenomId from "../../requests/phenom-id";
import { withPageLayout } from "./node-layout";
import DeletionConfirm2 from "../dialog/DeletionConfirm2";
import { findGuidPublishedStatus, getActiveChangeSetId } from "../../requests/actionCreators";
import ChangeSetPicker from "../widget/ChangeSetPicker";
import { scrubNodeExport } from "../util/util";
import NavTree from "../tree/NavTree";
import { cloneDeep } from "lodash";
import PlatformTypeLite from "./platformtype-lite";
import { Link } from 'react-router-dom';
import { _ajax, smmSaveNodes } from "../../requests/sml-requests";
import NodeDetails from "./node-details";


export class CharacteristicManager extends React.Component {
    constructor(props) {
        super(props);
        this.original = "";
        this.measRef = undefined;
        this.axisRefs = {};
        this.pathRef = undefined;
        this.noteRef = undefined;
        this.ptRef = React.createRef();
        this.phenomId = new PhenomId("edit-characteristic");
    }

    original = {}
    defaultChar = {
        guid: "",
        rolename: "",
        descriptionExtension: "",
        observable: undefined,
        measurement: {},
        pathPairs: [],
        platformType: false,
        parent: {},
        deprecated: false,
        optional: false,
        lowerBound: null,
        upperBound: null,
        attributeKind: "privatelyScoped",
        destructureLink: false,
        destructureReference: false
    }

    state = {
      ...cloneDeep(this.defaultChar),
      axes: {},
      platformTypeOptions: {},
      editable: true,
      changeSetId: "",
      selectPlatformType: true,
      changeSetId: "",
    }

    componentDidMount() {
        if (this.props.match.params.guid !== "new") {
            this.loadGuidBasedData(this.props.match.params.guid, true);
        } else {
            this.loadParentData();
        }
        this.fetchPlatformTypes();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.guid !== this.props.match.params.guid && this.props.match.params.guid !== "new") {
            this.loadGuidBasedData(this.props.match.params.guid, true);
        }
        if (this.state.guid !== prevState.guid && this.state.guid !== this.props.match.params.guid) {
            this.props.history.push(`/edit/details/characteristic/${this.state.guid}/`);
        }
        if (this.props.match.params.guid === "new" && this.props.match.params.guid !== prevProps.match.params.guid) {
            this.props.history.push(`/edit/details/view/${this.props.match.params.parentGuid}/`);
        }

        if (prevState.subModelId !== this.state.subModelId ||
            prevProps.subModels !== this.props.subModels) {
                this.setEditingStatus();
        }
    }

    loadGuidBasedData(guid, fetchVTUs = false) {
        this.setState({selectPlatformType: false});
        getNodeWithAddenda(guid, {
            coreAddenda: ["projectedCharacteristic", "measurement", "pathPairsMULTI", "platformType", "parent", "pathIsValid", "destructureLink"],
            measurementAddenda: ["measurementAxisMULTI", "measurementSystem", "addEnumerated", "addIdlStruct"],
            measurementSystemAddenda: ["measurementSystemAxisMULTI"],
            measurementAxisAddenda: ["realizationsMULTI", "valueTypeUnitMULTI"],
            measurementSystemAxisAddenda: ["defaultValueTypeUnitMULTI"],
            defaultValueTypeUnitAddenda: ["valueTypeMULTI", "childrenMULTI"],
            valueTypeUnitAddenda: ["valueTypeMULTI", "childrenMULTI"],
            valueTypeAddenda: ["childrenMULTI"],
            platformTypeAddenda: ["constraint"],
            parentAddenda: ["typers"],
            typersAddenda: ["parent", "projectedCharacteristic"],
        }).then((res) => {
            this.loadCharFromJSON(res, () => {
                this.setEditingStatus();
            });
            this.setState({selectPlatformType: false})
        });
    }

    loadParentData() {
        _ajax({
            url: "/index.php?r=/node/model-get-node/",
            method: "get",
            data: {
                guid: this.props.match.params.parentGuid,
                coreAddenda: ["typers"],
                typersAddenda: ["parent", "projectedCharacteristic"],
            },
        }).then((response) => {
            this.setState({
                parent: response,
            })
        });
    }

    setMeas = (measurement, updatePT=false) => {
        const axes = measurement.measurementAxis ? deGuidify(measurement.measurementAxis) : {};
        const platformType = (measurement.guid === this.state.platformType.realizes) ? this.state.platformType : false;
        this.setState({
            axes: axes,
            measurement: measurement,
            observable: measurement.realizes || this.state.observable,
            platformType: platformType,
            selectPlatformType: true,
            destructureLink: undefined,
            destructureReference: undefined,
        });
    };


    selectNewPlatformType = newPT => {
        this.setState({selectPlatformType: false, platformType: newPT});
    }

    handleSave = () => {
        //FIXME: flattenedNodes should be entirely unnecessary once we resolve SML not accepting mixed types.
        const { characteristic, flattenedNodes, errors } = this.generateChar();
        if(errors.length) {
            this.noteRef.error(errors);
            return Promise.resolve(errors);
        } else {
            const requestData = {
                nodes: [characteristic, ...flattenedNodes],
                returnTypes: ["platform:CharacteristicProjection"],
                changeSetId: getActiveChangeSetId(),
            };

            scrubNodeExport(requestData);

            return smmSaveNodes(requestData).then(res => {
                const response = res.data;
                Notifications2.parseResponse(response);

                const node = response.nodes?.[0];
                if (node?.guid) {
                    if (this.measRef.state.edited) {
                        this.measRef.resetMeasurementCache().then(_ => {
                            Object.values(this.state.axes).forEach(axis => window["cachedRequest"]("newVTUS").then(axis.ref.absorbVTUs));
                            if (this.props.match.params.guid === "new") {
                                this.props.history.push(`/edit/details/characteristic/${node.guid}/`);
                            }
                            this.loadGuidBasedData(node.guid);
                            this.fetchPlatformTypes();
                        });
                    } else {
                        if (this.props.match.params.guid === "new") {
                            this.props.history.push(`/edit/details/characteristic/${node.guid}/`);
                        }
                        this.loadGuidBasedData(node.guid);
                        this.fetchPlatformTypes();
                    }
                }
            });
        }
    };


    generateChar = () => {
        const isStandMS = this.state.measurement.measurementSystem && this.state.measurement.measurementSystem.xmiType === "logical:StandardMeasurementSystem";
        let errors = [];

        if (!isStandMS && !this.state.measurement.measurementAxis) {
            errors.push("Please select a measurement with at least one axis.");
        }
        let platformType = this.state.platformType;
        if (this.state.selectPlatformType) {
            platformType = {errors: "Please select a Platform Type"};
        } else if (!this.state.platformType && this.ptRef.current) {
            platformType = this.ptRef.current.generateNode();
        }

        if (platformType.errors) {
            errors = errors.concat(platformType.errors);
        }

        const characteristic = {
            guid: this.state.guid || undefined,
            parent: this.state.parent.guid || this.props.match.params.parentGuid,
            xmiType: "platform:CharacteristicProjection",
            rolename: this.state.rolename || undefined,
            descriptionExtension: this.state.descriptionExtension,
            projectedCharacteristic: this.pathRef.reportPathHead().guid,
            path: this.pathRef.state.pathPairs.map(pathPair => pathPair.guid).join(" ") || undefined,
            platformType: isPhenomGuid(platformType.guid) ? platformType : platformType?.guid,
            measurement: this.measRef.state.measurement.guid,
            optional: this.state.optional ? "true" : "false",
            lowerBound: this.state.lowerBound,
            upperBound: this.state.upperBound,
            attributeKind: this.state.attributeKind,
            destructureReference: this.state.destructureReference || undefined,
            destructureLink: this.state.destructureLink || undefined,
        };

        const flattenedNodes = [];

        if (this.measRef.state.edited) {
            const meas = {...this.state.measurement};
            delete meas["ref"];      // why is this here?

            if (findGuidPublishedStatus(meas.parent)) {
                delete meas["parent"];
            }

            const measAxes = meas.measurementAxis.map(data => {
                const measAxis = {...data};
                const vtu = {...data.valueTypeUnit};

                delete measAxis["ref"];      // why is this here?

                if (findGuidPublishedStatus(measAxis.parent)) {
                    delete measAxis["parent"];
                }

                if (findGuidPublishedStatus(vtu.parent)) {
                    delete vtu["parent"];
                }

                measAxis.valueTypeUnit = vtu;
                return measAxis;
            }) 

            characteristic.measurement = meas;
            characteristic.measurement.measurementAxis = measAxes.reduce((axes, axis) => {
                if(isPhenomGuid(axis.guid)) {
                    // the MSA is stored as a node and will attempt to be saved, this is unecessary as this page will never alter it. jsut keep the guid reference
                    axis["measurementSystemAxis"] = axis.measurementSystemAxis.guid;
                    flattenedNodes.push(axis)
                }
                axes.push(axis.guid);
                return axes;
            }, []);
        }
        return {
            characteristic,
            flattenedNodes,
            errors
        }
    }

    loadCharFromJSON(char) {
        char.optional = char.optional == "true";
        if (char.errors) {
            this.noteRef.note(char.errors);
        } else {
            this.original = cloneDeep(char);

            if (this.props.updateTemplateNode) {
                this.props.updateTemplateNode(char);
            }

            this.setState({ ...char });
            if (this.state.deprecated === "true") {this.setState({editable: false})}

            // REVIEW THIS
            // enumerationLiterals exist because of ValueType
            
            // update NavTree
            NavTree.addNodes([ char ]);
        }
    }

    handleDelete = () => {
        DeletionConfirm2.show(this.state.guid, this.state.rolename, this.resetChar);
    };

    refreshCharParent() {
        window["treeRef"].fetchData();
    }

    handleReset = () => {
      if (this.props.match.params.guid === "new") {
        return this.setState({ 
          ...cloneDeep(this.defaultChar),
          selectPlatformType: false,
        });
      }

      this.setState({ 
        ...cloneDeep(this.original),
        selectPlatformType: false
      });
    };

    changeState = e => {
      const target = e.target.id.match("rolename|descriptionExtension")[0]
        this.setState({[target]: e.target.value});
    };

    /**
     * Sets the 'destructureReference' state based on the provided destructureReference guid.
     *
     * @param {boolean} [newDestructureReference=undefined] - DestructureLink guid to set the linker state.
     * @returns {void} Updates the component's state based on the provided or current 'newDestructureReference' guid.
     *
     */
    handleChangeDestructureReference = ( newDestructureReference=undefined ) => {
        const { destructureReference } = this.state;

        // clear destructure reference if already selected
        if (destructureReference === newDestructureReference) {
            this.setState({destructureReference: undefined});
        } 
        // selecting a new destructure reference
        else {
            this.setState({destructureReference: newDestructureReference})
        }
    }

    /**
     * Sets the 'destructureLink' state based on the provided destructureLink object.
     *
     * @param {boolean} [newDestructureLinkValue=undefined] - destructureLink object to set the linker state.
     * @returns {void} Updates the component's state based on the provided or current 'newDestructureLinkValue' value.
     *
     */
    handleChangeDestructureLink = ( newDestructureLinkValue=undefined ) => {
        this.setState({destructureLink: newDestructureLinkValue});
    }

    fetchPlatformTypes = () => {
        _ajax({
            url:"/index.php?r=/node/model-nodes-of-type",
            method:"get",
            data: {
                type: ["platform:Boolean", "platform:Char", "platform:WChar", "platform:String", "platform:WString", "platform:Double",
                       "platform:LongDouble", "platform:Float", "platform:Long", "platform:LongLong", "platform:ULong", "platform:ULongLong",
                       "platform:Short", "platform:UShort", "platform:Octet", "platform:Enumeration", "platform:IDLStruct", "platform:Fixed",
                       "platform:BoundedString", "platform:CharArray", "platform:IDLArray", "platform:IDLSequence", "platform:WCharArray", "platform:BoundedWString"],
                coreAddenda: ["constraint"],
            }
        }).then((res) => {
            let platformTypes = {};
            res.data.nodes.forEach(pt => {
                let realizees = platformTypes[pt.realizes] || [];
                realizees.push(pt);
                platformTypes[pt.realizes] = realizees;
            });
            this.setState({platformTypeOptions: platformTypes});
        });
    }

    setBound = (lower,upper,lowerSelector,upperSelector) => {
        this.setState({[lowerSelector]: lower, [upperSelector]: upper});
    }

    setEditingStatus = () => {
        const { subModels={}, setParentEditingStatus } = this.props;
        const { subModelId } = this.state;
        const currSubModel = subModels[subModelId];
        this.setState({ editable: !currSubModel?.created }, () => {
            setParentEditingStatus && setParentEditingStatus(!currSubModel?.created);
        });
    };

    render() {
        const { measurement } = this.state;
        const phenomId = this.phenomId;
        const deprecated = this.state.deprecated === "true";
        let deprecatedClassName = "subview-wrapper"
        deprecatedClassName += deprecated ? " deprecated" : "";
        const canEdit = this.props.editable;
        const platformTypeOptions = (measurement.guid && this.state.platformTypeOptions[measurement.guid]) ? deGuidify(this.state.platformTypeOptions[measurement.guid]) : {};
        const isParentComposite = this.state.parent.structureKind === "composite";
        const editable = canEdit && this.state.editable && !isParentComposite;
        const mustBeIdlStructPt = measurement.realizeByIdlStruct;
        const mustBeEnumPt = measurement.realizeByEnumerated;
        const disableCreateNewButton = mustBeIdlStructPt

        return (
            <main className={deprecatedClassName}>
                {deprecated && <strong><div className="deprecated-banner">WARNING: This node has been DEPRECATED</div></strong>}
                {isParentComposite && <strong><div className="alert alert-warning">Parent is a Composite View. The current characteristic editor does not support the creation of a nested view field.</div></strong>}
                <Notifications ref={ele => this.noteRef = ele} />

                <div className="flex-h" id={phenomId.gen("details","wrapper")}>
                    <div className="flex-v" style={{flexGrow: 1}}>
                        <LineLabel text="VIEW ATTRIBUTE" idCtx={phenomId.gen("rolename","")}/>
                        <div className="flex-h">
                            <CadetInput text={this.state.rolename} idCtx={phenomId.gen("rolename")} onChange={this.changeState}
                                disabled={!editable} />
                            {this.props.nestedInView || <span style={{paddingLeft: 10, fontSize: "90%", paddingTop: 13}}>
                                in
                            <NavLink className="cadet-anchor"
                                    to={`/edit/details/view/${this.state.parent.guid || this.props.match.params.parentGuid}/`}
                                    id={phenomId.gen("rolename","view-link")}
                                    style={{
                                        padding: "0 5px 0 7px",
                                        fontSize: "100%"
                                    }}>{this.state.parent.name || this.props.match.params.parentName}</NavLink>
                            </span>}
                        </div>
                        <LineLabel text="Description" idCtx={phenomId.gen(["details","descriptionExtension"],"")}/>
                        <CadetTextArea text={this.state.descriptionExtension} idCtx={phenomId.gen("descriptionExtension")}
                            onChange={this.changeState} disabled={!editable} />
                    </div>
                    {this.props.nestedInView || <div className="edit-side-bar">
                        <NodeHistory2 ref={ele => this.historyRef = ele} guid={this.props.match.params.guid}  idCtx={phenomId.genPageId()}/>
                        <NodeDetails guid={this.props.match.params.guid}/>
                        {/* <ChangeSetPicker id={phenomId.genPageId()}
                                         disabled={!this.state.editable}
                                         label="Change Set" /> */}
                    </div>}
                </div>
                <div className="flex-h" style={{justifyContent:"flex-start"}}>
                  <div>
                    <LineLabel text="Is Optional?" idCtx={phenomId.gen("details","optional")}/>
                      <Toggle
                          disabled={!editable}
                          options={["Yes","No"]}
                          idCtx={phenomId.gen("details","")}
                          startingPosition={this.state.optional ? 0 : 1}
                          toggleFunction={() => this.setState({optional: !this.state.optional})}
                          style={{width: 200, margin: "15px 0px"}}
                      />
                  </div>
                  <div className="flex-v" style={{paddingLeft:"10px"}}>
                    <LineLabel text={"Bounds"} />
                    <BoundsPicker ele={this.state}
                                  idCtx={phenomId.gen("details","")}
                                  setBound={this.setBound}
                                  lowerSelector="lowerBound"
                                  upperSelector="upperBound"
                                  disabled={!editable}
                                  editableBounds={true}/>
                  </div>
                </div>
                <LineLabel text="Observable" idCtx={phenomId.gen("details","observable")}/>
                <Observables
                    idCtx={phenomId.gen("details")}
                    disabled={!editable}
                    obsGuid={this.state.observable}
                    setObs={(input) => this.setState({observable: input})}
                />
                <LineLabel text="Measurement" idCtx={phenomId.gen("details","measurement")}/>
                <Measurement
                    disabled={!editable}
                    idCtx={phenomId.gen("details")}
                    ref={ele => this.measRef = ele}
                    axisRefs={() => {
                        const axisObj = {};
                        Object.values(this.state.axes).forEach(axis => axisObj[axis.guid] = axis.ref);
                        return axisObj;
                    }}
                    noteRef={() => this.noteRef}
                    measGuid={measurement.guid}
                    filter={this.state.observable}
                    setMeas={this.setMeas}
                    setObsGuid={(obsGuid) => this.setState({observable: obsGuid})}
                    setShowSystems={state => this.setState({showSystems: state})}
                />
                {Object.values(this.state.axes).map((axis, key) => <Axis

                    ref={ele => {
                        if (this.state.axes[axis.guid] && ele) {
                            this.state.axes[axis.guid].ref = ele;
                        }
                    }}
                    idCtx={phenomId.gen("details")}
                    key={key}
                    disabled={!editable}
                    measurementRef={() => this.measRef}
                    axisObj={axis}
                    showPrim={false}
                    showSystems={this.state.showSystems}
                    canEdit={canEdit}
                    registerBoundPrimValChange={(bpvs) => {
//                      if (Object.keys(this.state.axes).length === 1) {
//                        this.setState({currentBoundPrimitiveValues: bpvs})
//                      }
                    }}/>
                )}

                <div style={{marginTop: 20}} id={phenomId.gen(["init","platform-type"],"wrapper")}>
                    <LineLabel text="Platform Type" style={{marginBottom: 15}} idCtx={phenomId.gen("platform-type","")}/>
                    {this.state.selectPlatformType || <Button icon="edit"
                            id={phenomId.gen("platform-type","select-button")}
                            className="k-button"
                            disabled={this.state.destructureReference || this.state.destructureLink || !editable || Object.keys(platformTypeOptions).length === 0}
                            onClick={() => this.setState({platformType: false, selectPlatformType: true})}>Select Platform Type</Button>}
                    {this.state.selectPlatformType && <select className="cadet-select"
                        id={phenomId.gen("platform-type","select")}
                        disabled={this.state.destructureReference || this.state.destructureLink || !editable}
                        onChange={(e) => this.selectNewPlatformType(platformTypeOptions[e.target.value])}>
                        <option value="new" id={phenomId.gen(["platform-type","default"],"option")}>--- Select Option ---</option>
                        {Object.entries(platformTypeOptions).map(([key, value], idx) => {
                            const depFlag = value.deprecated === "true" ? "[DEPRECATED] " : "";
                            return <option key={idx + key} id={phenomId.gen(["platform-type",idx],"option")} value={key}>{depFlag + value.name + " [" + value.xmiType + "]"}</option>
                        })}
                    </select>}
                    {(this.state.platformType || this.state.selectPlatformType) && <Button icon="add"
                            id={phenomId.gen("platform-type","add-button")}
                            className="k-button"
                            style={{marginLeft: 15}}
                            disabled={this.state.destructureReference || this.state.destructureLink || !editable || disableCreateNewButton}
                            onClick={() => this.setState({platformType: false, selectPlatformType: false})}>Create Platform Type</Button>}
                    {disableCreateNewButton && 
                        <span style={{paddingLeft: "10px"}}>
                            <span style={{ display: "inline-block", fontSize: 14, fontStyle: "italic", padding: 4 }}>
                                <span className="fa-solid fa-triangle-exclamation" />
                                To create IDLStruct Platform Types, please use the <Link className="cadet-anchor" to="/edit/details/platform_type/new">Platform Type create page.</Link></span>
                        </span>}
                    {!this.state.selectPlatformType &&
                        <div style={{marginTop: 15, padding:20, border: '2px solid #eaeaea', maxHeight: "490px", overflow: "auto"}}>
                            <PlatformTypeLite node={this.state.platformType}
                                              measurement={measurement}
                                              viewParent={this.state?.parent?.parent}
                                              destructureLink={this.state.destructureLink}
                                              destructureReference={this.state.destructureReference}
                                              editable={editable}
                                              idCtx={phenomId.gen("details")}
                                              viewGuid={this.state.parent.guid}
                                              handleChangeDestructureReference={this.handleChangeDestructureReference}
                                              handleChangeDestructureLink={this.handleChangeDestructureLink}
                                              ref={this.ptRef} />
                        </div> }
                </div>


                <Path
                    idCtx={phenomId.gen("details")}
                    disabled={!editable}
                    ref={ele => this.pathRef = ele}
                    measurement={this.state.measurement}
                    pathPairs={this.state.pathPairs}
                    pathIsValid={this.state.pathIsValid}
                    pathHead={this.state.projectedCharacteristic ? this.state.projectedCharacteristic : undefined}
                />
                {this.props.nestedInView || <NavLink to={`/edit/details/view/${this.state.parent.guid || this.props.match.params.parentGuid}/`}
                    className="bordered-button" style={{margin: 0}} id={phenomId.gen("details","back-to-parent-view-button")}>BACK TO PARENT VIEW</NavLink>}
                {this.props.nestedInView || < LoaderButton className="filled-button"
                    idCtx={phenomId.gen("details")}
                    divStyle={{height: "65px"}}
                    text="SAVE"
                    onClick={this.handleSave}
                    style={{margin: "15px 0px"}}
                />}
            </main>
        );
    }
}



export const EditCharacteristicManager = withPageLayout(CharacteristicManager);
