import React from "react";
import { PhenomLabel } from "../util/stateless";
import { isPhenomGuid } from "../util/util";

export default function NodeDetails({ guid }) {
    if (!guid || isPhenomGuid(guid) || guid === 'new') return null;

    return (
        <div className="node-history-wrapper">
            <PhenomLabel text="GUID" />
            <span><p>{guid}</p></span>
        </div>
    );
}