import React, {Component} from "react";
import {Notifications, Notifications2} from "../../edit/notifications";
import {ColorCollapsable, ImportQueryCheckList, LineLabel, PhenomCollapsable, PhenomLabel} from "../../util/stateless";
import {Upload} from "@progress/kendo-react-upload";
import ChangeSetPicker from "../../widget/ChangeSetPicker";
import PhenomLoadButton from "../../widget/LoaderButton";
import PhenomId from "../../../requests/phenom-id";
import { connect } from "react-redux";
import NavTree from "../../tree/NavTree";
import PermissionsDialog, { PermissionsDialogConfig } from '../../dialog/PermissionsDialog';
import { getActiveChangeSetId, receiveErrors } from "../../../requests/actionCreators";
import { _ajax } from "../../../requests/sml-requests";
import ReactTooltip from "react-tooltip";

class Merge extends Component {
    importRef = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            files: [],
            overwrite: false,
            checkUnique: true,
            results: null,
        };

        this.mergeModels = this.mergeModels.bind(this);
    }

    componentDidMount() {
      ReactTooltip.rebuild();
    }

    mergeModels () {
        if (!this.state.files.length) {
          return Notifications2.parseWarnings("Please select a file and try again.")
        }

        this.setState({isLoading: true, results: null});

        const newSubModel = new FormData();

        newSubModel.append("file", this.state.files[0] ? this.state.files[0].getRawFile() : null);
        newSubModel.append("overwrite", this.state.overwrite);
        newSubModel.append("checkUnique", this.state.checkUnique);
        
        if (this.importRef.current) {
          const queryData = this.importRef.current.getSerializeData();
          newSubModel.append("checks", JSON.stringify(queryData[0]));
          newSubModel.append("retained_types", JSON.stringify(queryData[1]));
        }

        if (getActiveChangeSetId()) newSubModel.append("changeSetId", getActiveChangeSetId());

        return _ajax({
            url: `/index.php?r=/sub-model/naive-merge`,
            method: "POST",
            data: newSubModel,
            processData: false,
            contentType: false,
            // headers: {
            //     "X-CSRF-TOKEN": window["_csrf"]
            // }
        }).then(resp => {

            if (resp.errors) {
              receiveErrors(resp.errors);
            } else if (resp.data?.newModels) {
              const permsConfig = new PermissionsDialogConfig();
              permsConfig.setModelIds(resp.data.newModels);
              permsConfig.setAlreadyAdded(true);
              PermissionsDialog.show(permsConfig);
            } else if (resp.data?.edits || resp.data?.additions) {
                this.setState({
                    files: [],
                    results: resp.data,
                }, () => {
                    NavTree.reset();
                });
            }
        });
    };

    fileStateChange = event => {
        const modifiedState = event.newState;
        modifiedState[0].status = 1;

        this.setState({
            files: modifiedState
        });
    };

    render() {
        const phenomId = new PhenomId("merge",this.props.idCtx);
        const additionsTable = <table
          // id="additions"
          id={phenomId.gen(["init","additions"],"wrapper")}>
            <tbody>
            {this.state.results && this.state.results.additions.map((node, idx) => <tr key={idx}>
                <td id={phenomId.gen(["additions",idx],"node-id")}>{node.name || node.rolename || node.guid}</td>
            </tr>)}
            </tbody>
        </table>;

        const editsTable = <table
          // id="edits"
          id={phenomId.gen(["init","edits"],"wrapper")}>
            <tbody>
            {this.state.results && this.state.results.edits.map((node, idx) => <tr key={idx}>
                <td id={phenomId.gen(["edits",idx],"node-id")}>{node.name || node.rolename || node.guid}</td>
            </tr>)}
            </tbody>
        </table>;
        const cinc_works = this.props.userRole.indexOf('c') != -1;

        return (<div className="phenom-content-scrollable standard-container" style={{ padding: "0 20px"}}>
            <Notifications ref={ele => this.noticeRef = ele}/>
            <MergeHeader />

            <Upload
              idCtx={phenomId.gen("","branch-create-upload")}
              className="branch-create-upload merge-upload"
              onStatusChange={this.fileStateChange}
              multiple={false}
              files={this.state.files}
              defaultFiles={[]}
              restrictions={{
                  allowedExtensions: [".face", ".skayl"],
                  maxFileSize: 209715200}}/>

            <div className="flex-h" style={{ gap: 20 }}>
                <PhenomLoadButton onClick={this.mergeModels} disabled={cinc_works} text="Merge" idCtx={phenomId.gen("")}/>
                <div>
                  <PhenomLabel text="Overwrite" style={{ textTransform: "none" }} />
                  <input type="checkbox" checked={this.state.overwrite} onChange={(e) => {this.setState({overwrite: e.target.checked});}} id={phenomId.gen("","overwrite-input")}/>
                </div>
                <div>
                  <PhenomLabel text="Check for Name Collisions" style={{ textTransform: "none" }} />
                  <input type="checkbox" checked={this.state.checkUnique} onChange={(e) => {this.setState({checkUnique: e.target.checked});}} id={phenomId.gen("","name-collisions-input")}/>
                </div>
            </div>

            <div className="p-row" style={{marginTop: 15}}>
              <div className="p-col p-col-6 p-col-fixed">
                <ImportQueryCheckList ref={this.importRef} />
              </div>
            </div>

            {this.state.results && <>
                <label style={{marginTop: 15}}>Results: </label>
                <ColorCollapsable heading="Nodes Added:" content={additionsTable} color={"#03c09b"}
                                  vMargin={10}
                                  idCtx={phenomId.gen("","additions-table")}
                                  contentId={phenomId.gen(["init","additions"],"wrapper")}
                                  default={true}/>
                <ColorCollapsable heading="Nodes Edited:" content={editsTable} color={"#c00328"}
                                  vMargin={10}
                                  idCtx={phenomId.gen("","edits-table")}
                                  contentId={phenomId.gen(["init","edits"],"wrapper")}
                                  default={true}/>
            </>}
        </div>);
    }
}

const MergeHeader = () => {
  return <div>
    <h3>Merge External Model</h3>
    <p>
      A merge external model will inject nodes from a provided model file into the current project unless the given node's id is already represented in the current project. Nodes which are unique to the source content and would normally be injected as part of the merge but which do not have a valid parent in the destination project will also be excluded from the merge. If Overwrite is enabled, nodes that are present in both the source and the destination project but are different in the destination project will be edited to resemble the ones in the source model.
    </p>
    <p>
      Any new nodes created during the process will be added to the model indicated on the right side of the page.
    </p>
    <p>
      If Overwrite is enabled, any nodes to be overwritten that are in a published model will be skipped.
    </p>
  </div>
}


const msp = (state) => ({
  userRole: state.user.userRole,
})

export default connect(msp)(Merge)