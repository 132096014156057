import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from './store/store';
import App from "./App";
import { getCriticalDetails } from "./requests/actionThunks";
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react'

// import "jstree";
import "./css/fontawesome/all.css";
import "./css/fontawesome/custom-icons.css";
import "./css/style.css";

getCriticalDetails().then((details) => {
  let instance = createInstance({
    urlBase: 'https://statistics.phenomportal.com/',
    siteId: details.siteId,
    // userId: 'randomId', // optional, default value: `undefined`.
    disabled: !(details.siteId === 2 || details.siteId === 3), // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: { // optional, enabled by default
      active: true, // optional, default value: true
      seconds: 10 // optional, default value: `15
    },
    linkTracking: false, // optional, default value: true
    configurations: { // optional, default value: {}
      // any valid matomo configuration, all below are optional
      disableCookies: true,
      setSecureCookie: true,
      setRequestMethod: 'POST'
    }
  })
  ReactDOM.render(
    <Provider store={store}>
      <MatomoProvider value={instance}>
        <Router getUserConfirmation={(message, callback) => {}}>
          <App />
        </Router>
      </MatomoProvider>
    </Provider>, document.getElementById("root")
  )
})
